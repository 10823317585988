/* global moment, stateAbbreviationToName, toTitleCase, toWords, isVowel, toRoman, fromRoman, isLowercaseLetter,
isUppercaseLetter, lettersToNumber, numberToLettersIncrement, pad, rangy, _, html2pdf */

// typedef for documentation
/**
 * LeaseField: A string representing a field in the lease, e.g. "lease.tenantInfo.name".
 *
 * @typedef { round } LeaseField
 */

import DOMPurify from 'dompurify';
import { Decimal } from 'decimal.js';
import { round } from 'mathjs';

window.isDebug = document.location.search.indexOf('debug') !== -1;

angular.module('LeasePilot').controller('LeaseEditorController', [
  '$timeout',
  '$scope',
  '$compile',
  '$parse',
  '$filter',
  '$rootScope',
  '$templateCache',
  '$templateRequest',
  '$cookies',
  '$mdDialog',
  '$window',
  '$http',
  '$q',
  'DocumentService',
  'BuildingService',
  'CompanyService',
  'FreeTextService',
  'AllOverrideService',
  'LeaseEditorService',
  'ProcessStatusService',
  'AttachmentService',
  'RolesService',
  '$mdToast',
  'FreeTextsBulkService',
  'VersionControlService',
  'DownloadService',
  'CleanFreeTextService',
  'ApiService',
  'LeaseVarService',
  'LabelsService',
  function(
    $timeout,
    $scope,
    $compile,
    $parse,
    $filter,
    $rootScope,
    $templateCache,
    $templateRequest,
    $cookies,
    $mdDialog,
    $window,
    $http,
    $q,
    DocumentService,
    BuildingService,
    CompanyService,
    FreeTextService,
    AllOverrideService,
    LeaseEditorService,
    ProcessStatusService,
    AttachmentService,
    RolesService,
    $mdToast,
    FreeTextsBulkService,
    VersionControlService,
    DownloadService,
    CleanFreeTextService,
    ApiService,
    LeaseVarService,
    LabelsService,
  ) {
    $rootScope.forms = window.forms;
    $rootScope.buildings = window.buildings;
    
    window.sources = {
      forms: window.forms,
      buildings: window.buildings,
    }
    
    if ($window.lease) {
      $scope.lease = $window.lease;

      if ($scope.lease.building) {
        $scope.building = $scope.lease.building;
        $scope.building.templateUrl ||= "blank.html";
        $scope.building.specificUrl ||= $scope.lease.company.name.toLowerCase() + "/" + $scope.building.templateUrl.toLowerCase();
        $scope.building.displayName ||= $scope.building.name;
        $scope.building.dashboardName ||= $scope.building.displayName;
      }
    } else {
      $scope.lease = null;
    }

    window.scopeVar = $scope;
    
    $scope.isAdmin = window.isAdmin;
    $scope.$filter = $filter;
    $scope.Math = window.Math;
    $scope.moment = moment;
    $scope.parentLeaseObject = null;
    $scope.building = null;
    $scope.selectedItem = {
      name: '',
    };
    $scope.sectionNumberMap = {
      article21: {},
    };
    $scope.btns = {
      bold: {
        label: 'B',
        state: false,
        tooltip: 'Bold',
        command: 'bold',
      },
      italic: {
        label: 'I',
        state: false,
        tooltip: 'Italic',
        command: 'italic',
      },
      underline: {
        label: 'U',
        state: false,
        tooltip: 'Underline',
        command: 'underline',
      },
    };
    // TODO - remove when old design is not relevant anymore
    $scope.datePickerOptions = {
      datepickerMode: 'month',
      minMode: 'month',
      showWeeks: 'false',
    };

    $scope.showConceptEditWarning = false;
    $scope.editingWithConceptTitle = false;
    $scope.showEmptyConceptTitle = false;
    $scope.leftPane = 'deal-terms';

    $scope.toggleLeftPane = (pane) => {
      if (pane === $scope.leftPane) {
        $scope.leftPane = null;
      } else {
        if (pane === 'clausebook') {
          const clausebook = document.querySelector('#clausebook');

          if  (clausebook.getAttribute("data-src")) {
            clausebook.setAttribute("src", clausebook.getAttribute("data-src"));
            clausebook.removeAttribute("data-src");
          }
        } else if (pane === 'autopilot') {
          const autopilot = document.querySelector('#autopilot');

          if  (autopilot.getAttribute("data-src")) {
            autopilot.setAttribute("src", autopilot.getAttribute("data-src"));
            autopilot.removeAttribute("data-src");
          }

          autopilotManager.updateDocumentContext();
        }

        $scope.leftPane = pane;
      }
    }

    function getConceptDisplayTitle(conceptTitleElement){
      let name = conceptTitleElement.getAttribute('name');

      if (!name || name.length ===0) {
        const concept = conceptTitleElement.closest('concept');
        name = concept.getAttribute('var');
      }

      if(name && name.length !== 0 && name.indexOf('.') !== -1 ){
        name = name.split('.')[1];
      }

      if(name){
        name = _.startCase(name);
        return `The marked text is part of the "${name}" abstract field`;
      }
      else{
        return "";
      }
    }

    $scope.toggleShowAbstractedFields = function() {
      $scope.showEmptyConceptTitle = !$scope.showEmptyConceptTitle;

      if ($scope.showEmptyConceptTitle) {
        window.track.event(
          new ShowConceptTitlesEvent({
            context: $rootScope.getContext()
          })
        );
      } else {
        window.track.event(
          new HideConceptTitlesEvent({
            context: $rootScope.getContext()
          })
        );
      }

      document.querySelectorAll("concept-title").forEach(el => {
        if (!$scope.showEmptyConceptTitle) {
          el.removeAttribute("title");
        } else {
          el.setAttribute("title", getConceptDisplayTitle(el));
        }
      });
    };

    $scope.currentTopAnchors = Object.defineProperty(
      $scope,
      "currentTopAnchors",
      {
        get: function() {
          return $scope._currentTopAnchors;
        },
        set: function(value) {
          if (!value.getTopAnchor) {
            return;
          }
          if($scope._currentTopAnchors && $scope._currentTopAnchors.length){
            $scope._currentTopAnchors.forEach((node)=>{
              node.classList.remove('in-edit');
              const tbl = node.closest('generate-table');
                if(tbl){
                  tbl.classList.remove('in-edit');
                }
            });
          }
          if (!LeaseEditorService.isSelecting()) {
            $scope._currentTopAnchors = [value];
          } else {
            if ($scope._currentTopAnchors) {
              if ($scope._currentTopAnchors.indexOf(value) === -1) {
                $scope._currentTopAnchors.push(value);
              }
            } else {
              $scope._currentTopAnchors = [value];
            }
          }

          if($scope._currentTopAnchors && $scope._currentTopAnchors.length){
            $scope._currentTopAnchors.forEach((node)=>{
              if(!$scope.lease.isLocked){
                node.classList.add('in-edit');
                const tbl = node.closest('generate-table');
                if(tbl){
                  tbl.classList.add('in-edit');
                }
              }
            });
          }

          if (window?.user?.company?.companyProfile?.conceptTitleProtection) {
            $scope.editingWithConceptTitle = document.querySelector('.in-edit concept-title',true) !== null;
          }
        }
      }
    );

    $scope.currentlyEditedNode = Object.defineProperty(
      $scope,
      "currentlyEditedNode",
      {
        get: function() {
          return $scope._currentlyEditedNode;
        },
        set: function(value) {
          $scope._currentlyEditedNode = value;
          if (value && value.getTopAnchor) {
            $scope.currentTopAnchors = value.getTopAnchor();
          }
        }
      }
    );

    $scope.currentlyEditedNode = null;
    $scope.lists = [];
    $scope.subdocuments = [];
    $scope.currentlyChanging = '';
    $scope.changeIsBeingMade = false;
    $scope.highlightScrollIndex = 0;
    $scope.numberOfHighlighted = 0;
    $scope.allChangesSaved = [];
    $scope.inInitialLoad = false;
    $scope.isPrerender =
      navigator.userAgent.toString().indexOf('Prerender') >= 0;
    $scope.isConnected = true;
    $scope.isControlCharacters = false;
    $scope.lastFormUpdate = null; //
    $scope.colorPalette = [
      '#fffd38',
      '#29fd2e',
      '#2dfffe',
      '#fd28fc',
      '#0b24fb',
      '#fc0d1b',
      '#020c7e',
      '#11807f',
      '#0f7f12',
      '#7f0f7e',
      '#7e0308',
      '#807f17',
      '#808080',
      '#c0c0c0',
      '#000000',
    ];

    window.isLeaseIfDeprecated = false;

    $rootScope.getContext = function() {
      let context = 'normal';

      if ($scope.diffgram.on) {
        context = 'version control';
      }

      return context;
    };

    



    window.pageLayersManager = new PageLayersManager($scope, $rootScope,LeaseEditorService,$q);
    $scope.addPageBreak = window.pageLayersManager.addPageBreak;
    downloadManager.init(
      $scope,
      $window,
      ProcessStatusService,
      $rootScope,
      LeaseEditorService,
      ApiService,
      DownloadService
    );
    clipboardManager.init(
      $scope,
      $compile,
      $q,
      LeaseEditorService,
      ApiService,
      $timeout
    );

    window.isTestEnv = window.isTestEnv || window._environment === 'test';
    if (window.isTestEnv) {
      $scope.isLoading = false;
    } else {
      $scope.isLoading = true;

      setTimeout(() => {
        if ($scope.isLoading) {
          // Something went wrong, lets remove the loader
          // TODO: In the future we might want to show some message to the user
          // or manually raise an error of some sort so we'll get notified about it
          $scope.safeApply(() => {
            $scope.isLoading = false;
          });
        }
      }, 60 * 1000);
    }

    function calcLastFormUpdate() {
      const date = $rootScope.lease.form.updatedAt;
      const dateStr = moment(date).format('MM/DD/YY HH:mm:ss');
      $scope.lastFormUpdate = dateStr;
    }

    // formatted values for lease
    $scope.formattedValues = {};

    // list of free texts of the lease
    $scope.leaseFreeTexts = [];

    $rootScope.adminMode = false;

    // default provisions before replacement by loaded FreeText
    $rootScope.defaultProvisions = {};

    $scope.editingTimeout = {};

    // download process indicator
    $scope.download = false;

    $scope.editingEnabled = true;
    $scope.showRevertChanges = false;
    $scope.addedDropListener = false;
    $scope.isFirstLeaseLoading = true;
    $scope.termsIncludingFreeRent = null;
    $scope.leaseLockLoaded = false;

    // global download error message
    $scope.downloadErrorMessage =
      'Oops... something went wrong with the download process, please wait a couple of seconds and try again.';

    // the most recently used freetext value
    $scope.lastFreeText = 1000000;
    // display the dropdown or not
    $scope.toggleListItemDropdown = function(outline) {
      if (outline) {
        $scope.currentOutline = { name: outline };
        $scope.updateAddListItems();

        $scope.safeApply(() => {
          setTimeout(() => {
            $("#insert").click();
            document.querySelector("#insert-list").parentElement.classList.add("open");

            let selectedOutline = null;
            if (selectedOutline = document.querySelector(".outline.selected")) {
              selectedOutline.classList.remove("selected");
            }
          });
        });
      }

      $scope.outlineToInsert = outline;
    };

    // cache for each list ID, the last used ID
    $scope.listsLastUsedIdCache = {};

    // in order to track list items and associate them with each other
    $scope.nextUnusedListItemId = 1;

    $scope.addItemDisabled = false;
    $scope.addOutlinesDisabled = false;
    $scope.changeListItemLevel = false;


    $scope.listLevels = [];

    $rootScope.lastMouseButtonClicked = undefined;

    $rootScope.tenantName = function() {
      return LeaseEditorService.getLeaseTenantName();
    };

    $rootScope.findAppScope = function() {
      return $scope;
    };

    $rootScope.getLeaseId = function() {
      return $rootScope.lease.id;
    };

    function getTermLength() {
      let totalTermLength = 0;

      if ($scope.lease.leaseYears) {
        totalTermLength += $scope.lease.leaseYears * 12;
      }
      if ($scope.lease.leaseMonths) {
        totalTermLength += $scope.lease.leaseMonths;
      }

      return totalTermLength;
    }

    function getAreaSize() {
      let areaSize = $scope.lease.premiseAreaSize;

      if ($scope.lease.premisesModType === 'expansion') {
        areaSize += $scope.lease.expansionPremiseAreaSize;
      } else if ($scope.lease.premisesModType === 'reduction') {
        areaSize = $scope.lease.reductionRemainingAreaSize;
      } else if ($scope.lease.premisesModType === 'relocation') {
        areaSize = $scope.lease.relocationPremisesAreaSize;
      }

      return areaSize;
    }

    function getFreeRentAreaSize() {
      let areaSize = $scope.lease.premiseAreaSize;

      if ($scope.lease.premisesModType === 'expansion') {
        if ($scope.lease.hasExtensionOfTerm) {
          if ($scope.lease.rentTableType === 'blended') {
            areaSize += $scope.lease.expansionPremiseAreaSize;
          } else if ($scope.lease.rentTableType === 'separate') {
            areaSize = $scope.lease.expansionPremiseAreaSize;
          }
        } else {
          areaSize = $scope.lease.expansionPremiseAreaSize;
        }
      } else if ($scope.lease.premisesModType === 'reduction') {
        areaSize = $scope.lease.reductionRemainingAreaSize;
      } else if ($scope.lease.premisesModType === 'relocation') {
        areaSize = $scope.lease.relocationPremisesAreaSize;
      }

      return areaSize;
    }


    $rootScope.getAmendmentId = function() {
      return $rootScope.lease && $rootScope.lease.type === 'Amendment'
        ? $rootScope.getLeaseId()
        : null;
    };

    $scope.goToSMLView = function(){
      leaseIfManager.toggleShowAllView();
    }

    $scope.downloadAbstract = async function(doc) {
      $scope.safeApply(() => {
        $scope.processing = true;
        ProcessStatusService.start("downloadAbstract");
      });

      const documentId = $scope.lease.id;
      const instructions = $scope.lease.form.customAbstractInstructions || "";
      const format = $scope.lease.form.customAbstractFormat || "";
      const documentType = $scope.lease.type.toLowerCase();
      const documentFlavor = $scope.lease.form.flavor.toLowerCase();

      const abstractResponse = await ApiService.abstract({ 
        doc, 
        instructions,
        format, 
        documentType, 
        documentFlavor
      });

      if ($scope.lease.form.abstractFormId) {
        const content = abstractResponse.data?.choices[0].message?.content.replace("```json", "").replace("```", "");
        window.abstractData = JSON.parse(content);

        ApiService.saveAbstract({
          documentId,
          json: content,
        });

        const iframe = angular.element('<iframe/>', {
          id: 'download-abstract',
          name: 'download-abstract',
          style:
            'position: absolute; top: 0; left: 0; width: 1px; height: 1px; z-index: -1; opacity: 0;',
          'event-name': "downloadAbstract",
        });

        angular
          .element('iframe[event-name="downloadAbstract"]')
          .remove();

        const iframeSrc = '/abstracts/' + $scope.lease.id;
        iframe.attr('src', iframeSrc);
        angular.element('body').append(iframe);
  
        $scope.$on("downloadAbstract", function(event, data) {
          console.log(new Date().getTime() + ' on event: ', "downloadAbstract");
          console.log(new Date().getTime() + ' data ', data);
          ApiService.htmlToWord(data.file, window.ASSETS_DESIRED_STYLES).then(
            function successCallback(response) {
              $scope.safeApply(() => {
                $scope.processing = false;
                ProcessStatusService.end('downloadAbstract');
              });
    
              const fileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              const fileName = `${LeaseEditorService.getFileName().replace(".docx", "")} - Abstract.docx`;

              ApiService.saveAbstract({
                documentId,
                docx: new File([response.data], fileName, {
                  type: fileType
                })
              });

              DownloadService.download(response.data, fileType, fileName);
            },
            function errorCallback(response) {
              $scope.safeApply(() => {
                $scope.processing = false;
                ProcessStatusService.end('downloadAbstract');
              });
              console.log(response);
            }
          );
        });
      } else {
        const converter = new showdown.Converter();
        const container = document.createElement("div");
        const paragraphs = abstractResponse.data?.choices[0].message?.content?.split("\n");
        paragraphs.forEach((paragraph) => {
          if (paragraph) {
            container.innerHTML += converter.makeHtml(paragraph.trim());
          } else {
            container.innerHTML += `<p>&nbsp</p>`;
          }
        });
        const html = container.outerHTML;
        const file = new Blob([html], { type: "text/html" });
  
        ApiService.htmlToWord(file, window.ASSETS_DESIRED_STYLES)
          .then(function successCallback(response) {
            $scope.safeApply(() => {
              $scope.processing = false;
              ProcessStatusService.end('downloadAbstract');
            });
  
            const fileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            const fileName = `${LeaseEditorService.getFileName().replace(".docx", "")} - Abstract.docx`;
            DownloadService.download(response.data, fileType, fileName);
          },
          function errorCallback(response) {
            $scope.safeApply(() => {
              $scope.processing = false;
              ProcessStatusService.end('downloadAbstract');
            });
            console.log(response);
          },
        );
      }
    }

    $scope.exportLease = function() {
      var clonedLease = _.cloneDeep($scope.lease);
      clonedLease.freeTexts = $scope.leaseFreeTexts;
      var type = 'application/json';
      DownloadService.download(JSON.stringify(clonedLease), type, 'exported_lease_' + $scope.lease.id);
    };

    $scope.duplicate = function() {
      const leaseId = $scope.lease.id;

      $http({
        method: 'post',
        url: `/api/leases/${leaseId}/duplicate`,
        headers: {
          'Content-Type': undefined,
        },
      }).then(function(response) {
        const doc = response.data;
        delete doc.id;

        var document = new DocumentService(doc);
        document.create().then(function (doc) {
          window.location.href = "/";
        });
      });
    };

    $scope.openCreateNewDocumentDialog = function() {
      const dealTerms = $scope.lease;
      const companyId = window.user.company.id;

      $mdDialog.show({
        controller:
          ('DialogController', ['$scope', '$mdDialog', DialogController]),
        template:
          `<md-dialog><new-document-modal deal-terms="${dealTerms}" company-id="${companyId}"></new-document-modal></md-dialog>`,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: false,
      });

      function DialogController($scope, $mdDialog) {
        $scope.cancel = function() {
          $mdDialog.cancel();
        };
      }
    };

    $scope.openTinyMceDialog = function(sectionId) {
      $mdDialog.show({
        template:
          "<md-dialog><tiny-mce-editor section-id='" +
          sectionId +
          "'/></md-dialog>",
        parent: angular.element(document.body),
        fullscreen: false,
      });

      window.track.event(new OpenTheAdvancedEditEvent({
        context: $rootScope.getContext(),
      }));
    };

    $scope.showCreateVersionDialog = function(event) {
      $mdDialog.show({
        template:
          '<md-dialog><new-version-modal></new-version-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
      });
    };

    $scope.showCompareDialog = function(event) {
      $mdDialog.show({
        template: '<md-dialog><compare-modal></compare-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: false,
        fullscreen: false,
      });
    };

    $scope.showAbstractDialog = function(event) {
      $mdDialog.show({
        template: '<md-dialog><abstract-modal></abstract-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: false,
        fullscreen: false,
      });
    };

    $scope.showImportChangesDialog = function(event) {
      let template;

      if (!$scope.lease.form.isVersionControlSupported) {
        template = '<import-changes-not-supported-modal></import-changes-not-supported-modal>';
      } else {
        template = '<import-changes-modal></import-changes-modal>';
      }

      $mdDialog.show({
        template: `<md-dialog>${template}</md-dialog>`,
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: false,
        fullscreen: false,
      });
    };

    $scope.showExpressAbstractDialog = function(event) {
      let template = '<express-abstract-modal></express-abstract-modal>';

      $mdDialog.show({
        template: `<md-dialog class="md-dialog--express-abstract">${template}</md-dialog>`,
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: false,
        fullscreen: false,
      });
    }

    $scope.showVersionControlUnsupportedChanges = function(event) {
      $mdDialog.show({
        template: `<md-dialog><version-control-unsupported-modal count="${$scope.diffgram.unsupported.length}"></version-control-unsupported-modal></md-dialog>`,
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: false,
        fullscreen: false,
      });
    };

    $scope.collapseAllCards = function() {
      $('.collapse-card.active .collapse-card__heading').each(function() {
        $(this).trigger('click');
      });
    };

    $scope.isCardActive = false;

    $(document).on('click', '.collapse-card__heading', function() {
      $timeout(function() {
        $scope.isCardActive = $('.collapse-card.active').length > 0;
        $scope.$broadcast('cardTriggered');
      });
    });

    /* --------------------------------------------------------------------------------------------------------------
       -- Selection / backspace functionality ----------------------------------------------------------------------- */

    // TODO: remove once bolding works on big selections
    $rootScope.decorationButtonsDisabled = true;
    $scope.disableDecorationButtons = function(yesDisable) {
      if (yesDisable !== $rootScope.decorationButtonsDisabled) {
        $rootScope.decorationButtonsDisabled = yesDisable;
        // set manually to make sure changes apply even if scope doesn't update
        if (yesDisable) {
          $rootScope.decorationButtonsDisabled = true;
          $('.deco-button').attr('disabled', true);
          $('.deco-button').addClass('disabled');
        } else if ($scope.editingEnabled) {
          $rootScope.decorationButtonsDisabled = false;
          $('.deco-button').attr('disabled', false);
          $('.deco-button').removeClass('disabled');
        }
      }
      $scope.disableAddPageBreak();
      $scope.disableAddImage();
    };

    $scope.disablePageBreak = true;
    $scope.disableAddPageBreak = function() {
      const sel = rangy.getSelection();

      const contentEditable = LeaseEditorService.findClosestContentEditable(sel.focusNode);
      const container = LeaseEditorService.findClosestContainer(contentEditable);
      const allContentEditableInContainer = $(container).find('.editable');
      const contentEditableIndex = allContentEditableInContainer.index(contentEditable);

      // We don't support creating new-paragraphs where there's logic after
      // the location of the caret (logic = there are other free-text in the same container)
      if (
        LeaseEditorService.isSelecting() ||
        contentEditableIndex < allContentEditableInContainer.length - 1
      ) {
        $scope.disablePageBreak = true;
      } else {
        $scope.disablePageBreak = false;
      }
    }


    $scope.disableImageButton = true;
    $scope.disableAddImage = function() {
      const sel = rangy.getSelection();

      const contentEditable = LeaseEditorService.findClosestContentEditable(sel.focusNode);
      const container = LeaseEditorService.findClosestContainer(contentEditable);
      const allContentEditableInContainer = LeaseEditorService.findAllContentEditable(container);
      const contentEditableIndex = allContentEditableInContainer.index(contentEditable);

      // We don't support creating new-paragraphs where there's logic after
      // the location of the caret (logic = there are other free-text in the same container)
      if (
        LeaseEditorService.isSelecting() ||
        contentEditableIndex < allContentEditableInContainer.length - 1
      ) {
        $scope.disableImageButton = true;
      } else {
        $scope.disableImageButton = false;
      }
    }



    /* ========================================================================== */

    $scope.calculateDistanceY = function(rect, y) {
      return Math.abs(y - (rect.top + rect.height / 2));
    };

    $scope.checkSameLine = function(elements, mouseX, mouseY) {
      var result = {
        distance: undefined,
        element: undefined,
        rect: undefined,
        direction: undefined,
        isSameLine: false,
      };

      // Find the first block ancestor element
      var CEdit = elements;

      for (var i = 0; i < CEdit.length; i++) {
        var paragraphs = $(CEdit).find('p');
        var rects = CEdit[i].getClientRects();

        // In some rare scenarios, paragraphs can be found inside content-editable
        // (usually, those are coming from building variables)
        // but the containing span doesn't have any size, that's why we're calculating
        // their rects too
        for (
          var paragraphIndex = 0;
          paragraphIndex < paragraphs.length;
          paragraphIndex++
        ) {
          rects = _.union(rects, paragraphs[paragraphIndex].getClientRects());
        }

        for (var rectIndex = 0; rectIndex < rects.length; rectIndex++) {
          var rect = rects[rectIndex];
          var threshold = 0;

          if (rect.width === 0) {
            continue;
          }

          if (
            rect.top - threshold < mouseY &&
            mouseY < rect.bottom + threshold
          ) {
            var minDistance;
            var direction;

            var left = Math.sqrt(
              Math.pow(mouseX - rect.left, 2) +
                Math.pow(mouseY - (rect.top + rect.height / 2), 2),
            );
            var right = Math.sqrt(
              Math.pow(mouseX - rect.right, 2) +
                Math.pow(mouseY - (rect.top + rect.height / 2), 2),
            );

            if (minDistance === undefined || left < minDistance) {
              minDistance = left;
              direction = 'start';
            }
            if (minDistance === undefined || right < minDistance) {
              minDistance = right;
              direction = 'end';
            }

            if (
              result.distance === undefined ||
              minDistance < result.distance
            ) {
              result.distance = minDistance;
              result.element = CEdit[i];
              result.rect = rect;
              result.direction = direction;
              result.isSameLine = true;
            }
          }
        }
      }

      return result;
    };

    // This function is being used from `placeCaretOnClosestChar`
    // It splits the HTML into two parts while making sure we're not breaking the HTML
    // (splitting by text alone can break the HTML)
    $scope.splitHTML = function(element) {
      var html = $(element).html();
      var text = $(element).text();
      var parts = $(element)[0].childNodes;

      if (parts.length > 1) {
        // First lets split by HTML parts
        var part1 = '';
        var part2 = '';

        for (var i = 0; i < parts.length; i++) {
          if (i < parts.length / 2) {
            if (parts[i].nodeType === Node.ELEMENT_NODE) {
              // element
              part1 = part1 + parts[i].outerHTML;
            } else if (parts[i].nodeType === Node.TEXT_NODE) {
              // text
              part1 = part1 + parts[i].textContent;
            }
          } else {
            if (parts[i].nodeType === Node.ELEMENT_NODE) {
              // element
              part2 = part2 + parts[i].outerHTML;
            } else if (parts[i].nodeType === Node.TEXT_NODE) {
              // text
              part2 = part2 + parts[i].textContent;
            }
          }
        }

        part1 = "<span class='split'>" + part1 + '</span>';
        part2 = "<span class='split'>" + part2 + '</span>';
      } else {
        // When there are no more HTML parts to split, split by text
        part1 =
          "<span class='split'>" + text.substr(0, text.length / 2) + '</span>';
        part2 =
          "<span class='split'>" +
          text.substr(text.length / 2, text.length - 1) +
          '</span>';
      }

      $(element).html(part1 + part2);
    };

    $scope.placeCaretOnClosestChar = function(element, mouseX, mouseY) {
      let originalHTML = $(element).html();
      let anchor = element;
      let iterator = element;
      let depth = 50; // If we reach this depth, we halt!
      let result;
      let direction;

      do {
        $scope.splitHTML(iterator);
        depth--;

        if (depth === 0) {
          // Change it all back!
          $(anchor).html(originalHTML);
          return;
        }

        let found;
        let nodes = iterator.childNodes;
        let minDistance;
        let subset = [];

        // First, let's find all those in the same line
        for (
          let elementIndex = 0;
          elementIndex < nodes.length;
          elementIndex++
        ) {
          // TODO: we can skip `span`s that contain other `span`s
          let currElement = nodes[elementIndex];
          let rects = currElement.getClientRects();

          for (let rectIndex = 0; rectIndex < rects.length; rectIndex++) {
            let r = rects[rectIndex];
            let d = $scope.calculateDistanceY(r, mouseY);

            if ((minDistance === undefined || d <= minDistance) && currElement.textContent.length > 0) {
              if (d < minDistance) {
                subset = [];
              }

              minDistance = d;
              subset.push({
                element: currElement,
                rect: r,
              });
            }
          }
        }

        // Now, let's narrow it down to one node element, the closest on the x axis
        for (let subsetIndex = 0; subsetIndex < subset.length; subsetIndex++) {
          let s = subset[subsetIndex];

          if (
            (s.rect.x <= mouseX && mouseX <= s.rect.x + s.rect.width) ||
            ((s.rect.x + (s.rect.width / 2) >= mouseX ||
              subsetIndex + 1 == subset.length))
          ) {
            if (!found) {
              let left = Math.abs(window.reservedCaretPosition - s.rect.left);
              let right = Math.abs(window.reservedCaretPosition - s.rect.right);
              found = s.element;
              direction = left < right ? "left" : "right";
            }
          }
        }

        // Fallback in case we didn't find anything
        if (!found) {
          found = nodes[0];
        }

        iterator = found;
        result = found;
      } while (iterator.textContent.length > 1);

      if (iterator.textContent.length <= 1) {
        // Keep a marker in the location we found
        if (direction === "left") {
          $(result).prepend('!@#$%');
        } else {
          $(result).append('!@#$%');
        }
        // Find the offset
        let text = $(anchor).text();
        let offsetAll = text.indexOf('!@#$%');
        // Change it all back!
        $(anchor).html(originalHTML);

        // Place the marker at the right place, eureka!
        let parts = $(anchor)[0].childNodes;
        let offset = offsetAll;
        for (let i = 0; i < parts.length; i++) {
          if (
            parts[i].nodeType === Node.TEXT_NODE ||
            parts[i].nodeName === 'BR'
          ) {
            if (offset > parts[i].length) {
              offset = offset - parts[i].length;
            } else {
              let range = rangy.createRange();
              range.setStart(parts[i], offset);
              range.collapse(true);
              let sel = rangy.getSelection();
              sel.removeAllRanges();
              sel.addRange(range);
              sel.focusNode.parentElement.scrollIntoViewIfNeeded();
              break;
            }
          }
        }
      } else {
        // Change it all back!
        $(anchor).html(originalHTML);
      }
    };

    // This function retrieves list of the direct parents of all text nodes in a given element
    var getEditableTextNodesIn = function(el) {
      return $(el)
        .find2('*')
        .contents()
        .filter(function() {
          return (
            (this.nodeType == Node.TEXT_NODE || this.nodeName === 'BR') &&
            $(this.parentElement).attr('contenteditable') !== 'false'
          );
        })
        .map(function() {
          if (this.parentElement.childNodes.length > 1) {
            $(this).wrap('<span></span>');
          }
          return this.parentElement;
        });
    };

    $scope.getCaretContainingElement = function(element, rect, mouseX, mouseY) {
      var found;

      var minDistance;
      var subset = [];

      var nodes = getEditableTextNodesIn(element);

      // First, let's find all those in the same line
      for (var elementIndex = 0; elementIndex < nodes.length; elementIndex++) {
        var currElement = nodes[elementIndex];

        // Skip the markers
        if (
          $(currElement).hasClass('SOCE') ||
          $(currElement).hasClass('EOCE')
        ) {
          continue;
        }

        var rects = currElement.getClientRects();

        for (var rectIndex = 0; rectIndex < rects.length; rectIndex++) {
          var r = rects[rectIndex];
          var d = $scope.calculateDistanceY(r, mouseY);

          if ((minDistance === undefined || d <= minDistance) && currElement.textContent.length > 0) {
            if (d < minDistance) {
              subset = [];
            }

            minDistance = d;
            subset.push({
              element: currElement,
              rect: r,
            });
          }
        }
      }

      // Now, let's narrow it down to one node element, the closest on the x axis
      for (var subsetIndex = 0; subsetIndex < subset.length; subsetIndex++) {
        var s = subset[subsetIndex];

        if (
          (s.rect.x <= mouseX && mouseX <= s.rect.x + s.rect.width) ||
          ((s.rect.x + s.rect.width >= mouseX ||
            subsetIndex + 1 == subset.length) &&
            !found)
        ) {
          found = s.element;
        }
      }

      // Fallback in case we didn't find anything
      if (!found) {
        found = nodes[0];
      }

      return found;
    };

    $scope.getCaretNewPlacement = function(elements, mouseX, mouseY, force) {
      // Don't proceed if there's a selection
      var sel = rangy.getSelection();
      if (LeaseEditorService.isSelecting() && LeaseEditorService.isMeaningfulSelection()) {
        return;
      }

      // If we're inside of an editable segment, halt since we're using native behavior
      if (
        $(sel.anchorNode).closest('.SOCE, .EOCE').length === 0 &&
        $(sel.anchorNode).closest('.editable').length === 1 &&
        !force
      ) {
        var closestContentEditable = LeaseEditorService.findClosestContentEditable(
          sel.anchorNode,
        );
        // ensure cursor is blinking
        sel.move('character', 0);
        $(closestContentEditable).focus();
        return;
      }

      var minDistance;
      var subset = [];
      var sameLineData = $scope.checkSameLine(elements, mouseX, mouseY);
      var result = {
        element: undefined,
        rect: undefined,
        direction: undefined,
      };

      // First, check if the cursor is in-line
      if (sameLineData.isSameLine && !force) {
        result = sameLineData;
      } else {
        // First, let's find all those in the same line
        for (
          var elementIndex = 0;
          elementIndex < elements.length;
          elementIndex++
        ) {
          // TODO: we can skip `span`s that contain other `span`s
          var currElement = elements[elementIndex];
          var rects = currElement.getClientRects();

          for (var rectIndex = 0; rectIndex < rects.length; rectIndex++) {
            var r = rects[rectIndex];

            var d = $scope.calculateDistanceY(r, mouseY);

            if ((minDistance === undefined || d <= minDistance) && currElement.textContent.length > 0) {
              if (d < minDistance) {
                subset = [];
              }

              minDistance = d;
              subset.push({
                element: currElement,
                rect: r,
              });
            }
          }
        }

        // Now, let's narrow it down to one node element, the closest on the x axis
        for (var subsetIndex = 0; subsetIndex < subset.length; subsetIndex++) {
          var s = subset[subsetIndex];

          // Skip the markers
          if ($(s).hasClass('SOCE') || $(s).hasClass('EOCE')) {
            continue;
          }

          if (s.rect.x <= mouseX && mouseX <= s.rect.x + s.rect.width) {
            result = s;
          }
        }

        // Fallback in case we didn't find anything
        if (!result.element) {
          minDistance = undefined;

          for (
            elementIndex = 0;
            elementIndex < elements.length;
            elementIndex++
          ) {
            var element = elements[elementIndex];
            rects = element.getClientRects();

            for (rectIndex = 0; rectIndex < rects.length; rectIndex++) {
              var rect = rects[rectIndex];

              var d = $scope.calculateDistanceY(rect, mouseY);

              if ((minDistance === undefined || d <= minDistance) && element.textContent.length > 0) {
                minDistance = d;
                result.element = element;
                result.rect = rect;
              }
            }
          }
        }
      }

      if (result.element) {
        // First, let's set the contenteditable back to `true` so it'll be editable
        result.element = LeaseEditorService.findClosestContentEditable(result.element);

        var coorX;
        var coorY =
          $('.preview').scrollTop() + result.rect.top + result.rect.height / 2;
        if (!sameLineData.isSameLine || force) {
          // verify that mouseX is within the element bounds, if not - round the mouseX to the closest bound
          if (mouseX < result.rect.left) {
            mouseX = Math.ceil(result.rect.left);
          } else if (mouseX > result.rect.right) {
            mouseX = Math.floor(result.rect.right);
          }

          // place the caret inside the rect in exact position
          // if (result.rect.left <= mouseX && mouseX <= result.rect.right) {
          coorX = mouseX;
          var containingElement = $scope.getCaretContainingElement(
            result.element,
            result.rect,
            mouseX,
            mouseY,
          );
          if (containingElement) {
            $scope.placeCaretOnClosestChar(containingElement, mouseX, mouseY);
          }
          sel = rangy.getSelection();
          var closestContentEditable = LeaseEditorService.findClosestContentEditable(
            sel.anchorNode,
          );
        }
      }
    };

    /* ========================================================================== */

    function getActionByTexts(savedText, changedText, defaultText) {
      var action;
      var oldText = LeaseEditorService.prepareForComparison(defaultText);
      var newText = LeaseEditorService.prepareForComparison(changedText);
      if (!savedText || savedText.type === 'override') {
        action = 'create';
      } else if (oldText === newText) {
        action = 'delete';
      } else {
        action = 'update';
      }
      return action;
    }

    function getReverseAction(action) {
      var actionPairs = {
        create: 'delete',
        delete: 'create',
        update: 'update',
      };
      return actionPairs[action];
    }

    function createBulk(contentEditableList) {
      var contentEditableListData = [];
      var contentEditableListUndoData = [];
      contentEditableList.forEach(function(value) {
        var sectionId = $(value).attr('section-id');

        if (!sectionId) {
          return;
        }

        var changedProvision = $(value).html();
        var prevText = $scope.leaseFreeTexts[sectionId]
          ? $scope.leaseFreeTexts[sectionId].text
          : null;
        var action = getActionByTexts(
          $scope.leaseFreeTexts[sectionId],
          changedProvision,
          ($rootScope.defaultProvisions[sectionId] ?
             $rootScope.defaultProvisions[sectionId].html :
             ''),
        );

        contentEditableListData.push({
          sectionId: sectionId,
          leaseId: $rootScope.getLeaseId(),
          amendmentId: $rootScope.getAmendmentId(),
          text: changedProvision,
          action: action,
        });

        contentEditableListUndoData.push({
          sectionId: sectionId,
          leaseId: $rootScope.getLeaseId(),
          amendmentId: $rootScope.getAmendmentId(),
          text: prevText,
          action: getReverseAction(action),
        });
      });

      function executeBulkAction(contentEditableList, shouldRecompile) {
        var deferred = $q.defer();
        $scope.startSaving();

        var createCallback = function(newFreeTexts) {
          var promises = [];
          var list = newFreeTexts.freeTexts;
          contentEditableList.forEach(function(ft) {
            var action = ft.action;
            if (ft.action === 'create') {
              // get the resource object so the database ID can be tracked for updates
              let original = _.find(list, function(x) {
                return x.sectionId == ft.sectionId;
              });

              original.text = ft.text;
              ft = original;
            }

            const node = document.querySelector("[section-id='" + ft.sectionId + "']");

            if (node && node.parentElement) {
              var defer = $q.defer();
              promises.push(defer.promise);

              $("[section-id='" + ft.sectionId + "']").trigger('afterSave', [
                ft,
                action,
                shouldRecompile,
                defer,
              ]);
            }
          });

          $q.all(promises).then(function() {
            $scope.structureChanged();
            $scope.endSaving();
            var currSelection = $rootScope.getSelectionData();
            deferred.resolve({
              selection: currSelection,
            });
          });
        };

        if ($rootScope.adminMode) {
          createCallback({
            freeTexts: contentEditableList,
          });
        } else {
          new FreeTextsBulkService(contentEditableList)
            .create()
            .then(createCallback);
        }
        return deferred.promise;
      }

      $scope.addToHistory({
        id: 'bulk',
        desc: 'created bulk of free-texts',
        redo: function(isFirstTime) {
          return executeBulkAction(contentEditableListData, !isFirstTime);
        },
        undo: function() {
          return executeBulkAction(contentEditableListUndoData, true);
        },
      });
    }
    $scope.createBulk = createBulk;


    function getAllContentEditableInElement(element){
      return element.querySelectorAll('[contenteditable][section-id]');
    }

    function removePageBreaksInSelection() {
      let sel = rangy.getSelection();
      let range = sel.getRangeAt(0);
      let pageBreaks = range.getNodes().filter(node => {
        return (
          node.tagName &&
          node.tagName === "BR" &&
          !node.closest("[free-text]") &&
          (node.nextElementSibling &&
            node.nextElementSibling.tagName === "PAGE-BREAK")
        );
      });

      let actionsCounter = 0;
      pageBreaks.forEach(node => {
        actionsCounter++;
        pageLayersManager.deletePageBreak(null, node);
      });

      return actionsCounter;
    }

    function getTablesInSelectedFreeText() {
      let sel = rangy.getSelection();
      let range = sel.getRangeAt(0);
      let tables = [];
      range.getNodes().forEach(node => {
        let table = node.closest ? node.closest("table") : null;
        if (
          table &&
          table.closest("[free-text]") &&
          tables.indexOf(table) === -1
        ) {
          tables.push(table);
        }
      });
      return tables;
    }

    async function newDeleteSelection(      
          preventCreateBatch,
          blocksArray,
          textNodes,
          caretNodePrev,
          caretNodeNext,
          caretNode,
          isBackwards,
          isForceBackwards
    ) {

      const deferred = $q.defer();
      
      const firstBlockTextNodes = blocksArray[0].getAllEditableTextNodes();
      const lastBlockTextNodes = blocksArray.at(-1).getAllEditableTextNodes();
      let splitFirstBlock = firstBlockTextNodes[0] !== textNodes[0];
      let splitLastBlock = lastBlockTextNodes.at(-1) !== textNodes.at(-1);

      if(splitFirstBlock && !blocksArray[0].canSplit()){
        splitFirstBlock = false;
        blocksArray.splice(0,1);
      }

      if(splitLastBlock && !blocksArray.at(-1).canSplit()){
        splitLastBlock = false;
        blocksArray.splice(blocksArray.length-1,1);
      }
      

      const  containerElement = document.createElement("ins");
      containerElement.classList.add("diff-item", "diff-item--accepted");
      const firstRoundTrip = blocksArray[0].getRoundTripNode();

      const listItemObjectsArray = [];
      const newStyleArray = [];
      const restyleElements = JSON.parse(JSON.stringify($scope.lease.restyle));

      let listItemUndoTracking = {};
      let listItemObjects = [];
      

      /// save data 
      const freeTextArray = [];
      blocksArray.forEach((block)=>{
          Array.from( block.containingNode.querySelectorAll('[section-id]')).forEach((item)=>{
            freeTextArray.push(item);
          });

          const blockId = (block && block.containingNode)
            ? block.containingNode.getAttribute("pid")
            : null;

          if (blockId) {
            delete restyleElements[blockId];
          }
      });

      let currentUndoIndex = $scope.histIndex;
      let currentListItems = JSON.parse(JSON.stringify($scope.listItems));
      const currentReStyle = JSON.parse(JSON.stringify($scope.lease.restyle));
      const currentListItemUndoTracking = JSON.parse(
        JSON.stringify($scope.listItemUndoTracking)
      );
      const currentListItemObjects = JSON.parse(
        JSON.stringify($scope.listItemObjects)
      );

      if (firstRoundTrip) {
        firstRoundTrip.parentElement.insertBefore(containerElement, firstRoundTrip);
      } else {
        blocksArray[0].containingNode.parentElement.insertBefore(
          containerElement,
          blocksArray[0].containingNode
        );
      }


      if (splitFirstBlock) {
        const {
          itemObject,
          paragraphStyle
        } = blocksArray[0].cloneAndDeleteInDirection(textNodes[0], true);
        listItemObjectsArray.push(itemObject);

        if (paragraphStyle) {
          newStyleArray.push(paragraphStyle);
        }
      }

      if (splitLastBlock) {
        const { itemObject, paragraphStyle } = blocksArray
          .at(-1)
          .cloneAndDeleteInDirection(textNodes.at(-1), false);
        listItemObjectsArray.push(itemObject);

        if (paragraphStyle) {
          newStyleArray.push(paragraphStyle);
        }
      }


      function addListItemToDom(currentListItemObject,block){
        let template = listItemsManager.createListItemFromTemplate(
          currentListItemObject.level,
          block.listLevel.format,
          block.listLevel.name,
          currentListItemObject,
          true,
          false
        );
        while (template.firstElementChild) {
          let el = template.firstElementChild;
          el.remove;
          el.setAttribute(
            "inserted-list-item-id",
            $scope.nextUnusedListItemId
          );
          containerElement.appendChild(el);
        }

        listItemUndoTracking[
          `${currentListItemObject.headerFreeText}-${currentListItemObject.bodyFreeText}`
        ] = $scope.nextUnusedListItemId;
        listItemObjects[
          `${currentListItemObject.headerFreeText}-${currentListItemObject.bodyFreeText}`
        ] = currentListItemObject;
        $scope.nextUnusedListItemId++;

      }

      if(splitFirstBlock){
        addListItemToDom(listItemObjectsArray[0],blocksArray[0]);
      }

      if(splitLastBlock){
        addListItemToDom(listItemObjectsArray.at(-1),blocksArray.at(-1));
      }


      ///  --------------------------

      const redo = function(
        restyle,
        listItemUndoTracking,
        listItemObjects,
        listItemObjectsArray,
        blocksArray,
        containerElement,
        block2PID,
        $scope
      ) {
        return function() {          
          const deferred = $q.defer();

          for (
            let blockIndex = 0;
            blockIndex < blocksArray.length;
            blockIndex++
          ) {
            let orgBlock = blocksArray[blockIndex];
            if (!orgBlock.containingNode.parentElement) {
              let pid = orgBlock.containingNode.getAttribute("pid");
              let el = document.querySelector(`[pid="${pid}"]`);
              let newBlock = el ? el.getBlockData() : null;
              if (newBlock) {
                blocksArray[blockIndex] = newBlock;
              }
            }
          }

         

          $scope.listItems = $scope.listItems.concat(listItemObjectsArray);
          $scope.lease.orderedListitem = JSON.stringify($scope.listItems);

          
          Object.keys(listItemUndoTracking).forEach(key => {
            const values = key.split('-');
            const el = document.querySelector(`[free-text="${values[0]}"]`);
            const idElement  = el ? el.closest('[inserted-list-item-id]') :null;
            const newId = idElement ? idElement.getAttribute('inserted-list-item-id') : null;
            $scope.listItemUndoTracking[key] = newId || listItemUndoTracking[key];
          });

          Object.keys(listItemObjects).forEach(key => {
            $scope.listItemObjects[key] = listItemObjects[key];
          });

          blocksArray.forEach((sourceBlock)=>{
            
            let orderListItem = listItemsManager.getInsertedListItemByElement(sourceBlock.containingNode);
            if (orderListItem) {
              orderListItem.deleted = true;
              $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
            }
            
            sourceBlock.markAsDeletedElement();
          });

          $scope.lease.restyle = restyle;

          containerElement.classList.add("lp-show-element", "diff-item");
          containerElement.classList.remove("lp-hide-element");

          $scope.restyle();
          setTimeout($scope.listsRefresh);
          
          $scope.update();

          deferred.resolve();
          return deferred.promise;
        };
      };

      const undo = function(
        restyle,
        listItemUndoTracking,
        listItemObjects,
        listItemObjectsArray,
        blocksArray,
        containerElement,
        $scope,
        caretNode,
        isBackwards,
        isForceBackwards
      ) {
        return function() {
          const deferred = $q.defer();
          for (
            let blockIndex = 0;
            blockIndex < blocksArray.length;
            blockIndex++
          ) {
            let orgBlock = blocksArray[blockIndex];
            if (!orgBlock.containingNode.parentElement) {
              let pid = orgBlock.containingNode.getAttribute("pid");
              let el = document.querySelector(`[pid="${pid}"]`);
              let newBlock = el ? el.getBlockData() : null;
              if (newBlock) {
                blocksArray[blockIndex] = newBlock;
              }
            }
          }

          $scope.listItems = listItemObjectsArray;
          $scope.lease.orderedListitem = JSON.stringify($scope.listItems);

          $scope.listItemUndoTracking = listItemUndoTracking;

          Object.keys($scope.listItemUndoTracking).forEach(key => {
            const values = key.split('-');
            const el = document.querySelector(`[free-text="${values[0]}"]`);
            const idElement  = el ? el.closest('[inserted-list-item-id]') :null;
            const newId = idElement ? idElement.getAttribute('inserted-list-item-id') : null;
            $scope.listItemUndoTracking[key] = newId || listItemUndoTracking[key];
          });


          $scope.listItemObjects = listItemObjects;
          $scope.lease.restyle = restyle;

          blocksArray.forEach((sourceBlock)=>{
            let orderListItem = listItemsManager.getInsertedListItemByElement(sourceBlock.containingNode);
            if (orderListItem) {
              delete orderListItem.deleted;
              $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
            }
            sourceBlock.unMarkAsDeletedElement();
          });

          containerElement.classList.remove("lp-show-element");
          containerElement.classList.add("lp-hide-element");

          if (!isBackwards && !isForceBackwards) {
            $rootScope.placeCaretAtStart(caretNode);
          } else {
            $rootScope.placeCaretAtEnd(caretNode);
          }

          $scope.restyle();
          setTimeout($scope.listsRefresh);
          $scope.update();
          deferred.resolve();
          return deferred.promise;
        };
      };

      
      await $scope.addToHistory({
        id: "restyle split delete selection :" ,
        desc: "restyle split delete selection ",
        ignoreCaret : true,
        redo: redo(
          restyleElements,
          listItemUndoTracking,
          listItemObjects,
          listItemObjectsArray,
          blocksArray,
          containerElement,
          null,
          $scope
        ),
        undo: undo(
          currentReStyle,
          currentListItemUndoTracking,
          currentListItemObjects,
          currentListItems,
          blocksArray,
          containerElement,
          $scope,
          caretNode,
          isBackwards,
          isForceBackwards
        )
      });


      const freeTextArrayNumbers = [];
      containerElement.querySelectorAll('[free-text]').forEach((el)=>{
        freeTextArrayNumbers.push(el.getAttribute('free-text'));
      });

      
      await  document.waitForFreeTextCompile(freeTextArrayNumbers);
      

      document.querySelectorAll('.keep-visible').forEach((el)=>{
        el.classList.remove('keep-visible');
      });


      // change caret node  caretNode
      if(splitFirstBlock){      
        const block = containerElement.querySelector('.SOCE').getBlockData();
        block.containingNode.classList.add('keep-visible');
        let newPrevNode =  block.getLastTextNode();
        if(caretNodePrev == caretNode){
          caretNode = newPrevNode;
        }
        caretNodePrev = newPrevNode;
      }


      
      if(splitLastBlock){
      
        const spans = containerElement.querySelectorAll('.SOCE');
        const block = spans[spans.length-1].getBlockData();
        block.containingNode.classList.add('keep-visible');

        let newNextNode =  block.getFirstTextNode();
        if(caretNodeNext == caretNode){
          caretNode = newNextNode;
        }
        caretNodeNext = newNextNode;
      }

      
      $scope.createBulk(freeTextArray);

      
      deferred.resolve({caretNodePrev : caretNodePrev , caretNodeNext : caretNodeNext , caretNode : caretNode });
      
      return deferred.promise;

    }

    $rootScope.deleteSelection = async function(
      beforeSaveFunc,
      isKeepSegment,
      isForceBackwards,
      preventCreateBatch
    ) {
      var actionsCount = 0;
      // save current history location
      let currentUndoIndex = $scope.histIndex;

      function getCaretNode(isForward, textNodes) {
        var caretNode;
        var isCaretInNewContainer = false;

        if (isForward) {
          caretNode = textNodes[textNodes.length - 1];
          caretNode = caretNode.nextSibling;

          if (!caretNode) {
            caretNode = textNodes[textNodes.length - 1];
            do {
              caretNode = caretNode.parentElement;

              if (caretNode.nextSibling) {
                caretNode = caretNode.nextSibling;
              }
            } while (caretNode.nodeType !== 1);
          }

          if (!caretNode || $(caretNode).is('.SOCE, .EOCE')) {
            if (isKeepSegment) {
              caretNode = textNodes[textNodes.length - 1];
              caretNode = LeaseEditorService.findClosestContentEditable(caretNode);
              caretNode = $(caretNode).find('.PLACEHOLDER');
            } else {
              var contentEditable = LeaseEditorService.findClosestContentEditable(
                textNodes[textNodes.length - 1],
              );
              caretNode = LeaseEditorService.findNextContentEditable(contentEditable);
              if (caretNode && caretNode.length > 0) {
                isCaretInNewContainer = true;
                caretNode = $(caretNode)
                  .find('.PLACEHOLDER')
                  .get(0);
                caretNode = LeaseEditorService.skipEmptyNodes(caretNode, true);
                caretNode = caretNode.childNodes[0];
              }
            }
          }
        } else {
          caretNode = textNodes[0];
          caretNode = caretNode.previousSibling;

          if (!caretNode) {
            caretNode = textNodes[0];
            do {
              caretNode = caretNode.parentElement;

              if (caretNode.previousSibling) {
                caretNode = caretNode.previousSibling;
              }
            } while (caretNode.nodeType !== 1);
          }

          if (!caretNode || $(caretNode).is('.SOCE, .EOCE')) {
            if (isKeepSegment) {
              caretNode = textNodes[0];
              caretNode = LeaseEditorService.findClosestContentEditable(caretNode);
              caretNode = $(caretNode)
                .find('.PLACEHOLDER')
                .get(0);
            } else {
              var contentEditable = LeaseEditorService.findClosestContentEditable(
                textNodes[0],
              );
              caretNode = LeaseEditorService.findPreviousContentEditable(
                contentEditable,
              );
              if (caretNode && caretNode.length > 0) {
                isCaretInNewContainer = true;
                caretNode = $(caretNode)
                  .find('.EOCE')
                  .get(0);
                caretNode = LeaseEditorService.skipEmptyNodes(caretNode, false);
                caretNode =
                  caretNode.childNodes[caretNode.childNodes.length - 1];
              }
            }
          }
        }

        if (!isCaretInNewContainer && caretNode) {
          isKeepSegment = true;
        }

        const isEditable = LeaseEditorService.findClosestContentEditable(caretNode).length > 0;
        if (isKeepSegment && isEditable) {
          keepContentEditableVisible = LeaseEditorService.findClosestContentEditable(
            caretNode,
          );
          keepContainerVisible = LeaseEditorService.findClosestContainer(
            keepContentEditableVisible,
          );
          keepContainerVisible.addClass('keep-visible');
        }

        return caretNode;
      }

      function placeCaret(caretNode) {
        var contentEditable = LeaseEditorService.findClosestContentEditable(caretNode);
        LeaseEditorService.enableEditingForSegment(contentEditable);
        if (!isBackwards && !isForceBackwards) {
          $rootScope.placeCaretAtStart(caretNode);
        } else {
          $rootScope.placeCaretAtEnd(caretNode);
        }
      }

      function processContentEditable(contentEditable, caretNode, isBackwards) {
        var container = LeaseEditorService.findClosestContainer(contentEditable);
        var isNewListItem =
          $(contentEditable).closest('.inserted-list-item').length > 0;
        var isBullet = $(contentEditable).attr('bullet') === 'true';
        var isNotDeletable = $(contentEditable).attr('deletable') === 'false';

        var childNodes = $(contentEditable).get(0).childNodes;
        var childNodesLength = childNodes.length;
        for (var i = childNodesLength - 1; i >= 0; i--) {
          if (
            LeaseEditorService.isEmpty($(childNodes[i]), false, true) &&
            !LeaseEditorService.isSelfClosing(childNodes[i]) &&
            !$(childNodes[i]).is('.SOCE, .PLACEHOLDER, .EOCE') &&
            !$.contains(childNodes[i], caretNode)
          ) {
            $(childNodes[i]).remove();
          }
        }

        contentEditable = $(contentEditable);
        caretNode = $(caretNode);
        if (LeaseEditorService.isEmpty(contentEditable, true)) {
          if (
            !isKeepSegment ||
            (isKeepSegment &&
              !$.contains(contentEditable.get(0), caretNode.get(0)))
          ) {
            contentEditable.html('');

            if (isBullet) {
              contentEditable.html(
                "<li class='empty-li'>" +
                  window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE +
                "</li>"
              );
            }

            if (isNewListItem) {
              var insertedListItemId = LeaseEditorService.closestNewListItemId(
                contentEditable,
              );
              var relatedContentEditable = $(
                ".inserted-list-item[inserted-list-item-id='" +
                  insertedListItemId +
                  "']:has(.editable)",
              );
              var listItemContainer;
              var listItemContentEditable;

              for (var i = 0; i < relatedContentEditable.length; i++) {
                // caretNode could've been deleted as part of the deletion of the list-item
                // move the caret to the prev/next node, according to `isBackwards`
                if (!$.contains(relatedContentEditable.get(i), caretNode)) {
                  if (!isBackwards) {
                    listItemContainer = relatedContentEditable.last();
                    listItemContentEditable = LeaseEditorService.findAllContentEditable(
                      listItemContainer,
                    );
                    listItemContentEditable = listItemContentEditable.last();
                    listItemContentEditable = LeaseEditorService.findNextContentEditable(
                      listItemContentEditable,
                    );
                    caretNode = $(listItemContentEditable)
                      .find('.SOCE')
                      .get(0);
                    caretNode = LeaseEditorService.skipEmptyNodes(caretNode, true);
                  } else {
                    listItemContainer = relatedContentEditable.first();
                    listItemContentEditable = LeaseEditorService.findAllContentEditable(
                      listItemContainer,
                    );
                    listItemContentEditable = listItemContentEditable.first();
                    listItemContentEditable = LeaseEditorService.findPreviousContentEditable(
                      listItemContentEditable,
                    );
                    caretNode = $(listItemContentEditable)
                      .find('.EOCE')
                      .get(0);
                    caretNode = LeaseEditorService.skipEmptyNodes(caretNode, false);
                  }
                  break;
                }
              }
            }
          }

          LeaseEditorService.addMarkers(contentEditable);
        }

        caretNode = $(caretNode);
        if (LeaseEditorService.isEmpty(container, true)) {
          if (!isNotDeletable) {
            if (
              !isKeepSegment ||
              (isKeepSegment &&
                !$.contains(container.get(0), caretNode.get(0)) &&
                !container.hasClass('keep-visible'))
            ) {
              if (LeaseEditorService.shouldHideEmptyParagraph(contentEditable)) {
                LeaseEditorService.hideEmptyParagraph(
                  contentEditable,
                  container,
                );
                structureChanged = true;
              }
            }
          }
        }

        return caretNode.get(0);
      }

      function clearSelection(range, nodes) {
        
        for (var i = 0; i < nodes.length; i++) {

          if(nodes[i] && nodes[i].parentElement && nodes[i].parentElement.closest && nodes[i].parentElement.closest('deleted-element') ){
            continue;
          }
          
          if (LeaseEditorService.isSelfClosing(nodes[i])) {
            nodes[i].remove();
          } else {
            sel.removeAllRanges();
            const range = rangy.createRange();
            range.selectNode(nodes[i]);
            range.deleteContents(nodes[i]);
          }
          
        }

        return range;
      }

      actionsCount+= removePageBreaksInSelection();
      let tablesInSelection = getTablesInSelectedFreeText();
      var sel = rangy.getSelection();
      var originalRange = sel.getRangeAt(0);
      var isBackwards = sel.isBackwards();
      var allContentEditableInSelection = LeaseEditorService.findAllContentEditableInSelection();
      var range;
      var keepContentEditableVisible;
      var keepContainerVisible;
      var structureChanged = false;

      if (typeof isKeepSegment === 'undefined') {
        isKeepSegment = false;
      }

      originalRange.splitBoundaries();
      originalRange.getNodes().forEach(function (node, index) {
        node.nodeIndex = index;
      });
      var textNodes = originalRange.getNodes([3], function(node) {
        const isEditable = $(node).closest('.editable').length > 0;
        const parentNode = node.parentElement;
        const isSOCE = $(parentNode).hasClass('SOCE');
        const isEmptyPlaceholder =
          $(parentNode).hasClass('PLACEHOLDER') &&
          (node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE);
        const isEOCE = $(parentNode).hasClass('EOCE');
        const isSkip = $(parentNode).hasClass('SKIP');
        const isListPadding =
          $(parentNode).hasClass('word-indent') &&
          parentNode.style.font.indexOf('7pt') !== -1;

        return (
          isEditable &&
          !isSOCE &&
          !isEmptyPlaceholder &&
          !isEOCE &&
          !isSkip &&
          !isListPadding
        );
      });
      var elementNodes = originalRange.getNodes([1], function(node) {
        // Filter out everything that's not inside a `contenteditable`
        // We're looking only for empty elements like <br/>, <img/> etc'
        return (
          (
            $(node).closest('.editable').length > 0 &&
            node.childNodes.length === 0 &&
            node.innerText.length > 0
          ) ||
          node.tagName === "LEASE-VAR" ||
          node.tagName === "CRR" ||
          node.tagName === "BR" ||
          node.tagName === "SOFT-RETURN"
        );
      });

      if (textNodes.length === 0) {
        return;
      }

      // Take note for the place of the caret after deleting the selected text
      var caretNode;
      var nodes = _.sortBy(_.concat(textNodes, elementNodes), 'nodeIndex');
      

      var caretNodeNext = getCaretNode(true, nodes);
      var caretNodePrev = getCaretNode(false, nodes);
      var node;

      if (!!beforeSaveFunc) {
        node = document.createTextNode(
          window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE,
        );
        caretNode = $(node).insertBefore(nodes[0]);
      } else if (!isBackwards && !isForceBackwards) {
        caretNode = caretNodeNext;
      } else {
        if(caretNodePrev && caretNodePrev.nodeType === Node.ELEMENT_NODE && caretNodePrev.classList.contains('PLACEHOLDER')) {
          caretNode = caretNodeNext;
          isBackwards = !isBackwards;
          isForceBackwards = false;
        } else {
          caretNode = caretNodePrev;
        }
      }

      const blocksIdArray = [];
      const blocksArray = [];
      textNodes.forEach(tNode => {
        const parent = tNode.parentElement;
        const block = parent ? parent.getBlockData() : null;
        const blockId =
          block && block.containingNode
            ? block.containingNode.getAttribute("pid")
            : null;
        if (blockId && blocksIdArray.indexOf(blockId) === -1) {
          blocksIdArray.push(blockId);
          blocksArray.push(block);
        }
      });
     

      let hasInsertListItemsAnywhere =
        $scope.features && $scope.features.insertListItemsAnywhere
          ? $scope.features.insertListItemsAnywhere
          : false;

      hasInsertListItemsAnywhere = hasInsertListItemsAnywhere || window.isDebug;

      const blocksInTable = blocksArray.filter(block => {
        return block.containingNode && block.containingNode.closest("table");
      });

      
      if (blocksArray.length > 1 && hasInsertListItemsAnywhere && blocksInTable.length == 0) {

        
        window.allowSetDecoration = true;

        const newDeleteRes = await newDeleteSelection(
          preventCreateBatch,
          blocksArray,
          textNodes,
          caretNodePrev,
          caretNodeNext,
          caretNode,
          isBackwards,
          isForceBackwards
        );

        caretNodePrev = newDeleteRes.caretNodePrev;
        caretNodeNext = newDeleteRes.caretNodeNext;
        caretNode = newDeleteRes.caretNode;
        
        
      }
      
        // Delete the content from the selected text, going through the nodes one-by-one
      range = clearSelection(range, nodes, caretNodeNext, caretNodePrev);
      
      window.allowSetDecoration = false;

      // Note: when hiding an entire paragraph, we always move the caret to the next
      // available content-editable
      // but since delete/backspace generates `isBackwards=false`, if we won't intervene
      // the caret will be placed at the previous available content-editable.
      // in this scenario, we intervene to switch the direction.
      var contentEditable;
      if (isKeepSegment) {
        if (!isBackwards) {
          contentEditable = LeaseEditorService.findClosestContentEditable(
            caretNodePrev,
          );
        } else {
          contentEditable = LeaseEditorService.findClosestContentEditable(
            caretNodeNext,
          );
        }
        if (
          $(caretNodePrev).closest("table").length === 0 &&
          $(caretNodeNext).closest("table").length === 0 &&
          LeaseEditorService.isEmpty(caretNodePrev, true) &&
          LeaseEditorService.isEmpty(caretNodeNext, true)
        ) {
          isBackwards = !isBackwards;
          if (!isBackwards) {
            caretNode = caretNodeNext;
            contentEditable = LeaseEditorService.findClosestContentEditable(
              caretNodePrev,
            );
          } else {
            caretNode = caretNodePrev;
            contentEditable = LeaseEditorService.findClosestContentEditable(
              caretNodeNext,
            );
          }
        }
        if ($('.keep-visible').length > 1) {
          // Now that we finally decided of the direction,
          // we can delete the second option
          $(contentEditable)
            .closest('.keep-visible')
            .removeClass('keep-visible');
          caretNode = processContentEditable(
            contentEditable,
            caretNode,
            isBackwards,
          );
        }
      } else {
        // This is used when the first/last content-editable in the document is deleted
        // so we switch the direction
        if (!caretNode) {
          caretNode = caretNodeNext || caretNodePrev;
          isBackwards = !isBackwards;
        }
      }
      $('.keep-visible').removeClass('keep-visible');

      // drop duplicates of free-texts that belong to the same list-item
      allContentEditableInSelection = _.uniqBy(allContentEditableInSelection, function(x) {
        return $(x).closest('[inserted-list-item-id]').attr('inserted-list-item-id') || x;
      });

      // After clearing the content from the selected text from nodes in the selection,
      // some segments can be empty but still contain empty spans
      // so lets clear them entirely
      _.reverse(allContentEditableInSelection).forEach(function(element) {
        if (contentEditable) {
          $(contentEditable).trigger('lp:contenteditable:change');
        }

        // we don't want to call `processContentEditable` twice, (maybe we did it already for `isKeepSegment`)
        if (
          contentEditable && contentEditable[0] === element[0] || 
          $(element).closest('.deleted-element').get(0)
        ) {
          return;
        }

        caretNode = processContentEditable(element, caretNode, isBackwards);
      });

      // Place the caret in the right place
      if (caretNode instanceof jQuery) {
        caretNode = caretNode.get(0);
      }
      placeCaret(caretNode);

      if (beforeSaveFunc) {
        beforeSaveFunc();
      }

      tablesInSelection.forEach((table) => {
        if(table.textContent === "") {
          table.remove();
        }
      });

      
      createBulk(allContentEditableInSelection);
      

      if (!preventCreateBatch) {
        $scope.histRegisterBatch($scope.histStack.length - 1, $scope.histIndex - currentUndoIndex);
      }

      if (structureChanged) {
        $scope.structureChanged();
      }
    };

    function isCaretInAnnotationArea() {
      var sel = rangy.getSelection();
      var focusNode = sel.focusNode;
      if (!focusNode) {
        return false;
      }
      var diffArea = focusNode.parentElement.closest('.highlight');
      var caretInAnnotationArea = !!(diffArea);
      return caretInAnnotationArea;
    }

    function currentAnnotationHasDeletedMarkers() {
      var diffList = document.querySelectorAll('.annotation.annotation--current [diff-index]');
      var originalLease = document.querySelector('.lease.lease--original');
      var markersChanged = false;
      diffList.forEach(function(diff) {
        var index = diff.getAttribute('diff-index');
        if (
          !$scope.diffgram.items[index]._isAffectingLeaseVar &&
          !$scope.diffgram.items[index]._isAffectingCrossReference &&
          !$scope.diffgram.items[index]._isAffectingAutoNumber
        ) {
          var originalMarker = originalLease.querySelector('[diff-index="' + index + '"]');
          if (!$scope.diffgram.items[index]._isBlocked) {
            if(!originalMarker) {
              $scope.diffgram.items[index]._hasDeletedMarkers = true;
              markersChanged = true;
            } else {
              $scope.diffgram.items[index]._hasDeletedMarkers = false;
            }
          }
        }
      });
      return markersChanged;
    }

    function initDiffgramWarnings(isFocusOnOriginalLease) {
      $timeout(function() {
        if (!$scope.diffgram.currentAnnotation)  {
          return;
        }
        $scope.caretInAnnotationArea = false;
        if (currentAnnotationHasDeletedMarkers()) {
          $scope.diffgram.currentAnnotation._hasDeletedMarkers = true;
        } else {
          $scope.diffgram.currentAnnotation._hasDeletedMarkers = false;
          if (isFocusOnOriginalLease && isCaretInAnnotationArea()) {
            $scope.caretInAnnotationArea = true;
          }
        }
      }, 0);
    }

    function openCorrespondingAnnotation() {
      var sel = rangy.getSelection();
      var focusNode = sel.focusNode;
      if (!focusNode) {
        return;
      }
      if (focusNode.nodeType === Node.TEXT_NODE) {
        focusNode = focusNode.parentElement;
      }
      var pid = focusNode.closest('[pid]') ? focusNode.closest('[pid]').getAttribute('pid') : null;
      if (pid) {
        pid = getCalculatedSectionId(focusNode, pid);
        var newAnnotation = document.querySelector(`.annotation[data-pid="${pid}"]`);
        if (newAnnotation) {
          $scope.annotationsHelper.jumpToAnnotation(pid);
        }
      }
    }

    function isActiveImage() {
      return !!(document.querySelector('img.active'));
    }

    $rootScope.isParagraph = function (focusNode) {
      if (!focusNode) {
        return false;
      }

      if (focusNode.nodeType === Node.TEXT_NODE) {
        focusNode = focusNode.parentElement;
      }

      let container = LeaseEditorService.findClosestContainer($(focusNode));

      if (container[0] && container[0].classList.contains('new-paragraph')) {
        return true;
      }

      if (focusNode.nodeType === Node.ELEMENT_NODE && focusNode.classList.contains('new-paragraph')) {
        return true;
      } else if ($rootScope.isListItem(container)) {
        return false;
      } else {
        return true;
      }
    }

    function disableIndentButtons(isDisable) {
      if (isDisable !== $scope.indentDisable) {
        $scope.indentDisable = isDisable;
        // set manually to make sure changes apply even if scope doesn't update
        $('.indent-btn').attr('disabled', isDisable);
      }
    }

    $scope.updateEmptyConceptTitleDisplay = function (){
      if (window.user?.company?.companyProfile?.conceptTitleProtection) {
        document.querySelectorAll('.empty-concept-title').forEach((concept) => {
          concept.classList.remove('empty-concept-title');
        });

        document.querySelectorAll('.in-edit concept-title').forEach((concept) => {
          if (concept.innerText.replace(/\u200B/g,'').trim().length === 0) {
            concept.classList.add('empty-concept-title');
          }
        });
      }
    }

    // bind to keydown for larger deletions (ie. when selection is made from outside of a contenteditable
    $scope.bindLeaseEvents = function() {
      // Disable B/I/U buttons when selecting big selections
      // TODO: remove once bolding works on big selections
      $(document).on('selectionchange', _.throttle(function(e) {
        if ($scope.diffgram.processing) {
          return;
        }

        $scope.updateEmptyConceptTitleDisplay();

        if ($scope.diffgram.on) {
          openCorrespondingAnnotation();
          initDiffgramWarnings(true);
        }

        if(!LeaseEditorService.isSelecting){
          return;
        }
        if (LeaseEditorService.isSelecting()) {
          var isSelectionOutsideLease =
            $(rangy.getSelection().anchorNode)
              .closest('.lease')
              .not('.lease--modified').length === 0;
          $scope.disableDecorationButtons(isSelectionOutsideLease);
        } else if (LeaseEditorService.isBlinkingCaret()) {
          $scope.disableDecorationButtons(false);

          var sel = rangy.getSelection();
          var focusNode = sel.focusNode;
          var contentEditable = LeaseEditorService.findClosestContentEditable(
            sel.anchorNode,
          );
          contentEditable = $(contentEditable).get(0);

          if (contentEditable) {
            LeaseEditorService.enableEditingForSegment(contentEditable);
            disableIndentButtons(!$rootScope.isParagraph(focusNode) && !isActiveImage());
          }
        } else {
          $scope.disableDecorationButtons(true);
          disableIndentButtons(!isActiveImage());
        }

        LeaseEditorService.skipMarkers();
        autopilotManager.updatePromptContext();
      }, 250, { trailing: true }));

      $(document).on('click', 'crr', function (e) {
        e.preventDefault();
        let id = e.currentTarget.id;
        let crb = document.querySelector(`crb#${id}`, true);
        let oldActive = document.querySelector('crb-active');
        if(oldActive) {
          oldActive.classList.remove('crb-active');
        }
        if(crb) {
          crb.classList.add('crb-active');
          crb.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      });

      $scope.handleCopy = async function(event) {
        if (LeaseEditorService.isSelecting()) {
          const sel = rangy.getSelection();
          const range = sel.getRangeAt(0);
          const commonAncestorContainer = range.commonAncestorContainer;
  
          if (commonAncestorContainer.closest(".lease")) {
            LeaseEditorService.disableEditingForSegment(sel.anchorNode);
            await LeaseEditorService.copySelection();
            window.track.event(new CopyEvent({
              context: $rootScope.getContext(),
            }));
  
            if (event) {
              event.preventDefault();
            }
          }
        }
      }

      $scope.handleCut = async function(event) {
        if (LeaseEditorService.isSelecting()) {
          const sel = rangy.getSelection();
          const range = sel.getRangeAt(0);
          const rangeCount = sel.rangeCount;
          const rangeToString = range.toString();
          const commonAncestorContainer = range.commonAncestorContainer;
  
          // First, let's copy the selection to the clipboard
          if (commonAncestorContainer.closest(".lease")) {
            LeaseEditorService.disableEditingForSegment(sel.anchorNode);
            await LeaseEditorService.copySelection();
            window.track.event(new CutEvent({
              context: $rootScope.getContext(),
            }));

            if (event) {
              event.preventDefault();
            }
  
            // Then, delete the selection
            if (rangeCount > 0) {
              if (rangeToString.length > 0) {
                await $rootScope.deleteSelection(null, null, true);
              }
            }
          }
        }
      }

      document.addEventListener('cut', async function(e) {
        $scope.handleCut(e);
      });

      document.addEventListener('copy', async function(event) {
        $scope.handleCopy(event);
      });

      $(document).on('selectionchange', _.throttle(function(e) {
        if ($scope.diffgram.processing) {
          return;
        }

        var sel = rangy.getSelection();
        if (!sel.anchorNode || sel.anchorNode === document) {
          return;
        }

        if (sel.anchorNode.parentElement.closest('#edit-building-language')) {
          LeaseEditorService.setContentEditableColor(window.CONSTS.ADMIN_COLOR);
          return;
        }


        if(!LeaseEditorService.isSelecting){
          return;
        }
        if (LeaseEditorService.isSelecting()) {
          let closestContentEditable;

          if (sel.isBackwards()) {
            closestContentEditable = $(
              LeaseEditorService.findClosestContentEditable(sel.focusNode),
            );
          } else {
            closestContentEditable = $(
              LeaseEditorService.findClosestContentEditable(sel.anchorNode),
            );
          }

          if ($(closestContentEditable).length > 0) {
            // There's a selection, let's disable editing to allow multi-segment selection
            LeaseEditorService.disableEditingForSegment(closestContentEditable);
          }
        }
      }, 250, { trailing: true }));

      $('.lease:not(.lease--modified) p, .lease:not(.lease--modified) h1, .lease:not(.lease--modified) h2, .lease:not(.lease--modified) h3, .lease:not(.lease--modified) h4, .lease:not(.lease--modified) h5, .lease:not(.lease--modified) h6').on('mousemove', _.throttle(function(e) {
        if ($scope.diffgram.processing) {
          return;
        }

        e.stopImmediatePropagation();
        e.stopPropagation();

        var element = $(this)
          .find('.editable')
          .first();

        if (e.which === window.ENUMS.MOUSE.LEFT_MOUSE_BUTTON) {
          LeaseEditorService.disableEditingForSegment(element);
        } else {
          LeaseEditorService.enableEditingForSegment(element);
        }
      }, 250));
    };

    $('.lease')
      .not('.lease--modified')
      .on('mouseup', function(e) {
        if ($scope.diffgram.processing) {
          return;
        }

        if (
          e && e.target && e.target.classList &&
          e.target.classList.contains('empty-concept-title')
        ) {
          $rootScope.placeCaretAtStart($(e.target));
        }

        if (LeaseEditorService.isSelecting()) {
          const sel = rangy.getSelection();
          
          if (sel.isBackwards()) {
            $scope.currentlyEditedNode = sel.anchorNode.getTopAnchor();
          } else {
            $scope.currentlyEditedNode = sel.focusNode.getTopAnchor();
          }
        }
      });

    // close all toasts when clicking outside them
    $('.lease')
      .not('.lease--modified')
      .on('click keydown', function(e) {
        $mdToast.cancel();
        var sel = rangy.getSelection();
        if (e.target.nodeName != 'IMG' && e.target.nodeName != 'BUTTON') {
          LeaseEditorService.deselectImage();
        }
      });

    /* -------------------------------------------------------------------------------------------------------------- */

    /* --------------------------------------------------------------------------------------------------------------
     -- Undo functionality ------------------------------------------------------------------------------------------ */

    $scope.histStack = [];
    $scope.histBatcher = {};
    $scope.histIgnoreClearBatch = 0;
    $scope.histIndex = -1;
    $scope.undoDisabled = true;
    $scope.redoDisabled = true;
    $scope.isSaving = false;

    // Used to prevent a recompile from adding a new entry to the undo/redo stack.
    // This is because an undo or redo itself must recompile an element in order for it to link to
    // lease fields.
    // Object is indexed on an id (free-text/sectionId)
    $scope.noHist = {};
    $scope.shouldRecompile = {}; // similar idea for compile

    // Object to map list items to their inserted-list-item-ids for looking for undo/redo
    $scope.listItemUndoTracking = {};
    $scope.listItemObjects = {};

    // Register a batch starting at `index` going back `n` history objects
    $scope.histRegisterBatch = function(index, n) {
      // register undo
      if ($scope.histBatcher[index]) {
        $scope.histBatcher[index].undo = n;
      } else {
        $scope.histBatcher[index] = {
          undo: n,
          redo: 0,
        };
      }

      // register redo
      if ($scope.histBatcher[index - n]) {
        $scope.histBatcher[index - n].redo = n;
      } else {
        $scope.histBatcher[index - n] = {
          undo: 0,
          redo: n,
        };
      }
    };

    $scope.histClearFutureBatch = function(afterIdx) {
      $scope.histBatcher = _.pickBy($scope.histBatcher, function(v, k) {
        k = parseInt(k);
        return k <= afterIdx && k + v.redo <= +afterIdx;
      });
    };

    function restoreSelection(bookmark) {
      if (!bookmark) {
        return;
      }
      // restore selection
      $timeout(() => {
        var sel = rangy.getSelection();
        sel.moveToBookmark(bookmark);

        // scroll into view
        var focusNode = sel.focusNode;

        if (focusNode) {
          if (focusNode.nodeType === Node.TEXT_NODE) {
            focusNode = focusNode.parentElement;
          }
          focusNode.scrollIntoViewIfNeeded();
          if (!LeaseEditorService.isSelecting()) {
            // ensure caret is blinking
            var node = LeaseEditorService.findClosestContentEditable(sel.focusNode)[0];
            LeaseEditorService.enableEditingForSegment(node);
            // fallback - for some reason there is no cursor, so we manually place it.
            if (node && !$(node).is(':focus')) {
              node.focus();
              $rootScope.placeCaretAtPosition(sel.focusNode, sel.focusOffset);
            }
          }
        }
      });
    }

    $rootScope.restoreCaret = function() {
      if (!isActiveImage()) {
        restoreSelection(window.savedSelection);
      }
    };

    $rootScope.getSelectionData = function() {
      var sel = rangy.getSelection();
      if (!sel.anchorNode) return;
      // find the closest container that is common to the anchorNode and the focusNode
      var editedContainer = sel.getRangeAt(0).commonAncestorContainer;
      editedContainer =
        LeaseEditorService.findClosestContentEditable(editedContainer)[0] ||
        editedContainer;

      if (LeaseEditorService.isNewListItem(editedContainer)) {
        editedContainer = LeaseEditorService.findClosestContainer(
          $(editedContainer).closest('.inserted-list-item'),
        )[0];
      }

      return sel.getBookmark(editedContainer);
    };

    $rootScope.saveSelection = function(changeMade, bookmark) {
      // if we are in the middle of continous action - no need to save the selection again
      if (changeMade && window.savedSelection && window.prevActionIsEdit) {
        return;
      }

      var sel = rangy.getSelection();
      var contentEditable = LeaseEditorService.findClosestContentEditable($(sel.anchorNode).get(0));
      if (!bookmark && !contentEditable) {
        return;
      }
      window.prevActionIsEdit = changeMade;
      window.savedSelection = bookmark || $rootScope.getSelectionData();
    };

    /* action is an object with:
        batch <bool> - if true, redo/undo will return the number of steps in their direction to batch (0 = ignore)
                       you only need to add UNDO batchers, redo ones will automatically be inserted after undo
        redo()
        undo()
        desc <string>
     */
    $scope.addToHistory = function(action) {
      action.prevSelection = window.savedSelection;
      // empty selection obj for the next actions (to indicate that the action completed)
      window.savedSelection = null;

      $scope.shouldRecompile[action.id] = false;
      action
        .redo(true)
        .then(function(actionData) {
          $scope.updateUndoRedoStatus();

          if (actionData && actionData['selection']) {
            action.currSelection = actionData.selection;
          }
        })
        .catch(ex => {
          console.log(ex);
        });

      // remove actions past current point
      $scope.histStack = $scope.histStack.slice(0, $scope.histIndex + 1);
      if ($scope.histIgnoreClearBatch > 0) {
        $scope.histIgnoreClearBatch--;
      } else {
        $scope.histClearFutureBatch($scope.histIndex);
      }

      // add the current action to the stack
      $scope.histStack.push(action);

      $scope.histIndex = $scope.histStack.length - 1;

      // batcher: copy `prevSelection` to the first action in the batch.
      var batchInfo = $scope.histBatcher[$scope.histIndex];
      if (batchInfo && batchInfo.undo) {
        $scope.histStack[$scope.histIndex - batchInfo.undo + 1].prevSelection =
          action.prevSelection;
      }

      return $scope.histIndex;
    };



    $scope.normalizeListItemArray = function(){
      const updatedArray = [];
      
      $scope.listItems.forEach((item)=>{
        
        const items =  updatedArray.filter((obj)=>{
          return item.afterFreeText ==obj.afterFreeText && 
          item.bodyFreeText ==obj.bodyFreeText &&
          item.headerFreeText ==obj.headerFreeText 
        });

        if(items.length === 0){
          updatedArray.push(item);
        }
        
      });
      if($scope.listItems.length !== updatedArray.length ){
        $scope.listItems = updatedArray;
        $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
        $scope.update();
      }
    }

    $scope.callUndo = function() {
      const retVal =  window.applyFilterVisible($scope._callUndo,[]);
      $scope.normalizeListItemArray();
      return retVal;
    }
    $scope._callUndo = function() {
      if ($scope.histIndex === -1 || $scope.undoDisabled) {
        return;
      }

      document.querySelector('.undo').classList.add('undo--pending');
      $scope.undoDisabled = true;
      $scope.safeApply();

      var batchInfo = $scope.histBatcher[$scope.histIndex];
      var batchLength = batchInfo ? batchInfo.undo || 1 : 1;

      const undoList = [];
      for (var i = 0; i < batchLength; i++) {
        var curObj = $scope.histStack[$scope.histIndex - i];
        $scope.shouldRecompile[curObj.id] = true;
        undoList.push(curObj.undo());
      }

      $q.all(undoList).then(function() {
        $scope.histIndex -= batchLength;
        var curObj = $scope.histStack[$scope.histIndex + 1];
        $scope.updateUndoRedoStatus();
        if(curObj && !curObj.ignoreCaret){
          restoreSelection(curObj.prevSelection);
        }
        LeaseEditorService.bindImgEvents($('.new-paragraph img'), $scope);

        setTimeout(function() {
          document.querySelector('.undo').classList.remove('undo--pending');
        }, 2000);
      });

      $scope.safeApply();
    };

    $scope.callRedo = function() {
      const retVal =  window.applyFilterVisible($scope._callRedo,[]);
      $scope.normalizeListItemArray();
      return retVal;
    }
    $scope._callRedo = function() {
      if (
        $scope.histIndex + 1 >= $scope.histStack.length ||
        $scope.redoDisabled
      ) {
        return;
      }

      document.querySelector('.redo').classList.add('redo--pending');
      $scope.redoDisabled = true;
      $scope.safeApply();

      var batchInfo = $scope.histBatcher[$scope.histIndex];
      var batchLength = batchInfo ? batchInfo.redo || 1 : 1;

      const redoList = [];
      for (var i = 0; i < batchLength; i++) {
        var curObj = $scope.histStack[$scope.histIndex + i + 1];
        $scope.shouldRecompile[curObj.id] = true;
        redoList.push(curObj.redo());
      }

      $q.all(redoList).then(function() {
        $scope.histIndex += batchLength;
        var curObj = $scope.histStack[$scope.histIndex];
        $scope.updateUndoRedoStatus();
        if(curObj && !curObj.ignoreCaret){
          restoreSelection(curObj.currSelection);
        }
        LeaseEditorService.bindImgEvents($('.new-paragraph img'), $scope);

        setTimeout(function() {
          document.querySelector('.redo').classList.remove('redo--pending');
        }, 2000);
      });
    };

    $scope.updateUndoRedoStatus = function() {
      if ($scope.histIndex < 0) {
        $scope.undoDisabled = true;
      } else {
        $scope.undoDisabled = false;
      }
      if ($scope.histIndex >= $scope.histStack.length - 1) {
        $scope.redoDisabled = true;
      } else {
        $scope.redoDisabled = false;
      }
      $scope.safeApply();
    };

    /* -------------------------------------------------------------------------------------------------------------- */

    /* --------------------------------------------------------------------------------------------------------------
     -- Inline editor functionality --------------------------------------------------------------------------------- */

    // TODO: disable in production (if necessary)
    $scope.debuggingMode = true;

    /**
     * Wrapper for inline editor functions, features, and variables.
     *
     * @property {boolean} enabled - True if the inline editor features should be active.  False otherwise.
     * @property {number} refreshStep - Incremented with each change of the inline editor to force it to refresh/digest.
     * @property {LeaseField[]} activeFields - Fields currently being displayed in the inline editor.
     * @property {Object.<LeaseField, LeaseField[]>} computedFields - A mapping from fields (which are computed) to the fields each one relies on.
     * @property {Object.<LeaseField, LeaseField>} computedFieldsReversed - A mapping from editor controls to the fields they are computed into.
     */
    $scope.inline = {
      on: false,
      enabled: false,
      pinned: true,
      refreshStep: 0,
      activeFields: [],
      computedFields: {
        'lease.tenantInfo.entityTypeCombined': [
          'lease.tenantInfo.entityType',
          'lease.tenantInfo.entityTypeOther',
        ],
        'lease.assigneeInfo.entityTypeCombined': [
          'lease.assigneeInfo.entityType',
          'lease.assigneeInfo.entityTypeOther',
        ],
        'lease.franchisorInfo.entityTypeCombined': [
          'lease.franchisorInfo.entityType',
          'lease.franchisorInfo.entityTypeOther',
        ],
        'lease.guarantorInfo.entityTypeCombined': [
          'lease.guarantorInfo.entityType',
          'lease.guarantorInfo.entityTypeOther',
        ],
        'lease.guarantorInfo.entities[0].entityTypeCombined': [
          'lease.guarantorInfo.entities[0].entityType',
          'lease.guarantorInfo.entities[0].entityTypeOther',
        ],
        'lease.hasSpecialRights': [
          'lease.hasRenewalOption',
          'lease.hasRightOfFirstOffer',
          'lease.hasExpansionOption',
          'lease.hasParking',
          'lease.hasTenantTerminationOption',
          'lease.hasRooftopEquipment',
        ],
        'lease.hasHardCommencementDate': [
          'lease.tenantImprovements.type',
          'lease.estimatedCommencementDate',
          'hasSpecificExpirationDate',
          'lease.hasInitialDeliveryWork',
          'estimatedCommencementDateType',
        ],
        'lease.hasBroker': [
          'lease.hasLandlordBroker',
          'lease.tenantInfo.hasBroker',
        ],
        'lease.guarantor': ['lease.guarantor', 'lease.addGuarantor'],
        'lease.fixedCam': ['lease.camType'],
        'lease.annualBaseRent': ['lease.rentPeriods[0].totalRent'],
      },
      computedFieldsReversed: {},
    };
    _.forOwn($scope.inline.computedFields, function(val, key) {
      for (var i = 0; i < val.length; i++) {
        $scope.inline.computedFieldsReversed[val[i]] = key;
      }
    });

    /**
     * Utility functions for the inline editor.
     * See each function for documentation.
     */
    $scope.inline.util = {
      /**
       * Get all the related fields for a given lease-if HTML node.
       *
       * @param {HTMLElement} node - Node for which to find related field.
       *
       * @returns {LeaseField[]} - List of lease fields which relate to the input node.
       */
      getRelateds: function(node) {
        var toRet = [];
        var $node = $(node);
        if ($node.attr('hl')) {
          toRet.push($node.attr('hl'));
        }
        if ($node.attr('hl2')) {
          toRet.push($node.attr('hl2'));
        }
        if ($node.attr('hl3')) {
          toRet.push($node.attr('hl3'));
        }
        if ($node.attr('hl4')) {
          toRet.push($node.attr('hl4'));
        }
        if ($node.attr('hl5')) {
          toRet.push($node.attr('hl5'));
        }
        if ($node.attr('hl6')) {
          toRet.push($node.attr('hl6'));
        }
        if ($node.attr('hl7')) {
          toRet.push($node.attr('hl7'));
        }
        if ($node.attr('hl8')) {
          toRet.push($node.attr('hl8'));
        }
        if ($node.attr('hl9')) {
          toRet.push($node.attr('hl9'));
        }
        if ($node.attr('hl10')) {
          toRet.push($node.attr('hl10'));
        }
        if ($node.attr('hl11')) {
          toRet.push($node.attr('hl11'));
        }
        if ($node.attr('hl12')) {
          toRet.push($node.attr('hl12'));
        }
        if ($node.attr('hl13')) {
          toRet.push($node.attr('hl13'));
        }
        if ($node.attr('hl14')) {
          toRet.push($node.attr('hl14'));
        }
        if ($node.attr('hl15')) {
          toRet.push($node.attr('hl15'));
        }
        return toRet;
      },
      /**
       * Check the fields given to ensure they are valid fields which can be linked to an editor control.
       *
       * @param {LeaseField[]} relateds - List of fields to filter for validity.
       * @param {HTMLElement} curElement - Element to check for $index for the isolate scope (for ng-repeats) to replace $index with.
       *
       * @returns {LeaseField[]} - List of valid lease fields which can be linked to editor controls.
       */
      checkRelateds: function(relateds, curElement) {
        var toReturn = [];

        if (!relateds) {
          return toReturn;
        }

        var elementScope = angular.element(curElement).scope();
        var isolateIdx = -1;
        if (elementScope) {
          isolateIdx =
            (elementScope.item && elementScope.item._originalIndex) ||
            elementScope.$index;
        }

        for (var i = 0; i < relateds.length; i++) {
          var elm = relateds[i];
          if (
            elm === 'lease.createdAt' ||
            elm === 'lease.error' ||
            elm === 'lease.company.error' ||
            elm === 'lease.freeTextError' ||
            elm.indexOf('building') >= 0
          ) {
            // filter out non-variable -> do nothing
          } else {
            // replace index if needed
            if (
              (elm.indexOf('$index') >= 0 ||
                elm.indexOf('item._originalIndex') >= 0) &&
              isolateIdx >= 0
            ) {
              elm = elm
                .replace('$index', isolateIdx)
                .replace('item._originalIndex', isolateIdx);
            }
            // check if this is a computed field that requires replacement
            toReturn = toReturn.concat(
              $scope.inline.util.computedFieldsConverter(elm),
            );
          }
        }

        return toReturn;
      },
      /**
       * For a given field, find the relevant fields which may have been used to compute that field.
       *
       * @param {LeaseField} computedField - Field in the lease which may be a computed field.
       *
       * @returns {LeaseField[]} - List containing either the input field or the fields used to compute the input field.
       */
      computedFieldsConverter: function(computedField) {
        if ($scope.inline.computedFields.hasOwnProperty(computedField)) {
          return $scope.inline.computedFields[computedField];
        } else {
          return [computedField];
        }
      },
      /**
       * For a given field, find the field which may be computed from this field.
       *
       * @param {LeaseField} inputField - Field in the lease which may be used to make a computed field.
       *
       * @returns {LeaseField} - Either the input field or the field which this field is used to compute.
       */
      computedFieldsReverseConverter: function(inputField) {
        if ($scope.inline.computedFieldsReversed.hasOwnProperty(inputField)) {
          return $scope.inline.computedFieldsReversed[inputField];
        } else {
          return inputField;
        }
      },
    };

    /**
     * EditorObj: Represents an editor control or a container for editor controls.
     *
     * @typedef {Object} EditorObj
     *
     * @property {string} type - Either "root", "card", "group", or "control" indicating the type of EditorObj.
     * @property {string} breadcrumb - Indicates the current location in the editor for this EditorObj.
     * @property {EditorObj[]} children - All children of this editorObj.
     * @property {jQuery} ref - References this node in the editor, should include all children.
     * @property {string} [title] - For type:"control", the title of the control object.
     * @property {LeaseField} [id] - For type:"control", the id of the variable modeled.
     * @property {EditorObj} [parent] - For all but type:"root", the parent card (for use in opening editor, etc.)
     */

    /**
     * Find any control nodes from the left-hand editor, and build the control objects for them.
     *
     * @param {HTMLElement} searchNode - The node in which to search for controls.
     * @param {string} breadcrumbSoFar - Represents the breadcrumb title so far within editor.
     * @param {EditorObj} parentObj - The parent object for a back reference when building EditorObjs.
     *
     * @returns {EditorObj[]} - all control nodes within the searchNode
     */
    $scope.inline.findControls = function(
      searchNode,
      breadcrumbSoFar,
      parentObj,
    ) {
      var controls = [];
      var editorControls, attributeName, groupEditorControls;

      editorControls = $(searchNode).find('editor-control[for]');

      $(editorControls).each(function() {
        var leaseVariable = $(this)
          .attr('for')
          .replace('$parent.$index', $(this).attr('parent-index'))
          .replace('$index', $(this).attr('index'));

        if (!leaseVariable) {
          return true;
        }

        var childObj = {
          type: 'control',
          id: leaseVariable,
          breadcrumb: breadcrumbSoFar,
          ref: $(this),
          parent: parentObj,
        };

        $scope.inline.indexedControls[leaseVariable] = childObj;
        controls.push(childObj);
      });

      groupEditorControls = $(searchNode).find('group-editor-control[for]');

      $(groupEditorControls).each(function() {
        var leaseVariable;
        leaseVariable = $(this).attr('for');

        var childObj = {
          type: 'group-control',
          id: leaseVariable,
          breadcrumb: breadcrumbSoFar,
          ref: $(this).attr('template'),
          parent: parentObj,
        };

        $scope.inline.indexedControls[leaseVariable] = childObj;
        controls.push(childObj);
      });
      return controls;
    };

    /**
     * Convert current left-hand editor into a tree of EditorObj.
     * Does this by automatically searches based on defined editor structure within the HTML document:
     *   - .collapse-card          (1 or more)
     *   |-- .collapse-card__title (1 per collapse-card)
     *   |-- .collapse-card__body  (1 per collapse-card)
     *   | \-- .info-group         (0 or more)
     *   |   \-- [CONTROLS]        (for each info group, 1 or more)
     *   \-- [CONTROLS]            (0 or more)
     *   CONTROLS are defined as: .input-container, md-switch, or md-radio-group, or md-checkbox
     *   NOTE: any node satisfying the above criteria to be a node must only contain one true control
     *
     * @returns {EditorObj} - Root EditorObj.
     */
    $scope.inline.processEditor = function() {
      // create root/starting point
      var editorData = {
        type: 'root',
        children: [],
        parent: null,
      };

      // iterate through editor cards
      _.forEach($('.collapse-card'), function(card) {
        var $card = $(card);
        var cardObj = {
          type: 'card',
          breadcrumb: $card
            .find('.collapse-card__title')
            .text()
            .trim(),
          children: [],
          ref: $card,
          parent: editorData,
        };

        // find all children for each card
        var children = [];
        $card
          .find('.collapse-card__body')
          .children()
          .each(function() {
            if ($(this).hasClass('info-group')) {
              // add in new control group
              var myBreadcrumb =
                cardObj.breadcrumb +
                ' > ' +
                $(this)
                  .find('.info-group-header')
                  .text()
                  .trim();
              var childObj = {
                type: 'group',
                breadcrumb: myBreadcrumb,
                ref: $(this),
                parent: cardObj,
              };
              childObj.children = $scope.inline.findControls(
                this,
                myBreadcrumb,
                childObj,
              );
              children.push(childObj);
            } else {
              // add all loose controls
              children = children.concat(
                $scope.inline.findControls(this, cardObj.breadcrumb, cardObj),
              );
            }
          });

        // assign children into card, then add the completed cardObj
        cardObj.children = children;
        editorData.children.push(cardObj);
      });

      return editorData;
    };

    /**
     * Show an editor field by opening the relevant card and focusing on the text box (if applicable).
     *
     * @param {EditorObj} controlObj - Represents the editor field we would like to show.
     */
    $scope.inline.showEditorField = function(controlObj) {
      // traverse up through parents to find path to open editor
      var obj = controlObj;
      var path = [];
      while (obj !== null) {
        path.unshift(obj); // add at beginning of path
        obj = obj.parent;
      }

      // go through path and open / navigate to the right part of the editor
      for (var i = 0; i < path.length; i++) {
        var cur = path[i];
        switch (cur.type) {
          case 'root':
            // collapse all?
            break;
          case 'card':
            if (cur.ref.hasClass('active')) {
              // card is already active/opened
            } else {
              // card is not active, so we must open it
              cur.ref.find('.collapse-card__heading').trigger('click');
            }
            break;
          case 'group':
            // do nothing -groups are already opened (as of now)
            break;
          case 'control':
            setTimeout(
              (function(cur) {
                return function() {
                  cur.ref.find2('[ng-model]').focus();
                };
              })(cur),
              200,
            );
            break;
        }
      }
    };

    /**
     * Find all linked related controls of parent elements for a given element.
     *
     * @param {HTMLElement} elem - Element to find relevant parent controls for.
     *
     * @returns {LeaseField[]} - List of relevant fields for the element's parents.
     */
    $scope.inline.findNestedControls = function(elem) {
      var nestedFields = [];
      $(elem)
        .parents('[lease-if-deprecated]')
        .each(function() {
          var $this = $(this);

          // ignore building-variable, prerender, and building lease-ifs
          if ($this.attr('lease-if-deprecated').indexOf('building') >= 0) {
            return true; // continue
          }

          var relateds = $scope.inline.util.getRelateds(this);

          relateds = $scope.inline.util.checkRelateds(relateds, this);
          if (relateds.length === 0) {
            return true; // continue
          }

          nestedFields = _.union(nestedFields, relateds);
        });

      return nestedFields;
    };

    /**
     * Open the inline editor from a click on an element.
     *
     * @param {jQuery} activeElm - Element that was clicked on to open the editor.
     * @param {LeaseField[]} relateds - Related fields to list in the inline editor.
     */
    $scope.inline.openInline = function(activeElm, relateds) {
      if ($(activeElm).closest('[lp-popout-modal]').length > 0) {
        this.hideInline();
        return;
      }
      $scope.inline.refresh();
      if ((!relateds || relateds.length === 0) && !$scope.inline.pinned) {
        $scope.inline.hideInline();
      } else {
        $scope.inline.$activeElm = $(activeElm);
        $scope.inline.showingInline = true;
        $scope.inline.activeFields = relateds;
        $scope.inline.refreshStep++;
        $scope.safeApply();
      }
    };

    /**
     * Directive mappings from the generated-directed attribute to the list of related fields.
     * We also maintain a reverse mapping for looking of the generated-directive attribute from a field.
     */
    $scope.inline.directiveMapping = {
      rentTable: [
        'lease.leaseYears',
        'lease.premiseAreaSize',
        'lease.expansionPremiseAreaSize',
        'lease.relocationPremisesAreaSize',
        'lease.reductionRemainingAreaSize',
        'lease.rentPeriods',
        'lease.leaseMonths',
        'lease.freeRent',
        'lease.hasFreeRent',
        'lease.rentInputType',
        'lease.rentCommenceAt',
        'lease.hasFreeRentAddedToTerm',
        'lease.cpiAnchorYears',
      ],
      securityDepositBurndownSchedule: [
        'lease.hasSecurityDepositBurndown',
        'lease.reductionPeriodsNumber',
        'lease.listOfLeaseYears',
      ],
    };
    $scope.inline.directiveMappingReversed = {};
    _.forOwn($scope.inline.directiveMapping, function(val, key) {
      for (var i = 0; i < val.length; i++) {
        if ($scope.inline.directiveMappingReversed[val[i]]) {
          $scope.inline.directiveMappingReversed[val[i]].push(key);
        } else {
          $scope.inline.directiveMappingReversed[val[i]] = [key];
        }
      }
    });

    /**
     * Open the inline editor from an ng-click on a directive.
     *
     * @param {Event} e - The click event from the ng-click. We will use this to extract the target click element.
     */
    $scope.inline.openInlineDirective = function(e) {
      if (!$scope.inline.on) {
        return;
      }

      $scope.inline.mouseX = e.clientX;
      $scope.inline.mouseY = e.clientY;

      var $element = $(e.target);
      if (!$element.is('[generated-directive]')) {
        $element = $element.closest('[generated-directive]');
      }
      var directiveName = $element.attr('generated-directive');

      setTimeout(function() {
        var relatedsOpen = $scope.inline.findNestedControls($element);
        relatedsOpen = _.union(
          $scope.inline.directiveMapping[directiveName],
          relatedsOpen,
        );

        relatedsOpen = $scope.inline.util.checkRelateds(relatedsOpen, $element);
        $scope.inline.openInline($element, relatedsOpen);
      });
    };

    /**
     * Close the inline editor.
     */
    $scope.inline.hideInline = function() {
      if ($scope.debuggingMode && $scope.inline.showingInline) {
        $scope.inline.$activeElm = null;
        $scope.inline.showingInline = false;
        $scope.inline.activeFields = [];
        $scope.inline.pinned = true;
        $scope.inline.refreshStep++;
        $scope.currentlyChanging = '';
        $scope.resetHighlighting();
      }
    };

    /**
     * Pin the inline editor.
     *
     * Pinning ensures that clicking on new fields, lease-ifs, and placeholders keeps the inline editor in the same
     * place on the page.
     */
    $scope.inline.pinInline = function() {
      $scope.inline.pinned = !$scope.inline.pinned;
    };

    /**
     * Open a field within the inline editor from the occurrences/scrolling buttons.
     *
     * @param {jQuery} $element - The element which we are currently controlling from the inline editor.
     * @param {LeaseField} field - The main field to keep open and focus on in the inline editor.
     */
    $scope.inline.openFromScroll = function($element, field) {
      var relateds, directiveName;

      if ($element.parent().is('lease-var')) {
        $element = $element.parent();
        relateds = $scope.inline.findNestedControls($element);
      } else if ($element.is('.inline-dashed')) {
        relateds = $element.attr('var-id').split(',');
      } else if ($element.is('[generated-directive]')) {
        directiveName = $element.attr('generated-directive');
        relateds = $scope.inline.directiveMapping[directiveName];
      } else if ($element.closest('[generated-directive]').length > 0) {
        directiveName = $element
          .closest('[generated-directive]')
          .attr('generated-directive');
        relateds = $scope.inline.directiveMapping[directiveName];
      } else {
        relateds = $scope.inline.util.checkRelateds(
          splitVariable($element.attr('lease-if-deprecated')),
          $element,
        );
      }

      // remove yourself from array and reposition at beginning
      var myIdx = relateds.indexOf(field);
      if (myIdx > -1) {
        relateds.splice(myIdx, 1);
      }
      relateds.unshift(field);

      relateds = $scope.inline.util.checkRelateds(relateds, $element);
      $scope.inline.mouseX = $element.offset().left + $element.width() / 2;
      $scope.inline.mouseY = $element.offset().top;
      $scope.inline.openInline($element, relateds);
    };

    /**
     * Scroll to the next occurrence, keeping the inline editor open for the correct field.
     */
    $scope.inline.scrollNext = function() {
      var hls = $scope.getAllHls().filter("[exclude!='true']");
      var currentEl = $(hls[$scope.highlightScrollIndex]);

      if (currentEl.get(0) && isInView(currentEl)) {
        setTimeout(function() {
          // We update the `previousField` so we'll know if the `currentField` is different
          // if it's different, we need to reset the highlight mechanism
          $scope.inline.previousField = $scope.inline.currentField;

          $scope.scrollNext();
          $scope.$apply();
        }, 10);
        setTimeout(function() {
          $scope.inline.openFromScroll(
            $($scope.getHighlightedElement()),
            $scope.inline.currentField,
          );
          $scope.$apply();
        }, 500);
      } else {
        $scope.scrollToElement(currentEl);
      }
    };

    /**
     * Scroll to the previous occurrence, keeping the inline editor open for the correct field.
     */
    $scope.inline.scrollPrev = function() {
      setTimeout(function() {
        // We update the `previousField` so we'll know if the `currentField` is different
        // if it's different, we need to reset the highlight mechanism
        $scope.inline.previousField = $scope.inline.currentField;

        $scope.scrollPrev();
        $scope.$apply();
      }, 10);
      setTimeout(function() {
        $scope.inline.openFromScroll(
          $($scope.getHighlightedElement()),
          $scope.inline.currentField,
        );
        $scope.$apply();
      }, 500);
    };

    /**
     * Toggle between "inline" editing mode and "editor" editing mode.
     *
     * When the left-panel is collapsed, we are in "inline" mode.
     * when the left-panel is open, we are in "editor" editing mode.
     */
    $scope.inline.toggle = function() {
      if ($scope.inline.on) {
        $('.inline-editor').trigger('dragstart');
        $scope.inline.refresh();

        // Hide the highlighting bar
        $scope.resetHighlighting();
        $scope.topHlBarActive = false;
      } else {
        $scope.inline.hideInline();
      }

      $('body').toggleClass('inline-editor-mode');
      $timeout(function() {
        LeaseVarService.setTitles($scope,LabelsService);
      });
    };

    /**
     * Turn off inline editing mode and open the currently active field in the left-hand editor.
     */
    $scope.inline.openFieldInEditor = function() {
      if ($scope.inline.on) {
        $scope.inline.on = false;
        $scope.inline.toggle();
      }
      $scope.inline.showEditorField(
        $scope.inline.indexedControls[$scope.inline.currentField],
      );
    };

    /**
     * List of update functions to run on enabling placeholders.  These update functions will typically
     * create and add placeholders.  It is done on a delay / async because we must first wait for the editor
     * and lease to fully update before we can add them in, and also we must do them in order to avoid duplicates.
     * @type {Array}
     */
    $scope.inline.toUpdate = [];
    $scope.inline.runUpdateTimer = null;
    /**
     * Set a timer to run the queued placeholder updates, or reset an already existing timer. Each time something
     * new is added, this is run, to ensure that the placeholders are updated all at the end.
     */
    $scope.inline.refreshUpdateTimer = function() {
      if ($scope.inline.runUpdateTimer) {
        clearTimeout($scope.inline.runUpdateTimer);
      }
      $scope.inline.runUpdateTimer = setTimeout(function() {
        for (var i = 0; i < $scope.inline.toUpdate.length; i++) {
          var run = $scope.inline.toUpdate[i];
          run();
        }
        $scope.inline.toUpdate = [];
      }, 600);
    };

    /**
     * Register a function in the queue of placeholder update functions, and set a timer to eventually run
     * all of the queued updates.
     * @param {function} updateFunc - A function of no parameters which will be added to the queue to run.
     */
    $scope.inline.registerPlaceholderUpdate = function(updateFunc) {
      $scope.inline.toUpdate.push(updateFunc);
      $scope.inline.refresh();
      $scope.inline.refreshUpdateTimer();
    };

    /**
     * Clear cached information and reprocess the left-hand editor for access from the inline editor.
     */
    $scope.inline.refresh = function() {
      if ($scope.debuggingMode) {
        $scope.inline.indexedControls = {};
        $scope.inline.processEditor();
        $scope.inline.refreshUpdateTimer();
      }
    };

    /* -------------------------------------------------------------------------------------------------------------- */

    $scope.pmt = function(ir, np, pv, fv, type) {
      /*
       * ir   - interest rate per month
       * np   - number of periods (months)
       * pv   - present value
       * fv   - future value
       * type - when the payments are due:
       *        0: end of the period, e.g. end of month (default)
       *        1: beginning of period
       */
      var pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt;
    };

    $scope.isWatchedCollectionBeingChanged = function(collection) {
      var result = false;

      if (typeof collection === 'string') {
        collection = JSON.parse(collection);
      }

      if (_.includes(collection, $scope.currentlyChanging)) {
        result = true;
      } else {
        for (var j = 0; j < collection.length; j++) {
          if (_.startsWith($scope.currentlyChanging, collection[j])) {
            result = true;
          }
        }
      }

      return result;
    };

    const sanitizeField = _.throttle(function(field) {
      const text = _.get($scope, field);
      if (typeof text === 'string') {
        let cleanText = text;
        
        cleanText = cleanText.replace(/{{.*?}}/g, '');

        cleanText = DOMPurify.sanitize(cleanText, {
          ALLOWED_TAGS: [],
          ALLOWED_ATTR: [],
        });

        _.set($scope, field, cleanText);
      }
    }, 250);

    $scope.updateCurrent = function(field = '', shouldShowHighlightingBar = true) {
      window.track.event(new InteractWithTheAutomationEvent({
        field: field,
        context: $rootScope.getContext(),
      }));

      if (window.isDebug) {
        console.time(`Updating field: ${field}`);
      }

      sanitizeField(field);

      $scope.changeIsBeingMade = true;

      if ($scope.inline.on) {
        $('.inline-editor').trigger('dragstart');
      }

      if ($scope.currentlyChanging != field) {
        $scope.currentlyChanging = '';

        // If the inline editor is being used, we don't want to reset the highlight
        if (!$scope.inline.on) {
          $scope.resetHighlighting();
        }

        //manage lease-var highlight
        $scope.currentlyChanging = field;
      }

      $scope.calculatedFields.updateDependencies(field);

      //manage lease-if highlight
      $timeout(async function() {
        await $scope.reInsertMissingListItems();

        setTimeout(function() {
          $scope.highlightItem(field);
          $scope.anchorScroll();
          $scope.structureChanged();
        });

        // Async update and set counters
        setTimeout(function() {
          $scope.changeIsBeingMade = false;
          if (shouldShowHighlightingBar) {
            $scope.showHighlightingBar(null);
          }
          if ($scope.inline.on) {
            $scope.inline.refresh();
          }
  
          if (window.isDebug) {
            console.timeEnd(`Updating field: ${field}`);
          }
        }, 10);
  
        // Async - make sure that the first occurrence of the highlighted field is marked as 'current'
        setTimeout(function() {
          $scope.setCurrentlyUpdatingElement($scope.getHighlightedElement());
        }, 120);
  
        if (!$scope.isLoading && autopilotManager.initialized) {
          _.throttle(
            () => {
              autopilotManager.updateDocumentContext();
            },
            2000,
            { leading: false, trailing: true }
          )();
        }
      }, 0);
    };

    $scope.resetHighlighting = function() {
      $('.current')
        .removeClass('current');

      $(".adding")
        .removeClass('adding')
        .addClass('added');

      $(".adding-para")
        .removeClass('adding-para')
        .addClass('added');

      $scope.highlightScrollIndex = 0;
      $scope.numberOfHighlighted = 0;
    };

    $scope.getAllHls = function(activeField) {
      if (!activeField) {
        activeField = $scope.inline.currentField;
      }

      var toRet = $('.adding, .adding-para');

      // Exclude all the empty elements from the list by adding the `exclude=true` attribute
      toRet.each(function() {
        var text = $(this)
          .find2('*:not(:has(*)):visible')
          .text()
          .trim();
        // (1) Lets remove all undo buttons to support this feature to work with ctrl+shift+0
        text = text.replace(/undo/g, '');
        // (2) Let's remove all non-width-chars
        text = text.replace(/﻿/g, '');

        if (
          text.length === 0 &&
          $(this).find("table, img, [bullet='true']").length === 0
        ) {
          $(this).attr('exclude', 'true');
        } else {
          $(this).attr('exclude', 'false');
        }
      });

      var relevantDirectives =
        $scope.inline.directiveMappingReversed[activeField];

      if (relevantDirectives) {
        for (var i = 0; i < relevantDirectives.length; i++) {
          toRet = toRet.add(
            $("[generated-directive='" + relevantDirectives[i] + "']"),
          );
        }
      }

      return toRet;
    };

    function showHighlightingBarImmediately(index) {
      if ($window.prerenderReady) {
        // Remove the `adding` & `adding-para` classes from results
        // which aren't currently shown
        document
          .querySelectorAll('.adding, .adding-para')
          .forEach(result => {
            if (result.closest('.lp-hide')) {
              result.classList.remove('adding', 'adding-para');
            }
          });

        window.applyFilterVisible(() => {
          $scope.numberOfHighlighted = $(".adding, .adding-para")
            .filter("[exclude!='true']")
            .get().length;

          if ($scope.inline.on) {
            $scope.topHlBarActive = false;
          } else {
            $scope.topHlBarActive = true;
          }

          $scope.setCurrentlyUpdatingElement($scope.getHighlightedElement());
          if (!$scope.inline.showingInline && index) {
            $scope.safeApply();
            $scope.scrollFirst();
          } else if (index >= 0) {
            $scope.highlightScrollIndex = index;
            $scope.safeApply();
          } else {
            $scope.safeApply();
          }
        });
      }
    }

    // Makes sure that the highlighting bar is shown correctly with the proper numbers and the view is scrolled to the first item
    $scope.showHighlightingBar = function(hlIndex) {
      if ($scope.inline.showingInline) {
        // LK: This is kinda suck but it takes some time for the inline-editor to figure out
        //     which of the fields it needs to highlight so we need to postpone this accordingly
        setTimeout(function() {
          showHighlightingBarImmediately($scope.highlightScrollIndex);
        }, 500);
      } else {
        showHighlightingBarImmediately(hlIndex);
      }
    };

    $scope.hideHighlighting = function() {
      $scope.topHlBarActive = false;
      $scope.resetHighlighting();
    };

    // This is here because when changing anything through the inline editor,
    // It generate a new highlighting bar without the lease-if changes (it just happens faster)
    // So we call this every time a lease-if generates a placeholder to update the numbers
    $scope.updateHighlightingBarNumbers = function() {
      // Remove the `adding` & `adding-para` classes from results
      // which aren't currently shown
      document
        .querySelectorAll('.adding, .adding-para')
        .forEach(result => {
          if (result.closest('.lp-hide')) {
            result.classList.remove('adding', 'adding-para');
          }
        });

      window.applyFilterVisible(() => {
        $scope.numberOfHighlighted = $scope
          .getAllHls()
          .filter("[exclude!='true']")
          .get().length;
      });
    };

    $scope.highlightItem = function(item1, item2, addLeaseVars) {
      return window.applyFilterVisible($scope._highlightItem,[item1, item2, addLeaseVars]);
    }
    $scope._highlightItem = function(item1, item2, addLeaseVars) {
      var highlights;
      if (item1 && !item2) {
        highlights = $('.preview').find(
          "*[hl='" +
            item1 +
            "'], *[hl2='" +
            item1 +
            "'], *[hl3='" +
            item1 +
            "'], *[hl4='" +
            item1 +
            "'], *[hl5='" +
            item1 +
            "'], *[hl6='" +
            item1 +
            "'], *[hl7='" +
            item1 +
            "'], *[hl8='" +
            item1 +
            "'], *[hl9='" +
            item1 +
            "'], *[hl10='" +
            item1 +
            "'], *[hl11='" +
            item1 +
            "'], *[hl12='" +
            item1 +
            "'], *[hl13='" +
            item1 +
            "'], *[hl14='" +
            item1 +
            "'], *[hl15='" +
            item1 +
            "']",
        );
      } else if (item1 && item2) {
        highlights = $('.preview').find(
          "*[hl='" +
            item1 +
            "'], *[hl2='" +
            item1 +
            "'], *[hl3='" +
            item1 +
            "'], *[hl4='" +
            item1 +
            "'], *[hl5='" +
            item1 +
            "'], *[hl6'" +
            item1 +
            "'], *[hl7='" +
            item1 +
            "'], *[hl8='" +
            item1 +
            "'], *[hl9='" +
            item1 +
            "'], *[hl10='" +
            item1 +
            "'], *[hl11='" +
            item1 +
            "'], *[hl12='" +
            item1 +
            "'], *[hl13='" +
            item1 +
            "'], *[hl14='" +
            item1 +
            "'], *[hl15='" +
            item1 +
            "']",
            "*[hl='" +
            item2 +
            "'], *[hl2='" +
            item2 +
            "'], *[hl3='" +
            item2 +
            "'], *[hl4='" +
            item2 +
            "'], *[hl5='" +
            item2 +
            "'], *[hl6='" +
            item2 +
            "'], *[hl7='" +
            item2 +
            "'], *[hl8='" +
            item2 +
            "'], *[hl9='" +
            item2 +
            "'], *[hl10='" +
            item2 +
            "'], *[hl11='" +
            item2 +
            "'], *[hl12='" +
            item2 +
            "'], *[hl13='" +
            item2 +
            "'], *[hl14='" +
            item2 +
            "'], *[hl15='" +
            item2 +
            "']",
        );
      }
      if (addLeaseVars) {
        highlights = $.merge(
          highlights,
          $('.preview').find(
            '[is-changing="\'' + item1 + '\'==currentlyChanging"] span',
          ),
        );
      }

      $scope.highlight(highlights);

      // Highlight elements that contain a collection of watched properties
      var elements = $('.preview').find('[watch]');
      _.forEach(elements, function(element) {
        var highlight = $(element).attr('watch');

        if (typeof highlight === 'string') {
          highlight = JSON.parse(highlight);
        }

        if (_.includes(highlight, item1)) {
          $scope.highlight($(element));
        } else if (_.includes(highlight, item2)) {
          $scope.highlight($(element));
        } else {
          for (var i = 0; i < highlight.length; i++) {
            if (
              _.startsWith(item1, highlight[i]) ||
              _.startsWith(item2, highlight[i])
            ) {
              $scope.highlight($(element));
              break;
            }
          }
        }
      });

      var relevantDirectives = [];
      var i;
      if (item1) {
        relevantDirectives = $scope.inline.directiveMappingReversed[item1];
        if (relevantDirectives) {
          for (i = 0; i < relevantDirectives.length; i++) {
            // LK: that's the reason the rent-table is highlighted when changing the `renewalTerm`
            $scope.highlight(
              $('.preview').find(
                "[generated-directive='" + relevantDirectives[i] + "']",
              ),
            );
          }
        }
      }
      if (item2) {
        relevantDirectives = $scope.inline.directiveMappingReversed[item2];
        if (relevantDirectives) {
          for (i = 0; i < relevantDirectives.length; i++) {
            $scope.highlight(
              $('.preview').find(
                "[generated-directive='" + relevantDirectives[i] + "']",
              ),
            );
          }
        }
      }
    };

    $scope.highlight = function(nodes) {
      if (nodes) {
        nodes.each(function() {
          var $this = $(this);
          $this.removeClass('added');
          $this.addClass('adding-para');
        });

        document.querySelectorAll(".adding-para .adding-para").forEach(function (node) {
          node.closest(".adding-para").classList.remove("adding-para");
        });

        if (!$scope.inline.showingInline) {
          $scope.showHighlightingBar();
        }
      }
    };

    $scope.execute = function(action) {
      $scope.toggleSearch(false);

      switch (action) {
        case 'undo':
          $scope.callUndo();
          $scope.disableDecorationButtons(true);
          window.track.event(new UndoEvent({
            context: $rootScope.getContext(),
          }));
          // document.execCommand("undo", false, null);
          break;
        case 'redo':
          $scope.callRedo();
          $scope.disableDecorationButtons(true);
          window.track.event(new RedoEvent({
            context: $rootScope.getContext(),
          }));
          // document.execCommand("redo", false, null);
          break;
        case 'cut':
          $scope.handleCut();
          break;
        case 'copy':
          $scope.handleCopy();
          break;
        case 'paste':
          if ($scope.currentlyEditedNode) {
            $($scope.currentlyEditedNode).trigger('paste');
          }
          $scope.disableDecorationButtons(true);
          break;
      }
    };

    // Disable selection mode if B/I/U on a selection
    $scope.selectionAction = function() {
      var sel = rangy.getSelection();
      if (sel && sel.rangeCount > 0) {
        var range = sel.getRangeAt(0);
        if (range.toString().length > 0) {
          $scope.currentlyEditedNode = range.commonAncestorContainer;
        }
      }
    };

    /* ============= Alignment & Indentation tools ============= */

    $scope.calculateIndentation = function (node, type, steps) {

      const indent_actions = {
        paragraph: 'marginLeft',
        text: 'textIndent',
      }

      
      let dataOriginalIndent = `data-original-${type}-indent`;
      let dataIndent = `data-${type}-indent`;

      const originalIndentStyle = parseInt(node.getAttribute(dataOriginalIndent));
      const currentIndentationSteps = +node.getAttribute(dataIndent);
      const updatedIndentationSteps = currentIndentationSteps + steps;
      const indentStyle = updatedIndentationSteps * window.CONSTS.STEP_SIZE
      const totalIndentationSteps = (1 * node.getAttribute(`data-paragraph-indent`)) + ((1 * node.getAttribute(`data-text-indent`)) || 0) + steps;

      return {
        originalIndentStyle: originalIndentStyle,
        currentIndentationSteps: currentIndentationSteps,
        updatedIndentationSteps: updatedIndentationSteps,
        totalIndentationSteps: totalIndentationSteps,
        indentStyle: indentStyle,
        indentAction: indent_actions[type],
      }
    }

    $scope.isUnstyled = function(container) {
      return !container.classList.contains("restyled");
    }

    $scope.setOriginalStyle = function(container, restyle) {
      if (!$scope.indentDisable && !container.classList.contains('restyled')) {

        let originalParagraphIndent = container.style.marginLeft;
        let originalTextIndent = container.style.textIndent;
        let originalTextAlign = container.style.textAlign;
        let originalFontSize = container.style.fontSize;

        if (restyle) {
          originalParagraphIndent = restyle.originalParagraphIndent;
          originalTextIndent = restyle.originalTextIndent;
          originalTextAlign = restyle.originalTextAlign;
          originalFontSize = restyle.originalFontSize;
        }

        container.setAttribute('data-original-paragraph-indent', originalParagraphIndent || '0pt');
        container.setAttribute('data-original-text-indent', originalTextIndent || '0pt');
        container.setAttribute('data-original-text-align', originalTextAlign || 'start');
        container.setAttribute('data-original-font-size', originalFontSize || $scope.defaultFont.fontSize);

        const marginLeft = parseInt(originalParagraphIndent);
        if (marginLeft && marginLeft % window.CONSTS.STEP_SIZE === 0) {
          container.setAttribute('data-paragraph-indent', marginLeft / window.CONSTS.STEP_SIZE);
        }

        const textIndent = parseInt(originalTextIndent);
        if (textIndent && textIndent % window.CONSTS.STEP_SIZE === 0) {
          container.setAttribute('data-text-indent', textIndent / window.CONSTS.STEP_SIZE);
        }

        const fontSize = originalFontSize;
        container.setAttribute("data-font-size", fontSize);
        
        container.classList.add('restyled');
      }
    }


    function undoRedoSetIndent(node, type, steps,$scope){
      return function(){
        var deferred = $q.defer();
        $scope.setIndent(node, type, steps);

        const freeText = node.closest("[free-text]");
        
        if (freeText) {
          $(freeText)
            .find('[contenteditable]')
            .trigger('change');
        }

        deferred.resolve();
        return deferred.promise;
      }
    }

    $scope.setIndent = function(node, type, steps) {
      const MAX_INDENTATION_STEPS = 10;
      const calcInfo = $scope.calculateIndentation(node, type, steps);
      const updatedIndentationSteps = calcInfo.updatedIndentationSteps;
      var dataIndent = `data-${type}-indent`;
      var indentAction = calcInfo.indentAction;

      if (calcInfo.totalIndentationSteps <= MAX_INDENTATION_STEPS) {
        const indentStyle = calcInfo.indentStyle + 'pt';
        node.style[indentAction] = indentStyle;
        node.setAttribute(dataIndent, updatedIndentationSteps);
        return updatedIndentationSteps;
      }

      return false;
    }

    $scope.indent = function(steps, type) {
      
      let currentUndoIndex = $scope.histIndex;

      const sel = rangy.getSelection();
      const startBlock = sel.anchorNode.getTopAnchor();
      const endBlock = sel.focusNode.getTopAnchor();
      const range = sel.getRangeAt(0);
      const blocks = range.getNodes([Node.ELEMENT_NODE], function(node) {
        return (
          ["P", "H1", "H2", "H3", "H4", "H5", "H6"].indexOf(node.tagName) > -1
        );
      });

      if (startBlock && startBlock.getAttribute && startBlock.getAttribute("pid") && blocks.indexOf(startBlock) === -1) {
        blocks.push(startBlock);
      }
      if (endBlock && endBlock.getAttribute && endBlock.getAttribute("pid") && blocks.indexOf(endBlock) === -1) {
        blocks.push(endBlock);
      }
      
      let focusNode = sel.focusNode;

      if (focusNode.nodeType === Node.TEXT_NODE) {
        focusNode = focusNode.parentElement;
      }

      if (!focusNode.classList.contains("new-paragraph")) {
        focusNode = LeaseEditorService.findClosestContainer(focusNode).get(0);
      }

      let listInfo = focusNode.getBlockData();

      if (LeaseEditorService.isSelecting()) {
        
        let allParagraphs = blocks;
        let paragraphsIndex = allParagraphs.map(node => {
          let p = null;
          if (node.tagName === "P") {
            p = node;
          } else {
            listInfo = node.getBlockData();
            if (steps > 0) {
              if (listInfo) {
                listInfo.moveToNextLevel();
              }
            } else {
              if (listInfo) {
                listInfo.moveToPreviousLevel();
              }
            }
          }

          if (p === null) {
            return;
          }

          if ($rootScope.isListItem($(p))) {
            return;
          }
          if (p.classList.contains("new-paragraph")) {
            let nestedIndex = p.getAttribute("data-nested-paragraph-index");
            if (nestedIndex) {
              return { type: "new-paragraph", id: nestedIndex };
            }
          } else {
            let pid = p.getAttribute("pid");
            if (pid) {
              return { type: "paragraph", id: pid };
            }
          }
        });
        for (let index = 0; index < paragraphsIndex.length; ++index) {
          let item = paragraphsIndex[index];

          if (!item) {
            continue;
          }

          let p = null;
          if (item.type === "new-paragraph") {
            p = document.querySelector(
              `[data-nested-paragraph-index="${item.id}"]`
            );
          } else if (item.type === "paragraph") {
            p = document.querySelectorAll(`[pid="${item.id}"]`).lastItem();
          }

          if (p) {
            if (type) {
              scopeVar.indentOnce(type, steps, p);
            } else {
              scopeVar.indentOnce("paragraph", steps, p);
            }
          }
        }
      } else if (!scopeVar.indentDisable || listInfo) {
        const textIndent = 1 * focusNode.getAttribute("data-text-indent");

        LeaseEditorService.skipNonWidthChar("forward");

        if (listInfo && listInfo.listLevel && listInfo.listLevel.level !== "paragraph" && !focusNode.hasAttribute('data-nested-paragraph-index')) {
          if (steps > 0) {
            if (listInfo) {
              listInfo.moveToNextLevel();
            } else if (listInfo.blockType === BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH)  {
              if ((steps > 0 && textIndent === 0) || (steps < 0 && textIndent > 0)) {
                scopeVar.indentOnce("text", steps, focusNode);
              } else {
                scopeVar.indentOnce("paragraph", steps, focusNode);
              }
            }
          } else {
            if (listInfo) {
              listInfo.moveToPreviousLevel();
            } else if (listInfo.blockType === BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH) {
              if (steps < 0 && textIndent > 0) {
                scopeVar.indentOnce("text", steps, focusNode);
              } else {
                scopeVar.indentOnce("paragraph", steps, focusNode);
              }
            }
          }
        } else if (contentEditableManager.isCaretAtStartContainer() || type) {
          if (type) {
            scopeVar.indentOnce(type, steps, focusNode);
          } else if ( (steps > 0 && textIndent === 0) || (steps < 0 && textIndent > 0 ) ) {
            scopeVar.indentOnce("text", steps, focusNode);
          } else {
            scopeVar.indentOnce("paragraph", steps, focusNode);
          }
        }
      }

      setTimeout(()=>{
        $scope.histRegisterBatch($scope.histStack.length - 1, $scope.histIndex - currentUndoIndex);
      });
    }

    $scope.indentOnce = function(type, steps, paragraphElement) {
      const sel = rangy.getSelection();
      let node = sel.focusNode;
      let container = node;
      const contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);

      if (paragraphElement) {
        container = paragraphElement;
        node = container;
      } else {
        if (!(node.nodeType === Node.ELEMENT_NODE && node.classList.contains('new-paragraph'))) {
          container = LeaseEditorService.findClosestContainer(node).get(0);
        }
        if (!contentEditable || !container) {
          return;
        }
      }

      let pid;
      pid = container.getAttribute('pid');
      pid = getParagraphId(pid, true);

      // If this is not a `new-paragraph` and for some reason we couldn't find it,
      // don't allow to indent the paragraph and - stop!
      if (!(pid || container.classList.contains('new-paragraph'))) {
        return;
      }

      const savedSelection = rangy.saveSelection();

      // We only save `restyle`s for top-level paragraphs
      if (!$scope.lease.restyle) {
        $scope.lease.restyle = [];
      }
      if (pid && !$scope.lease.restyle[pid]) {
        let originalMarginLeft = container.style.marginLeft || '0pt';
        let originalTextIndent = container.style.textIndent || '0pt';
        let originalTextAlign = container.style.textAlign || 'start';
        let originalFontSize = container.style.fontSize || $scope.defaultFont.fontSize;
        let originalLineHeight = container.style.lineHeight || $scope.defaultFont.lineHeight;
        let paragraphIndent = null;
        let textIndent = null;
        let textAlign = originalTextAlign;
        let fontSize = originalFontSize;
        let lineHeight = originalLineHeight;

        if (parseInt(originalMarginLeft) % window.CONSTS.STEP_SIZE === 0) {
          paragraphIndent = parseInt(originalMarginLeft) / window.CONSTS.STEP_SIZE;
        }

        if (parseInt(originalTextIndent) % window.CONSTS.STEP_SIZE === 0) {
          textIndent = parseInt(originalTextIndent) / window.CONSTS.STEP_SIZE;
        }

        $scope.lease.restyle[pid] = {
          paragraphId: pid,
          originalParagraphIndent: originalMarginLeft,
          originalTextIndent: originalTextIndent,
          originalTextAlign: originalTextAlign,
          originalFontSize: originalFontSize,
          originalLineHeight: originalLineHeight,
          paragraphIndent: paragraphIndent,
          textIndent: textIndent,
          textAlign: textAlign,
          fontSize: fontSize,
          lineHeight: lineHeight,
        };

        $scope.setOriginalStyle(container, $scope.lease.restyle[pid]);
      }

      rangy.restoreSelection(savedSelection, true);

      if (node.nodeType === Node.TEXT_NODE) {
        node = node.parentElement;
      }

      if (!node.classList.contains('new-paragraph')) {
        node = container;
      }

      // Block the exit text from the range
      const currentSteps = +node.getAttribute('data-'+ type + "-indent");
      if(currentSteps + steps < 0) {
        if(currentSteps > 0){
          steps = (-1 * currentSteps);
        }
        else{
          return;
        }
      }

      window.track.event(new IndentParagraphEvent({
        type: type,
        steps: steps,
        isNewParagraph: !pid,
        context: $rootScope.getContext(),
      }));

      if (type === 'paragraph') {
        // Save the change of the alignment in the data-model
        if (pid) {
          $scope.addToHistory({
            id: 'paragraph-indent',
            desc: '',
            redo: function() {
              const deferred = $q.defer();
              const updatedIndentationSteps = $scope.setIndent(node, type, steps);

              if (updatedIndentationSteps !== false) {
                $scope.lease.restyle[pid].paragraphIndent = updatedIndentationSteps;
                $scope.update();
              }

              deferred.resolve();

              return deferred.promise;
            },
            undo: function() {
              const deferred = $q.defer();
              const updatedIndentationSteps = $scope.setIndent(node, type, -steps);

              if (updatedIndentationSteps !== false) {
                $scope.lease.restyle[pid].paragraphIndent = updatedIndentationSteps;
                $scope.update();
              }

              deferred.resolve();

              return deferred.promise;
            },
          });

          $scope.histIgnoreClearBatch += 2;
          $scope.histRegisterBatch($scope.histIndex + 1, 2);
        } else {
          if ($scope.isUnstyled(node)) {
            $scope.setOriginalStyle(node, $scope.lease.restyle[pid]);

            rangy.restoreSelection(savedSelection, true);
          }

          $scope.addToHistory({
            id: 'paragraph-indent',
            desc: '',
            redo : undoRedoSetIndent(node, type, steps,$scope),
            undo :undoRedoSetIndent(node, type, steps *-1,$scope),
          });


        }
      } else if (type === 'text') {
        // Save the change of the alignment in the data-model
        if (pid) {
         $scope.addToHistory({
            id: 'text-indent',
            desc: '',
            redo: function() {
              const deferred = $q.defer();
              const updatedIndentationSteps = $scope.setIndent(node, type, steps);

              if (updatedIndentationSteps !== false) {
                $scope.lease.restyle[pid].textIndent = updatedIndentationSteps;
                $scope.update();
              }

              deferred.resolve();

              return deferred.promise;
            },
            undo: function() {
              const deferred = $q.defer();
              const updatedIndentationSteps = $scope.setIndent(node, type, -steps);

              if (updatedIndentationSteps !== false) {
                $scope.lease.restyle[pid].textIndent = updatedIndentationSteps;
                $scope.update();
              }

              deferred.resolve();

              return deferred.promise;
            },
          });

          $scope.histIgnoreClearBatch += 2;
          $scope.histRegisterBatch($scope.histIndex + 1, 2);
        } else {
          if ($scope.isUnstyled(node)) {
            $scope.setOriginalStyle(node, $scope.lease.restyle[pid]);

            rangy.restoreSelection(savedSelection, true);
          }

          $scope.addToHistory({
            id: 'text-indent',
            desc: '',
            redo : undoRedoSetIndent(node, type, steps,$scope),
            undo :undoRedoSetIndent(node, type, steps *-1,$scope),
          });

        }
      }
    };

    $scope.align = function(alignment) {
      let currentUndoIndex = $scope.histIndex;

      
      if (isActiveImage()) {
        $scope.alignOnce(alignment);
      } else {
        const sel = rangy.getSelection();
        const startBlock = sel.anchorNode.getTopAnchor();
        const endBlock = sel.focusNode.getTopAnchor();
        const range = sel.getRangeAt(0);
        const blocks = range.getNodes([Node.ELEMENT_NODE], function(node) {
          return (
            ["P", "H1", "H2", "H3", "H4", "H5", "H6"].indexOf(node.tagName) > -1
          );
        });
  
        if (startBlock && startBlock.getAttribute && startBlock.getAttribute("pid") && blocks.indexOf(startBlock) === -1) {
          blocks.push(startBlock);
        }
        if (endBlock && endBlock.getAttribute && endBlock.getAttribute("pid") && blocks.indexOf(endBlock) === -1) {
          blocks.push(endBlock);
        }
  
        if (LeaseEditorService.isSelecting()) {
          let allParagraphs = blocks;
          let paragraphsIndex = allParagraphs.map(p =>{
            if (p.textContent.trim().startsWith("!!SET_LEVEL")) {
              return false;
            } else if (p.classList.contains('new-paragraph'))  {
              let nestedIndex = p.getAttribute('data-nested-paragraph-index');
              if (nestedIndex){
                return { type: "new-paragraph", id: nestedIndex };
              }
            } else {
              let pid = p.getAttribute('pid');
              if (pid){
                return { type: "paragraph", id: pid };
              }
            }
          });
          for (let index = 0; index < paragraphsIndex.length; ++index) {
            let item = paragraphsIndex[index];

            if (!item) {
              continue;
            }
            
            let p = null;
            if (item.type === "new-paragraph") {
              p = document.querySelector(`[data-nested-paragraph-index="${item.id}"]`);
            } else if (item.type === "paragraph") {
              p = document.querySelectorAll(`[pid="${item.id}"]`).lastItem();
            }
  
            if (p) {
              $scope.alignOnce(alignment, p);
            }
          }
        } else {
          $scope.alignOnce(alignment);
        }
  
        setTimeout(()=>{
          $scope.histRegisterBatch($scope.histStack.length - 1, $scope.histIndex - currentUndoIndex);
        });
      }
    }

    $scope.alignOnce = function(alignment, element) {
      let node;
      let container;
      let contentEditable;
      let _isActiveImage = isActiveImage();
      if (element) {
        if (_isActiveImage) {
          node = document.querySelector('img.active');
        } else {
          node = element;
        }
        container = node;
        contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);

        if (!contentEditable) {
          const list = LeaseEditorService.findAllContentEditable(container);
          contentEditable = list[0];
        }
      } else {
        if (_isActiveImage) {
          node = document.querySelector('img.active');
          container = LeaseEditorService.findClosestContainer(node).get(0);
        } else {
          const sel = rangy.getSelection();
          node = sel.focusNode;
          container = node;
        }
        contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);

        if (!(node.nodeType === Node.ELEMENT_NODE && node.classList.contains('new-paragraph'))) {
          container = LeaseEditorService.findClosestContainer(node).get(0);
        }

        if (!contentEditable || !container) {
          return;
        }
      }

      var prevAlignment = "";
      if (container.style.textAlign === "") {
        prevAlignment = "start";
      } else {
        prevAlignment = container.style.textAlign;
      }

      let pid;
      pid = container.getAttribute('pid');
      pid = getParagraphId(pid, true);

      // If this is not a `new-paragraph` and for some reason we couldn't find it,
      // don't allow to align the paragraph and - stop!
      if (!(pid || container.classList.contains('new-paragraph'))) {
        return;
      }

      const savedSelection = rangy.saveSelection();

      // We only save `restyle`s for top-level paragraphs
      if (!$scope.lease.restyle) {
        $scope.lease.restyle = [];
      }
      if (pid && !$scope.lease.restyle[pid]) {
        let originalMarginLeft = container.style.marginLeft || '0pt';
        let originalTextIndent = container.style.textIndent || '0pt';
        let originalTextAlign = container.style.textAlign || 'start';
        let originalFontSize = container.style.fontSize || $scope.defaultFont.fontSize;
        let originalLineHeight = container.style.lineHeight || $scope.defaultFont.lineHeight;
        let paragraphIndent = null;
        let textIndent = null;
        let fontSize = originalFontSize;
        let lineHeight = originalLineHeight;

        if (parseInt(originalMarginLeft) % window.CONSTS.STEP_SIZE === 0) {
          paragraphIndent = parseInt(originalMarginLeft) / window.CONSTS.STEP_SIZE;
        }

        if (parseInt(originalTextIndent) % window.CONSTS.STEP_SIZE === 0) {
          textIndent = parseInt(originalTextIndent) / window.CONSTS.STEP_SIZE;
        }

        $scope.lease.restyle[pid] = {
          paragraphId: pid,
          originalParagraphIndent: originalMarginLeft,
          originalTextIndent: originalTextIndent,
          originalTextAlign: originalTextAlign,
          originalFontSize: originalFontSize,
          originalLineHeight: originalLineHeight,
          paragraphIndent: paragraphIndent,
          textIndent: textIndent,
          textAlign: alignment,
          fontSize: fontSize,
          lineHeight: lineHeight,
        };

        $scope.setOriginalStyle(container, $scope.lease.restyle[pid]);
      }

      rangy.restoreSelection(savedSelection, true);

      window.track.event(new AlignParagraphEvent({
        alignment: alignment,
        isNewParagraph: !pid,
        context: $rootScope.getContext(),
      }));

      // Save the change of the alignment in the data-model
      if (pid) {
        $scope.addToHistory({
          id: 'text-align',
          desc: '',
          redo: function() {
            const deferred = $q.defer();
            container.setAttribute('data-text-align', alignment);
            container.style.textAlign = alignment;

            $scope.lease.restyle[pid].textAlign = alignment;
            $scope.update();

            deferred.resolve();
            return deferred.promise;
          },
          undo: function() {
            const deferred = $q.defer();
            container.setAttribute('data-text-align', prevAlignment);
            container.style.textAlign = prevAlignment;

            $scope.lease.restyle[pid].textAlign = prevAlignment;
            $scope.update();

            deferred.resolve();
            return deferred.promise;
          },
        });

        $scope.histIgnoreClearBatch += 2;
        $scope.histRegisterBatch($scope.histIndex + 1, 2);
      } else {
        if ($scope.isUnstyled(container)) {
          $scope.setOriginalStyle(container, $scope.lease.restyle[pid]);

          rangy.restoreSelection(savedSelection, true);
        }
        container.style.textAlign = alignment;
      }
    };

    $scope.changeBlockFontSize = function(fontSize) {
      let currentUndoIndex = $scope.histIndex;
      
      const sel = rangy.getSelection();
      const startBlock = sel.anchorNode.getTopAnchor();
      const endBlock = sel.focusNode.getTopAnchor();
      const range = sel.getRangeAt(0);
      const blocks = range.getNodes([Node.ELEMENT_NODE], function(node) {
        return (
          ["P", "H1", "H2", "H3", "H4", "H5", "H6"].indexOf(node.tagName) > -1
        );
      });

      if (startBlock && startBlock.getAttribute && startBlock.getAttribute("pid") && blocks.indexOf(startBlock) === -1) {
        blocks.push(startBlock);
      }
      if (endBlock && endBlock.getAttribute && endBlock.getAttribute("pid") && blocks.indexOf(endBlock) === -1) {
        blocks.push(endBlock);
      }

      if (LeaseEditorService.isSelecting()) {
        let allParagraphs = blocks;
        let paragraphsIndex = allParagraphs.map(p =>{
          if (p.textContent.trim().startsWith("!!SET_LEVEL")) {
            return false;
          } else if (p.classList.contains('new-paragraph'))  {
            let nestedIndex = p.getAttribute('data-nested-paragraph-index');
            if (nestedIndex){
              return { type: "new-paragraph", id: nestedIndex };
            }
          } else {
            let pid = p.getAttribute('pid');
            if (pid){
              return { type: "paragraph", id: pid };
            }
          }
        });
        for (let index = 0; index < paragraphsIndex.length; ++index) {
          let item = paragraphsIndex[index];

          if (!item) {
            continue;
          }
          
          let p = null;
          if (item.type === "new-paragraph") {
            p = document.querySelector(`[data-nested-paragraph-index="${item.id}"]`);
          } else if (item.type === "paragraph") {
            p = document.querySelectorAll(`[pid="${item.id}"]`).lastItem();
          }

          if (p) {
            $scope.changeBlockFontSizeOnce(fontSize, p);
          }
        }
      } else {
        $scope.changeBlockFontSizeOnce(fontSize);
      }

      setTimeout(()=>{
        $scope.histRegisterBatch($scope.histStack.length - 1, $scope.histIndex - currentUndoIndex);
      });
    }

    $scope.changeBlockFontSizeOnce = function(fontSize, element) {
      let node;
      let container;
      let contentEditable;
      let _isActiveImage = isActiveImage();
      if (element) {
        if (_isActiveImage) {
          node = document.querySelector('img.active');
        } else {
          node = element;
        }
        container = node;
        contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);

        if (!contentEditable) {
          const list = LeaseEditorService.findAllContentEditable(container);
          contentEditable = list[0];
        }
      } else {
        if (_isActiveImage) {
          node = document.querySelector('img.active');
        } else {
          const sel = rangy.getSelection();
          node = sel.focusNode;
        }
        container = node;
        contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);

        if (!(node.nodeType === Node.ELEMENT_NODE && node.classList.contains('new-paragraph'))) {
          container = LeaseEditorService.findClosestContainer(node).get(0);
        }

        if (!contentEditable || !container) {
          return;
        }
      }

      var prevFontSize = "";
      if (container.style.fontSize === "") {
        prevFontSize = $scope.defaultFont.fontSize;
      } else {
        prevFontSize = container.style.fontSize;
      }

      let pid;
      pid = container.getAttribute('pid');
      pid = getParagraphId(pid, true);

      // If this is not a `new-paragraph` and for some reason we couldn't find it,
      // don't allow to align the paragraph and - stop!
      if (!(pid || container.classList.contains('new-paragraph'))) {
        return;
      }

      const savedSelection = rangy.saveSelection();

      // We only save `restyle`s for top-level paragraphs
      if (!$scope.lease.restyle) {
        $scope.lease.restyle = [];
      }
      if (pid && !$scope.lease.restyle[pid]) {
        let originalMarginLeft = container.style.marginLeft || '0pt';
        let originalTextIndent = container.style.textIndent || '0pt';
        let originalTextAlign = container.style.textAlign || 'start';
        let originalFontSize = container.style.fontSize || $scope.defaultFont.fontSize;
        let originalLineHeight = container.style.lineHeight || $scope.defaultFont.lineHeight;
        let paragraphIndent = parseInt(originalMarginLeft) / window.CONSTS.STEP_SIZE;
        let textIndent = parseInt(originalTextIndent) / window.CONSTS.STEP_SIZE;
        let lineHeight = originalLineHeight;

        $scope.lease.restyle[pid] = {
          paragraphId: pid,
          originalParagraphIndent: originalMarginLeft,
          originalTextIndent: originalTextIndent,
          originalTextAlign: originalTextAlign,
          originalFontSize: originalFontSize,
          originalLineHeight: originalLineHeight,
          paragraphIndent: paragraphIndent,
          textIndent: textIndent,
          textAlign: originalTextAlign,
          fontSize: fontSize,
          lineHeight: lineHeight,
        };

        $scope.setOriginalStyle(container, $scope.lease.restyle[pid]);
      }

      rangy.restoreSelection(savedSelection, true);

      window.track.event(new ChangeFontSizeEvent({
        size: fontSize,
        isNewParagraph: !pid,
        context: $rootScope.getContext(),
      }));

      // Save the change of the fontSize in the data-model
      if (pid) {
        $scope.addToHistory({
          id: 'font-size',
          desc: '',
          redo: function() {
            const deferred = $q.defer();
            const blockData = container.getBlockData();

            container.setAttribute('data-font-size', fontSize);
            container.style.fontSize = fontSize;
            
            if (blockData && blockData.node) {
              blockData.node.style.fontSize = fontSize;
            }

            $scope.lease.restyle[pid].fontSize = fontSize;
            $scope.update();

            setTimeout(() => {
              $scope.listsRefresh();
            });

            deferred.resolve();
            return deferred.promise;
          },
          undo: function() {
            const deferred = $q.defer();
            const blockData = container.getBlockData();

            container.setAttribute('data-font-size', prevFontSize);
            container.style.fontSize = prevFontSize;

            if (blockData && blockData.node) {
              blockData.node.style.fontSize = fontSize;
            }

            $scope.lease.restyle[pid].fontSize = prevFontSize;
            $scope.update();

            setTimeout(() => {
              $scope.listsRefresh();
            });

            deferred.resolve();
            return deferred.promise;
          },
        });

        $scope.histIgnoreClearBatch += 2;
        $scope.histRegisterBatch($scope.histIndex + 1, 2);
      } else {
        if ($scope.isUnstyled(container)) {
          $scope.setOriginalStyle(container, $scope.lease.restyle[pid]);

          rangy.restoreSelection(savedSelection, true);
        }
        container.style.fontSize = fontSize;
      }
    };

    $scope.changeBlockLineHeight = function(lineHeight) {
      let currentUndoIndex = $scope.histIndex;
      
      const sel = rangy.getSelection();
      const startBlock = sel.anchorNode.getTopAnchor();
      const endBlock = sel.focusNode.getTopAnchor();
      const range = sel.getRangeAt(0);
      const blocks = range.getNodes([Node.ELEMENT_NODE], function(node) {
        return (
          ["P", "H1", "H2", "H3", "H4", "H5", "H6"].indexOf(node.tagName) > -1
        );
      });

      if (startBlock && startBlock.getAttribute && startBlock.getAttribute("pid") && blocks.indexOf(startBlock) === -1) {
        blocks.push(startBlock);
      }
      if (endBlock && endBlock.getAttribute && endBlock.getAttribute("pid") && blocks.indexOf(endBlock) === -1) {
        blocks.push(endBlock);
      }

      if (LeaseEditorService.isSelecting()) {
        let allParagraphs = blocks;
        let paragraphsIndex = allParagraphs.map(p =>{
          if (p.textContent.trim().startsWith("!!SET_LEVEL")) {
            return false;
          } else if (p.classList.contains('new-paragraph'))  {
            let nestedIndex = p.getAttribute('data-nested-paragraph-index');
            if (nestedIndex){
              return { type: "new-paragraph", id: nestedIndex };
            }
          } else {
            let pid = p.getAttribute('pid');
            if (pid){
              return { type: "paragraph", id: pid };
            }
          }
        });
        for (let index = 0; index < paragraphsIndex.length; ++index) {
          let item = paragraphsIndex[index];

          if (!item) {
            continue;
          }
          
          let p = null;
          if (item.type === "new-paragraph") {
            p = document.querySelector(`[data-nested-paragraph-index="${item.id}"]`);
          } else if (item.type === "paragraph") {
            p = document.querySelectorAll(`[pid="${item.id}"]`).lastItem();
          }

          if (p) {
            $scope.changeBlockLineHeightOnce(lineHeight, p);
          }
        }
      } else {
        $scope.changeBlockLineHeightOnce(lineHeight);
      }

      setTimeout(()=>{
        $scope.histRegisterBatch($scope.histStack.length - 1, $scope.histIndex - currentUndoIndex);
      });
    }

    $scope.changeBlockLineHeightOnce = function(lineHeight, element) {
      let node;
      let container;
      let contentEditable;
      let _isActiveImage = isActiveImage();
      if (element) {
        if (_isActiveImage) {
          node = document.querySelector('img.active');
        } else {
          node = element;
        }
        container = node;
        contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);

        if (!contentEditable) {
          const list = LeaseEditorService.findAllContentEditable(container);
          contentEditable = list[0];
        }
      } else {
        if (_isActiveImage) {
          node = document.querySelector('img.active');
        } else {
          const sel = rangy.getSelection();
          node = sel.focusNode;
        }
        container = node;
        contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);

        if (!(node.nodeType === Node.ELEMENT_NODE && node.classList.contains('new-paragraph'))) {
          container = LeaseEditorService.findClosestContainer(node).get(0);
        }

        if (!contentEditable || !container) {
          return;
        }
      }

      var prevLineHeight = "";
      if (container.style.lineHeight === "") {
        prevLineHeight = $scope.defaultFont.lineHeight;
      } else {
        prevLineHeight = container.style.lineHeight;
      }

      let pid;
      pid = container.getAttribute('pid');
      pid = getParagraphId(pid, true);

      // If this is not a `new-paragraph` and for some reason we couldn't find it,
      // don't allow to align the paragraph and - stop!
      if (!(pid || container.classList.contains('new-paragraph'))) {
        return;
      }

      const savedSelection = rangy.saveSelection();

      // We only save `restyle`s for top-level paragraphs
      if (!$scope.lease.restyle) {
        $scope.lease.restyle = [];
      }
      if (pid && !$scope.lease.restyle[pid]) {
        let originalMarginLeft = container.style.marginLeft || '0pt';
        let originalTextIndent = container.style.textIndent || '0pt';
        let originalTextAlign = container.style.textAlign || 'start';
        let originalFontSize = container.style.fontSize || $scope.defaultFont.fontSize;
        let originalLineHeight = container.style.lineHeight || $scope.defaultFont.lineHeight;
        let paragraphIndent = parseInt(originalMarginLeft) / window.CONSTS.STEP_SIZE;
        let textIndent = parseInt(originalTextIndent) / window.CONSTS.STEP_SIZE;
        let textAlign = originalTextAlign;
        let fontSize = originalFontSize;

        $scope.lease.restyle[pid] = {
          paragraphId: pid,
          originalParagraphIndent: originalMarginLeft,
          originalTextIndent: originalTextIndent,
          originalTextAlign: originalTextAlign,
          originalFontSize: originalFontSize,
          originalLineHeight: originalLineHeight,
          paragraphIndent: paragraphIndent,
          textIndent: textIndent,
          textAlign: textAlign,
          fontSize: fontSize,
          lineHeight: lineHeight,
        };

        $scope.setOriginalStyle(container, $scope.lease.restyle[pid]);
      }

      rangy.restoreSelection(savedSelection, true);

      window.track.event(new ChangeLineHeightEvent({
        size: lineHeight,
        isNewParagraph: !pid,
        context: $rootScope.getContext(),
      }));

      // Save the change of the lineHeight in the data-model
      if (pid) {
        $scope.addToHistory({
          id: 'line-height',
          desc: '',
          redo: function() {
            const deferred = $q.defer();
            const blockData = container.getBlockData();

            container.setAttribute('data-line-height', lineHeight);
            container.style.lineHeight = lineHeight;
            
            if (blockData && blockData.node) {
              blockData.node.style.lineHeight = lineHeight;
            }

            $scope.lease.restyle[pid].lineHeight = lineHeight;
            $scope.update();
            $scope.listsRefresh();

            deferred.resolve();
            return deferred.promise;
          },
          undo: function() {
            const deferred = $q.defer();
            const blockData = container.getBlockData();

            container.setAttribute('data-line-height', prevLineHeight);
            container.style.lineHeight = prevLineHeight;

            if (blockData && blockData.node) {
              blockData.node.style.lineHeight = lineHeight;
            }

            $scope.lease.restyle[pid].lineHeight = prevLineHeight;
            $scope.update();
            $scope.listsRefresh();

            deferred.resolve();
            return deferred.promise;
          },
        });

        $scope.histIgnoreClearBatch += 2;
        $scope.histRegisterBatch($scope.histIndex + 1, 2);
      } else {
        if ($scope.isUnstyled(container)) {
          $scope.setOriginalStyle(container, $scope.lease.restyle[pid]);

          rangy.restoreSelection(savedSelection, true);
        }
        container.style.lineHeight = lineHeight;
      }
    };

    $scope.restyle = function() {
      
      document.querySelectorAll('.list-item-paragraph img').forEach((item)=>{
        var block =  item.getBlockData();
        if(block){
          block.containingNode.classList.add('image-container')
        }

      });
      

      if (!$scope.lease.restyle) {
        $scope.lease.restyle = [];
      }

      if ($scope.lease.restyle.reduce) {
        $scope.lease.restyle = $scope.lease.restyle.reduce(function(o, v) {
          o[v.paragraphId] = v;
          return o;
        }, {});
      }

      const styles = $scope.lease.restyle;

      for (let pid in styles) {
        const container = getParagraph(pid, true);
        const style = styles[pid];

        if (!container) {
          continue;
        }

        if (!style) {
          continue;
        }

        container.setAttribute('data-original-paragraph-indent', style.originalParagraphIndent || '0pt');
        container.setAttribute('data-original-text-indent', style.originalTextIndent || '0pt');
        container.setAttribute('data-original-text-align', style.originalTextAlign || 'start');

        if (style.paragraphIndent !== null || style.paragraphIndent !== undefined) {
          const updatedIndentationSteps = style.paragraphIndent;
          const marginLeft = parseInt(updatedIndentationSteps * window.CONSTS.STEP_SIZE) + 'pt';
          container.style.marginLeft = marginLeft;
          container.setAttribute('data-paragraph-indent', updatedIndentationSteps);
        }

        if (style.textIndent !== null || style.textIndent !== undefined) {
          const updatedIndentationSteps = style.textIndent;
          const textIndent = parseInt(updatedIndentationSteps * window.CONSTS.STEP_SIZE) + 'pt';
          container.style.textIndent = textIndent;
          container.setAttribute('data-text-indent', updatedIndentationSteps);
        }

        if (style.textAlign) {
          container.style.textAlign = style.textAlign;
          container.setAttribute('data-text-align', style.textAlign);
        }

        if (style.marginTop) {
          container.style.marginTop = style.marginTop;
        }

        if (style.marginBottom) {
          container.style.marginBottom = style.marginBottom;
        }

        if (style.fontSize) {
          const blockData = container.getBlockData();
          container.style.fontSize = style.fontSize;

          if (blockData && blockData.node) {
            blockData.node.style.fontSize = style.fontSize;
          }
        }

        if (style.lineHeight) {
          container.style.lineHeight = style.lineHeight;
        }

        container.classList.add('restyled');
      }

      if (window.isDownload) {
        const newParagraphs = document.querySelectorAll('.new-paragraph');

        newParagraphs.forEach(paragraph => {
          if (!paragraph.closest('[advanced-edit')) {
            const container = LeaseEditorService.findClosestContainerAbove(paragraph).get(0);
            const parentIndent = container.style.marginLeft || '0pt';
            const paragraphIndent = paragraph.style.marginLeft || '0pt';
            paragraph.style.marginLeft = parseInt(parentIndent) + parseInt(paragraphIndent) + 'pt';
          }
        });
      }
    };

    /* ============= /Alignment & Indentation tools ============= */

    $scope.changeFontSize = async function(fontSize) {
      if (LeaseEditorService.isBlinkingCaret()) {
        $scope.changeBlockFontSize(fontSize);
      } else {
        var sel = rangy.getSelection();
        var range = sel.getRangeAt(0);
        const startBlock = sel.anchorNode.getTopAnchor();
        const endBlock = sel.focusNode.getTopAnchor();
        const savedSelection = rangy.saveSelection();
        range.refresh();
        $scope.toggleSearch(false);
  
        const blocks = range.getNodes([Node.ELEMENT_NODE], function(node) {
          return (
            ["P", "H1", "H2", "H3", "H4", "H5", "H6"].indexOf(node.tagName) > -1 && 
            node.getAttribute("pid") && 
            !node.classList.contains("lp-hide-element")
          );
        });
  
        if (startBlock.getAttribute("pid") && blocks.indexOf(startBlock) === -1) {
          blocks.push(startBlock);
        }
        if (endBlock.getAttribute("pid") && blocks.indexOf(endBlock) === -1) {
          blocks.push(endBlock);
        }
  
        const blocksToChange = [];
        blocks.forEach((block) => {
          const blockData = block.getBlockData();
  
          if (blockData.isFullySelected()) {
            blocksToChange.push({
              block, 
              blockData
            });
          }
        });
  
        if (blocksToChange.length > 0) {
          for (let i = 0; i < blocksToChange.length; i++) {
            $rootScope.placeCaretAtStart(blocksToChange[i].block.querySelector(".editable"));
            $scope.changeBlockFontSize(fontSize);
          };
          
          rangy.restoreSelection(savedSelection, true);
        }
  
        setTimeout(function() {
          if (window.getSelection().type === 'Range') {
            $scope.applyDecoration("fontSize", fontSize);
          }
          if (window.getSelection().type === 'Caret') {
            $scope.execCommand("fontSize", fontSize);
          }
          $(sel.anchorNode)
            .closest('.editable')
            .focus();
          $scope.setDecorationButtonsState();
        }, 1);
      }
    };

    $scope.toggleDecoration = function(btn) {
      var sel = rangy.getSelection();
      btn.state = !btn.state;

      $scope.toggleSearch(false);

      setTimeout(function() {
        if (window.getSelection().type === 'Range') {
          $scope.applyDecoration(btn.command);
        }
        if (window.getSelection().type === 'Caret') {
          $scope.execCommand(btn.command);
        }
        $(sel.anchorNode)
          .closest('.editable')
          .focus();
        $scope.setDecorationButtonsState();
      }, 1);
    };

    $scope.toggleColorDecoration = function(command, value) {
      var sel = rangy.getSelection();

      $scope.toggleSearch(false);

      setTimeout(function() {
        if (window.getSelection().type === 'Range')
          $scope.applyDecoration(command, value);
        if (window.getSelection().type === 'Caret')
          $scope.execCommand(command, value);
        $(sel.anchorNode)
          .closest('.editable')
          .focus();
      }, 1);

      window.track.event(new HighlightTextWithColorEvent({
        type: command,
        color: value,
        context: $rootScope.getContext(),
      }));
    };

    $scope.applyDecorationToSelection = function(
      decorationType,
      decorationFunc,
    ) {
      var savedSelection = rangy.saveSelection();
      var sel = rangy.getSelection();
      var originalRange = sel.getRangeAt(0);
      var range;
      originalRange.splitBoundaries();
      var freeTexts = [];
      var nodes = originalRange.getNodes([3], function(node) {
        const isEditable = $(node).closest('.editable').length > 0;
        const parentNode = node.parentElement;
        const isSOCE = $(parentNode).hasClass('SOCE');
        const isEmptyPlaceholder =
          $(parentNode).hasClass('PLACEHOLDER') &&
          (node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE);
        const isEOCE = $(parentNode).hasClass('EOCE');
        const isSkip = $(parentNode).hasClass('SKIP');
        const isListPadding =
          $(parentNode).hasClass('word-indent') &&
          parentNode.style.font.indexOf('7pt') !== -1;

        return (
          isEditable &&
          !isSOCE &&
          !isEmptyPlaceholder &&
          !isEOCE &&
          !isSkip &&
          !isListPadding
        );
      });

      let currentNode = null;
      for (var i = 0; i < nodes.length; i++) {
        currentNode = nodes[i];
        var sectionId = $(currentNode.parentElement)
          .closest('[section-id]')
          .attr('section-id');
        if (sectionId) freeTexts.push(sectionId);
        // Unselect everything
        sel.removeAllRanges();
        range = rangy.createRange();
        range.selectNode(currentNode);
        sel.addRange(range);

        if (
          !$scope.btns[decorationType] ||
          $scope.btns[decorationType].state !==
            document.queryCommandState(decorationType)
        ) {
          decorationFunc(nodes[i]);
        }
      }
      freeTexts = _.uniq(freeTexts);

      let freeTextId;
      let freeText;
      for (let i = 0; i < freeTexts.length; i++) {
        freeTextId = freeTexts[i];
        freeText = document.querySelector(`[free-text="${freeTextId}"]`);

        if (freeText) {
          $(freeText)
            .find('[contenteditable]')
            .trigger('change');
        }
      }
      
      if (freeTexts.length > 1) {
        $scope.histIgnoreClearBatch += freeTexts.length;
        $scope.histRegisterBatch(
          $scope.histIndex + freeTexts.length,
          freeTexts.length,
        );
      }

      setTimeout(() => {
        rangy.restoreSelection(savedSelection, true);
      }, 250);
    };

    $scope.execCommand = function(command, value) {
      if ($rootScope.adminMode) {
        LeaseEditorService.setContentEditableColor(window.CONSTS.ADMIN_COLOR);
      }

      document.execCommand('styleWithCSS', true, null);
      document.execCommand(command, false, value);

      var sel = rangy.getSelection();
      var originalRange = sel.getRangeAt(0);

      if ($scope.btns[command] && !$scope.btns[command].state) {
        var textNodes = originalRange.getNodes([3], function(node) {
          const isEditable = $(node).closest('.editable').length > 0;
          const parentNode = node.parentElement;
          const isSOCE = $(parentNode).hasClass('SOCE');
          const isEmptyPlaceholder =
            $(parentNode).hasClass('PLACEHOLDER') &&
            (node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE);
          const isEOCE = $(parentNode).hasClass('EOCE');
          const isSkip = $(parentNode).hasClass('SKIP');
          const isListPadding =
            $(parentNode).hasClass('word-indent') &&
            parentNode.style.font.indexOf('7pt') !== -1;

          return (
            isEditable &&
            !isSOCE &&
            !isEmptyPlaceholder &&
            !isEOCE &&
            !isSkip &&
            !isListPadding
          );
        });

        if (command === 'bold') {
          textNodes.forEach(node => {
            let parent = node.parentElement;

            if (parent.tagName.toLowerCase() !== 'span') {
              parent = parent.closest('span');
            }

            parent.style.fontWeight = 'normal';
          });
        } else if (command === 'underline') {
          textNodes.forEach(node => {
            let parent = node.parentElement;

            if (parent.tagName.toLowerCase() !== 'span') {
              parent = parent.closest('span');
            }
            parent.style.textDecoration = 'none';
          });
        } else if (command === 'italic') {
          textNodes.forEach(node => {
            let parent = node.parentElement;

            if (parent.tagName.toLowerCase() !== 'span') {
              parent = parent.closest('span');
            }
            parent.style.fontStyle = 'normal';
          });
        }
      } else if (command === 'underline'){
        let node = sel.anchorNode;
        if (node.nodeType === Node.TEXT_NODE) {
          node = node.parentElement;
        }
        if (node.style["textDecorationLine"] === "underline") {
          node.style["textDecorationLine"] = null;
          node.style["textDecoration"] = "underline"
        }
      } else if (command === 'fontSize') {
        let node = sel.anchorNode;

        if (node.nodeType === Node.TEXT_NODE) {
          node = node.parentElement;
        }

        node.style["fontSize"] = value;
      }
    };

    $scope.uploadImage = async function(file) {
      if(!file) {
        return;
      }

      if (file.name && _.endsWith(file.name.toLowerCase(), '.pdf')) {
        const images = await window.ImageHelper.getImage(file);
        $($scope.currentlyEditedNode).trigger('uploadImage', [images]);
      } else {
        var reader = new FileReader();
        reader.onload = function(image) {
          var sel = rangy.getSelection();
          if (!sel || !sel.anchorNode) {
            return;
          }
          var el = LeaseEditorService.findClosestContentEditable(sel.anchorNode);
          if (!el.length) {
            return;
          }
          $($scope.currentlyEditedNode).trigger('uploadImage', [image.target.result]);
        };
        reader.readAsDataURL(file);
      }
    }

    $scope.openUploadImgDialog = function() {
      document.getElementById('upload-image').click();
    }

    $scope.toggleControlCharacters = function() {
      $scope.isControlCharacters = !$scope.isControlCharacters;

      const spans = document
        .querySelector('.lease.lease--original')
        .querySelectorAll('.editable span');

      if ($scope.isControlCharacters) {
        window.track.event(new TurnOnControlCharactersEvent({
          context: $rootScope.getContext(),
        }));

        spans.forEach(span => {
          span.setAttribute('data-original-font-family', span.style.fontFamily);
          span.style.fontFamily = `lp, ${span.style.fontFamily}`;
        });
      } else {
        window.track.event(new TurnOffControlCharactersEvent({
          context: $rootScope.getContext(),
        }));

        spans.forEach(span => {
          span.style.fontFamily = span.getAttribute('data-original-font-family');
          span.removeAttribute('data-original-font-family');
        });
      }
    }

    var cancelContentEditable = function() {
      var sel = rangy.getSelection();
      LeaseEditorService.disableEditingForSegment(sel.anchorNode);
    };

    var applyContentEditable = function() {
      var sel = rangy.getSelection();
      LeaseEditorService.enableEditingForSegment(sel.anchorNode);
    };

    $('body').on('focus', 'input[type=number]', function (e) {
      $(this).bind('mousewheel', function(e){
        e.preventDefault();
      });
    });

    $('body').on('keydown', 'input[type=number]', function (e) {
      var key = e.charCode || e.keyCode;
      if (key == window.ENUMS.KEYS.UP_ARROW || key == window.ENUMS.KEYS.DOWN_ARROW) {
        e.preventDefault();
      } else {
        return;
      }
    });

    $scope.applyDecoration = function(command, value) {
      const contentEditable = LeaseEditorService.findAllContentEditableInSelection();
      contentEditable.forEach(item => {
        let current = item;
        if (current.get) {
          current = item.get(0);
        }

        current.setAttribute(
          'original-contenteditable',
          current.getAttribute('contenteditable'),
        );
        current.setAttribute('contenteditable', true);
      });

      if (window.getSelection) {
        applyContentEditable();
        // Indicate the decoration was executed by clicking on the buttons in the toolbar
        $scope.applyDecorationToSelection(command, function(textNode) {
          const contentEditable = textNode.parentElement.closest('[contenteditable="false"]');

          if (contentEditable) {
            contentEditable.setAttribute("contenteditable", "true");
            $scope.execCommand(command, value);
            contentEditable.setAttribute("contenteditable", "false");
          } else if (textNode.textContent !== "﻿" || textNode.textContent !== "​") {
            $scope.execCommand(command, value);
          }
        });

        cancelContentEditable();
      }

      contentEditable.forEach(item => {
        let current = item;
        if (current.get) {
          current = item.get(0);
        }

        if (current.getAttribute('original-contenteditable')) {
          current.setAttribute(
            'contenteditable',
            current.getAttribute('original-contenteditable'),
          );
          current.removeAttribute('original-contenteditable');
        }
      });
    };

    $scope.resetDecoration = function() {
      _.forEach($scope.btns, function(btn) {
        btn.state = false;
      });
    };

    $scope.setDecoration = function() {
      var savedSelection = rangy.saveSelection();
      var sel = rangy.getSelection();

      if (sel.rangeCount === 0) {
        return;
      }

      if (!sel.anchorNode) {
        return;
      }

      var originalRange = sel.getRangeAt(0);
      var range;
      var statusBtn = ['bold', 'italic', 'underline'];

      originalRange.splitBoundaries();
      var nodes = originalRange.getNodes([3], function(node) {
        const isEditable = $(node).closest('.editable').length > 0;
        const parentNode = node.parentElement;
        const isSOCE = $(parentNode).hasClass('SOCE');
        const isEmptyPlaceholder =
          $(parentNode).hasClass('PLACEHOLDER') &&
          (node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || node.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE);
        const isEOCE = $(parentNode).hasClass('EOCE');
        const isListPadding =
          $(parentNode).hasClass('word-indent') &&
          parentNode.style.font.indexOf('7pt') !== -1;
        const isSkip = parentNode.classList.contains('SKIP');

        return (
          isEditable &&
          !isSOCE &&
          !isEmptyPlaceholder &&
          !isEOCE &&
          !isListPadding &&
          !isSkip
        );
      });

      if (!nodes.length && !LeaseEditorService.isSelecting()) {
        nodes = [sel.focusNode];
      }

      // Lets stop this heavy process if the selection is unlikely to have a common decoration
      // Arbitrary, if the number of text nodes in the selection is greater than 150
      if (nodes.length > 150) {
        $scope.resetDecoration();
        return;
      }

      var btnState = nodes.length > 0;
      _.forEach(statusBtn, function(decoration) {
        if ($scope.btns[decoration]) {
          $scope.btns[decoration].state = btnState;
        }
      });

      for (var i = 0; i < nodes.length; i++) {
        if (nodes[i].style && nodes[i].style["textDecorationLine"] === "underline") {
          nodes[i].style["textDecorationLine"] = null;
          nodes[i].style["textDecoration"] = "underline"
        }
        if (LeaseEditorService.findClosestContentEditable(nodes[i]).length > 0) {
          if (LeaseEditorService.isSelecting()) {
            sel.removeAllRanges();
            range = rangy.createRange();
            range.selectNode(nodes[i]);
            sel.addRange(range);
          }
          _.forEach(statusBtn, function(decoration) {
            if (!document.queryCommandState(decoration)) {
              if ($scope.btns[decoration]) {
                $scope.btns[decoration].state = false;
              }
            }
          });
        }
      }
      // Bring back the original selection

      rangy.restoreSelection(savedSelection, true);
      $scope.setDecorationButtonsState();
    };

    $scope.setDecorationButtonsState = function() {
      // Set the actual state of the button since full "update" causes major angularJS cycle
      $('button.deco-button').each(function() {
        switch ($(this).attr('label')) {
          case 'B':
            $scope.setDecorationButtonState($(this), $scope.btns.bold);
            break;
          case 'I':
            $scope.setDecorationButtonState($(this), $scope.btns.italic);
            break;
          case 'U':
            $scope.setDecorationButtonState($(this), $scope.btns.underline);
            break;
        }
      });
    };

    $scope.setDecorationButtonState = function(button, buttonObj) {
      if (button) {
        if (buttonObj.state) {
          button.addClass('on' + buttonObj.label);
          button.removeClass('off' + buttonObj.label);
        } else {
          button.addClass('off' + buttonObj.label);
          button.removeClass('on' + buttonObj.label);
        }
      }
    };

    $scope.clearRestyle = function(container) {
      if (container.hasClass('restyled')) {
        let originalTextIndent = container.attr("data-original-text-indent");
        let originalParagraphIndent = container.attr("data-original-paragraph-indent");
        let originalTextAlign = container.attr("data-original-text-align");
        let originalFontSize = container.attr("data-original-font-size");
        let pid;

        pid = container.attr('pid');
        pid = getParagraphId(pid, true);

        container.css("text-indent", originalTextIndent || '');
        container.css("margin-left", originalParagraphIndent || '');
        container.css("text-align", originalTextAlign || '');
        container.css("font-size", originalFontSize || '');

        container.attr("data-original-text-indent", null);
        container.attr("data-original-paragraph-indent", null);
        container.attr("data-original-text-align", null);
        container.attr("data-original-font-size", null);
        container.attr("data-text-align", null);
        container.attr("data-paragraph-indent", null);
        container.attr("data-text-indent", null);
        container.attr("data-font-size", null);

        container.removeClass('restyled');

        $scope.lease.restyle[pid] = null;

        $scope.update();
      }
    }

    // reset free text areas in lease
    $scope.deleteFreeText = function(sectionId, isReset) {
      var deferred = $q.defer();
      var element = $('[section-id="' + sectionId + '"]');
      var container = LeaseEditorService.findClosestContainer(element);

      $scope.startSaving();
      // get the FreeText resource to delete
      var toRemove = $scope.leaseFreeTexts[sectionId];

      if (isReset) {
        $scope.clearRestyle(container);
      }

      // ensure that it exists (no bug letting the user click delete when not possible to delete)
      if (toRemove != null) {
        // must set to null since remove() does not automatically set it to null
        $scope.leaseFreeTexts[sectionId] = null;
        if (!$rootScope.adminMode) {
          // delete on server
          var url = '/api/free_texts/' + toRemove.id;
          FreeTextService.$delete(url).then(function() {
            deferred.resolve();
            $(container).removeClass('deleted-container');

            $scope.structureChanged();
            $scope.endSaving();
          });
        } else {
          $timeout(function() {
            $(container).removeClass('deleted-container');
            $scope.structureChanged();
          });
          deferred.resolve();
        }
      } else {
        deferred.resolve();
        $scope.endSaving();
      }
      return deferred.promise;
    };

    $scope.setHighlightScrollIndex = function() {
      if ($scope.inline.on) {
        var hls = $scope.getAllHls();
        var targetElements = hls.add('#active-element-anchor');
        var activeEl = $($scope.inline.$activeElm).is('lease-var')
          ? $($scope.inline.$activeElm).find('.adding')[0]
          : $scope.inline.$activeElm;

        var elementIndex = targetElements.index(activeEl);
        $scope.highlightScrollIndex =
          elementIndex == -1 ? 0 : elementIndex % hls.length;
      } else {
        var hls = $scope.getAllHls().filter("[exclude!='true']");
        var targetIndex = null;

        for (let i = 0; i < hls.length && targetIndex === null; i++) {
          if (isInView(hls[i])) {
            targetIndex = i;
          }
        }

        if (targetIndex === null) {
          targetIndex = 0;
        }

        $scope.highlightScrollIndex = targetIndex;
      }
    };

    $scope.scrollFirst = function() {
      window.applyFilterVisible(() => {
        $scope.setHighlightScrollIndex();
        var hls = $('.adding, .adding-para').filter("[exclude!='true']");
        var elements = hls.get();

        if (elements.length > 0) {
          if ($scope.inline.on) {
            $scope.scrollToElement($scope.inline.$activeElm);
          } else {
            $scope.scrollToElement($(elements[$scope.highlightScrollIndex]));
          }
        }
      });
    };

    $scope.scrollPrev = function() {
      window.applyFilterVisible(() => {
        // elements to scroll
        var hls = $scope.getAllHls().filter("[exclude!='true']");

        // step back (circular)
        if ($scope.highlightScrollIndex >= 1) {
          $scope.highlightScrollIndex--;
        } else {
          $scope.highlightScrollIndex = hls.length - 1;
        }

        if (hls.length) {
          var scrollElt = $(hls[$scope.highlightScrollIndex]);
          $scope.scrollToElement(scrollElt);
        }
      });
    };

    $scope.scrollNext = function() {
      // elements to scroll
      //var hls = $("*[class^='adding']");
      var hls = $scope.getAllHls().filter("[exclude!='true']");

      // step forward (circular)
      $scope.highlightScrollIndex = ++$scope.highlightScrollIndex % hls.length;

      if (hls.length) {
        var scrollElt = $(hls[$scope.highlightScrollIndex]);
        $scope.scrollToElement(scrollElt);
      }
    };

    /*
     * Scrolls to the given element in the lease preview panel.
     * @param scrollElt - a jQuery object
     *
     */
    $scope.scrollToElement = function(scrollElt) {
      var container = $('.preview');

      if (scrollElt && scrollElt.offset() && !isInView(scrollElt)) {
        // if you found it and it's visible
        // top of the screen
        var scrollLoc =
          scrollElt.offset().top -
          container.offset().top +
          container.scrollTop();

        // if element would be shorter than the screen height, scroll to a lower point so it's centered
        if (scrollElt.height() < $(container).height()) {
          scrollLoc -= ($(container).height() - scrollElt.height()) / 2;
        }

        // do the animation
        $(container).animate(
          {
            scrollTop: scrollLoc,
          },
          400,
        );
      }
    };

    const isInView = function(elem) {
      var docViewTop = $('.preview').offset().top;
      var docViewBottom = docViewTop + $('.preview').height();

      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();

      var isCompletelyInView =
        elemBottom <= docViewBottom && elemTop >= docViewTop;
      var isOverflowFromView =
        elemBottom > docViewBottom && parseInt(elemTop) === docViewTop;

      return isCompletelyInView || isOverflowFromView;
    };

    $scope.anchorScroll = function() {
      if (
        $window.prerenderReady &&
        (!$scope.inline.on || $scope.inline.pinned)
      ) {
        setTimeout(function() {
          $scope.scrollFirst();
        });
      }
    };

    $scope.setCalculatedExpirationDate = function() {
      if ($scope.lease) {
        var baseDate = $scope.lease.rentCommenceAt
          ? $scope.lease.rentCommenceAt
          : $scope.lease.estimatedCommencementDate;

        if (baseDate) {
          var deliveryDate = new Date(baseDate);
          // Add years = number of years in the lease
          var years = $scope.lease.leaseYears || 0;
          var months = $scope.lease.leaseMonths || 0;
          $scope.lease.calculatedExpirationDate = moment(deliveryDate)
            .add(years, 'year')
            .add(months, 'month')
            .toDate();
          // $scope.lease.calculatedExpirationDate = new Date(deliveryDate.setFullYear(deliveryDate.getFullYear() + $scope.lease.leaseYears));

          // Subtruct the last day
          $scope.lease.calculatedExpirationDate.setDate(
            $scope.lease.calculatedExpirationDate.getDate() - 1,
          );
        }
      }
    };

    $scope.isDate = function(date) {
      return moment(date).isValid();
    };

    $scope.addYears = window.datesHelper.addYears;

    $scope.addMonths = window.datesHelper.addMonths;

    $scope.addDays = window.datesHelper.addDays;

    $scope.firstDayOfMonth = window.datesHelper.firstDayOfMonth;

    $scope.isFirstDayOfMonth = window.datesHelper.isFirstDayOfMonth;

    $scope.lastDayOfMonth = window.datesHelper.lastDayOfMonth;

    $scope.isLastDayOfMonth = window.datesHelper.isLastDayOfMonth;

    $scope.formatDateAsMonthDayYear = window.datesHelper.formatDateAsMonthDayYear;

    $scope.formatDateAsText = window.datesHelper.formatDateAsText;

    $scope.formatDateAs = window.datesHelper.formatDateAs;

    $scope.setListItems = function() {
      var deferred = $q.defer();
      var promises = [];

      if ($scope.listLevels) {
        var articleTypeExists = $scope.listLevels.find(function(ele) {
          return ele.level == "article";
        });
        var sectionTypeExists = $scope.listLevels.find(function(ele) {
          return ele.level == "section";
        });
        if (!articleTypeExists) {
          $("#article-buttons").addClass("ng-hide");
        }
        if (!sectionTypeExists) {
          $("#list-buttons").addClass("ng-hide");
        }
      } else {
        $("#article-buttons").addClass("ng-hide");
        $("#list-buttons").addClass("ng-hide");
      }

      // loads the list items json from the lease - if it has not been used yet, puts an empty list
      const overrides = $scope.overrideOrderedListitem;
      const listItems = $scope.lease.orderedListitem
        ? JSON.parse($scope.lease.orderedListitem)
        : [];

      const allListItems = overrides.concat(listItems);

      // goes through each item and inserts the list item

      /* mark old added articles Start  */
      $scope.listItems = allListItems;
      allListItems.forEach(item => {
        if (item.level.toLowerCase() === "article") {
          if (Object.keys(item).indexOf("isNewArticle") === -1) {
            item.isNewArticle = false;
          }
        }
      });
      /* mark old added articles End  */

      $rootScope.listItemsPromises = {};

      if (!$scope.lease.layers || !Array.isArray($scope.lease.layers)) {
        $scope.lease.layers = [];
      }

      allListItems.forEach(function(itemObject) {
        if (itemObject && itemObject.afterFreeText && pageLayersManager) {
          pageLayersManager.updateOutlineById(itemObject.afterFreeText);
        }

        listItemsManager.listsInsertItem(itemObject);
      });

      var promises = _.chain($rootScope.listItemsPromises)
        .mapValues(function(val) {
          return val.promise;
        })
        .values()
        .value();

      $q.all(promises).then(function() {
        $scope.reInsertMissingListItems();
        deferred.resolve();
        delete $rootScope.listItemsPromises;
      });

      return deferred.promise;
    };

    $scope.getPctRentEscalatedUnnaturalBreakpoint = function(year) {
      if ($scope.lease) {
        var value = $scope.lease.pctRentBaseGrossSales;

        if (value !== '' && value != null) {
          if (year == 0) {
            return value;
          } else if (year) {
            for (var i = 1; i <= year; i++) {
              value =
                value *
                (1 + $scope.lease.percentageRentUnnaturalEscalation / 100);
            }
            return value;
          }
        }
      }

      // Intentionally return NaN to prevent showing $0.00 when number is not set
      return '-';
    };

    $rootScope.isBuildingLanguageNeeded = false;
    $rootScope.isBuildingLanguageLoaded = false;
    $scope.setBuildingUrl = function(specificUrl) {
      if (
        !window.isAdmin &&
        $rootScope.isBuildingLanguageNeeded &&
        $rootScope.isBuildingLanguageLoaded
      ) {
        return $scope.specificUrl;
      }

      var parts;

      specificUrl = specificUrl.toString();
      parts = specificUrl.split('/');

      if (parts.length == 2) {
        if ($scope.lease && typeof $scope.lease.error === 'undefined') {
          var buildingFileUrl = parts[1];

          specificUrl =
            $scope.ASSETS_BUILDING_DIR_URL +
            '/' +
            buildingFileUrl;

          $rootScope.isBuildingLanguageNeeded = true;
        } else {
          specificUrl = '';
        }
      }

      if (
        $rootScope.isBuildingLanguageNeeded &&
        !$rootScope.isBuildingLanguageLoaded
      ) {
        $scope.$on('$includeContentLoaded', function(event, src) {
          if (specificUrl === src) {
            $rootScope.isBuildingLanguageLoaded = true;
          }
        });
        $scope.$on('$includeContentError', function(event, src) {
          if (specificUrl === src) {
            $rootScope.isBuildingLanguageLoaded = true;
          }
        });
      }

      $scope.specificUrl = specificUrl;

      return $scope.specificUrl;
    };

    function setBuildingOverrides(buildingOverrides) {
      var data = {};
      buildingOverrides.forEach(function(bo, i) {
        data[bo.buildingId] = data[bo.buildingId] || {};
        data[bo.buildingId][bo.bsName] = buildingOverrides[i];
      });
      $rootScope.buildingOverrides = data;
    }

    $scope.getOverrides = function() {
      $scope.inInitialLoad = false;
      setBuildingOverrides($scope.lease.form.buildingOverrides || []);

      var ordered_listitem = _.find($scope.lease.form.overrides, function(o) {
        return o.overrideType === 'ordered_listitem';
      });

      const overrideRestyle = _.find($scope.lease.form.overrides, function(o) {
        return o.overrideType === 'restyle';
      });
      
      let restyleData = null;
      if(overrideRestyle){
        restyleData = JSON.parse( overrideRestyle.orderedListitem);
        if(!lease.restyle || Object.keys(lease.restyle).length == 0){
          lease.restyle = restyleData;
        }
      }

      const overrideLayers = _.find($scope.lease.form.overrides, function(o) {
        return o.overrideType === 'layers';
      });

      let layersData = null;
      if(overrideLayers){
        layersData = JSON.parse( overrideLayers.orderedListitem);
        if( lease.layers == null || Object.keys(lease.layers).length == 0){
          lease.layers = layersData;

        }
      }


      $scope.overrideOrderedListitem = [];

      if (ordered_listitem && !$rootScope.adminMode) {
        $scope.overrideOrderedListitem = ordered_listitem.orderedListitem
          ? JSON.parse(ordered_listitem.orderedListitem)
          : [];
      }

      $scope.overrides = $scope.lease.form.overrides;
    };

    $scope.setOverrides = function() {
      var overrides = $scope.overrides
        ? $scope.overrides.reduce(function(o, v) {
            v.type = 'override';
            o[v.sectionId] = v;
            return o;
          }, {})
        : {};
      // concat overrides to freeText list
      $scope.leaseFreeTexts = Object.assign(overrides, $scope.leaseFreeTexts);
      if ($rootScope.adminMode) {
        $scope.prevOverrides = $scope.leaseFreeTexts;
      }
    };

    $scope.setAdminBuildings = function() {
      BuildingService.query({
        companyId: $rootScope.editedCompanyId,
        includeBlanks: true,
      }).then(function(response) {
        $scope.adminBuildings = response;
        $scope.currentAdminBuilding = response[0];
      });
    };

    $scope.openConfirmModal = function(
      msg,
      okCallback,
      cancelCallback,
      buttons,
      redirectUrl,
    ) {
      var confirm = $mdDialog.confirm({
        skipHide: true,
        template:
          '<div class="confirm-modal md-dialog-content">' +
          '<p class="title" >' +
          msg +
          '</p>' +
          '<div class="modal-actions">' +
          '<button ng-if=' +
          _.has(buttons, 'ok') +
          ' class="btn blue-outline" ng-click="dialog.hide()">' +
          buttons.ok +
          '</button>' +
          '<button ng-if=' +
          _.has(buttons, 'cancel') +
          ' class="btn blue active" ng-click="dialog.abort()">' +
          buttons.cancel +
          '</button>' +
          '</div>' +
          '</div>',
        onRemoving: function() {
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        },
      });

      $mdDialog.show(confirm).then(
        function confirmPublish() {
          if (okCallback) okCallback();
        },
        function preventPublish() {
          if (cancelCallback) cancelCallback();
        },
      );
    };

    $scope.confirmPublish = function(pathToRedirect) {
      var msg =
        'Note: changes are made to the form and are not asset specific. Publishing the form will update this form template language for new deals, are you sure?';
      $scope.openConfirmModal(
        msg,
        function confirmPublish() {
          $scope.saveOverride(true, pathToRedirect);
        },
        null,
        {
          ok: 'Yes',
          cancel: 'No',
        },
      );
    };

    $scope.confirmDiscard = function(pathToRedirect) {
      var msg = 'All last changes will be discarded, continue?';
      $scope.openConfirmModal(
        msg,
        function confirmDiscard() {
          document.querySelectorAll('[override=true]').forEach(function(el) {
            el.removeAttribute("override");
          });

          window.location = pathToRedirect;
        },
        null,
        {
          ok: 'Yes',
          cancel: 'No',
        },
      );
    };

    $scope.setAdminMode = function(lease, role) {
      lease = lease || window.lease;
      $rootScope.adminMode = true;
      RolesService.setRole(role);
      $rootScope.editedCompanyId = lease.companyId;
      $scope.setAdminBuildings();
      $scope.setLease(lease);
    };

    function setLeaseTerms() {
      if (!$scope.lease.terms) {
        $scope.lease.terms = [];
      }

      if ($scope.lease.terms.length === 0) {
        $scope.lease.terms.push({});
      }

      if (
        $scope.lease.terms.length === 1 &&
        !$scope.lease.terms[0].isInitialized
      ) {
        if (
          $scope.lease.form.editor &&
          $scope.lease.form.editor.defaultFields.terms &&
          $scope.lease.form.editor.defaultFields.terms.length
        ) {
          $scope.lease.terms[0] = {
            ...$scope.lease.terms[0],
            ...$scope.lease.form.editor.defaultFields.terms[0],
          };
        }
        $scope.lease.terms[0].isInitialized = true;
      }
      $scope.setAvailableSpaces();
    }

    $scope.setAvailableSpaces = function() {
      var termsSpaces = _.flatMap(
        _.flatMap($scope.lease.terms, 'spaces'),
        'number',
      );
      $scope.availableSpaces = _.filter($scope.lease.premiseSuites, function(
        suite,
      ) {
        return !termsSpaces.includes(suite.number);
      });
    };

    $scope.setLeaseDefaults = function() {
      if ($scope.lease.guarantorInfo) {
        $scope.lease.individualGuarantors = $scope.lease.guarantorInfo
          .individuals
          ? $scope.lease.guarantorInfo.individuals.length > 0
            ? $scope.lease.guarantorInfo.individuals.length
            : 0
          : 0;
        $scope.lease.marriedGuarantors = $scope.lease.guarantorInfo
          .marriedCouples
          ? $scope.lease.guarantorInfo.marriedCouples.length > 0
            ? $scope.lease.guarantorInfo.marriedCouples.length
            : 0
          : 0;
        $scope.lease.entityGuarantors = $scope.lease.guarantorInfo.entities
          ? $scope.lease.guarantorInfo.entities.length > 0
            ? $scope.lease.guarantorInfo.entities.length
            : 0
          : 0;
        if (
          $scope.lease.guarantorInfo.guarantors &&
          $scope.lease.guarantorInfo.guarantors.length
        ) {
          initGuarantorsFromOldObject();
        }
        $scope.setGuarantorLists();
        $scope.initCombinedGuarantorEntities($scope.lease.guarantorInfo);
        $scope.initCombinedGuarantorEntities($scope.lease.existingGuarantorInfo);
      }

      if (!$scope.lease.existingGuarantorInfo) {
        $scope.lease.existingGuarantorInfo = {};
      }

      if (!$scope.lease.minimumSales) {
        $scope.lease.minimumSales = [];
        for (var i = 0; i < $scope.lease.termNumber; i++) {
          $scope.lease.minimumSales.push({
            value: undefined,
          });
        }
      }

      setLeaseTerms();

      if (!$scope.lease.rentBumps) {
        $scope.lease.rentBumps = [
          {
            begin: 1,
          },
        ];
      }
      if (!$scope.lease.expansion || !$scope.lease.expansion.rentBumps) {
        $scope.lease.expansion = {};
        $scope.lease.expansion.rentBumps = [
          {
            begin: 1,
          },
        ];
      }
      if (!$scope.lease.relocation || !$scope.lease.relocation.rentBumps) {
        $scope.lease.relocation = {};
        $scope.lease.relocation.rentBumps = [
          {
            begin: 1,
          },
        ];
      }
      if (!$scope.lease.reduction || !$scope.lease.reduction.rentBumps) {
        $scope.lease.reduction = {};
        $scope.lease.reduction.rentBumps = [
          {
            begin: 1,
          },
        ];
      }

      if ($scope.lease.existingGuarantorInfo) {
        if (!$scope.lease.existingGuarantorInfo.individuals) {
          $scope.lease.existingGuarantorInfo.individuals = [];
        }
        if (!$scope.lease.existingGuarantorInfo.marriedCouples) {
          $scope.lease.existingGuarantorInfo.marriedCouples = [];
        }
        if (!$scope.lease.existingGuarantorInfo.entities) {
          $scope.lease.existingGuarantorInfo.entities = [];
        }
        $scope.lease.individualExistingGuarantors = $scope.lease
          .existingGuarantorInfo.individuals
          ? $scope.lease.existingGuarantorInfo.individuals.length > 0
            ? $scope.lease.existingGuarantorInfo.individuals.length
            : 1
          : 1;
        $scope.lease.marriedExistingGuarantors = $scope.lease
          .existingGuarantorInfo.marriedCouples
          ? $scope.lease.existingGuarantorInfo.marriedCouples.length > 0
            ? $scope.lease.existingGuarantorInfo.marriedCouples.length
            : 0
          : 0;
        $scope.lease.entityExistingGuarantors = $scope.lease
          .existingGuarantorInfo.entities
          ? $scope.lease.existingGuarantorInfo.entities.length > 0
            ? $scope.lease.existingGuarantorInfo.entities.length
            : 0
          : 0;
      }

      if (!$scope.lease.openingCoTenancy) {
        $scope.lease.openingCoTenancy = {};
      }

      if (!$scope.lease.openingCoTenancy.minNamedTenants) {
        $scope.lease.openingCoTenancy.minNamedTenants = 1;
      }

      if (!$scope.lease.ongoingCoTenancy) {
        $scope.lease.ongoingCoTenancy = {};
      }

      if (!$scope.lease.ongoingCoTenancy.minNamedTenants) {
        $scope.lease.ongoingCoTenancy.minNamedTenants = 1;
      }

      if (!$scope.lease.calc) {
        $scope.lease.calc = {};
      }

      // init array variables.
      var arrays = [
        'reductionPeriods',
        'renewalInfo',
        'guarantyCapBurnOffReductionPeriods',
        'abatementDates',
        'premiseSuites',
        'previousAmendment',
        'priorAssignments',
        'rentPeriods',
        'freeRentPeriods',
        'consentContingencies',
      ];

      for (var i = 0; i < arrays.length; i++) {
        if (!$scope.lease[arrays[i]]) {
          $scope.lease[arrays[i]] = [];
        }
      }

      if (!$scope.lease.event) {
        $scope.lease.event = {};
      }

      if (!$scope.lease.event.times) {
        $scope.lease.event.times = [];
      }

      if (!$scope.lease.ongoingCoTenancy.namedTenantsList) {
        $scope.lease.ongoingCoTenancy.namedTenantsList = [];
      }

      if ($scope.lease.securityDepositCents != 0) {
        $scope.lease.securityDeposit = 1 * (
          $scope.lease.securityDepositCents / 100
        ).toFixed(2);
      }

      if ($scope.lease.rentCommenceAt) {
        $scope.lease.rentCommenceAt = new Date($scope.lease.rentCommenceAt);
      }

      if ($scope.lease.rentCommenceAt && $scope.lease.leaseYears) {
        $scope.setCalculatedExpirationDate();
      }

      if (
        $scope.lease.rentInputType === 'automatic' &&
        $scope.lease.rentPeriods
      ) {
        $scope.refreshRentParams($scope.lease.rentPeriods);
      }

      if ($scope.lease.renewalInfo && $scope.lease.renewalInfo.length > 0) {
        if (
          $scope.lease.renewalCount === null ||
          typeof $scope.lease.renewalCount === 'undefined'
        ) {
          $scope.lease.renewalCount = $scope.lease.renewalInfo.length;
        }
        if (
          $scope.lease.renewalTerm === null ||
          typeof $scope.lease.renewalTerm === 'undefined'
        ) {
          $scope.lease.renewalTerm =
            $scope.lease.renewalInfo[0].renewalRentPeriods.length;
        }
      }

      if(!$scope.lease['renewalRentBumps']) {
        $scope.lease['renewalRentBumps'] = [];
      }

      for(let i = 0; i < $scope.lease.renewalCount ; ++i) {
        if (!$scope.lease['renewalRentBumps'][i]) {
          $scope.lease['renewalRentBumps'][i] = {
            periods : [
              {
                begin: 1,
              },
            ],
          };
        }
      }

      if (
        !$scope.lease.rentPeriods || 
        $scope.lease.rentPeriods.length === 0
      ) {
        $scope.lease.rentPeriods = [];

        setTimeout(function() {
          $scope.updateNumberOfRentPeriods($scope.lease.leaseYears, $scope.lease.leaseMonths);
        });
      }

      if ($scope.lease.rentPeriods) {
        $scope.consolidateRentPeriods();
      }

      if (
        $scope.lease.renewalInfo.length !== $scope.lease.renewalCount || 
        ($scope.lease.renewalInfo[0] && $scope.lease.renewalInfo[0].renewalRentPeriods.length !== $scope.lease.renewalTerm)
      ) {
        $scope.updateNumberOfRenewalTermsAndRentPeriods($scope.lease.renewalCount, $scope.lease.renewalTerm);
      }

      if ($scope.lease.renewalInfo) {
        $scope.consolidateRenewalInfo();
      }

      if ($scope.lease.landlordWarranty) {
        $scope.countWarranties();
      }

      //convert date fields
      datesConvert($scope.lease);

      if ($scope.lease.leaseYears) {
        $scope.cpiAnchorYearsOptions = Array.from(
          new Array($scope.lease.leaseYears - 1),
          function(val, index) {
            return index + 1;
          },
        );
      }

      var listOfLeaseYears = [];
      var listOfLeaseYearsWords = {};
      let listOfLeaseYearsLength;

      if ($rootScope.hasEditorConfig("optionalExtensionTermLength")) {
        listOfLeaseYearsLength = 20;
      } else {
        listOfLeaseYearsLength = $scope.lease.leaseYears || 0;
      }

      for (var i = 0; i < listOfLeaseYearsLength; i++) {
        listOfLeaseYears.push(i + 1);
        listOfLeaseYearsWords[i + 1] = 'Year ' + (i + 1);
      }

      $scope.lease.listOfLeaseYears = listOfLeaseYears;
      $scope.lease.listOfLeaseYearsWords = listOfLeaseYearsWords;

      if ($scope.lease.renewalTerm) {
        $scope.cpiRenewalAnchorYearsOptions = Array.from(
          new Array($scope.lease.renewalTerm - 1),
          function(val, index) {
            return index + 1;
          },
        );
      }

      if ($scope.lease.tenantInfo) {
        if (!$scope.lease.tenantInfo.individuals) {
          $scope.lease.tenantInfo.individuals = [{}];
        }
        if ($scope.lease.tenantInfo.entityType === 'Other')
          $scope.lease.tenantInfo.entityTypeCombined =
            $scope.lease.tenantInfo.entityTypeOther;
        else
          $scope.lease.tenantInfo.entityTypeCombined =
            $scope.lease.tenantInfo.entityType;
      } else {
        $scope.lease.tenantInfo = {
          individuals: [{}],
        };
      }

      if  ($scope.lease.assigneeInfo) {
        if (!$scope.lease.assigneeInfo.individuals) {
          $scope.lease.assigneeInfo.individuals = [{}];
        }
      }

      if ($scope.lease.reductionPeriods) {
        $scope.lease.reductionPeriodsNumber =
          $scope.lease.reductionPeriods.length;
        for (var i = 0; i < $scope.lease.reductionPeriods.length; i++) {
          $scope.lease.reductionPeriods[i].date = moment(
            $scope.lease.reductionPeriods[i].date,
            'YYYY-MM-DDTHH:mm:ssZ',
          ).toDate();
        }
      }
      if ($scope.lease.guarantyCapBurnOffReductionPeriods) {
        $scope.lease.guarantyCapBurnOffReductionPeriodsNumber =
          $scope.lease.guarantyCapBurnOffReductionPeriods.length;
      }
      if ($scope.lease.previousAmendment) {
        $scope.lease.previousAmendmentsNumber =
          $scope.lease.previousAmendment.length;
      }
    };

    $scope.broadcastCustomEvent = function(file) {
      if ($window.frameElement) {
        console.log(
          new Date().getTime() +
            ' completed loading. broadcasting event with file: ',
          file,
        );
        var eventName = $window.frameElement.getAttribute('event-name');
        var parentScope = $window.parent.angular
          .element($window.frameElement)
          .scope();
        parentScope.$broadcast(eventName, {
          file: file,
        });
      }
    };

    function hideEmptyElements() {
      var sectionIds = _.keys($scope.leaseFreeTexts);
      _.each(sectionIds, function(sectionId) {
        LeaseEditorService.setDeletedParagraphVisibility(
          $('[section-id="' + sectionId + '"]'),
        );
      });
    }

    /**
     * ================================ Version Control ================================
     */


    let throttleListItemsSaveTimer = null;
    function throttleListItemsSave (func,time) {
     clearTimeout(throttleListItemsSaveTimer);
     throttleListItemsSaveTimer = setTimeout(func,time);
    }

    function updateListItems () {
      $scope.update();
      listItemsManager.createListItemsMap();
    }

    $scope.diffgram = {
      on: false,
      processing: false,
      individualRequest: false,
      isPreview: false,
    };
    $scope.annotationsHelper = {
      classes: {
          accepted: 'diff-item--accepted',
          rejected: 'diff-item--rejected',
          resolved: 'diff-item--resolved',
        },
      timers : {
        updateTablesVisibilityTimer: null,
      },
      helpers: {
        getUniqItems: function (src) {
          return  _.uniq(src);
        },
      },
      actions: {
        updateTablesVisibility : function(){
          _.throttle($scope.annotationsHelper.actions.updateTablesVisibilityInternal,500,{ trailing: true })();
        },
        updateTablesVisibilityInternal : function(){
          if(!$scope.annotationsHelper.tables){
            const tblList = [];

            document.querySelectorAll('.lease--original table del').forEach((el)=>{
              var tbl = el.closest('table');
              if(tblList.indexOf(tbl) === -1){
                tblList.push(tbl)
              }
            });
            $scope.annotationsHelper.tables = tblList;
          }
          $scope.annotationsHelper.tables.forEach((tableElement)=>{
            const freeTextInTable = tableElement.querySelectorAll('[free-text]');

            if(freeTextInTable.length !== 0 &&  freeTextInTable.length ===
              tableElement.querySelectorAll('del.diff-item--accepted [free-text]').length){
                tableElement.classList.add('hidden-table');
            }
            else{
              tableElement.classList.remove('hidden-table');
            }

          });
        },
        resolveAnnotation: function (diffItem) {
          const dataIndex = parseInt(diffItem);
          $scope.diffgram.resolved = document.querySelectorAll('.lease--modified .diff-item.diff-item--primary.diff-item--resolved');

          $scope.annotationsHelper.setDiffgramItemActions(
            $scope.diffgram.items[dataIndex],
          );
        },
        focusToSection : function (pid){
          if(pid){
            const el = document.querySelector('[section-id="'+pid+'"]');
            if(el)
            {
              const anchor = $(el)
              .find('.SOCE')
              .get(0);
              $rootScope.placeCaretAtStart(anchor);
            }
          }
        },
        acceptAndSave: async function (src) {
          let currentUndoIndex = $scope.histIndex;
          let sectionsToSave = [];
          let deferred = $q.defer();
          let annotation = src.items ? src :null;
          let sourceItem = src.items ? null :src;

          if (annotation) {
            for (let i = 0; i < annotation.items.length; i++) {
              let currentRequest = annotation.items[i];
              if (!currentRequest._showAcceptReject) {
                continue;
              }
              let item = document.querySelector(`.diff-item[diff-index="${currentRequest._index}"]`);
              sectionsToSave = [...sectionsToSave, ...await $scope.annotationsHelper.actions._accept(item)];
            }
          } else if (sourceItem) {
            sectionsToSave = await $scope.annotationsHelper.actions._accept(sourceItem);
          }

          sectionsToSave = $scope.annotationsHelper.helpers.getUniqItems(sectionsToSave);
          let elementsToSave = [];

          sectionsToSave.forEach((sectionId) => {
            elementsToSave.push(document.querySelector('[section-id="' + sectionId + '"]'));
          });
          createBulk(elementsToSave, true);
          $rootScope.waitForSave(deferred);
          await deferred.promise;
          $scope.histRegisterBatch($scope.histStack.length - 1, $scope.histIndex - currentUndoIndex);
          $scope.annotationsHelper.actions.focusToSection(sectionsToSave[0]);
        },
        rejectAndSave: async function (src) {
          let currentUndoIndex = $scope.histIndex;
          let sectionsToSave = [];
          let deferred = $q.defer();

          let annotation = src.items ? src :null;
          let sourceItem = src.items ? null :src;

          if (annotation) {
            for (let i = 0; i < annotation.items.length; i++) {
              let currentRequest = annotation.items[i];
              if (!currentRequest._showAcceptReject) {
                continue;
              }
              let item = document.querySelector(`.diff-item[diff-index="${currentRequest._index}"]`);
              sectionsToSave = [...sectionsToSave, ...await $scope.annotationsHelper.actions._reject(item)];
            }
          } else if (sourceItem) {
            sectionsToSave = await $scope.annotationsHelper.actions._reject(sourceItem);
          }

          sectionsToSave = $scope.annotationsHelper.helpers.getUniqItems(sectionsToSave);
          let elementsToSave = [];

          sectionsToSave.forEach((sectionId) => {
            elementsToSave.push(document.querySelector('[section-id="' + sectionId + '"]'));
          });
          createBulk(elementsToSave, true);
          $rootScope.waitForSave(deferred);
          await deferred.promise;
          $scope.histRegisterBatch($scope.histStack.length - 1, $scope.histIndex - currentUndoIndex);
          $scope.annotationsHelper.actions.focusToSection(sectionsToSave[0]);
        },
        _accept: async function (item) {
          const itemId = item.getAttribute('diff-index');
          let diffItemObject = {
            ...$scope.diffgram.items[itemId]
          };
          const actionId = 'version-control' + itemId;
          const annotationElement = document.querySelector('.annotation [diff-index="' + itemId + '"]').closest('.annotation');
          const annotationId = annotationElement.getAttribute('data-pid');
          const singleRequestElements = Array.from(document.querySelectorAll('[diff-index="' + itemId + '"]'));
          const allRequestsIds = $scope.annotationsHelper.actions.getAllRequestsIds(annotationElement);
          const allRequestsElements = $scope.annotationsHelper.actions.getAllRequestsElements(allRequestsIds);
          const annotationObject = {
            ...$scope.annotations[annotationId]
          };
          let sectionsIdsToSave = [];
          let classListArray = [];
          let containsListItems = false;
          let scopeListItems = [];
          $scope.listItems.forEach((item) => {
            scopeListItems.push({
              ...item
            });
          });
          let leaseListItems = $scope.lease.orderedListitem;

          allRequestsElements.forEach((element) => {
            classListArray.push([...element.classList]);
          });
          singleRequestElements.forEach((element) => {
            let listHeader = element.getAttribute('list-header');
            if (listHeader && listHeader.trim() !== '') {
              containsListItems = true;
            }
            if (element.querySelector('[list]')) {
              containsListItems = true;
            }
          });

          let redo = function (itemId, sectionsIdsToSave, containsListItems, scopeListItems, leaseListItems, $scope, annotationId) {
            return function () {
              const singleRequestElements = Array.from(document.querySelectorAll('[diff-index="' + itemId + '"]'));
              const allRequestsElements = $scope.annotationsHelper.actions.getAllRequestsElements(allRequestsIds);
              const deferred = $q.defer();

              $scope.annotationsHelper.jumpToAnnotation(annotationId, true);
              $scope.annotationsHelper.exitIndividualRequestMode();
              $scope.diffgram.items[itemId]._status = "accepted";

              allRequestsElements.forEach((element, index) => {
                if (classListArray[index].indexOf('diff-item--current') !== -1) {
                  if (
                    (
                      classListArray[index].indexOf('diff-item--partial') !== -1 &&
                      classListArray[index].indexOf('diff-item--partial--first') !== -1
                    ) ||
                    classListArray[index].indexOf('diff-item--partial') === -1
                  ) {
                    element.classList.remove('diff-item--current');
                    $scope.annotationsHelper.enterIndividualRequestMode(element.getAttribute('diff-index'));
                  }
                }
              });
              singleRequestElements.forEach((element) => {
                element.classList.add($scope.annotationsHelper.classes.accepted, $scope.annotationsHelper.classes.resolved);
                element.classList.remove($scope.annotationsHelper.classes.rejected);
                let listHeader = element.getAttribute('list-header');
                // handle accept list item
                if (listHeader && listHeader.trim() !== '' && element.closest('.lease--original')) {
                  if (isDeletion(element)) {
                    let orderListItem = listItemsManager.getInsertedListItemByElement(element);
                    if (orderListItem) {
                      orderListItem.deleted = true;
                      $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
                    }
                  } else if (isInsertion(element)) {
                    $scope.annotationsHelper.actions.acceptListItem(element);
                  }
                }
                let editableElement = element.closest('.editable');
                const editableElements = editableElement ? [editableElement] : element.querySelectorAll('.editable');
                editableElements.forEach((el) => {
                  sectionsIdsToSave.push(el.getAttribute('section-id'));
                });
              });
              if (containsListItems) {
                throttleListItemsSave(updateListItems, 250);
              }
              $scope.annotationsHelper.actions.resolveAnnotation(itemId);
              deferred.resolve();
              $scope.annotationsHelper.actions.updateTablesVisibility();
              return deferred.promise;
            }
          };

          let undo = function (itemId, classListArray, containsListItems, scopeListItems, leaseListItems, diffItemObject, annotationId, annotationObject, $scope) {
            return function () {
              const allRequestsElements = $scope.annotationsHelper.actions.getAllRequestsElements(allRequestsIds);
              const deferred = $q.defer();

              for (let index = 0; index < allRequestsElements.length; index++) {
                const element = allRequestsElements[index];
                element.classList = classListArray[index].join(" ");
              }

              $scope.annotationsHelper.jumpToAnnotation(annotationId, true);
              $scope.annotationsHelper.enterIndividualRequestMode(itemId);
              $scope.annotations[annotationId] = annotationObject;
              $scope.diffgram.items[diffItemObject._index] = {...diffItemObject};
              $scope.annotationsHelper.actions.resolveAnnotation(itemId);

              for (var index = 0; index < $scope.annotations[annotationId].items.length; index++) {
                $scope.annotations[annotationId].items[index] = $scope.diffgram.items[$scope.annotations[annotationId].items[index]._index];
              }

              if (containsListItems) {
                $scope.listItems = scopeListItems;
                $scope.lease.orderedListitem = leaseListItems;
                throttleListItemsSave(updateListItems, 250);
              }

              deferred.resolve();
              $scope.annotationsHelper.actions.updateTablesVisibility();
              return deferred.promise;
            }
          };

          await scopeVar.addToHistory({
            id: actionId,
            desc: 'version control accept : ' + itemId,
            redo: redo(itemId, sectionsIdsToSave, containsListItems, scopeListItems, leaseListItems, $scope, annotationId),
            undo: undo(itemId, classListArray, containsListItems, scopeListItems, leaseListItems, diffItemObject, annotationId, annotationObject, $scope),
          });
          return sectionsIdsToSave;

        },
        acceptListItem: async function (ins) {
          const contentEditable = $(LeaseEditorService.findAllContentEditable($(ins))[0]);
          const block = contentEditable[0].getBlockData();
          const afterFreeText = block.getPreviousVisibleBlock().containingNode.getAttribute('PID');          
          const diffItem = scopeVar.diffgram.items[ins.getAttribute('diff-index')];

          let restyleObject = null;
          if(diffItem.ListHeader &&  diffItem.ListHeader == "paragraph" ){
            // get restyle object 
            restyleObject =  window.getRestyleObjectFromParagraph(ins.querySelector('.list-item-paragraph'),diffItem._pid);
            lease.restyle[diffItem._pid] =restyleObject; 
          }
          
          let itemObject = {
            level: diffItem._listLevel.level,
            headerFreeText: diffItem._listHeaderTextId,
            bodyFreeText: diffItem._listBodyTextId,
            afterFreeText: afterFreeText,
            isNewListItem: true,
            singleFT : block.containingNode.querySelectorAll('[free-text]').length ==1 ? true : false,
            index : block.getPreviousVisibleBlock().containingNode.getNodeOccurrenceIndexByAttribute('pid'),
          };

          if (diffItem._listLevel.outline) {
            itemObject.outline = diffItem._listLevel.outline;
          }

          let item = $scope.listItems.filter((obj) => {
            return obj.headerFreeText === itemObject.headerFreeText;
          });

          if (item.length === 0) {

            // update list items order : afterFreeText
            let freeTexts = diffItem._node.querySelectorAll('[free-text]');
            let lastFreeTextElement = freeTexts[freeTexts.length - 1];
            let lastFreeTextId = lastFreeTextElement.getAttribute('free-text');
            let updatedAfterFreeText = false;
            let index = 0;
            let addAtIndex = null;
            $scope.listItems.forEach((item) => {
              if (item.afterFreeText === afterFreeText && item !== itemObject) {
                item.afterFreeText = lastFreeTextId;
                updatedAfterFreeText = true;
                addAtIndex = addAtIndex ? addAtIndex : index;
              }
              index++;
            });
            if (updatedAfterFreeText) {
              $scope.listItems.splice(addAtIndex, 0, itemObject);
            } else {
              $scope.listItems.push(itemObject);
            }
          } else {
            delete item[0].deleted;
          }

          $scope.lease.orderedListitem = JSON.stringify($scope.listItems);

          let prevElement = ins.previousElementSibling;
          if(prevElement && prevElement.tagName == "IGNORE"){
            prevElement = prevElement.previousElementSibling;
          }

          let prevListItem = listItemsManager.getClosestListItem(prevElement);
          
          let prevListItemInfo = prevListItem ?  prevListItem.getListItemInfoFromNode() : null;
          if (prevListItemInfo) {
            let nextListItem = listItemsManager.listItemObjectsArray[prevListItemInfo.currentIndex + 1];
            let listNode = ins.querySelector('[list]');
            while (nextListItem && (nextListItem.node === listNode || nextListItem.static)) {
              nextListItem = listItemsManager.listItemObjectsArray[nextListItem.currentIndex + 1];
            }
            if (nextListItem && nextListItem.listLevel === diffItem._listLevel) {
              listNode.setAttribute('list', nextListItem.list);
            }
          }

        },
        _reject: async function (item) {
          const itemId = item.getAttribute('diff-index');
          const diffItemObject = {
            ...$scope.diffgram.items[itemId]
          };
          const annotationElement = document.querySelector('.annotation [diff-index="' + itemId + '"]').closest('.annotation');
          const singleRequestElements = Array.from(document.querySelectorAll('[diff-index="' + itemId + '"]'));
          const allRequestsIds = $scope.annotationsHelper.actions.getAllRequestsIds(annotationElement);
          const allRequestsElements = $scope.annotationsHelper.actions.getAllRequestsElements(allRequestsIds);
          const actionId = 'version-control' + itemId;
          const annotationId = annotationElement.getAttribute('data-pid');
          const annotationObject = {
            ...$scope.annotations[annotationId]
          };
          let sectionsIdsToSave = [];
          let classListArray = [];
          let containsListItems = false;
          let scopeListItems = [];
          $scope.listItems.forEach((item) => {
            scopeListItems.push({
              ...item
            });
          });
          let leaseListItems = $scope.lease.orderedListitem;
          allRequestsElements.forEach((element) => {
            classListArray.push([...element.classList]);
          });
          singleRequestElements.forEach((element) => {
            let listHeader = element.getAttribute('list-header');
            if (listHeader && listHeader.trim() !== '') {
              containsListItems = true;
            }
            if (element.querySelector('[list]')) {
              containsListItems = true;
            }
          });

          let redo = function (itemId, sectionsIdsToSave, containsListItems, scopeListItems, leaseListItems, $scope, annotationId) {
            return function () {
              const singleRequestElements = Array.from(document.querySelectorAll('[diff-index="' + itemId + '"]'));
              const allRequestsElements = $scope.annotationsHelper.actions.getAllRequestsElements(allRequestsIds);
              const deferred = $q.defer();

              $scope.annotationsHelper.jumpToAnnotation(annotationId, true);
              $scope.annotationsHelper.exitIndividualRequestMode();
              $scope.diffgram.items[itemId]._status = "rejected";

              allRequestsElements.forEach((element, index) => {
                if (classListArray[index].indexOf('diff-item--current') !== -1) {
                  if (
                    (
                      classListArray[index].indexOf('diff-item--partial') !== -1 &&
                      classListArray[index].indexOf('diff-item--partial--first') !== -1
                    ) ||
                    classListArray[index].indexOf('diff-item--partial') === -1
                  ) {
                    element.classList.remove('diff-item--current');
                    $scope.annotationsHelper.enterIndividualRequestMode(element.getAttribute('diff-index'));
                  }
                }
              });

              singleRequestElements.forEach((element) => {
                element.classList.add($scope.annotationsHelper.classes.rejected, $scope.annotationsHelper.classes.resolved);
                element.classList.remove($scope.annotationsHelper.classes.accepted);
                let listHeader = element.getAttribute('list-header');
                // handle accept list item
                if (element.tagName === 'INS') {
                  let orderListItem = listItemsManager.getInsertedListItemByElement(element);
                  if (orderListItem) {
                    orderListItem.deleted = true;
                    $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
                    $scope.update();
                  }
                }
                let editableElement = element.closest('.editable');
                const editableElements = editableElement ? [editableElement] : element.querySelectorAll('.editable');
                editableElements.forEach((el) => {
                  sectionsIdsToSave.push(el.getAttribute('section-id'));
                });
              });
              if (containsListItems) {
                throttleListItemsSave(updateListItems, 250);
              }
              $scope.annotationsHelper.actions.resolveAnnotation(itemId);
              deferred.resolve();
              $scope.annotationsHelper.actions.updateTablesVisibility();
              return deferred.promise;
            }
          };

          let undo = function (itemId, classListArray, containsListItems, scopeListItems, leaseListItems, diffItemObject, annotationId, annotationObject, $scope) {
            return function () {
              const allRequestsElements = $scope.annotationsHelper.actions.getAllRequestsElements(allRequestsIds);
              const deferred = $q.defer();

              for (let index = 0; index < allRequestsElements.length; index++) {
                const element = allRequestsElements[index];
                element.classList = classListArray[index].join(" ");
              }

              $scope.annotationsHelper.jumpToAnnotation(annotationId, true);
              $scope.annotationsHelper.enterIndividualRequestMode(itemId);
              $scope.annotations[annotationId] = annotationObject;
              $scope.diffgram.items[diffItemObject._index] = {...diffItemObject};
              $scope.annotationsHelper.actions.resolveAnnotation(itemId);

              for (var index = 0; index < $scope.annotations[annotationId].items.length; index++) {
                $scope.annotations[annotationId].items[index] = $scope.diffgram.items[$scope.annotations[annotationId].items[index]._index];
              }

              if (containsListItems) {
                $scope.listItems = scopeListItems;
                $scope.lease.orderedListitem = leaseListItems;
                throttleListItemsSave(updateListItems, 250);
              }

              deferred.resolve();
              $scope.annotationsHelper.actions.updateTablesVisibility();
              return deferred.promise;
            }
          };

          await scopeVar.addToHistory({
            id: actionId,
            desc: 'version control accept : ' + itemId,
            redo: redo(itemId, sectionsIdsToSave, containsListItems, scopeListItems, leaseListItems, $scope, annotationId),
            undo: undo(itemId, classListArray, containsListItems, scopeListItems, leaseListItems, diffItemObject, annotationId, annotationObject, $scope),
          });
          return sectionsIdsToSave;
        },
        getAllRequestsIds : function(annotation){
          const annotationId = annotation.getAttribute('data-pid');
          const annotationObject = $scope.annotations[annotationId];
          const idsArray = [];

          for(var i=0; i< annotationObject.items.length; i++){
            idsArray.push(annotationObject.items[i]._index);
          }

          return idsArray;
        },
        getAllRequestsElements: function(items) {
          let result = []
          let nodes;

          items.forEach(item => {
            if ($scope.diffgram.items[item]._change === "block") {
              result.push($scope.diffgram.items[item]._node);
              result.push($scope.diffgram.items[item]._annotationNode);
            } else {
              nodes = document.querySelectorAll(`[diff-index="${item}"]`);
              for (let i = 0; i < nodes.length; i++) {
                result.push(nodes[i]);
              }
            }
          });

          return result;
        }
      },
      prevAnnotation: function() {
        let prevAnnotationIndex;
        if ($scope.annotationsHelper.lastOpenAnnotation === undefined) {
          prevAnnotationIndex = -1;
        } else {
          prevAnnotationIndex = $scope.annotationsHelper.lastOpenAnnotation;
        }

        const activeAnnotation = document.querySelector(
          '.annotation[data-index="' + prevAnnotationIndex + '"]',
        );

        let prevAnnotationParagraphId;

        if (!activeAnnotation) {
          prevAnnotationIndex = 0;
        } else {
          const activeAnnotationIndex = parseInt(
            activeAnnotation.getAttribute('data-index'),
          );

          if (activeAnnotationIndex === 0) {
            prevAnnotationIndex = Object.keys($scope.annotations).length - 1;
          } else {
            prevAnnotationIndex = activeAnnotationIndex - 1;
          }

          $scope.annotationsHelper.closeAnnotation();
        }
        $scope.annotationsHelper.lastOpenAnnotation = prevAnnotationIndex;

        const prevAnnotationElement = document.querySelector(
          `.annotation--request[data-index="${prevAnnotationIndex}"]`,
        );
        prevAnnotationParagraphId = prevAnnotationElement.getAttribute('data-pid');
        $scope.annotationsHelper.highlightAnnotation(
          $scope.annotations[prevAnnotationParagraphId],
        );
        prevAnnotationElement.classList.add('annotation--current');
        $scope.diffgram.currentAnnotation = $scope.annotations[prevAnnotationParagraphId];
        $scope.scrollToElement(
          $($scope.annotations[prevAnnotationParagraphId].container),
        );
        var sel = rangy.getSelection();
        sel.removeAllRanges();

        window.track.event(new MoveToThePreviousTenantRequestEvent({
          context: $rootScope.getContext(),
        }));
      },
      nextAnnotation: function() {
        let nextAnnotationIndex;
        if ($scope.annotationsHelper.lastOpenAnnotation === undefined) {
          nextAnnotationIndex = -1;
        } else {
          nextAnnotationIndex = $scope.annotationsHelper.lastOpenAnnotation;
        }

        const activeAnnotation = document.querySelector(
          '.annotation[data-index="' + nextAnnotationIndex + '"]',
        );

        if (!activeAnnotation) {
          nextAnnotationIndex = 0;
        } else {
          const activeAnnotationIndex = parseInt(
            activeAnnotation.getAttribute('data-index'),
          );

          if (activeAnnotationIndex === Object.keys($scope.annotations).length - 1) {
            nextAnnotationIndex = 0;
          } else {
            nextAnnotationIndex = activeAnnotationIndex + 1;
          }

          $scope.annotationsHelper.closeAnnotation();
        }

        $scope.annotationsHelper.lastOpenAnnotation = nextAnnotationIndex;

        const nextAnnotationElement = document.querySelector(
          `.annotation--request[data-index="${nextAnnotationIndex}"]`,
        );
        const nextAnnotationParagraphId = nextAnnotationElement.getAttribute('data-pid');
        $scope.annotationsHelper.highlightAnnotation(
          $scope.annotations[nextAnnotationParagraphId],
        );
        nextAnnotationElement.classList.add('annotation--current');
        $scope.diffgram.currentAnnotation = $scope.annotations[nextAnnotationParagraphId];
        $scope.scrollToElement(
          $($scope.annotations[nextAnnotationParagraphId].container),
        );
        var sel = rangy.getSelection();
        sel.removeAllRanges();

        window.track.event(new MoveToTheNextTenantRequestEvent({
          context: $rootScope.getContext(),
        }));
      },
      jumpToAnnotation: function(pid,scrollTo = false) {
        const newAnnotation = document.querySelector(
          `.annotation[data-pid="${pid}"]`,
        );
        const nextAnnotationIndex = parseInt(
          newAnnotation.getAttribute('data-index'),
        )
        $scope.annotationsHelper.lastOpenAnnotation = nextAnnotationIndex;

        if(!newAnnotation.classList.contains('annotation--current')){
          $scope.annotationsHelper.closeAnnotation();
          if (newAnnotation) {
            newAnnotation.classList.add('annotation--current');

            if(scrollTo){
              document.querySelector('[pid="'+pid+'"]').scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }
        }
        $scope.diffgram.currentAnnotation = $scope.annotations[pid];
        $scope.annotationsHelper.highlightAnnotation($scope.annotations[pid]);

        window.track.event(new OpenTenantRequestEvent({
          context: $rootScope.getContext(),
        }));
      },
      highlightAnnotation: annotation => {
        let arr = [];

        annotation.items.forEach(item => {
          if (item._node) {
            arr = [...arr, item._node];
          } else {
            let paragraph = getParagraph(item._pid);
            let container = paragraph.getTopAnchor();
            if(container) {
              arr.push(container);
            } else {
              arr.push(paragraph);
            }
          }
        });

        arr.forEach(node => {
          node.classList.add('highlight');
        });
        $scope.annotationsHelper.actions.updateTablesVisibility();
      },
      removeAnnotationHighlight: () => {
        document.querySelectorAll('.highlight').forEach(node => {
          node.classList.remove('highlight');
        });
      },
      closeAnnotation: function() {
        const currentAnnotation = document.querySelector(
          '.annotation.annotation--current',
        );
        if (currentAnnotation) {
          currentAnnotation.classList.remove('annotation--current');
          $scope.diffgram.currentAnnotation = null;
        }
        $scope.annotationsHelper.removeAnnotationHighlight();
        $scope.annotationsHelper.exitPreviewMode();
        if ($scope.diffgram.individualRequest) {
          $scope.annotationsHelper.exitIndividualRequestMode();
        }

        window.track.event(new CloseTenantRequestEvent({
          context: $rootScope.getContext(),
        }));
      },
      setDiffgramItemActions: diffgramItem => {
        diffgramItem._showAcceptReject =
          !diffgramItem._isBlocked &&
          diffgramItem._isResolvable &&
          !diffgramItem._listLevelViolation;
        diffgramItem._showAdjustManually = diffgramItem._isAffectingLeaseVar && !diffgramItem._isAffectingDocumentStructure;
      },
      resolveAnnotation: function(diffItem, status) {
        const dataIndex = parseInt(diffItem.getAttribute('diff-index'));
        const diffAnnotationItems = document.querySelectorAll(
          `.lease.lease--modified .diff-item[diff-index="${dataIndex}"]`,
        );

        diffAnnotationItems.forEach(annotationItem => {
          annotationItem.classList.remove(
            'diff-item--resolved',
            'diff-item--accepted',
            'diff-item--rejected',
            'diff-item--manual',
          );

          if (status === 'accepted') {
            annotationItem.classList.add('diff-item--accepted');
          } else if (status === 'rejected') {
            annotationItem.classList.add('diff-item--rejected');
          } else if (status === 'manual') {
            if (annotationItem.classList.contains('diff-item--blocked')) {
              annotationItem.classList.add('diff-item--manual');
            } else {
              if (!annotationItem.classList.contains('diff-item--accepted')) {
                annotationItem.classList.add('diff-item--rejected');
              }
            }
          }

          annotationItem.classList.add('diff-item--resolved');
        });

        $scope.diffgram.items[dataIndex]._status = status;
        $scope.diffgram.resolved = document.querySelectorAll('.lease--modified .diff-item.diff-item--primary.diff-item--resolved');

        $scope.annotationsHelper.setDiffgramItemActions(
          $scope.diffgram.items[dataIndex],
        );
      },
      prevRequest: function() {},
      nextRequest: function() {},
      jumpToRequest: function(requestId) {
        if ($scope.diffgram.isPreview) {
          return;
        }

        if ($scope.diffgram.individualRequest) {
          const currentRequest = document.querySelector(
            '.lease.lease--modified .diff-item.diff-item--current',
          );
          const currentRequestId = parseInt(
            currentRequest.getAttribute('diff-index'),
          );

          if (requestId !== currentRequestId) {
            $scope.annotationsHelper.enterIndividualRequestMode(requestId);
          } else {
            initDiffgramWarnings();
            $scope.annotationsHelper.exitIndividualRequestMode();
            $scope.annotationsHelper.exitPreviewMode();
          }
        } else {
          $scope.annotationsHelper.enterIndividualRequestMode(requestId);
        }
      },
      resolveRequest: function(requestId, status) {},
      preview: function() {
        if (!$scope.diffgram.isPreview) {
          $scope.annotationsHelper.enterPreviewMode();
        } else {
          $scope.annotationsHelper.exitPreviewMode();
        }
      },
      enterPreviewMode: function() {
        $scope.diffgram.isPreview = true;
      },
      exitPreviewMode: function() {
        $scope.diffgram.isPreview = false;
      },
      enterIndividualRequestMode: function(requestId) {
        const currentAnnotation = document.querySelector(
          '.lease.lease--modified .annotation--current',
        );
        $scope.safeApply(() => {
          $scope.diffgram.individualRequest = true;
          const currentNode = document.querySelectorAll(
            '.lease.lease--modified .diff-item.diff-item--current',
          );
          const requestNode = document.querySelectorAll(
            `.lease.lease--modified .diff-item[diff-index="${requestId}"]`,
          );
          requestNode[0].scrollIntoViewIfNeeded({
            behavior: 'smooth',
            block: 'center'
          });


          let requestItem = $scope.diffgram.items[requestId];

          if(requestItem._isGhostItem){
            let backCount = 1;
            while($scope.diffgram.items[requestId-backCount] && $scope.diffgram.items[requestId-backCount]._isGhostItem){
              backCount++;
            }
            requestItem = $scope.diffgram.items[requestId-backCount];
          }

          const annotation = $scope.annotations[requestItem._annotation.pid];
          let text = '';
          let active;

          $scope.diffgram.individualRequest = false;
          currentNode.forEach(node => {
            node.classList.remove('diff-item--current');
          });

          // Enter to individual request node only if the node is different than the one
          // we just exited
          if (currentNode[0] !== requestNode[0]) {
            $scope.diffgram.individualRequest = true;
            active = requestItem;

            requestNode.forEach(node => {
              node.classList.add('diff-item--current');
              text += node.innerText;
            });

            active.request = `${text}`;
          }

          if(active && active._index){
            annotation.activeRequest =  $scope.diffgram.items[ active._index];
          }
          else{
            annotation.activeRequest =  active;
          }
        });
        $scope.safeApply();

        window.track.event(new EnterAnIndividualTenantRequestEvent({
          context: $rootScope.getContext(),
        }));
      },
      exitIndividualRequestMode: function() {
        $scope.safeApply(() => {
          $scope.diffgram.individualRequest = false;
          $scope.annotationsHelper.exitPreviewMode();

          const currentRequest = document.querySelector(
            '.lease.lease--modified .diff-item.diff-item--current',
          );
          if (currentRequest) {
            currentRequest.classList.remove('diff-item--current');
          }

          window.track.event(new ExitAnIndividualTenantRequestEvent({
            context: $rootScope.getContext(),
          }));
        });
      },
      save: function(list) {
        list.forEach(contentEditable => {
          LeaseEditorService.enableEditingForSegment(contentEditable);
          contentEditable.dispatchEvent(new Event('change'));
        });
      },
      exitReviewMode: function() {
        window.track.event(new FinishReviewOfTenantRequestsEvent({
          context: $rootScope.getContext(),
        }));

        location.reload();
        // Commented out temporarily until we can find a better solution
        //
        // $scope.safeApply(() => {
        //   $scope.inline.on = false;
        //   $scope.inline.toggle();
        //   $scope.annotations = {};
        //   $scope.removeVersionControlTagsFromDefaultsAndFreeText();
        //   $scope.diffgram = {
        //     on: false,
        //     processing: false,
        //     individualRequest: false,
        //     isPreview: false,
        //   };
        //   CleanFreeTextService.cleanHtmlForAcceptReject(
        //     document.querySelector('.lease.lease--original'),true
        //   );
        // });
      },
    };

    $rootScope.waitForSave = function(deferred, counter = 0) {
      if ($scope.allChangesSaved.length === 0) {
        $scope.endSaving();
        deferred.resolve();
      } else if (counter < 20) {
        counter++;
        setTimeout(() => {
          $rootScope.waitForSave(deferred, counter);
        }, 300);
      } else {
        deferred.resolve();
      }
    };

    $rootScope.getInitialAcceptRejectHtml = function(html){
      let el = document.createElement('el');
      el.innerHTML = html;
      el.querySelectorAll('ins,del').forEach((node)=>{
        node.classList.remove('diff-item--accepted','diff-item--resolved','diff-item--rejected');
      });
      return el.innerHTML;
    }

    $rootScope.updateFreeTextDefaultForVersionControl = function (html,element){
      let sectionId = element.getAttribute('section-id');
      let cleanHtml = $rootScope.getInitialAcceptRejectHtml(html);
      if(!$scope.defaultProvisions[sectionId].orgHtml){
        $scope.defaultProvisions[sectionId].orgHtml = $scope.defaultProvisions[sectionId].html;
        $scope.defaultProvisions[sectionId].html = cleanHtml;
      }
    }

    function getCalculatedSectionId(element, sectionId) {
      let result = sectionId;

      if (sectionId.indexOf('+') !== -1) {
        const scope = angular.element(element).scope();
        result = scope.$eval(sectionId);
      }

      return result;
    }

    window.getCalculatedSectionId = getCalculatedSectionId;

    function getSection(sectionId) {
      return document.querySelector(`[data-vc-id="${sectionId}"], [section-id="${sectionId}"]`);
    }

    function getParagraph(sectionId, isInnerMost = false) {
      const section = getSection(sectionId);
      let results = Array.from($(section).closest('[pid]'));
      let container;

      if (results.length === 0) {
        results = document.querySelectorAll(`[pid="${sectionId}"]`);
      }

      if (results.length === 0) {
        results = document.querySelectorAll(`[pid*="${sectionId}"]`);

        results.forEach(p => {
          let pid = getCalculatedSectionId(p, p.getAttribute('pid'));

          if (pid === sectionId) {
            results = [p];
          }
        });
      }

      if (!isInnerMost) {
        container = results[0];
      } else {
        container = results[results.length - 1];
      }

      return container;
    }

    function getParagraphId(sectionId, isInnerMost = false) {
      let container = getParagraph(sectionId, isInnerMost);
      let pid;

      if (container) {
        container = container.getTopAnchor();
        pid = container.getAttribute('pid');
        pid = getCalculatedSectionId(container, pid);
      }

      return pid;
    }

    function getTable(tableId) {
      return document.querySelector(`[table-id="${tableId}"]`);
    }

    function getTableParagraphId(tableId) {
      const table = getTable(tableId);
      const container = $(table)
        .closest('[pid]')
        .get(0);
      let pid;

      if (container) {
        pid = container.getAttribute('pid');
      } else if (table) {
        table.parentElement.setAttribute('pid',tableId);
        pid = tableId;
      }

      return pid;
    }


    function isInsertion(node) {
      return (
        node.nodeType === Node.ELEMENT_NODE &&
        node.tagName.toLowerCase() === 'ins'
      );
    }

    function isDeletion(node) {
      return (
        node.nodeType === Node.ELEMENT_NODE &&
        node.tagName.toLowerCase() === 'del'
      );
    }

    function cleanDiffgram(diffgram) {
      let current;
      let toBeRemoved = [];
      let deletedBlocks = [];

      for (let index = 0; index < diffgram.length; index++) {
        current = diffgram[index];

        if (current.Type === "del" && current.Block && current.Nested === -1) {
          deletedBlocks.push(current.Section);
        }

        if (
          current.Type === "del" &&
          current.Block &&
          current.Nested > -1 &&
          deletedBlocks.indexOf(current.Section) !== -1
        ) {
          toBeRemoved.push(index);
        }
      }

      for (let index = 0; index < toBeRemoved.length; index++) {
        diffgram.splice(toBeRemoved[index], 1);
      }

      return diffgram;
    }

    $scope.showDiffgram = async function(diffgram) {
      if ($scope.diffgram.processing) {
        return;
      }

      if (diffgram === "") {
        alert('Oops... something went wrong with the import process, please wait a couple of seconds and try again.');
        finishProcessing();
        return;
      }

      
      versionControlManager.setIds();
      

      $scope.annotations = {};
      $scope.diffgram.raw = JSON.parse(JSON.stringify(diffgram));
      $scope.diffgram.items = cleanDiffgram(diffgram);
      $scope.diffgram.actual = [];
      $scope.diffgram.resolved = [];
      $scope.diffgram.unsupported = [];

      const DEL_MARKER = '[[DEL]]';
      const DEL_END_MARKER = '[[/DEL]]';
      const INS_MARKER = '[[INS]]';

      function getNewPid() {
        return `${Math.floor(Math.random() * 100000000000)}`;
      }

      async function startProcess() {
        const contentEditable = document.querySelectorAll('[contenteditable]:not(.SOCE):not(.EOCE)');
        contentEditable.forEach(item => {
          item.setAttribute(
            'original-contenteditable',
            item.getAttribute('contenteditable'),
          );
          item.setAttribute('contenteditable', true);
        });

        $scope.safeApply(() => {
          $scope.diffgram.processing = true;
          document.querySelector(".preview").classList.toggle("version-control--processing");
        });

        if (window.isDebug) {
          const progressNode = document.createElement("div");
          progressNode.id = "vc-progress";
          progressNode.innerText = "Pending";
          progressNode.style.position = "fixed";
          progressNode.style.zIndex = "999999";
          progressNode.style.top = "0";
          progressNode.style.left = "0";
          progressNode.style.padding = "10px 15px";
          progressNode.style.backgroundColor = "#fc5457";
          progressNode.style.fontSize = "18px";
          document.querySelector("body").appendChild(progressNode);
        }

        setTimeout(function() {
          processDiffgramItems();
          
        });
      }

      async function processDiffgramItems(){
        let annotations = [];
        let removeDiffItems = [];
        const progressNode = document.querySelector("#vc-progress");

        try {
          for (
            let diffgramIndex = 0; diffgramIndex < $scope.diffgram.items.length; diffgramIndex++
          ) {
            if (progressNode) {
              progressNode.innerText = `${diffgramIndex}/${$scope.diffgram.items.length}`;
            }

            const current = $scope.diffgram.items[diffgramIndex];
            let previous =
              diffgramIndex > 0 ?
              $scope.diffgram.items[diffgramIndex - 1] :
              null;

            if (previous && previous._isGhostItem) {
              // backIndex starts from 2 since we need to skeep the current length + the previous item 
              let backIndex = 2;
              while (previous && previous._isGhostItem) {
                previous = $scope.diffgram.items[diffgramIndex - backIndex];
                backIndex++;
              }
            }

            if (previous && previous._includedInPreviousItem) {
              let backIndex = diffgramIndex;
              while (backIndex >= 0 && previous._includedInPreviousItem) {
                backIndex--;
                previous = $scope.diffgram.items[backIndex];
              }
            }

            current._index = diffgramIndex;
            current._status = 'unresolved';
            current._isBlocked = false;
            current._isResolvable = true;
            current._showWarnings = true;

            try {
              if (current.Type === "new table") {
                current.Type = "ins";
                current.Block = "p";
                current._showWarnings = false;
              }

              if (
                current.Type === "ins" &&
                current.ListHeader &&
                current.ListHeader.length !== 0 &&
                current.Nested !== -1
              ) {
                current.ListHeader = "";
              }

              if (current.Type === "ins") {
                if (current.PreSection) {
                  if (current.Block) {
                    current._change = "block";
                    current._position = "after";
                    current._pid = getNewPid();
                    current._node = await insertBlock(
                      current,
                      diffgramIndex,
                      previous
                    );
                  } else {
                    current._pid = getParagraphId(current.PreSection);
                    current._position = "self";
                    current._change = "text";
                    insertTextWithSpanOffset(current, diffgramIndex);
                  }
                } else if (current.PostSection) {
                  if (current.Block) {
                    current._change = "block";
                    current._position = "before";
                    current._pid = getNewPid();
                    current._node = await insertBlock(
                      current,
                      diffgramIndex,
                      previous
                    );
                  } else if (current.IsStatic) {
                    current._change = "static";
                    current._position = "after";
                    insertStaticText(
                      current,
                      current.PostSection,
                      current.Html
                    );
                  } else {
                    current._change = "text";
                    current._position = "self";
                    current._pid = getParagraphId(current.PostSection);
                    insertTextWithSpanOffset(current, diffgramIndex);
                  }
                } else {
                  if (!current.IsStatic) {
                    current.unsupportedItem = true;
                    $scope.diffgram.unsupported.push({
                      item: current,
                      description: `Not supported`
                    });
                  }
                }
              } else if (current.Type === "del") {
                if (current.PostSection) {
                  current._pid = getParagraphId(current.PostSection);

                  if (current.IsStatic) {
                    current._change = "static";
                    current._position = "after";
                    deleteStaticText(current, current.PostSection);
                  }
                } else if (current.Section) {
                  current._pid = getParagraphId(current.Section);

                  if (current.IsStatic) {
                    current._change = "static";
                    current._position = "after";
                    deleteStaticText(current, current.Section);
                  } else if (current.Block) {
                    current._change = "block";
                    current._position = "self";
                    deleteBlock(current, diffgramIndex, previous);
                  } else {
                    current._change = "text";
                    current._position = "self";
                    deleteTextWithSpanOffset(current, diffgramIndex);
                  }
                } else {
                  // TODO: [version control] figure this out
                  if (!current.IsStatic) {
                    current.unsupportedItem = true;
                    $scope.diffgram.unsupported.push({
                      item: current,
                      description: "Not supported"
                    });
                  }
                }
              } else if (current.Type === "change") {
                if (current.TableId) {
                  current._change = "table";
                  current._position = "self";
                  current._showWarnings = false;
                  current._isBlocked = true;
                  current._isChange = true;
                  current._pid = getTableParagraphId(current.TableId);
                  current._isResolvable = false;
                  current._blockedReason =
                    "Accepting changes to tables is not currently supported. To make the change use our editor after exiting \"import changes\" mode.";

                  if (!current._pid) {
                    current.unsupportedItem = true;
                    $scope.diffgram.unsupported.push({
                      item: current,
                      description: `Can't find table-id=${current.TableId}`
                    });
                  } else {
                    current.Html = getIncomingHtml(current);
                  }
                } else {
                  current.unsupportedItem = true;
                  $scope.diffgram.unsupported.push({
                    item: current,
                    description: "Not supported"
                  });
                }
              } else if (current.Type === "complex table") {
                current._change = "table";
                current._position = "self";
                current._showWarnings = false;
                current._isBlocked = true;
                current._isComplexTable = true;
                current._pid = current.PreSection;
                current._blockedReason =
                  "Accepting changes to tables is not currently supported. Make the needed adjustments on the lease body itself and mark as resolved when finished.";

                  if (versionControlManager) {
                    let sectionId = current.PreSection || current.PostSection;
                    const anchor = document.querySelector(`[data-vc-id="${sectionId}"]`);
                    const block = anchor.getBlockData();           
                    const updatedPid = getCalculatedSectionId(block.containingNode,block.containingNode.getAttribute("pid"));
                    current._pid = updatedPid;
                    current.PreSection = updatedPid;
                    
                  }

                const pidElements = Array.from(document.querySelectorAll('[pid]'));
                const anchor = getParagraph(current.PreSection);
                const index = pidElements.indexOf(anchor);
                if (pidElements[index + 1]) {
                  current._pid = pidElements[index + 1].getAttribute('pid');
                }

                if (!current._pid) {
                  current.unsupportedItem = true;
                  $scope.diffgram.unsupported.push({
                    item: current,
                    description: `Can't find anchor=${current._pid}`
                  });
                } else {
                  current.Html = getIncomingHtml(current);
                }
              } else if (current.Type === "complex block") {
                current._change = "block";
                current._position = "self";
                current._showWarnings = false;
                current._isBlocked = true;
                current._isComplexText = true;
                current._pid = current.Section;
                current._blockedReason =
                  "Accepting this change is not supported. Make any needed changes in the lease body itself and mark as resolved when finished.";

                if (!current._pid) {
                  current.unsupportedItem = true;
                  $scope.diffgram.unsupported.push({
                    item: current,
                    description: `Can't find anchor=${current._pid}`
                  });
                } else {
                  current.Html = getIncomingHtml(current);
                }
              }

              if (current && !current.unsupportedItem) {
                if (current.IsBullet) {
                  const container = getParagraph(current._pid);
                  let listElement = container.querySelector('[list]');
                  if(listElement){
                    listElement.classList.add('version-control--is-bullet');
                  }
                }
                if (!current._includedInPreviousItem) {
                  annotations.push(current);
                }
                if (current._includedInPreviousItem) {
                  removeDiffItems.push(current);
                }
              }
            } catch (ex) {
              current.unsupportedItem = true;
              $scope.diffgram.unsupported.push({
                item: current,
                description: "Not supported"
              });

              console.error(
                "[version control] Failed to process item",
                current,
                ex
              );

              Raven.captureException(ex);
            }
          }


          

          // join close deleted blocks Start
          const delList = document.querySelectorAll("[free-text] del");
          const delArrIds = [];
          delList.forEach(del => {
            const diffItem =
              $scope.diffgram.items[del.getAttribute("diff-index")];

            if (!del.childNodes[0].tagName) {
              const previousTextNode = del.childNodes[0].getPreviousTextNodeInBlock();
              const element = previousTextNode
                ? previousTextNode.parentElement
                : null;
              if (element && element.tagName == "DEL") {
                if (
                  !del.classList.contains("diff-item--blocked") &&
                  !element.classList.contains("diff-item--blocked")
                )
                  diffItem._isGhostItem = true;

                del.setAttribute(
                  "diff-item",
                  element.getAttribute("diff-item")
                );
                del.setAttribute(
                  "diff-index",
                  element.getAttribute("diff-index")
                );

                del.setAttribute(
                  "ng-click",
                  element.getAttribute("ng-click")
                );

                if (
                  delArrIds.indexOf(element.getAttribute("diff-index")) == -1
                ) {
                  delArrIds.push(element.getAttribute("diff-index"));
                }
              }
            }
          });

          // diff-partial-index="${i}"
          delArrIds.forEach(id => {
            const delList = document.querySelectorAll(
              `del[diff-index="${id}"]`
            );
            if (delList.length > 1) {
              delList.forEach((item, index) => {
                item.setAttribute("diff-partial-index", index);
                if (index == 0) {
                  item.classList.add("diff-item--partial--first");
                } else if (index < delList.length - 1) {
                  item.classList.add("diff-item--partial--middle");
                  item.classList.remove('diff-item--primary');
                } else {
                  item.classList.add("diff-item--partial--last");
                  item.classList.remove('diff-item--primary');
                }
              });
            }
          });
          

          // join close deleted blocks End

          // A scenario of nested diff-items is not supported
          // (even though can occur while processing the diffgram items)
          // We need to move nested diff-items outside
          const nested = document.querySelectorAll('.lease.lease--original .diff-item .diff-item');
          let container;
          for (let nestedIndex = nested.length - 1; nestedIndex >= 0; nestedIndex--) {
            let index = nested[nestedIndex].getAttribute('diff-index');
            $scope.diffgram.items[index]._showWarnings = false;
            $scope.diffgram.items[index]._isRelocated = true;
            $scope.diffgram.items[index]._isBlocked = true;
            $scope.diffgram.items[index]._blockedReason =
              "Accepting this change is not supported. Make any needed changes in the lease body itself and mark as resolved when finished.";
            nested[nestedIndex].classList.add('diff-item--relocated', 'diff-item--blocked');
            container = nested[nestedIndex].parentElement.closest('.diff-item');
            container.parentElement.insertBefore(nested[nestedIndex], container.nextElementSibling);
          }

          // remove empty insertions that have been included in previous  items
          scopeVar.diffgram.items.filter((item)=>{ return item._includedInPreviousItem}).forEach((itemToRemove)=>{
            document.querySelectorAll(`[diff-index="${itemToRemove._index}"]`).forEach((item)=>{
              item.remove();
            });
          });

          setTimeout(function() {
            $scope.diffgram.on = true;
            $scope.leftPane = null;
            document.querySelector(".preview").classList.toggle("review-changes");

            listItemsManager.createListItemsMap();

            createAnnotations(annotations, () => {
              var close = false;
              var noChanges = true;

              if (!addNoChangesWarning()) {
                cacheElements();
                generateSidebar();
                printVcStatus();
                generateUnsupportedView();
                updateDefaultsAndFreeTextValues();
                close = true;
                noChanges = false;
              }
              finishProcessing();
              $rootScope.$emit('updateImportChangesDialogStatus', {
                close: close,
                noChanges: noChanges,
                error: false,
              });
            });
          });
        } catch (ex) {
          $rootScope.$emit('updateImportChangesDialogStatus', {
            close: false,
            error: true,
          });
          //alert('Failed importing tenant requests. are you sure you imported the correct file?');
          console.error('[version control] Failed processing diffgram', ex);
          Raven.captureException(ex);
        }
      }

      function zipFiles(files) {
        var zip = new JSZip();

        files.forEach(file => {
          zip.file(file.name, file.file);
        });

        return zip.generateAsync({ type: "blob" });
      }

      function reportUnsupportedChanges() {
        var timestamp = moment().format('YYYYMMDDHHmmss');
        var leaseFileName, diffgramFileName;
        var clonedLease = _.cloneDeep($scope.lease);
        clonedLease.freeTexts = $scope.leaseFreeTexts;
        var leaseFile = new Blob([JSON.stringify(clonedLease)], {
          type: 'application/json',
        });
        var diffgramFile = new Blob([JSON.stringify($scope.diffgram.raw)], {
          type: 'application/json',
        });
        leaseFileName = `${$scope.companyName}_lease_file_${timestamp}.json`;
        diffgramFileName = `${$scope.companyName}_diffgram_file_${timestamp}.json`;

        VersionControlService.report(
          leaseFile,
          diffgramFile,
          leaseFileName,
          diffgramFileName,
          window.user.email
        ).then(function(res) {
          console.log(
            "[version control] Unsupported items report sent successfully",
            res,
          );
        });
      }

      function reportVersionControlUsage() {
        if (!$scope.lease) {
          return;
        }

        if (!window._version_control_tenant_requests_document.document) {
          return;
        }

        const clonedLease = _.cloneDeep($scope.lease);
        clonedLease.freeTexts = $scope.leaseFreeTexts;

        const timestamp = moment().format('YYYYMMDDHHmmss');
        const url = window.location.href;
        const email = window.user.email;
        const originalFile = new Blob([JSON.stringify(clonedLease)], {
          type: 'application/json',
        });

        const blockedRequests = _.groupBy($scope.diffgram.status.blocked, '_blockedReason');
        const blockedRequestsKeys = Object.keys(blockedRequests);

        const status =
        [
          { name: "All requests", value: $scope.diffgram.status.all.length },
          { name: "Normal requests", value: $scope.diffgram.status.normal.length },
          { name: "Insertions", value: $scope.diffgram.status.insertions.length },
          { name: "Deletions", value: $scope.diffgram.status.deletions.length },
          { name: "Changes", value: $scope.diffgram.status.changes.length },
          { name: "Complex Tables", value: $scope.diffgram.status.complexTables.length },
          { name: "Complex Blocks", value: $scope.diffgram.status.complexBlocks.length },
          { name: "Relocations", value: $scope.diffgram.status.relocations.length },
          { name: "Unsupported requests", value: $scope.diffgram.status.unsupported.length },
          { name: "Blocked requests", value: $scope.diffgram.status.blocked.length },
        ];

        blockedRequestsKeys.forEach(block => {
          status.push({
            name: `Blocked requests (${block})`,
            value: blockedRequests[block].length,
          });
        });

        const filesToZip = [{
            name: 'original.json',
            file: originalFile,
          },
          {
            name: 'modified.docx',
            file: window._version_control_tenant_requests_document.document,
          },
        ];

        zipFiles(filesToZip).then(function (zipFile) {
          const id = $scope.lease.id;
          const company = $scope.lease.form.company.name;
          const tenant = $scope.lease.customFields?.fileName || $scope.lease.tenantInfo?.tradeName || $scope.lease.tenantInfo?.name;
          const zipFileName = `${id}_${company}_${tenant}_${timestamp}.zip`;

          VersionControlService.usage(
            url, email, zipFile, zipFileName, status
          ).then(function(res) {
            console.log(
              "[version control] Usage report sent",
              res,
            );
          });
        });
      }

      function removeBlockedRequests() {
        var leaseVarChanges = document.querySelectorAll(
          '.lease.lease--original lease-var .diff-item',
        );
        leaseVarChanges.forEach(item => {
          var leaseVar = item.closest('lease-var');
          let elScope = angular.element(leaseVar).scope();
          $compile(leaseVar)(elScope || $scope);
        });

        // TODO: [version control] can the above be removed in favor of the following code?
        var blockedRequests = document.querySelectorAll(
          '.lease.lease--original .diff-item.diff-item--blocked',
        );
        blockedRequests.forEach(item => {
          if (item.tagName.toLowerCase() === 'del') {
            if (item.firstChild) {
              item.parentElement.insertBefore(item.firstChild, item);
            }
            item.remove();
          } else if (item.tagName.toLowerCase() === 'ins') {
            if (item.getAttribute('diff-type') !== 'block') {
              item.remove();
            }
          }
        });
      }

      function finishProcessing() {        
        $scope.safeApply(() => {
          $scope.diffgram.processing = false;
          document.querySelector(".preview").classList.toggle("version-control--processing");
        });

        const progressNode = document.querySelector("#vc-progress");

        if (progressNode) {
          progressNode.remove();
        }

        removeBlockedRequests();
        /* insertions of list items are added as accepted in order to allow the list items to get an updated number
        this code will remove the accepted status from the html in order to show the list items number without the changes.  */
        const newListItems = document.querySelectorAll('.diff-item--accepted');
        newListItems.forEach((node) => {
            node.classList.remove('diff-item--accepted');
            node.classList.remove('diff-item--processing');
        });

        const contentEditable = document.querySelectorAll('[contenteditable]:not(.SOCE):not(.EOCE)');
        contentEditable.forEach(item => {
          if (item.getAttribute('original-contenteditable')) {
            item.setAttribute(
              'contenteditable',
              item.getAttribute('original-contenteditable'),
            );
            item.removeAttribute('original-contenteditable');
          }
        });

        $scope.structureChanged(false);
        listItemsManager.createListItemsMap();

        setTimeout(() => {
          let item;
          let annotationRequestNode;

          for (let i = 0; i < $scope.diffgram.items.length; i++) {
            item = $scope.diffgram.items[i];
            annotationRequestNode = document.querySelector(`.lease.lease--modified [diff-index="${item._index}"]`);
            item._annotationNode = annotationRequestNode;
          }
        })
      }

      function generateUnsupportedView() {
        if (window._version_control_enable_unsupported_reporting) {
          // If there are any unsupported items, lets create a zendesk ticket
          // so it'll be on our radar, otherwise, currently all we want to know
          // is the usage and the breakdown of requests.
          if (
            $scope.diffgram.unsupported &&
            $scope.diffgram.unsupported.length > 0
          ) {
            reportUnsupportedChanges();
            $scope.showVersionControlUnsupportedChanges();
          } else {
            reportVersionControlUsage();
          }
        }
      }

      function addNoChangesWarning() {
        if (
          Object.keys($scope.annotations).length === 0 &&
          $scope.diffgram.unsupported &&
          $scope.diffgram.unsupported.length === 0
        ) {
          return true;
        }
        return false;
      }

      function cacheElements() {
        $scope.diffgram.actual = [];
        const keys = Object.keys($scope.annotations);
        for (let annotationIndex = 0; annotationIndex < keys.length; annotationIndex++) {
          const annotation = $scope.annotations[keys[annotationIndex]];
          const annotationNode = document.createElement('dummy');
          annotationNode.innerHTML = annotation.html;

          annotationNode
            .querySelectorAll('.diff-item.diff-item--primary:not(.diff-item--static)')
            .forEach((item) => {
              $scope.diffgram.actual.push(item);
            });
        }
      }

      function generateSidebar() {
        const container = document.querySelector('.lease.lease--modified');
        const comments = document.createElement('comments');

        container.appendChild(comments);
        $compile(comments)($scope);
      }

      function findTextNodeByOffset(section, diffgramItem) {
        const container = LeaseEditorService.findClosestContainer(section).get(0);
        let iterator;
        let spanList = [];
        let walk = document.createTreeWalker(
          container,
          NodeFilter.SHOW_TEXT,
          null,
          false
        );

        while ((iterator = walk.nextNode())) {
          const parentNode = iterator.parentElement;
          const isSOCE = parentNode.classList.contains('SOCE');
          const isEmpty = iterator.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || iterator.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE;
          const isEOCE = parentNode.classList.contains('EOCE');
          const isSkip = parentNode.classList.contains('SKIP');
          const isListPadding = parentNode.style.font.indexOf('7pt') !== -1;

          if (
            !isSOCE &&
            !isEmpty &&
            !isEOCE &&
            !isSkip &&
            !isListPadding
          ) {
            spanList.push(iterator);
          }
        }

        let anchorNode;
        let anchorOffset = diffgramItem.Offset;
        let isBulletFound = false;

        for (let spanIndex = 0; spanIndex < spanList.length; spanIndex++) {
          const current = spanList[spanIndex];

          let currentLength;
          if (current.parentElement.classList.contains('version-control--is-bullet')) {
            if (!isBulletFound) {
              currentLength = 1;
              isBulletFound = true;
            } else {
              continue;
            }
          } else if (
            current.parentElement.getAttribute('list') &&
            !current.parentElement.closest('.editable') &&
            current.parentElement.querySelector('ins:not(.diff-item--static')
          ) {
            continue;
          } else {
            currentLength = current.length;
          }

          // Ignore non-width characters which we anyway filter out while
          // preparing the document for version control
          if (
            current.textContent.startsWith(window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE) ||
            current.textContent.startsWith(window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE)
          ) {
            anchorOffset++;
          }

          // Ignore `&nbsp` which is part of the list format
          // under the assumption they appear only at the end
          if (
            current.parentElement.getAttribute('list') &&
            current.parentElement.nextElementSibling &&
            current.parentElement.nextElementSibling.style.font.indexOf('7pt') > -1 &&
            current.textContent.contains(' ') // &nbsp
          ) {
            const count = current.textContent.split(' ').length - 1;
            anchorOffset += count;
          }

          if (!anchorNode) {
            if (anchorOffset >= currentLength) {
              anchorOffset = anchorOffset - currentLength;
            } else {
              anchorNode = current;
              anchorOffset = anchorOffset;
            }
          }

          if (anchorNode) {
            break;
          }
        }

        if (spanList[0] === anchorNode || spanList[spanList.length - 1] === anchorNode) {
          anchorNode = undefined;
        }

        if (!anchorNode) {
          anchorNode = spanList[spanList.length - 1];

          const isWhitespace = !/[^\t\n\r ]/.test(anchorNode.textContent);

          if (isWhitespace) {
            anchorNode = spanList[spanList.length - 2];
          }

          if (anchorOffset === 0) {
            anchorOffset = anchorNode.textContent.length;
          }
        }

        const isAffectingLeaseVar = isWithinLeaseVar(anchorNode);
        const isAffectingCrossReference = isWithinCrossReference(anchorNode);
        const isAffectingContentEditable = isWithinContentEditable(anchorNode);
        const isAffectingAutoNumber = isWithinAutoNumber(anchorNode);
        const isAffectingDocumentStructure = isChangeDocumentStructure(anchorNode);

        return {
          anchorNode,
          anchorOffset,
          isAffectingLeaseVar,
          isAffectingCrossReference,
          isAffectingContentEditable,
          isAffectingAutoNumber,
          isAffectingDocumentStructure,
        };
      }

      function markTextAsDeleted(section, diffgramItem) {
        let offset = diffgramItem.Offset;
        let length = diffgramItem.Length;
        const container = LeaseEditorService.findClosestContainer(section).get(0);
        let iterator;
        let spanList = [];
        let walk = document.createTreeWalker(
          container,
          NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT,
          null,
          false
        );

        while ((iterator = walk.nextNode())) {
          const parentNode = iterator.parentElement;
          const isSOCE = parentNode.classList.contains('SOCE');
          const isEmpty = iterator.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || iterator.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE;
          const isEOCE = parentNode.classList.contains('EOCE');
          const isSkip = parentNode.classList.contains('SKIP');
          const isListPadding = parentNode.style.font.indexOf('7pt') !== -1;

          if (iterator.nodeType === Node.TEXT_NODE) {
            if (
              !isSOCE &&
              !isEmpty &&
              !isEOCE &&
              !isSkip &&
              !isListPadding
            ) {
              spanList.push(iterator);
            }
          }

          if (iterator.nodeType === Node.ELEMENT_NODE) {
            if (LeaseEditorService.isSelfClosing(iterator)) {
              spanList.push(iterator);
            }
          }
        }

        // Delete the last text-node in case it's just whitespace
        if (
          spanList[spanList.length - 1].nodeType === Node.TEXT_NODE &&
          !/[^\t\n\r ]/.test(spanList[spanList.length - 1].textContent)
        ) {
          spanList.splice(spanList.length - 1, 1);
        }

        let anchorNode = null;
        let anchorOffset = offset;
        let focusNode = null;
        let focusOffset = offset + length;
        let startIndex = 0;
        let endIndex = 0;
        let isBulletFound = false;

        for (let spanIndex = 0; spanIndex < spanList.length; spanIndex++) {
          const current = spanList[spanIndex];

          if (LeaseEditorService.isSelfClosing(current)) {
            continue;
          }

          let currentLength = 0;
          if (current.parentElement.classList.contains('version-control--is-bullet')) {
            if (!isBulletFound) {
              currentLength = 1;
              isBulletFound = true;
            } else {
              continue;
            }
          } else if (
            current.parentElement.getAttribute('list') &&
            !current.parentElement.closest('.editable') &&
            current.parentElement.querySelector('ins:not(.diff-item--static')
          ) {
            continue;
          } else {
            currentLength = current.length;
          }

          // Ignore non-width characters which we anyway filter out while
          // preparing the document for version control
          if (
            current.textContent.startsWith(window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE) || 
            current.textContent.startsWith(window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE)
          ) {
            anchorOffset++;
            focusOffset++;
          }

          // Ignore `&nbsp` which is part of the list format
          // under the assumption they appear only at the end
          if (
            current.parentElement.getAttribute('list') &&
            current.parentElement.nextElementSibling &&
            current.parentElement.nextElementSibling.style.font.indexOf('7pt') > -1 &&
            current.textContent.contains(' ') // &nbsp
          ) {
            const count = current.textContent.split(' ').length - 1;
            anchorOffset += count;
            focusOffset += count;
          }

          if (!anchorNode) {
            if (anchorOffset >= currentLength) {
              anchorOffset = anchorOffset - currentLength;
            } else {
              anchorNode = current;
              startIndex = spanIndex;
            }
          }

          if (!focusNode) {
            if (focusOffset > currentLength) {
              focusOffset = focusOffset - currentLength;
            } else {
              if (anchorNode) {
                focusNode = current;
                endIndex = spanIndex;
              }
            }
          }

          if (anchorNode && focusNode) {
            break;
          }
        }

        let deletions = [];
        let current;
        let editable;

        if (diffgramItem.Length > 0) {
          for (let i = startIndex; i <= endIndex; i++) {
            current = spanList[i];
            editable = current.parentElement.closest('.editable');

            if (!editable) {
              diffgramItem._isBlocked = true;
              diffgramItem._blockedReason = "You can't accept this change, please make the changes manually";
              return;
            }

            let sectionId = editable.getAttribute('section-id');
            let isAffectingLeaseVar = isWithinLeaseVar(current);
            let isAffectingDocumentStructure = isChangeDocumentStructure(current);
            let isAffectingCrossReference = isWithinCrossReference(current);
            let isAffectingAutoNumber = isWithinAutoNumber(current);

            if (!deletions[sectionId]) {
              deletions[sectionId] = [];
            }

            if (i === startIndex && i === endIndex) {
              deletions[sectionId].push({
                anchorNode,
                anchorOffset,
                focusNode,
                focusOffset,
                isAffectingLeaseVar,
                isAffectingCrossReference,
                isAffectingAutoNumber,
                isAffectingDocumentStructure,
                isFirst: false,
                isLast: false,
              });
            } else if (i === startIndex) {
              deletions[sectionId].push({
                anchorNode,
                anchorOffset,
                focusNode: anchorNode,
                focusOffset: anchorNode.textContent.length,
                isAffectingLeaseVar,
                isAffectingCrossReference,
                isAffectingAutoNumber,
                isAffectingDocumentStructure,
                isFirst: false,
                isLast: false,
              });
            } else if (i === endIndex) {
              deletions[sectionId].push({
                anchorNode: focusNode,
                anchorOffset: 0,
                focusNode,
                focusOffset,
                isAffectingLeaseVar,
                isAffectingCrossReference,
                isAffectingAutoNumber,
                isAffectingDocumentStructure,
                isFirst: false,
                isLast: false,
              });
            } else {
              deletions[sectionId].push({
                anchorNode: spanList[i],
                anchorOffset: 0,
                focusNode: spanList[i],
                focusOffset: spanList[i].textContent.length,
                isAffectingLeaseVar,
                isAffectingCrossReference,
                isAffectingAutoNumber,
                isAffectingDocumentStructure,
                isFirst: false,
                isLast: false,
              });
            }
          }
        }

        const handledItems = [];
        const dummy = document.createElement("dummy");
        dummy.innerHTML = diffgramItem.Html;
        const children = dummy.childNodes;

        if (LeaseEditorService.isSelfClosing(children[0])) {
          let current;
          let sectionId;
          let counter = 0;

          for (let x = 0; x < children.length; x++) {
            if (LeaseEditorService.isSelfClosing(children[x])) {
              counter++;
              continue;
            } else {
              break;
            }
          }

          for (let startNodeIndex = 0; startNodeIndex < counter; startNodeIndex++) {
            let currentStartSpanIndex = startIndex - startNodeIndex - 1;

            if (
              children[startNodeIndex] &&
              spanList[currentStartSpanIndex] &&
              LeaseEditorService.isSelfClosing(children[startNodeIndex]) &&
              LeaseEditorService.isSelfClosing(spanList[currentStartSpanIndex])
            ) {
              current = spanList[currentStartSpanIndex];

              if (handledItems.indexOf(currentStartSpanIndex) === -1) {
                editable = current.parentElement.closest('.editable');
                sectionId = editable.getAttribute('section-id');

                if (!deletions[sectionId]) {
                  deletions[sectionId] = [];
                }

                deletions[sectionId].unshift({
                  anchorNode: current,
                  anchorOffset: 0,
                  focusNode: current,
                  focusOffset: 0,
                  isAffectingLeaseVar: false,
                  isAffectingCrossReference: false,
                  isAffectingAutoNumber: false,
                  isAffectingDocumentStructure: false,
                  isFirst: false,
                  isLast: false,
                });

                handledItems.push(currentStartSpanIndex)
              }
            } else {
              break;
            }
          }
        }

        if (LeaseEditorService.isSelfClosing(children[children.length - 1])) {
          let current;
          let sectionId;
          let counter = 0;

          for (let y = children.length - 1; y >= 0; y--) {
            if (LeaseEditorService.isSelfClosing(children[y])) {
              counter++;
              continue;
            } else {
              break;
            }
          }

          for (let endNodeIndex = 0; endNodeIndex < counter; endNodeIndex++) {
            let currentEndSpanIndex = endIndex + endNodeIndex + 1;

            if (
              children[children.length - endNodeIndex - 1] &&
              spanList[currentEndSpanIndex] &&
              LeaseEditorService.isSelfClosing(children[children.length - endNodeIndex - 1]) &&
              LeaseEditorService.isSelfClosing(spanList[currentEndSpanIndex])
            ) {
              current = spanList[currentEndSpanIndex];

              if (handledItems.indexOf(currentEndSpanIndex) === -1) {
                editable = current.parentElement.closest('.editable');
                sectionId = editable.getAttribute('section-id');

                if (!deletions[sectionId]) {
                  deletions[sectionId] = [];
                }

                deletions[sectionId].push({
                  anchorNode: current,
                  anchorOffset: 0,
                  focusNode: current,
                  focusOffset: 0,
                  isAffectingLeaseVar: false,
                  isAffectingCrossReference: false,
                  isAffectingAutoNumber: false,
                  isAffectingDocumentStructure: false,
                  isFirst: false,
                  isLast: false,
                });

                handledItems.push(currentEndSpanIndex)
              }
            } else {
              break;
            }
          }
        }

        const keys = Object.keys(deletions);
        deletions[keys[0]][0].isFirst = true;
        deletions[keys[keys.length - 1]][deletions[keys[keys.length - 1]].length - 1].isLast = true;

        return deletions;
      }

      function insertMarker(nodeToInsert) {
        const sel = rangy.getSelection();
        var contentEditable = nodeToInsert;
        if (sel.getRangeAt && sel.rangeCount) {
          LeaseEditorService.enableEditingForSegment(contentEditable);
          const range = sel.getRangeAt(0);
          range.insertNode(nodeToInsert);
          range.collapseAfter(nodeToInsert);
          range.select();
        }
      }

      function insertMarkerByNodeAndOffset(node, offset, marker) {
        const text = Array.from(node.textContent);
        text.splice(offset, 0, marker);
        node.textContent = text.join('');
      }

      function focusToSection(sectionId) {
        // Place the caret at the beginning of the first text node
        const section = getSection(sectionId);
        let anchor = $(section)
          .find('.SOCE')
          .get(0);
        anchor = LeaseEditorService.skipEmptyNodes(anchor, true);
        $rootScope.placeCaretAtStart(anchor);
        section.focus();

        return section;
      }

      function replaceMarker(section, marker, diffgramItem, diffgramItemHtml) {
        let html = $(section).html();

        html = html.replace(marker, diffgramItemHtml);
        $(section).html(html);
      }

      function cleanDummyHtml(dummy, isListItemChange) {
        let indentElement = dummy.querySelector('span[style*="7pt"]');
        if (indentElement) {
          while (
            indentElement.parentElement.firstElementChild !== indentElement
          ) {
            indentElement.parentElement.firstElementChild.remove();
          }
          indentElement.remove();
        } else if (isListItemChange) {
          // remove number span
          dummy.children[0].remove();

          // remove spaces
          while (dummy.children[0] && dummy.children[0].textContent.trim() === "") {
            dummy.children[0].remove();
          }
        }
        return dummy;
      }

      async function insertBlockListItem(insertedBlock, diffgramItem) {
        
        let html = diffgramItem.Html;
        let level = listItemsManager.getLevelInfoByHeader(diffgramItem.ListHeader);

        if(!level && diffgramItem.Block == "p"){
          level = {format : "",
            level: "paragraph",
            listHeader: "",
            name: "",
            outerTag: "P",
            outline: "",
            previewText: ""}

        }

        let useIncomingHtml = function () {
          let randomPid = getNewPid();
          insertedBlock[0].innerHTML = `<div pid="${randomPid}" style="">${diffgramItem.Html}</div>`;
          diffgramItem._isBlocked = true;
          diffgramItem._blockedReason =
            "Inserting a list-item from an unknown format is not supported. To add a new list item use the \"Add List\" button in the toolbar and mark as resolved when finished.";
          diffgramItem._pid = randomPid;
        }

        if (!level) {
          useIncomingHtml();
        } else {
          const headerFreeTextId = $scope.getNextFreeTextValue();
          const bodyFreeTextId = $scope.getNextFreeTextValue();
          diffgramItem._listLevel = level;
          diffgramItem._listHeaderTextId = headerFreeTextId;
          diffgramItem._listBodyTextId = bodyFreeTextId;

          let listItem = await listItemsManager.asyncCreateListItemFromTemplate(level.level, level.format, level.name, headerFreeTextId, bodyFreeTextId);

          if(level.level == "paragraph"){
            const pargraphElement = listItem.querySelector('p');
            pargraphElement.setAttribute('style',diffgramItem.BlockStyle);
          }

          let dummyHtml = document.createElement('dummy');
          dummyHtml.innerHTML = html;

          diffgramItem._bodyElement = listItem.querySelector(
            '[free-text="' + bodyFreeTextId + '"]',
          );
          diffgramItem._headerElement = listItem.querySelector(
            '[free-text="' + headerFreeTextId + '"]',
          );

          if(listItem.querySelector('table')){
            diffgramItem._expectedParagraph =
              diffgramItem._bodyElement && diffgramItem._headerElement ?
              true :
              false;
          }
          else{
            diffgramItem._expectedParagraph = false;
          }


          let isListItemChange = diffgramItem.ListHeader ? true : false;
          const freeTextsInTemplate = listItem.querySelectorAll('[free-text]');

          if(freeTextsInTemplate.length !== 1 && !listItem.querySelector('table') ){
            freeTextsInTemplate[1].parentElement.remove();
          }
          
          dummyHtml = cleanDummyHtml(dummyHtml, isListItemChange && level.level !== "paragraph");
          let appendElementsTo = diffgramItem._headerElement ? diffgramItem._headerElement : diffgramItem._bodyElement;
          let appendBefore = appendElementsTo.querySelector('.EOCE');

          

          let useTableTemplate = false;

          if (listItem.querySelector('table')) {
            let incomingTDList = dummyHtml.querySelectorAll('td');
            let templateTDList = listItem.querySelectorAll('td')

            if (incomingTDList.length === 2 && diffgramItem._bodyElement && diffgramItem._headerElement &&
              incomingTDList.length === templateTDList.length) {
              useTableTemplate = true;
              diffgramItem._expectedParagraph = false;

              for (let cellIndex = 0; cellIndex < incomingTDList.length; cellIndex++) {
                appendElementsTo = cellIndex === 0 ? diffgramItem._headerElement : diffgramItem._bodyElement;
                appendBefore = appendElementsTo.querySelector('.EOCE');

                Array.from(incomingTDList[cellIndex].children).forEach((node) => {
                  node.remove();
                  appendBefore.parentElement.insertBefore(node, appendBefore);
                });
              }
            } else {
              useIncomingHtml();
              return;
            }
          }

          if (!useTableTemplate) {
            Array.from(dummyHtml.children).forEach((node) => {
              node.remove();
              appendBefore.parentElement.insertBefore(node, appendBefore);
            });
          }

          Array.from(listItem.children).forEach((node) => {
            node.remove();
            insertedBlock[0].appendChild(node);
          });

          let newPid = insertedBlock[0].querySelector('[pid]');

          insertedBlock[0].classList.add('diff-item--accepted');
          insertedBlock[0].classList.add('diff-item--processing');
          diffgramItem._pid = newPid.getAttribute('pid');
        }
      }

      function isChangeDocumentStructure(node) {
        if (!node) {
          return false;
        }

        let anchorNode = node;

        if (anchorNode.nodeType === Node.TEXT_NODE) {
          anchorNode = anchorNode.parentElement;
        }

        let leaseVar = anchorNode.closest('lease-var');

        if (leaseVar && leaseVar.getAttribute('non-click') == 'true') {
          return true;
        }

        return false;
      }

      function isWithinLeaseVar(node) {
        if (!node) {
          return false;
        }

        let anchorNode = node;

        if (anchorNode.nodeType === Node.TEXT_NODE) {
          anchorNode = anchorNode.parentElement;
        }

        if (anchorNode.closest('lease-var')) {
          return true;
        }

        return false;
      }

      function isWithinCrossReference(node) {
        if (!node) {
          return false;
        }

        let anchorNode = node;

        if (anchorNode.nodeType === Node.TEXT_NODE) {
          anchorNode = anchorNode.parentElement;
        }

        if (anchorNode.closest('crr')) {
          return true;
        }

        return false;
      }

      function isWithinContentEditable(node) {
        const contentEditable = LeaseEditorService.findClosestContentEditable(node).get(0);
        return !!contentEditable;
      }

      function isWithinAutoNumber(node) {
        if (!node) {
          return false;
        }

        let anchorNode = node;

        if (anchorNode.nodeType === Node.TEXT_NODE) {
          anchorNode = anchorNode.parentElement;
        }

        if (
          anchorNode.closest('[list]') &&
          anchorNode.closest('[list]').closest('.editable')
        ) {
          return true;
        }

        return false;
      }

      function isInsertionRequestBlocked(insertion) {
        return insertion.isAffectingLeaseVar || insertion.isAffectingCrossReference || insertion.isAffectingAutoNumber || !insertion.isAffectingContentEditable;
      }

      function isDeletionRequestBlocked(deletions) {
        let currentBatch;

        for (let sectionId in deletions) {
          currentBatch = deletions[sectionId];

          let current;
          for (let i = currentBatch.length - 1; i >= 0; i--) {
            current = currentBatch[i];

            if (
              current.isAffectingLeaseVar ||
              current.isAffectingCrossReference ||
              current.isAffectingAutoNumber
            ) {
              return true;
            }
          }
        }

        return false;
      }

      function compareObjectsProps(obj1, obj2, arr) {
        let result = true;
        arr.forEach(prop => {
          if (obj1[prop] !== obj2[prop]) {
            result = false;
          }
        });
        return result;
      }

      function getLastPidElementTagName(element) {
        const el = element.querySelectorAll('[pid]').lastItem();
        if (el) {
          return el.tagName;
        } else {
          return null;
        }
      }

      function mergeSameListItemTemplateBlock(
        diffgramItem,
        insertedElement,
        previousItem,
      ) {
        if (
          previousItem &&
          previousItem._expectedParagraph &&
          compareObjectsProps(diffgramItem, previousItem, [
            'PreSection',
            'PostSection',
          ]) &&
          getLastPidElementTagName(insertedElement) ===
          getLastPidElementTagName(previousItem._node) &&
          !(insertedElement.firstElementChild.tagName === 'TABLE')
        ) {

          
          const nodes = Array.from(
            insertedElement.querySelectorAll("[pid]").lastItem().childNodes
          );
          const contentEditable = previousItem._bodyElement.querySelector('.editable');
          const EOCE = contentEditable.querySelector('.EOCE');

          nodes.forEach(node => {
            contentEditable.insertBefore(node, EOCE);
            });

          previousItem._expectedParagraph = false;
          diffgramItem._includedInPreviousItem = true;
        }
      }

      /**
       * update the location of the anchor
       * + skip <p/> at the end of list items
       * + make sure we are not in a table
       */
      function updateDiffgramItemAnchorSection(
        currentAnchor,
        insertedBlock
      ) {
        let anchor = currentAnchor;

        /* update anchor if we are in a table */
        if (
          anchor.closest('table') &&
          insertedBlock &&
          insertedBlock.querySelector('table')
        ) {
          anchor = anchor.closest('table');
        }

        return anchor;
      }

      function insertParagraphInListItem(
        diffgramItem,
        insertedItem,
        previousItem,
      ) {
        if (
          previousItem &&
          compareObjectsProps(diffgramItem, previousItem, [
            'PreSection',
            'PostSection',
          ])
        ) {
          let isListElement = !!diffgramItem.ListHeader;
          if (!isListElement) {
            let isPreviousListElement = previousItem.ListHeader ? true : false;
            if (isPreviousListElement) {
              let targetElement;
              if (previousItem._bodyElement) {
                targetElement = previousItem._bodyElement;
              } else if (previousItem._headerElement) {
                targetElement = previousItem._headerElement;
              }
              if (targetElement) {
                const eoceList = targetElement.querySelectorAll('.EOCE');
                const EOCE = eoceList[eoceList.length -1 ];

                if (eoceList !== 0) {
                  targetElement = eoceList[eoceList.length -1].parentElement;
                }

                insertedItem.childNodes.forEach(node => {
                  targetElement.insertBefore(node, EOCE);
                });

                diffgramItem._includedInPreviousItem = true;
              }
            }
          }
        }
      }

      async function insertBlock(diffgramItem, diffgramIndex, previousItem) {

        let isListElement = !!diffgramItem.ListHeader;
        let sectionId = diffgramItem.PreSection || diffgramItem.PostSection;

        if(!isListElement && diffgramItem.Block == "p"){
          diffgramItem.ListHeader = 'paragraph';
          isListElement = true;
        }

        let anchor = document.querySelector(`[data-vc-id="${sectionId}"]`);        

        if (anchor) {
          sectionId = anchor.getSectionId();
          if (diffgramItem.PreSection) {
            diffgramItem.PreSection = sectionId;
          }
          if (diffgramItem.PostSection) {
            diffgramItem.PostSection = sectionId;
          }
        }


        if (!diffgramItem._pid) {
          throw `Can't find section ${sectionId}`;
        }

        const section = getSection(sectionId);
        const container = LeaseEditorService.findClosestContainer(section);

        const insertedBlock = $(
          `<ins list-header="${diffgramItem.ListHeader}" class="diff-item diff-item--insertion diff-item--primary" diff-item="diffgram.items[${diffgramIndex}]" diff-index="${diffgramIndex}" diff-pid="${diffgramItem._originalParagraphId || diffgramItem._pid}" diff-type="block" ng-click="annotationsHelper.jumpToRequest(${diffgramIndex})"></ins>`,
        );
        
        await insertBlockListItem(insertedBlock, diffgramItem);

        if (diffgramItem.unsupportedItem) {
          return;
        }

        if (diffgramItem.Nested !== -1) {
          insertedBlock[0].setAttribute('diff-type', 'text');
          insertedBlock[0].classList.add('diff-item--nested');
          diffgramItem._change = 'text';
        }

        if(!diffgramItem._isBlocked){
          mergeSameListItemTemplateBlock(
            diffgramItem,
            insertedBlock.get(0),
            previousItem,
          );
        }

        if (!diffgramItem._includedInPreviousItem) {
          insertParagraphInListItem(
            diffgramItem,
            insertedBlock.get(0),
            previousItem,
          );

          

          let anchor = container.get(0);
          const el = insertedBlock.get(0).querySelector("[pid]");
          const insBlock = insertedBlock.get(0);
          anchor = updateDiffgramItemAnchorSection(anchor, insBlock);

          if(diffgramItem.Block && diffgramItem.Block == "p" && diffgramItem.inTable == false && anchor.closest('table')){
            const tbodyWithPid = anchor.closest('TBODY[pid]')
            anchor = tbodyWithPid ? tbodyWithPid : anchor.closest('TR[pid]');
          }

          let isListElement = !!diffgramItem.ListHeader;

          if (diffgramItem.Nested !== -1) {
            anchor = anchor.querySelector('[data-nested-paragraph-index="' + diffgramItem.Nested + '"]');
            while (anchor && anchor.nextElementSibling && anchor.nextElementSibling.tagName === "INS") {
              anchor = anchor.nextElementSibling;
            }
            insertedBlock.insertAfter(anchor);
          } else if (diffgramItem._position === "before") {
            if (!isListElement) {
              const placeholder = anchor.querySelector(".PLACEHOLDER");
              var insertBeforeElement = placeholder.firstChild;
              while (
                insertBeforeElement.nextSibling &&
                (isInsertion(insertBeforeElement.nextSibling) ||
                  isDeletion(insertBeforeElement.nextSibling))
              ) {
                insertBeforeElement = insertBeforeElement.nextSibling;
              }
              insertBeforeElement.parentElement.insertBefore(
                insertedBlock.get(0),
                insertBeforeElement
              );
            } else {
              while (
                anchor.previousElementSibling &&
                (isInsertion(anchor.previousElementSibling) ||
                  isDeletion(anchor.previousElementSibling) ||
                  (anchor.previousElementSibling &&
                    anchor.previousElementSibling.tagName.toLowerCase() ===
                    "ignore"))
              ) {
                anchor = anchor.previousElementSibling;
              }

              let updateAnchor = anchor.closest('[diff-type="block"]');
              if (updateAnchor) {
                insertedBlock.insertAfter(updateAnchor);
              } else {
                insertedBlock.insertBefore(anchor);
              }
            }
          } else if (diffgramItem._position === "after") {
            if (!isListElement) {
              const eoceList = anchor.querySelectorAll(".EOCE");
              const eoce = eoceList[eoceList.length - 1];
              eoce.parentElement.insertBefore(insertedBlock.get(0), eoce);
            } else {
              while (
                anchor.nextElementSibling &&
                (isInsertion(anchor.nextElementSibling) ||
                  isDeletion(anchor.nextElementSibling) ||
                  (anchor.nextElementSibling.tagName &&
                    (anchor.nextElementSibling.tagName.toLowerCase() === 'ignore' ||
                      (anchor.nextElementSibling.tagName.toLowerCase() === 'generate-table' &&
                        isListElement))
                  ))
              ) {
                anchor = anchor.nextElementSibling;
              }
              let updateAnchor = anchor.closest('[diff-type="block"]');
              if (updateAnchor) {
                insertedBlock.insertAfter(updateAnchor);
              } else {
                insertedBlock.insertAfter(anchor);
              }
            }
          }

          const containingDiffItem = insertedBlock.get(0).parentElement.closest('.diff-item');
          if (containingDiffItem) {
            diffgramItem._isBlocked = true;
            diffgramItem._blockedReason = "You can't accept this change, please make the changes manually";
          }

          insertedBlock.get(0).setAttribute('consolidated-pid', diffgramItem._pid);

          return insertedBlock.get(0);
        } else {
          return null;
        }
      }

      function insertStaticText(diffgramItem, sectionId, html) {
        const section = getSection(sectionId);

        if (!section) {
          diffgramItem.unsupportedItem = true;
          $scope.diffgram.unsupported.push({
            item: diffgramItem,
            description: `Section ${sectionId} can't be found in the DOM, skipping...`,
          });
          return;
        }

        const container = LeaseEditorService.findClosestContainer(section);
        const htmlToInsert = `<ins class="diff-item diff-item--insertion diff-item--primary diff-item--static">${html}</ins>`;
        $(container).prepend(htmlToInsert);
      }

      function insertTextWithSpanOffset(diffgramItem, diffgramIndex) {
        const anchorNumber =
          diffgramItem.PreSection || diffgramItem.PostSection;
        const anchor = document.querySelector(`[data-vc-id="${anchorNumber}"]`);

        if (!anchor) {
          diffgramItem.unsupportedItem = true;
          $scope.diffgram.unsupported.push({
            item: diffgramItem,
            description: `Section ${anchorNumber} can't be found in the DOM, skipping...`
          });
          return;
        }

        const x = {
          isAffectingLeaseVar: isWithinLeaseVar(anchor),
          isAffectingCrossReference: isWithinCrossReference(anchor),
          isAffectingContentEditable: isWithinContentEditable(anchor),
          isAffectingAutoNumber: isWithinAutoNumber(anchor),
          isAffectingDocumentStructure: isChangeDocumentStructure(anchor)
        };

        const isBlocked = isInsertionRequestBlocked(x);
        const block = anchor.getBlockData();
        const style = getStyle(block.containingNode);
        const contentEditable = anchor.closest(".editable");

        diffgramItem._pid = block.containingNode.getAttribute("pid");

        diffgramItem._isResolvable =
          !x.isAffectingCrossReference && !x.isAffectingAutoNumber;
        diffgramItem._isAffectingCrossReference = x.isAffectingCrossReference;
        diffgramItem._isAffectingLeaseVar = x.isAffectingLeaseVar;
        diffgramItem._isAffectingContentEditable = x.isAffectingContentEditable;
        diffgramItem._isAffectingAutoNumber = x.isAffectingAutoNumber;
        diffgramItem._isAffectingDocumentStructure =
          x.isAffectingDocumentStructure;
        diffgramItem._isBlocked = isBlocked;

        if (isBlocked) {
          if (!x.isAffectingContentEditable) {
            diffgramItem._blockedReason =
              "Accepting this change is not supported. Make any needed changes in the lease body itself and mark as resolved when finished.";
          } else if (x.isAffectingLeaseVar) {
            diffgramItem._blockedReason =
              "You can't accept changes that affects the automation, please make the changes manually";
          } else if (x.isAffectingCrossReference || x.isAffectingAutoNumber) {
            diffgramItem._blockedReason =
              "You can't accept changes that affects cross-references, please make the changes manually";
          }
        }

        let insertElement = null;

        if (diffgramItem.PostSection) {
          insertElement = document.createElement("ins");
          insertElement.innerHTML = diffgramItem.Html;
          anchor.parentElement.insertBefore(insertElement, anchor);
        } else {
          insertElement = anchor.insertInsElement(
            diffgramItem.Offset,
            diffgramItem.Html,
            !isBlocked
          );
        }

        
        insertElement.classList.add(
          "diff-item",
          "diff-item--insertion",
          "diff-item--primary"
        );
        insertElement.setAttribute(
          "diff-item",
          `diffgram.items[${diffgramIndex}]`
        );
        insertElement.setAttribute("diff-index", `${diffgramIndex}`);
        insertElement.setAttribute(
          "diff-pid",
          `${diffgramItem._originalParagraphId || diffgramItem._pid}`
        );
        insertElement.setAttribute("diff-type", `text`);
        insertElement.setAttribute(
          "ng-click",
          `annotationsHelper.jumpToRequest(${diffgramIndex})`
        );
        if (isBlocked) {
          insertElement.classList.add("diff-item--blocked");
        }
      }

      

      function deleteBlock(diffgramItem, diffgramIndex, previous) {
        let sectionId = diffgramItem.Section;

        if (versionControlManager) {
          const anchor = document.querySelector(`[data-vc-id="${sectionId}"]`);
          const block = anchor.getBlockData();           
          const updatedPid = getCalculatedSectionId(block.containingNode,block.containingNode.getAttribute("pid"));
          diffgramItem._pid = updatedPid;
          if (anchor) {
            sectionId = anchor.getSectionId();
            diffgramItem.Section = sectionId;
          }
        }

        const section = getSection(sectionId);

        if (!section) {
          diffgramItem.unsupportedItem = true;
          $scope.diffgram.unsupported.push({
            item: diffgramItem,
            description: `Section ${sectionId} can't be found in the DOM, skipping...`,
          });
          return;
        }

        const del = document.createElement('del');
        const container = LeaseEditorService.findClosestContainer(section).get(0);

        if (diffgramItem.Nested > -1) {
          const nested = document.querySelector(`[data-nested-paragraph-index="${diffgramItem.Nested}"]`);

          if (nested) {
            nested.parentElement.replaceChild(del, nested);
            del.append(nested);
          }
        } else {
          let previousElementText = '';
          if (container.previousElementSibling) {
            previousElementText = container.previousElementSibling.textContent.trim();
          }

          if (
            previousElementText.indexOf("!!SET") !== -1 &&
            previousElementText.endsWith("!!")
          ) {
            const listSetupElement = container.previousElementSibling;
            container.parentElement.insertBefore(del, listSetupElement);
            del.append(listSetupElement);
            del.append(container);
          } else if (
            diffgramItem.Html === "" &&
            diffgramItem.Block === "complex"
          ) {
            let eoce = null;
            Array.from(section.children).forEach(el => {
              if (
                !el.classList.contains("SOCE") &&
                !el.classList.contains("EOCE")
              ) {
                el.remove();
                del.appendChild(el);
              } else if (el.classList.contains("EOCE")) {
                eoce = el;
              }
            });
            eoce.parentElement.insertBefore(del, eoce);
          } else {
            container.parentElement.insertBefore(del, container);
            del.append(container);
          }
        }

        del.classList.add(
          'diff-item',
          'diff-item--deletion',
          'diff-item--primary',
        );
        del.setAttribute('diff-item', `diffgram.items[${diffgramIndex}]`);
        del.setAttribute('diff-index', diffgramIndex);
        del.setAttribute('diff-pid', diffgramItem._originalParagraphId || diffgramItem._pid);
        del.setAttribute('diff-type', 'block');
        del.setAttribute(
          'ng-click',
          `annotationsHelper.jumpToRequest(${diffgramIndex})`,
        );
        del.setAttribute('consolidated-pid', diffgramItem._pid);

        if (diffgramItem.Nested !== -1) {
          del.setAttribute('diff-type', 'text');
          diffgramItem._change = 'text';
        }
        diffgramItem._node = del;
      }

      function deleteStaticText(diffgramItem, sectionId) {
        const section = getSection(sectionId);

        if (!section) {
          diffgramItem.unsupportedItem = true;
          $scope.diffgram.unsupported.push({
            item: diffgramItem,
            description: `Section ${sectionId} can't be found in the DOM, skipping...`,
          });
          return;
        }

        const container = LeaseEditorService.findClosestContainer(section);
        $(container)
          .find('[list]')
          .addClass('diff-item diff-item--static diff-item--deletion');
      }

      function deleteTextWithSpanOffset(diffgramItem, diffgramIndex) {
        
        
        const anchorNumber =
          diffgramItem.PreSection ||
          diffgramItem.PostSection ||
          diffgramItem.Section;
        const anchor = document.querySelector(`[data-vc-id="${anchorNumber}"]`);
        const block = anchor.getBlockData();

        diffgramItem._pid = block.containingNode.getAttribute("pid");

        if (!anchor) {
          diffgramItem.unsupportedItem = true;
          $scope.diffgram.unsupported.push({
            item: diffgramItem,
            description: `Section ${anchorNumber} can't be found in the DOM, skipping...`
          });
          return;
        }

        if(diffgramItem.Length == 0){
          diffgramItem.unsupportedItem = true;
          $scope.diffgram.unsupported.push({
            item: diffgramItem,
            description: `Section ${diffgramItem.Section} unsuported to delete with length 0 , 
            anchor text : ${anchor.textContent}.
            start offest : ${diffgramItem.Offset}.
            length : ${diffgramItem.Length}.
             `,
          });
          return;
        }

        // fix offset for fisrt node in list item and blocks
        if (
          diffgramItem.Offset + diffgramItem.Length >
          anchor.textContent.length
        ){
          const prevTextNode = anchor.childNodes[0].getPreviousTextNodeInBlock();
          
          if(prevTextNode && prevTextNode.parentElement && prevTextNode.parentElement.classList.contains('word-indent')){
            diffgramItem.Offset = 0;
          }
          else if(prevTextNode && !prevTextNode.parentElement.closest('[free-text]')){            
            diffgramItem.Offset = 0;
          }
        }

        
        if (
          diffgramItem.Offset + diffgramItem.Length <=
          anchor.textContent.length
        ) {
          const leaseVar = anchor.closest('lease-var');
          const crr = anchor.closest('crr');
          let delElement = null;

          if(leaseVar ){ 
            delElement = anchor.insertDelElement(
              diffgramItem.Offset,            
              diffgramItem.Length
            );
            
          }
          else{
            delElement = anchor.insertDelElement(
              diffgramItem.Offset,            
              diffgramItem.Length
            );
          }

          if (delElement) {

            

            const x = {
              isAffectingLeaseVar : isWithinLeaseVar(anchor),
              isAffectingCrossReference : isWithinCrossReference(anchor),
              isAffectingContentEditable : isWithinContentEditable(anchor),
              isAffectingAutoNumber : isWithinAutoNumber(anchor),
              isAffectingDocumentStructure : isChangeDocumentStructure(anchor),

            }



            diffgramItem._isResolvable = !x.isAffectingCrossReference && !x.isAffectingAutoNumber;
            diffgramItem._isAffectingCrossReference = x.isAffectingCrossReference;
            diffgramItem._isAffectingLeaseVar = x.isAffectingLeaseVar;
            diffgramItem._isAffectingContentEditable = x.isAffectingContentEditable;
            diffgramItem._isAffectingAutoNumber = x.isAffectingAutoNumber;
            diffgramItem._isAffectingDocumentStructure = x.isAffectingDocumentStructure;
            


            delElement.classList.add(
              "diff-item",
              "diff-item--deletion",
              "diff-item--primary",
              "diff-item--partial"
            );

            
            if(leaseVar && leaseVar.contains(delElement)){
              delElement.classList.add('diff-item--blocked');
              diffgramItem._isBlocked = true;
            }

            if(crr && crr.contains(delElement)){
              delElement.classList.add('diff-item--blocked');
              diffgramItem._isBlocked = true;
            }

            delElement.setAttribute(
              "diff-item",
              `diffgram.items[${diffgramIndex}]`
            );
            delElement.setAttribute('diff-partial-section-id', delElement.closest('[free-text]').getAttribute('free-text'));
            
            delElement.setAttribute("diff-index", `${diffgramIndex}`);
            delElement.setAttribute(
              "diff-pid",
              `${diffgramItem._originalParagraphId || diffgramItem._pid}`
            );
            delElement.setAttribute("diff-type", `text`);
            delElement.setAttribute(
              "ng-click",
              `annotationsHelper.enterIndividualRequestMode(${diffgramIndex})`
            );

            /// test if we need to delete block

            if(block.containingNode.areAllTextNodesMarkedForDelete()){

              const delList = block.containingNode.querySelectorAll('del');
              
              delList.forEach((del)=>{
                del.childNodes.forEach((c)=>{
                  del.parentElement.insertBefore(c,del);
                });

                const diffItemToUpdate = $scope.diffgram.items[del.getAttribute('diff-index')];
                diffItemToUpdate._isGhostItem = true;
                del.remove();
              });
              
              const newDiffitem = {
                Block: block.containingNode.tagName,
                IsBullet: false,
                IsStatic: false,
                Length: 76,                
                Nested: -1,
                Node: null,
                Offset: 0,
                PostSection: "",
                PreSection: "",
                Section: `${anchorNumber}`,
                TableId: "",
                Type: "del",
              }
              $scope.diffgram.items.splice(diffgramIndex+1,0,newDiffitem);
            }
            
          } 
        } else {
          
          diffgramItem.unsupportedItem = true;
          $scope.diffgram.unsupported.push({
            item: diffgramItem,
            description: `Section ${diffgramItem.Section} is too short for delete details , 
            anchor text : ${anchor.textContent}.
            start offest : ${diffgramItem.Offset}.
            length : ${diffgramItem.Length}.
             `,
          });
        }

        
      }

      function generateTableHTML(container) {
        let html;

        if (container.tagName === 'TABLE') {
          html = container.getVisibleElements().outerHTML;
        } else if (container.tagName === 'TR') {
          // TODO: check if this code can be removed now that the annotation now
          // previews an entire table
          const newRow = container.cloneNode(true);
          const newTable = container.closest('table').cloneNode(true).getVisibleElements();
          newTable.innerHTML = '';
          newTable.appendChild(newRow);
          html = newTable.outerHTML;
        }

        return html;
      }

      function getIncomingHtml(diffgramItem) {
        let html = diffgramItem.Html;
        html = html.replace(/<del/g, '<1del').replace(/<\/del/g, '</1del');
        html = html.replace(/<ins/g, '<del').replace(/<\/ins/g, '</del');
        html = html.replace(/<1del/g, '<ins').replace(/<\/1del/g, '</ins');

        let el = document.createElement("el");
        el.innerHTML = html;
        el.querySelectorAll("ins, del").forEach((node, index) => {
          if (node.tagName === "DEL") {
            node.classList.add(
              "diff-item",
              "diff-item--deletion",
              "diff-item--blocked",
            );
          } else if (node.tagName === 'INS') {
            node.classList.add(
              "diff-item",
              "diff-item--insertion",
              "diff-item--blocked",
            );
          }

          node.classList.add('diff-item--primary');

          if (diffgramItem._isChange) {
            node.classList.add('diff-item--change');
          } else if (diffgramItem._isComplexTable) {
            node.classList.add('diff-item--complex-table');
          } else if (diffgramItem._isComplexText) {
            node.classList.add('diff-item--complex-block');
          }

          node.setAttribute('diff-index', diffgramItem._index);
          node.style = {};
        });

        return el.innerHTML;
      }

      function getHtml(diffgramItem, container) {
        let html;

        container = container.getTopAnchor();
        if (container.parentElement.closest('.diff-item')) {
          container = container.parentElement.closest('.diff-item');
        }

        if (diffgramItem.Type === 'change' || diffgramItem.Type === 'complex table'  || diffgramItem.Type === 'complex block') {
          html = diffgramItem.Html;
        } else {
          if (
            container.tagName.toLowerCase() === 'tr' ||
            container.tagName.toLowerCase() === 'table'
          ) {
            html = generateTableHTML(container);
          } else {
            html = container.getVisibleElements().outerHTML;  
          }
        }

        return html;
      }

      function cleanHtml(item, html) {
        html = html.replace(/{{.*?}}/g, '');

        html = sanitizeHtml(html, {
          allowedTags: [
            'b',
            'strong',
            'i',
            'u',
            'p',
            'span',
            'div',
            'br',
            'img',
            'font',
            'table',
            'thead',
            'tbody',
            'tfoot',
            'colgroup',
            'col',
            'tr',
            'th',
            'td',
            'ins',
            'del',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'crr',
            'crb',
            'sup',
            'sub',
            'lease-var',
          ],
          allowedAttributes: {
            a: ['href', 'name', 'target'],
            img: ['src', 'width', 'height'],
            table: ['cellspacing', 'cellpadding', 'border'],
            td: ['colspan', 'rowspan'],
            span: ['style', 'class', 'var', 'ref'],
            ins: ['*'],
            del: ['*'],
            'lease-var': ['ng-attr-name', 'non-click'],
            '*': ['id', 'style', 'class', 'title', 'var'],
          },
          allowedClasses: {
            span: [
              'word-indent',
              'diff-item',
              'diff-item--static',
              'diff-item--insertion',
              'diff-item--deletion',
              'diff-item--blocked',
              'diff-item--current',
              'diff-item--accepted',
              'diff-item--rejected',
              'diff-item--resolved',
              'diff-item--partial',
              'diff-item--partial--first',
              'diff-item--partial--middle',
              'diff-item--partial--last',
              'transformed--lease-var',
              'transformed--crr',
            ],
          },
          allowedSchemes: ['http', 'https'],
          allowedSchemesByTag: {
            img: ['data', 'http', 'https'],
          },
        });

        let dummy = document.createElement('dummy');
        dummy.innerHTML = html;

        if(!item.Block){
          dummy.querySelectorAll('[diff-type="block"]').forEach(item => {
            item.remove();
          });
        }

        const pTags = dummy.querySelectorAll('p');
        pTags.forEach(pTag => {
          const div = document.createElement('div');
          div.innerHTML = pTag.innerHTML;
          div.classList.add("transformed--paragraph");
          div.setAttribute("style", pTag.getAttribute("style"));
          pTag.replaceWith(div);
        });
        
        const leaseVarTags = dummy.querySelectorAll('lease-var');
        leaseVarTags.forEach(leaseVarTag => {
          const span = document.createElement('span');
          span.innerHTML = leaseVarTag.innerHTML;
          span.classList.add("transformed--lease-var");
          span.setAttribute("var", leaseVarTag.getAttribute("ng-attr-name"));
          span.setAttribute("non-click", leaseVarTag.getAttribute("non-click") == 'true');
          leaseVarTag.replaceWith(span);
        });

        const crrTags = dummy.querySelectorAll('crr');
        crrTags.forEach(crrTag => {
          const span = document.createElement('span');
          span.innerHTML = crrTag.innerHTML;
          span.classList.add("transformed--crr");
          span.setAttribute("ref", crrTag.id);
          crrTag.replaceWith(span);
        });

        const nodes = dummy.querySelectorAll('.transformed--paragraph');
        nodes.forEach(node => {
          if (LeaseEditorService.isNodeWordStyle($(node))) {
            node.remove();
          }
        });

        const elementsToRemove = dummy.querySelectorAll(
          ".lp-hide-element, .remove-on-download, .deleted-container, .deleted-page-break, .deleted-element"
        );
        for (
          let removeIndex = 0;
          removeIndex < elementsToRemove.length;
          removeIndex++
        ) {
          elementsToRemove[removeIndex].remove();
        }

        html = dummy.innerHTML;

        return html;
      }
      $scope.removeVersionControlTagsFromDefaultsAndFreeText = function () {
        let itemsList = document.querySelectorAll('.diff-item');
        let sectionIds = [];
        itemsList.forEach((item) => {
          let sectionIdElement = item.closest('[section-id]');
          if(sectionIdElement){
            let sectionId = sectionIdElement.getAttribute('section-id');
            if (sectionIds.indexOf(sectionId) === -1) {
              sectionIds.push(sectionId);
            }
          }
        });
        sectionIds.forEach((sectionId) => {
          let freeTextUpdated = $scope.leaseFreeTexts[sectionId];
          let defaultProvision = $scope.defaultProvisions[sectionId];
          if (freeTextUpdated) {
            freeTextUpdated.text = CleanFreeTextService.cleanHTMLText(freeTextUpdated.text);
          } else if (defaultProvision) {
            defaultProvision.html = CleanFreeTextService.cleanHTMLText(defaultProvision.html);
          }
        });

      }
      function updateDefaultsAndFreeTextValues() {

        let itemsList = document.querySelectorAll('.diff-item');
        let sectionIds = [];
        itemsList.forEach((item) => {
          let sectionIdElement = item.closest('[section-id]');
          if(sectionIdElement){
            let sectionId = sectionIdElement.getAttribute('section-id');
            if (sectionIds.indexOf(sectionId) === -1) {
              sectionIds.push(sectionId);
            }
          }
        });
        sectionIds.forEach((sectionId) => {
          let freeTextUpdated = $scope.leaseFreeTexts[sectionId];
          let defaultProvision = $scope.defaultProvisions[sectionId];
          let currentHtmlWIthVersionControlTags = CleanFreeTextService.cleanElementBySectionId(sectionId, true);
          if (freeTextUpdated) {
            freeTextUpdated.text = currentHtmlWIthVersionControlTags;
          } else if (defaultProvision) {
            defaultProvision.html = currentHtmlWIthVersionControlTags;
          }
          else{
            hash =  LeaseEditorService.prepareForComparison(currentHtmlWIthVersionControlTags).hashCode();
            $scope.defaultProvisions[sectionId] = {hashCode :hash , html : currentHtmlWIthVersionControlTags , versionControlDeafult : true,};
          }
        });
      }

      function printVcStatus() {

        const allRequests = $scope.diffgram.actual;
        const normalRequests = allRequests
          .filter(item => !item.classList.contains('diff-item--blocked'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);
        const blockedRequests = allRequests
          .filter(item => item.classList.contains('diff-item--blocked'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);
        const insertions = allRequests
          .filter(item => item.classList.contains('diff-item--insertion'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);
        const deletions = allRequests
          .filter(item => item.classList.contains('diff-item--deletion'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);
        const changes = allRequests
          .filter(item => item.classList.contains('diff-item--change') && item.classList.contains('diff-item--primary'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);;
        const complexTables = allRequests
          .filter(item => item.classList.contains('diff-item--complex-table') && item.classList.contains('diff-item--primary'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);;;
        const complexBlocks = allRequests
          .filter(item => item.classList.contains('diff-item--complex-block') && item.classList.contains('diff-item--primary'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);;;
        const relocations = allRequests
          .filter(item => item.classList.contains('diff-item--relocated'))
          .map(item => scopeVar.diffgram.items[item.getAttribute('diff-index')]);

        $scope.diffgram.status = {
          all: allRequests,
          normal: normalRequests,
          blocked: blockedRequests,
          insertions: insertions,
          deletions: deletions,
          changes: changes,
          complexTables: complexTables,
          complexBlocks: complexBlocks,
          relocations: relocations,
          unsupported: $scope.diffgram.unsupported,
        };

        const groupedBlockedRequests = _.groupBy(blockedRequests, '_blockedReason');
        const groupedBlockedRequestsKeys = Object.keys(groupedBlockedRequests);
        const groupedAllBlocked = [{
          name: 'All',
          value: blockedRequests,
        }];
        groupedBlockedRequestsKeys.forEach(block => {
          groupedAllBlocked.push({
            name: block,
            value: groupedBlockedRequests[block],
          });
        });

        $scope.diffgram.status.blocked = groupedAllBlocked;

        const vcStatus =
        [
          { "All requests": allRequests },
          { "Normal requests": normalRequests },
          { "Blocked requests": groupedAllBlocked },
          { "Insertions": insertions },
          { "Deletions": deletions },
          { "Changes": changes },
          { "Complex Tables": complexTables },
          { "Complex Blocks": complexBlocks },
          { "Relocations": relocations },
          { 'Unsupported requests': $scope.diffgram.unsupported },
        ]

        console.log("Version Control", vcStatus);
      }

      function createAnnotations(items, callback) {

        setTimeout(() => {
          let container;
          let previousAnnotation = null;

          items = items.filter(item => {
            return !item.unsupportedItem && !item._isGhostItem ;
          });

          for (let index = 0; index < items.length; index++) {
            let item = items[index];

            if (!item.IsStatic) {
              let containerLookupId;
              if (item.ListHeader) {
                containerLookupId = item.PostSection ?
                  item.PostSection :
                  item.PreSection;
              } else {
                containerLookupId = item._pid;
              }

              container = getParagraph(containerLookupId);

              if (container && container.closest('tr')) {
                container = container.closest('tr');
              }

              if (item.ListHeader && item._node) {
                container = item._node;
              }

              if (!container && item._node) {
                container = item._node;
              }

              if (item.Nested !== -1 && item.Block && item.Type === 'ins') {
                container = container.parentElement.closest('[pid]');
                item._pid = container.getAttribute('pid');
                delete item._node;
              }

              if (!container) {
                item.unsupportedItem = true;
                $scope.diffgram.unsupported.push({
                  item,
                  description: `pid ${item._pid} can't be found in the DOM, skipping...`,
                });
                continue;
              }

              if (container.parentElement.closest('[pid]')) {
                container = container.getTopAnchor();
                item._originalParagraphId = item._pid;
                item._pid = container.getAttribute('pid');

                if (item._node) {
                  item._node.setAttribute('consolidated-pid', item._pid);
                }
              }

              let html;
              html = getHtml(item, container);
              html = cleanHtml(item, html);

              let pid;
              // test for consolidation option
              if (item && item._node) {
                let node = item._node;
                while (
                  node.previousElementSibling &&
                  node.previousElementSibling.tagName === item._node.tagName
                ) {
                  node = node.previousElementSibling;
                }
                pid = node.getAttribute('consolidated-pid');
              } else {
                pid = item._pid;
              }

              let consolidateItem = item._pid !== pid;
              item._isConsolidatedItem = consolidateItem;

              let isForceConsolidate = false;
              if(previousAnnotation && previousAnnotation.container.querySelector('[diff-index="'+index+'"]')){
                consolidateItem = true;
                isForceConsolidate = true;
              }

              $scope.annotationsHelper.setDiffgramItemActions(item);
              if (!$scope.annotations[pid]) {
                let items = [];

                if (item.Type === 'change' || item.Type === 'complex table') {
                  const annotationNode = document.createElement('dummy');
                  annotationNode.innerHTML = html;

                  annotationNode
                    .querySelectorAll('.diff-item.diff-item--primary:not(.diff-item--static)')
                    .forEach((diffItem) => {
                      let type;

                      if (diffItem.classList.contains('diff-item--insertion')) {
                        type = "ins";
                      } else if (diffItem.classList.contains('diff-item--deletion')) {
                        type = "del";
                      }

                      items.push({
                        ...item,
                        Type: type,
                      });
                    });
                } else {
                  items.push(item);
                }

                consolidateItem = false;
                $scope.annotations[pid] = {
                  container,
                  pid,
                  type: 'diff',
                  items,
                  html,
                  status: 'unresolved',
                  isBlocked: item._isBlocked,
                  isResolvable: item._isResolvable,
                  isManageable: item.Type !== 'change',
                  index: Object.keys($scope.annotations).length,
                  _showAcceptReject: item._showAcceptReject,
                  _showAdjustManually: item._showAdjustManually,
                  _showWarnings: item.__showWarnings,
                };
              } else {
                if (consolidateItem) {
                  if(!isForceConsolidate){
                    $scope.annotations[pid].html += html;
                    $scope.annotations[pid]._isConsolidated = true;
                  } else {
                    if ($scope.annotations[pid].items[0].Type === "complex table") {
                      $scope.annotations[pid].html = $scope.annotations[pid].items[0].Html + html;
                    } else {
                      $scope.annotations[pid].html = html;
                    }
                  }
                } else {
                  $scope.annotations[pid].html = html;
                }
                $scope.annotations[pid].items.push(item);
                $scope.annotations[pid].isBlocked =
                  $scope.annotations[pid].isBlocked && item._isBlocked;

                if (!item._showAcceptReject) {
                  $scope.annotations[pid]._showAcceptReject = false;
                }
                if (!item._showAdjustManually) {
                  $scope.annotations[pid]._showAdjustManually = false;
                }
                if (item._showWarnings) {
                  $scope.annotations[pid]._showWarnings = true;
                }
              }

              let dummy = document.createElement('dummy');
              dummy.innerHTML = $scope.annotations[pid].html;

              item._annotation = $scope.annotations[pid];
              previousAnnotation =  $scope.annotations[pid];
              $scope.annotations[pid].insertions = dummy.querySelectorAll('ins.diff-item--primary:not(.diff-item--static)');
              $scope.annotations[pid].deletions = dummy.querySelectorAll('del.diff-item--primary:not(.diff-item--static)');
            }
          }
          callback();
        });
      }

      function getStyle(node) {
        let style = '';

        if (node && node.nodeType === Node.TEXT_NODE) {
          let span;
          let parent = node.parentElement;
          let tagName = parent.tagName.toLowerCase();

          if (tagName === 'span') {
            span = parent;

            if (span.getAttribute('contenteditable')) {
              span = parent.closest('span:not([contenteditable])');
            }
          } else {
            span = parent.closest('span:not([contenteditable])');
          }

          if (span) {
            style = span.getAttribute('style');
          }
        }

        if (style) {
          style = style.replace(/"/g, "'");
        }

        return style;
      }

      startProcess();
    };

    window.showDiffgram = $scope.showDiffgram;

    /**
     * =================================================================================
     */

    $scope.completeLoading = function() {
      preserveAsposeCustomStyles();

      const freeTexts = document.querySelectorAll('p:not([pid]):has([free-text], [free-text-placeholder]), tr:not([pid]):has([free-text], [free-text-placeholder]), table:not([pid]):has([free-text], [free-text-placeholder])');
      for (let i = 0; i < freeTexts.length; i++) {
        if (freeTexts[i].parentElement.nodeName.toLowerCase() !== 'generate-table') {
          let current = freeTexts[i];
          let firstFreeText = current.querySelector("[free-text], [free-text-placeholder]")
          let firstFreeTextId = firstFreeText.getAttribute("free-text") || firstFreeText.getAttribute("free-text-placeholder");
          current.setAttribute("pid", firstFreeTextId);
        }
      }

      setTimeout(()=>{
        $timeout(function() {
          const tables = document.querySelectorAll('.lease table');
          for (let tableIndex = 0; tableIndex < tables.length; tableIndex++) {
            if (
              !tables[tableIndex].hasAttribute('pid') &&
              tables[tableIndex].parentElement.nodeName.toLowerCase() !== 'generate-table'
            ) {
              const currentPidElement = tables[tableIndex].querySelector('[pid]');
              if (currentPidElement) {
                const pid = currentPidElement.getAttribute('pid');
                tables[tableIndex].setAttribute('pid', pid);
              } else if (!tables[tableIndex].closest('[pid]')) {
                console.log(tables[tableIndex]);
              }
            }
          }

          const uls = document.querySelectorAll('.lease ul');
          let li;
          let freeTextId;
          for (let ulIndex = 0; ulIndex < uls.length; ulIndex++) {
            li = uls[ulIndex].querySelector("li.empty-li");

            if (li) {
              li.innerText = window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE;
            }

            if (uls[ulIndex].hasAttribute('free-text')) {
              freeTextId = uls[ulIndex].getAttribute('free-text');
            } else {
              freeTextId = uls[ulIndex].getAttribute('free-text-placeholder');
            }
            const div = document.createElement('div');
            div.setAttribute('pid', freeTextId);
            uls[ulIndex].parentElement.insertBefore(div, uls[ulIndex]);
            div.insertBefore(uls[ulIndex], null);
          }

          /* add editor helper polyfill for first text id and Comments End   */
          
          pageLayersManager.updatePageBreaks();

          $scope.inInitialLoad = false;
          $scope.addArticleDisabled = true;

          //create list items map
          listItemsManager.updateListItemsLevelsInDom();
          listItemsManager.createListItemsMap();

          var branch = window._current_deploy_branch;
          var timestamp = window._current_deploy_timestamp;
          var company = $scope.lease.company.name;
          var majorVersion = parseInt($scope.lease.version);
          var minorVersion =
            $scope.lease.minorVersion == null
              ? $scope.lease.form.latestMinorVersion
              : $scope.lease.minorVersion;
          var documentType = !$scope.lease.type
            ? 'lease'
            : $scope.lease.type.toLowerCase();
          var buildingVersion = $scope.lease.form.buildingVersion;
          var asset = $scope.lease.building
            ? $scope.lease.building.displayName
            : 'blank';
          var flavor = _.snakeCase($scope.lease.form.flavor);

          if (!!branch && !!timestamp) {
            console.log(
              "%cVersion was deployed from branch '" +
                branch +
                "' at " +
                timestamp,
              'color: blue; font-size: 16px;',
            );
          }
          console.log('%cCompany: ' + company, 'color: blue; font-size: 16px;');
          console.log(
            '%cLease Version: ' +
              majorVersion +
              '.' +
              minorVersion,
            'color: blue; font-size: 16px;',
          );
          console.log(
            '%cDocument Type: ' + documentType,
            'color: blue; font-size: 16px;',
          );
          console.log('%cFlavor: ' + flavor, 'color: blue; font-size: 16px;');
          console.log(
            '%cBuilding Version (' +
              (window.isLocalBuildingInformation ? 'local' : 'remote') +
              '): ' +
              buildingVersion,
            'color: blue; font-size: 16px;',
          );
          console.log('%cAsset: ' + asset, 'color: blue; font-size: 16px;');

          calcLastFormUpdate();

          $scope.bindLeaseEvents();
          LeaseEditorService.bindImgEvents($('.new-paragraph img'), $scope);
          $scope.resetHighlighting();
          $scope.updateReleaseGuarantors();
          $scope.hideWhiteSpaceInOldTables();
          $scope.appendEditingSpaceToLeaseVars();
          $scope.initializeVersionControl();

          // move normal list items to paragraphs Start
          // after reload all "normal" list items will be converted to "paragraph" list items
          
          const listItemsData = $scope.lease.orderedListitem
          ? JSON.parse($scope.lease.orderedListitem)
          : [];

          let shouldUpdateListitems = false;

          listItemsData.forEach((item)=>{
            if(item.level === "normal"){
              shouldUpdateListitems = true;
              item.level = "paragraph";
            }
          });

          if(shouldUpdateListitems){
            $scope.lease.orderedListitem = JSON.stringify(listItemsData);
            
          }

          // move normal list items to paragraphs End
          

          // insert all list items
          $scope.setListItems().then(function() {
            $scope.restyle();

            // refresh lists after initial load
            $scope.inInitialLoad = false;

            $scope.safeApply(() => {
              $scope.isLoading = false;
              $('body').removeClass('skeleton-loader');
            });

            hideEmptyElements();

            $scope.structureChanged();

            $('.lease')
              .not('.lease--modified')
              .find('span:not(.SOCE):not(.EOCE)')
              .css('white-space', 'pre-wrap');

            $('table').css('float', '');

            fixSubSupTextDecorations();
            fixEmptyParagraphs();
            fixInsertedImages();
            fixGenerateTableFirstColumnStyles();

            /* temp fix to remove spaces from list items  templates will be moved
            TODO: move this to the editorHelper model */
            document
              .querySelectorAll('p[inserted-list-item-id]>span>span, p.list-item-override>span>span')
              .forEach(node => {
                node.parentElement.cleanEmptyTextNodes();
              });
            /* end tmp fix */

            // marks prerender that document is ready
            // start download
            $window.prerenderReady = true;

          
            versionControlManager.init(
              $scope,
              $rootScope,
              null,
              ApiService,
              LeaseEditorService
            );
            
            
            if(window.downloadForVersionControl){
              versionControlManager.setIds();
              downloadManager.prepareDownload("diffgramOriginal",$rootScope);
            }
            else if ($scope.download) {
              
              downloadManager.prepareDownload();
              // convert all images (with 'src' set and except for AWS) to embedded Base64 before download
              // end or download
            } else {
              $scope.safeApply(() => {
                $scope.isLoading = false;
                $('body').removeClass('skeleton-loader');
                console.log('%cLoad time: ' + performance.now() / 1000 + " seconds", 'color: red; font-size: 16px;');
              });
            }
          });

          if (!$scope.isPrerender) {
            $timeout(function() {
              if ($scope.lease.tenantInfo && $scope.building) {
                LeaseEditorService.setLeaseTitle();
              }
            }, 200);
          }

          // run the total-rent calculation on load
          $scope.calculateRentPSF($scope.lease);
          if ($scope.lease.renewalInfo && $scope.lease.renewalInfo.length > 0) {
            $scope.calculateRenewalPSF($scope.lease);
          }
          $scope.$apply();

          $rootScope.$on('showDiffgram', function(event, data) {
            $scope.showDiffgram(data.diffgram);
          });

          $scope.compileVisibleFreeTexts();
          
          setTimeout(()=>{
            if(window?.location?.href.indexOf('smlView')!== -1 && leaseIfManager){
              leaseIfManager.toggleShowAllView();
            }
          },100)

          if ($scope.isClausebookEnabled) {
            clausebookManager.init($scope, $mdDialog);
          }

          if ($scope.isCopilotEnabled) {
            autopilotManager.init($scope, $mdDialog);
          }

        }, 100);
      });
       
    };

    $scope.getFreeText = function() {
      $scope.setFreeTexts();
    };

    // invoked as a callback after fetching free text objects
    $scope.setFreeTexts = function() {
      // convert the free text array into an object indexed on the section ID
      if (Object.keys($scope.leaseFreeTexts).length === 0) {
        $scope.leaseFreeTexts = $scope.lease.freeTexts
          ? $scope.lease.freeTexts.reduce(function(o, v) {
              o[v.sectionId] = v;
              return o;
            }, {})
          : null;
      }
    };

    $scope.getBase64Image = function(img) {
      // Create an empty canvas element
      var canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      // Copy the image contents to the canvas
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL('image/png');
      return dataURL;
    };

    $scope.setCompany = function() {
      $scope.companyName = $scope.lease.company.name;
    };

    $scope.getCompany = function() {
      CompanyService.get({
        id: $scope.lease.companyId,
      }).then(
        function(company) {
          $scope.lease.company = company;
          $scope.setCompany();
        },
        function(error) {
          error.data = '';
          $scope.lease.company = {
            error: 'Error retrieving the company data (details below)',
            errorObject: error,
          };
          $scope.downloadErrorHandling();
        },
      );
    };

    $scope.setBuilding = function() {
      if ($scope.lease.building) {
        $scope.building = $scope.lease.building;
        $rootScope.buildingId = $scope.lease.building.id;
        $scope.building.displayName ||= $scope.building.name;
        $scope.building.dashboardName ||= $scope.building.displayName;
        $scope.building.version = $scope.lease.version;

        if (
          !$scope.lease.premiseAreaSize && 
          $scope.building.buildingOfficeArea && 
          $scope.hasEditorConfig('premisesAreaSize', 'useBuildingOfficeArea')
        ) {
          $scope.lease.premiseAreaSize = $scope.building.buildingOfficeArea;
        }
      }
    };

    $scope.setAssetsPaths = function() {
      var company = $scope.lease.company.name;
      var parentDocumentType = $scope.lease.type.toLowerCase();
      var currentDocumentType = parentDocumentType;
      var buildingVersion = $scope.lease.form.buildingVersion;
      var listItemsVersion = $scope.lease.form.listItemsVersion;
      var flavor = _.snakeCase($scope.lease.form.flavor);
      var formVersion = $scope.lease.form.version;

      if (window.name === 'download-abstract') {
        currentDocumentType = 'abstract';
        flavor = 'abstract_for_' + flavor;

        // TODO: right now we only support 1 version for abstracts,
        // we'll have to think about how to make it dynamic
        formVersion = "00001";
      }

      var baseBuildingUrl =
        'companies/' +
        company +
        '/' +
        currentDocumentType +
        '/forms/' +
        flavor + 
        '/buildings/' +
        buildingVersion;

      var desiredStylesUrl =
        'companies/' +
        company +
        '/' +
        currentDocumentType +
        '/forms/' +
        flavor +
        '/versions/' +
        formVersion +
        '/style.docx';

      $scope.ASSETS_BUILDING_DIR_URL = '/assets/' + baseBuildingUrl;
      $scope.ASSETS_DESIRED_STYLES = '/assets/' + desiredStylesUrl;

      if ($window.parent) {
        $window.parent.ASSETS_DESIRED_STYLES = $scope.ASSETS_DESIRED_STYLES;
      }
    };

    /**
     * [lkedmi] NOTE:
     * Both `window.lease` and `lease` are set and equal, you could argue that we can
     * omit the `lease` parameter. the reasoning not to omit it is that instead of
     * counting `window.lease` to be set by some code, it forces you to pass the lease
     * as a param.
     */
    $scope.setLease = function(lease, lease_id, download, isBlank) {
      $scope.lease = lease || window.lease;
      $scope.lease.version = $scope.lease.form.version;
      $scope.isMultipleTerms = lease.form.editor && lease.form.editor.isMultipleTerms;
      $scope.getFreeText();
      $scope.setAssetsPaths();

      if (window.lease) {
        try {
          $scope.isClausebookEnabled = false;

          const formConfig = JSON.parse(window.lease.form.config);
    
          if (formConfig?.clausebook) {
            $scope.isClausebookEnabled = true;
          }
        } catch (ex) {
          // Do nothing.
        }

        try {
          $scope.isCopilotEnabled = false;

          if (window?.user?.company?.companyProfile?.hasCoPilot) {
            $scope.isCopilotEnabled = true;
          }
        } catch (ex) {
          // Do nothing.
        }
      }

      var promises = [];

      var attachmentsList = _.keys(AttachmentService.attachments);
      for (var i = 0; i < attachmentsList.length; i++) {
        if (lease[attachmentsList[i]]) {
          var attachmentPromise = AttachmentService.getFile(
            lease[attachmentsList[i]],
            attachmentsList[i],
          );

          if (window.isDownload) {
            promises.push(attachmentPromise);
          }
        }
      }

      $scope.isPrerender = download ? true : $scope.isPrerender;
      $scope.download = $window.location.href.indexOf('/edit') < 0 && download;

      if ($scope.isFirstLeaseLoading) {
        // marks prerender that document is not ready
        $window.prerenderReady = false;
        $scope.isFirstLeaseLoading = false;
      }

      // temp initialization for exists lists
      if (lease.radiusRestriction) {
        lease.hasRadiusRestriction = true;
      }
      if (
        lease.permittedUseRestaurant ||
        lease.permittedUseLiquorSales ||
        lease.permittedUseMedical
      ) {
        lease.hasSpecialUseProvisions = true;
      }
      if (lease.freeRent && lease.hasFreeRent === undefined) {
        lease.hasFreeRent = true;
      }
      if (lease.fixturingPeriod) {
        lease.hasFixturingPeriod = true;
      }

      $scope.inInitialLoad = true;
      $rootScope.lease = lease;
      $scope.currentRootAddress = window.location.origin;

      $scope.setLeaseDefaults();

      if ($scope.lease.company) $scope.setCompany();
      else $scope.getCompany();

      if (!$rootScope.adminMode) {
        $scope.setBuilding();
      }

      // Init amendments fields
      if ($scope.lease.type === 'Amendment') {
        $scope.initAmendment($scope.lease);
      }

      // NOTE: for leases fetched from VTS calculate frontend fields on the basis of backend preset fields
      if ($scope.lease.source && $scope.lease.source.sourceId) {
        $scope.baseRent = $scope.lease.baseRent;
        $scope.annualIncrease = $scope.lease.annualIncrease;
        $scope.calculateRentPSF($scope.lease);
        $scope.consolidateRentPeriods();
      }

      //initialize calculate fields
      if ($scope.lease.form.customTransformations) {
        const customTransformations = JSON.parse($scope.lease.form.customTransformations);
        const keys = Object.keys(customTransformations);
        for (let i = 0; i < keys.length; i++) {
          customTransformations[keys[i]].calcFunc = eval("(" + customTransformations[keys[i]].calcFunc + ")");
          $scope.calculatedFields.calculatedFields[keys[i]] = customTransformations[keys[i]];
        }
      }
      $scope.calculatedFields.init();

      $scope.fromThen = $q.all(promises).then(function(values) {
        $scope.getOverrides();
        $scope.setOverrides();

        // This is stupid but unfortunately, couldn't find a better way
        // the `promises` array contains promise for each `getFile` call
        // When the promise is evaluated, its value is an array of promises,
        // one for each page in the pdf/image, we need to make sure to wait for those
        // too before we proceed, otherwise, the images might not show
        var nestedPromises = [];
        for (var i = 0; i < values.length; i++) {
          if (values[i] && values[i].isAttachment) {
            nestedPromises = _.concat(nestedPromises, values[i].list);
          }
        }

        $q.all(nestedPromises).then(function() {
          setTimeout(function() {
            $scope.completeLoading();
          });
          return;
        });
        return;
      });

      if ($scope.editingEnabled) {
        $rootScope.$emit('leaseStatusChanged', {
          lock: $scope.lease.isLocked,
        });
      }
    };

    $scope.initAmendment = function(lease) {
      //  convert string to dates
      if ($scope.lease.existingLeaseDate) {
        $scope.lease.existingLeaseDate = new Date(
          $scope.lease.existingLeaseDate,
        );
      }
      if ($scope.lease.existingLeaseExpirationDate) {
        $scope.lease.existingLeaseExpirationDate = new Date(
          $scope.lease.existingLeaseExpirationDate,
        );
      }
      if ($scope.lease.existingGuarantorInfo) {
        if ($scope.lease.existingGuarantorInfo.individuals) {
          for (
            var i = 0;
            i < $scope.lease.existingGuarantorInfo.individuals.length;
            i++
          ) {
            var curr = $scope.lease.existingGuarantorInfo.individuals[i];
            $scope.lease.existingGuarantorInfo.individuals[
              i
            ].hasBeenReleased = curr.hasBeenReleased
              ? curr.hasBeenReleased
              : false;
            $scope.lease.existingGuarantorInfo.individuals[
              i
            ].guarantyDate = curr.guarantyDate
              ? moment(curr.guarantyDate, 'YYYY-MM-DDTHH:mm:ssZ').toDate()
              : null;
            $scope.lease.existingGuarantorInfo.individuals[
              i
            ].releaseDate = curr.releaseDate
              ? moment(curr.releaseDate, 'YYYY-MM-DDTHH:mm:ssZ').toDate()
              : null;
          }
        }
        if ($scope.lease.existingGuarantorInfo.marriedCouples) {
          for (
            var i = 0;
            i < $scope.lease.existingGuarantorInfo.marriedCouples.length;
            i++
          ) {
            var curr = $scope.lease.existingGuarantorInfo.marriedCouples[i];
            $scope.lease.existingGuarantorInfo.marriedCouples[
              i
            ].hasBeenReleased = curr.hasBeenReleased
              ? curr.hasBeenReleased
              : false;
            $scope.lease.existingGuarantorInfo.marriedCouples[
              i
            ].guarantyDate = curr.guarantyDate
              ? moment(curr.guarantyDate, 'YYYY-MM-DDTHH:mm:ssZ').toDate()
              : null;
            $scope.lease.existingGuarantorInfo.marriedCouples[
              i
            ].releaseDate = curr.releaseDate
              ? moment(curr.releaseDate, 'YYYY-MM-DDTHH:mm:ssZ').toDate()
              : null;
          }
        }
        if ($scope.lease.existingGuarantorInfo.entities) {
          for (
            var i = 0;
            i < $scope.lease.existingGuarantorInfo.entities.length;
            i++
          ) {
            var curr = $scope.lease.existingGuarantorInfo.entities[i];
            $scope.lease.existingGuarantorInfo.entities[
              i
            ].hasBeenReleased = curr.hasBeenReleased
              ? curr.hasBeenReleased
              : false;
            $scope.lease.existingGuarantorInfo.entities[
              i
            ].guarantyDate = curr.guarantyDate
              ? moment(curr.guarantyDate, 'YYYY-MM-DDTHH:mm:ssZ').toDate()
              : null;
            $scope.lease.existingGuarantorInfo.entities[
              i
            ].releaseDate = curr.releaseDate
              ? moment(curr.releaseDate, 'YYYY-MM-DDTHH:mm:ssZ').toDate()
              : null;
          }
        }
      }
      if ($scope.lease.previousAmendment) {
        for (var i = 0; i < $scope.lease.previousAmendment.length; i++) {
          if (typeof lease.previousAmendment[i].date === 'string') {
            $scope.lease.previousAmendment[i] = {
              date: moment(
                $scope.lease.previousAmendment[i].date,
                'YYYY-MM-DDTHH:mm:ssZ',
              ).toDate(),
            };
          }
        }
      }
      if ($scope.lease.abatementDates) {
        for (var i = 0; i < $scope.lease.abatementDates.length; i++) {
          if (typeof $scope.lease.abatementDates[i].date === 'string') {
            $scope.lease.abatementDates[i].date = moment(
              $scope.lease.abatementDates[i].date,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).toDate();
          }
        }
      }
      // If needed, initialize the priorAssignments array
      if (!$scope.lease.priorAssignments) {
        $scope.lease.priorAssignments = [];
      }
      //  convert string to dates
      if ($scope.lease.priorAssignments) {
        for (var i = 0; i < $scope.lease.priorAssignments.length; i++) {
          $scope.lease.priorAssignments[i]._originalIndex = i;

          if (typeof $scope.lease.priorAssignments[i].date === 'string') {
            $scope.lease.priorAssignments[i].date = moment(
              $scope.lease.priorAssignments[i].date,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).toDate();
          }
        }
      }
      // Maintain the `numberOfContinuingGuarantors`
      $scope.numberOfContinuingGuarantors = _.filter(
        _.union(
          $scope.lease.existingGuarantorInfo.individuals || [],
          $scope.lease.existingGuarantorInfo.marriedCouples || [],
          $scope.lease.existingGuarantorInfo.entities || [],
        ),
        {
          hasBeenReleased: false,
        },
      ).length;
      // Create the initial data for the breakpoint table
    };


    $scope.compileVisibleFreeTexts = async function(element) {
      setTimeout(() => {
        window.applyFilterVisible(() => {

          element = element ? element : document;

          let list = element.querySelectorAll("[free-text-placeholder]");
          let scope;

          for (let i = 0; i < list.length; i++) {
            list[i].setAttribute(
              "free-text",
              list[i].getAttribute("free-text-placeholder")
            );
            list[i].removeAttribute("free-text-placeholder");

            scope = angular.element(list[i]).scope();

            // NOTE: Every node SHOULD have a scope
            // The only rason we do this is to accomodate for SML errors which
            // breaks the document from loading. Specifically, this is a hint
            // of an SML issue where an `ifi` might not be colored properly
            if (scope) {
              $compile(list[i])(scope);
            } else {
              $compile(list[i])($scope);
              const errorMessage = `The following node doesn't have a scope! this might be an indication of missing colors in the SML: ${list[i].outerHTML}`;
              console.error(errorMessage);
              Raven.captureMessage(errorMessage);
            }
          }
        });
      });
    };

    $rootScope.hasEditorConfig = function(template, key = null) {
      let cards = [];

      if (
        window.lease &&
        window.lease.form &&
        window.lease.form.editor &&
        window.lease.form.editor.cards
      ) {
        cards = window.lease.form.editor.cards;
      }

      if ($scope.editor && $scope.editor.cards) {
        cards = $scope.editor.cards;
      }

      for (let cardIndex = 0; cardIndex < cards.length; cardIndex++) {
        let categories = cards[cardIndex].categories;

        for (let categoryIndex = 0; categoryIndex < categories.length; categoryIndex++) {
          let templates = categories[categoryIndex].templates;

          for (let templateIndex = 0; templateIndex < templates.length; templateIndex++) {
            if (templates[templateIndex].id !== template) {
              continue;
            }

            let config = templates[templateIndex].config;
            let keys = Object.keys(config);

            if (key === null) {
              return !!config;
            }

            if (keys.indexOf(key) !== -1) {
              return config[key];
            } else {
              return false;
            }
          }
        }
      }
      return false;
    }

    HTMLDocument.prototype.queryCommandState = (command) => {
      let sel = window.getSelection()
      let anchorNode = sel.anchorNode;
      if (anchorNode.nodeType === Node.TEXT_NODE) {
        anchorNode = anchorNode.parentElement;
      }
      let leaseVarDecorationNode = anchorNode.closest("[data='lease-var-decoration']");
      let leaseVarOriginalValue = leaseVarDecorationNode ? leaseVarDecorationNode.style.textDecoration : "";
      let computedStyle = getComputedStyle(anchorNode);
      let originalValue;
      let result = false;

      switch (command) {
        case 'bold':
          originalValue = computedStyle.fontWeight;
          if ((originalValue)/1 >= 700 || originalValue.contains(command)) {
            return true;
          } else {
            anchorNode.childNodes.forEach((child) => {
              if (child.nodeType !== Node.ELEMENT_NODE) {
                return;
              }
              computedStyle = getComputedStyle(child);
              originalValue = computedStyle.fontWeight;
              if ((originalValue)/1 >= 700 || originalValue.contains(command)) {
                result = true;
              }
            });
            return result;
          }
        case 'underline':
          originalValue = computedStyle.textDecoration;
          if (originalValue.contains(command) || leaseVarOriginalValue.contains(command)) {
            return true;
          } else {
            anchorNode.childNodes.forEach((child) => {
              if (child.nodeType !== Node.ELEMENT_NODE) {
                return;
              }
              computedStyle = getComputedStyle(child);
              originalValue = computedStyle.textDecoration;
              if (originalValue.contains(command)) {
                result = true;
              }
            });
            return result;
          }
        case 'italic':
          originalValue = computedStyle.fontStyle;
          if (originalValue.contains(command)) {
            return true;
          } else {
            anchorNode.childNodes.forEach((child) => {
              if (child.nodeType !== Node.ELEMENT_NODE) {
                return;
              }
              computedStyle = getComputedStyle(child);
              originalValue = computedStyle.fontStyle;
              if (originalValue.contains(command)) {
                result = true;
              }
            });
            return result;
          }
      }
      return false;
    }


    $scope.reloadDocument = function() {
      $scope.loadDocument(window._lease_id, window._lease_type);
    };

    $scope.loadDocument = function(id, type, download) {
      let documentType;
      let dealType;
      let flavor;
      let version;
      let building;
      let tenant;

      window._lease_id = id;
      window._lease_type = type;

      $scope.compileVisibleFreeTexts();

      const elementsToRemove = document.querySelectorAll(".lease.lease--original .document > script");
      for (
        let elementIndex = 0;
        elementIndex < elementsToRemove.length;
        elementIndex++
      ) {
        elementsToRemove[elementIndex].classList.add("remove-on-download");;
      }

      if (window.lease?.form) {
        documentType = window.lease.form ? window.lease.form.name.toLowerCase() : '';
        dealType = window.lease.form ? window.lease.form.dealType : '';
        flavor = window.lease.form ? window.lease.form.flavor : '';
        version = window.lease.form ? window.lease.form.version : '';
        building = window.lease.building ? window.lease.building.displayName : '';
        tenant = window.lease.customFields?.fileName || window.lease.tenantInfo?.tradeName || window.lease.tenantInfo?.name;
      }

      window.track.event(new LoadDocumentEvent({
        documentType,
        dealType,
        flavor,
        version,
        building,
        tenant,
      }));

      if (window.isAbstract) {
        $rootScope.abstract = $scope.abstract = window.parent.abstractData;
      }

      $scope.download = download;
      if (download) {
        console.log('render start: ' + new Date().getTime()); // debug log
      }

      // Align the structure of advanced-edit with other free-texts
      // Other free-texts are nested within a container, but advanced-edit is not
      setTimeout(function() {
        const advancedEdit = document.querySelectorAll('[advanced-edit]');
        advancedEdit.forEach(node => {
          const container = document.createElement('div');
          node.parentElement.insertBefore(container, node);
          node.remove();
          container.appendChild(node);
        });
      });

      $scope.setLease(window.lease, null, download);

      $('body').addClass('skeleton-loader');
    };

    $scope.showPromptRefresh = function(msg, buttons) {
      $scope.openConfirmModal(
        msg,
        function showPromptRefresh() {
          $window.location.reload();
        },
        null,
        buttons,
      );
    };

    $scope.showPromptRedirectToDashboard = function(msg, buttons) {
      $scope.openConfirmModal(msg, null, null, buttons, '/');
    };

    $scope.setLockMessage = function() {
      var message = '';
      if ($scope.lockData) {
        message = 'This lease is locked by ' + $scope.lockData.email;
      }
      $rootScope.lockMessage = message;
    };

    $scope.downloadErrorHandling = function() {
      if ($scope.download) {
        $cookies.put('download_in_process', 'false', {
          path: '/',
        });
        alert($scope.downloadErrorMessage);
      }
    };

    $scope.updateAreaSize = function() {
      $scope.calculateRentPSF($scope.lease);
      $scope.calculateRenewalPSF($scope.lease);
      $scope.consolidateRentPeriods();
      $scope.consolidateRenewalInfo();
    };

    $scope.updateAnnualIncrease = function() {
      $scope.annualIncrease = $scope.lease.annualIncrease;
      $scope.updateNumberOfRenewalTermsAndRentPeriods(
        $scope.lease.renewalCount,
        $scope.lease.renewalTerm,
      );
      $scope.calculateRentPSF($scope.lease);
      $scope.calculateRenewalPSF($scope.lease);
      $scope.consolidateRentPeriods();
      $scope.consolidateRenewalInfo();
    };

    $scope.updateBaseRent = function() {
      $scope.baseRent = $scope.lease.baseRent;
      $scope.updateNumberOfRenewalTermsAndRentPeriods(
        $scope.lease.renewalCount,
        $scope.lease.renewalTerm,
      );
      $scope.calculateRentPSF($scope.lease);
      $scope.calculateRenewalPSF($scope.lease);
      $scope.consolidateRentPeriods();
      $scope.consolidateRenewalInfo();
    };

    $scope.updateBuilding = async function(buildingId, showWarning = false) {
      var building = _.find($scope.adminBuildings, function(b) {
        return b.id === buildingId;
      });
      $rootScope.buildingId = building.id;
      $scope.building = building;
      $scope.building.displayName ||= $scope.building.name;
      $scope.building.dashboardName ||= $scope.building.displayName;

      let specificUrl = $scope.building.specificUrl;
      const parts = specificUrl.split('/');

      if (parts.length == 2) {
        if ($scope.lease && typeof $scope.lease.error === 'undefined') {
          var buildingFileUrl = parts[1];

          specificUrl =
            $scope.ASSETS_BUILDING_DIR_URL +
            '/' +
            buildingFileUrl;

          $rootScope.isBuildingLanguageNeeded = true;
        } else {
          window.isBuildingLanguageNewPath = false;
        }
      }

      $scope.setAssetsPaths();
      $scope.setBuildingUrl(specificUrl);

      setTimeout(function() {
        $scope.structureChanged();
      });

      if (showWarning) {
        $mdToast.show({
          hideDelay: 10000,
          position: 'top right',
          template:
            '<md-toast>' +
            '<div class="md-toast-content">' +
            '<span class="md-toast-text">Changes are made to the form template and are not asset specific</span>' +
            '</div>' +
            '<div class="bottom-arrow"></div>' +
            '</md-toast>',
        });
      }
    };

    $scope.refreshRentParams = function(rentPeriods) {
      if (rentPeriods && rentPeriods[0]) {
        $scope.baseRent = rentPeriods[0].rentPerSqFoot;
        if (rentPeriods.length > 1) {
          var rent0 = rentPeriods[0].rentPerSqFoot;
          var rent1 = rentPeriods[1].rentPerSqFoot;
          $scope.annualIncrease = parseFloat(
            ((rent1 / rent0 - 1) * 100).toFixed(2),
          );
        }
      }
    };

    function updateTerms(updatedTerms) {
      var deletedTerm = _.differenceBy(
        $scope.lease.terms,
        updatedTerms,
        'id',
      )[0];
      if (deletedTerm) {
        _.remove(lease.terms, function(t) {
          return t.id == deletedTerm.id;
        });
      }
      var addedTerm = _.differenceBy(updatedTerms, $scope.lease.terms, 'id');
      if (addedTerm) {
        $scope.lease.terms = $scope.lease.terms.concat(addedTerm);
      }
      $scope.allowRemoveTerm = true;
    }

    $scope.isUpdating = false;

    function timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function sleep(fn, ...args) {
      await timeout(1500);
      return fn(...args);
    }

    async function retry(fn, error, n) {
      for (let i = 0; i < n; i++) {
        try {
          if (i === 0) {
            return await fn();
          } else {
            return await sleep(fn);
          }
        } catch {}
      }

      if (error) {
        error();
      }
    }

    function updateError() {
      const errorMessage = "Due to a network error your latest change wasn't saved, please reload the document and try again";

      $scope.showPromptRedirectToDashboard(errorMessage, {
        ok: 'OK'
      });

      $scope.isUpdating = false;
      $rootScope.serverRunning = false;
      $timeout(function() {
        $rootScope.serverRunning = true;
        $rootScope.$digest();
      }, 10000);

      throw new Error(errorMessage);
    }

    function updateInternal() {
      var url =
        '/api/' +
        pluralize($scope.lease.type).toLowerCase() +
        '/' +
        $scope.lease.id;
        $scope.undoDisabled = true;

      $scope.startSaving();
      
      return DocumentService.$put(url, $scope.lease).then(
        function(res) {
          $rootScope.serverRunning = true;
          // update last updated lease to be the current lease
          $scope.isUpdating = false;
          $scope.undoDisabled = false;
          $scope.endSaving();

          if (res?.terms) {
            updateTerms(res.terms);
          } else {
            $scope.allowRemoveTerm = true;
          }

          LeaseEditorService.setLeaseTitle();
        },
        function(err) {
          $scope.undoDisabled = false;
          $scope.endSaving();
          throw err;
        },
      );
    }

    $scope.removeListItemsInWrongLocation = async ()=>{
      let deferred = $q.defer();
      let refresh = false;
      if($scope.listItems && listItemsManager){
        $scope.listItems.forEach(function (itemObject){
          let freeText = document.querySelector('[free-text="' +itemObject.afterFreeText +'"]');

          if(freeText){
            let anchor = freeText.getTopAnchor();
            let listItemFreeText = document.querySelector('[free-text="' +itemObject.headerFreeText +'"]');
            listItemFreeText = listItemFreeText ? listItemFreeText : document.querySelector('[free-text="' +itemObject.bodyFreeText +'"]');
            if(listItemFreeText){
              let listAnchor = listItemFreeText.getTopAnchor();

              if(anchor.parentElement.hasAttribute('lease-if')){
                if(!anchor.parentElement.contains(listAnchor)){
                  let insertedListItemId = listAnchor.getAttribute('inserted-list-item-id');

                  document.querySelectorAll('[inserted-list-item-id="'+insertedListItemId+'"]').forEach((el)=>{
                    refresh = true;
                    el.remove();
                  });
                }
              }
            }
          }
        });
      }

      if (refresh) {
        listItemsManager.createListItemsMap();
      }

      deferred.resolve();
      return deferred;
    }

    $scope.reInsertMissingListItems = async () => {
      setTimeout(async function() {
        if (!window.isDownload) {
          // Remove list items that are not in the right location
          await $scope.removeListItemsInWrongLocation();
        }

        if ($scope.listItems && listItemsManager) {
          $scope.listItems.forEach(function(itemObject) {
            if (itemObject.json || itemObject.fromPID) {
              return;
            }

            const headerFreeText = document.querySelector(
              '[free-text="' + itemObject.headerFreeText + '"]',
              true
            );
            const bodyFreeText = document.querySelector(
              '[free-text="' + itemObject.bodyFreeText + '"]',
              true
            );

            if (!headerFreeText && !bodyFreeText) {
              if (itemObject && itemObject.afterFreeText && pageLayersManager) {
                pageLayersManager.updateOutlineById(itemObject.afterFreeText);
              }
              listItemsManager.listsInsertItem(itemObject);
            }
          });
        }
      });
    };

    function forceLeaseIfCompile(){
      document.querySelectorAll('.lease [lease-if]:not([data-if-id])').forEach((node)=>{
        var scope = angular.element(node).scope();
        $compile(node)(scope);

      });
    }

    $scope.resetTimezone = (ref) => {
      const date = _.get($scope, ref);

      if (date) {
        const utcDate = new Date(date.toISOString().substring(0,11) + "04:00:00.000Z");
        _.set($scope, ref, utcDate);
      }
    }

    $scope.update = _.debounce(
      function() {
        if ($scope.isLoading || document.body.classList.contains("skeleton-loader")) {
          return;
        }

        $scope.compileVisibleFreeTexts();
        forceLeaseIfCompile();

        const unhiddenElements = document.querySelectorAll('.lp-hide div[ng-repeat]:not(.lp-hide-element)');
        const hiddenElements = document.querySelectorAll('.lp-show div[ng-repeat]:not(.lp-show-element)');
        if(unhiddenElements.length !== 0 || hiddenElements.length !== 0){
          const ifKeys = Object.keys( leaseIfManager.leaseIfItems);

          for(let index=0; index < ifKeys.length; index ++){
            let ifEl = leaseIfManager.leaseIfItems[ifKeys[index]];
            if(ifEl && ifEl.element.tagName === "DIV"){
              try{
                ifEl.updateElementStyle();
              }
              catch(e){
                
              }
            }
          }
        }

        if (!$scope.adminMode) {
          if ($scope.lease && $scope.lease.id) {
            retry(updateInternal, updateError, 3);
          }
        }

        autopilotManager.updateDocumentContext();
      },
      300,
      { trailing: true, leading: false },
    );

    $scope.hideWhiteSpaceInOldTables = function() {
      $('p:has( > span > table)').addClass('no-after');
    }

    $scope.appendEditingSpaceToLeaseVars = function() {
      if ($scope.isPrerender) {
        return;
      }

      var leaseVars = $('lease-var, [data-list-static-item="true"], crr');

      leaseVars.each(function() {
        var $current = $(this).get(0);
        var $parent = $(this).parent();

        if ($current.tagName === "CRR") {
          if ($current.contentEditable !== "false") {
            $current.setAttribute("contenteditable", false);
          }
        }

        if ($parent[0].childNodes.length === 1) {
          if (!$current.classList.contains("inserted-list-item")) {
            $parent.append('<span class="SKIP">&#xFEFF;</span>');
            $parent.prepend('<span class="SKIP">&#xFEFF;</span>');
          }
        }
      });
    };


    $scope.initializeVersionControl = function() {
      
      const leaseEditor = document.querySelector('.lease--editor');

      if (!leaseEditor) {
        return;
      }

      if ($window.frameElement && $window.frameElement.getAttribute('isDiff') === 'true') {
        return;
      }

      if (!$rootScope.user) {
        return;
      }

      if (!$rootScope.user.company.companyProfile.versionControl) {
        return;
      }

      const editor = window.getComputedStyle(leaseEditor);
      const paddingLeft = editor['padding-left'];
      const paddingRight = editor['padding-right'];

      var style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = `
        .review-changes .lease.lease--modified .lease-nav {
          padding-left: ${paddingLeft};
          padding-right: ${paddingRight};
        }

        .annotations .annotation {
          width: calc(100% - ${paddingLeft} - ${paddingRight});
          right: ${paddingRight};
        }
      `;
      document.getElementsByTagName('head')[0].appendChild(style);
    };

    /*-----------------------------------------------------------------------------

       Ordered Lists related functionality

     ----------------------------------------------------------------------------*/
    /* list items manager ------------------------------------------------------------------------------------ Start  */

    (function (scope) {

      function ListItemsManager(scope) {
        let $scope = scope;

        this.listItemObjectsArray = [];
        this.listLevelsMap = null;
        let listLevelByName = null;
        this.listsCounters = null;
        this.listCurrentLevels = {};
        this.listOulinesDisplayName = {};
        this.$templateCache = $templateCache;

        this.getOutlineParagraphLevel = function(outlineName) {
          if (
            outlineName &&
            this.listLevelsMap &&
            this.listLevelsMap[outlineName]
          ) {
            if (
              this.listLevelsMap[outlineName].filter(o => {
                return o.level == `${outlineName}/paragraph`;
              }).length !== 0
            ) {
              return `${outlineName}/paragraph`;
            }
          }
          return "paragraph";
        };

        this.getLevelInfoByName = (name) => {
          return listLevelByName[name];
        };

        this.getLevelInfoByHeader = (header) => {
          var level =  $scope.listLevels.filter((o)=>{ return o.listHeader == header});
          return level[0] ? level[0] : null;
        };

        this.getListItemObjectFromNode = node => {
          let containerNode = node.closest("[data-list-map-index]");
          let returnObject = null;
          
          if (containerNode) {
            returnObject =
              listItemsManager.listItemObjectsArray[
                containerNode.getAttribute("data-list-map-index") * 1
              ];
          }

          return new Block(returnObject,node,$scope,$q,LeaseEditorService,$compile);
        };

        this.getInsertedListItemByElement = (el) => {
          if(el.querySelector('[list]')){
            let ids = [];
            el.querySelectorAll('.editable').forEach((node) => {
              ids.push(node.getAttribute('section-id') * 1);
            });

            for (let i = 0; i < $scope.listItems.length; i++) {
              let li = $scope.listItems[i];
              if (ids.indexOf(li.headerFreeText) !== -1 || ids.indexOf(li.bodyFreeText) !== -1) {
                return li;
              }
            }
          }
          return null;
        }


        this.addSectionEndForOutlineEnd = () => {
          let count=0;
          document.querySelectorAll('outline-end,lease-end').forEach((outline_end) => {
            if (!outline_end.getAttribute('section-end-added')) {
              let name = outline_end.getAttribute('name');
              let el = document.createElement('section-end');
              el.setAttribute('listlevel', 0);
              el.setAttribute('data-list-static-level', 0);
              el.setAttribute('data-list-static-outline', name);
              el.setAttribute('data-list-static-id', count);
              outline_end.parentElement.insertBefore(el, outline_end);
              outline_end.setAttribute('section-end-added', true);
              outline_end.setAttribute('data-list-static-id', count);
              count++;
            }
          });

        }
        this.createListItemsMap = ()=>{
          window.applyFilterVisible(this._createListItemsMap);
        }
        this._createListItemsMap = () => {
          this.addSectionEndForOutlineEnd();
          this.listsCounters = {};

          if (!this.listLevelsMap && $scope.listLevels) {
            this.listLevelsMap = {};
            this.listOulinesDisplayName = {};
            listLevelByName = {};
            $scope.listLevelDepth = {};

            for (let index = 0; index < $scope.listLevels.length; index++) {
              let levelInfo = $scope.listLevels[index];
              listLevelByName[levelInfo.level] = levelInfo;
              let outline = levelInfo.outline ? levelInfo.outline : 'main';

              if (!this.listLevelsMap[outline]) {
                this.listLevelsMap[outline] = [];
              }

              if(outline !== 'main'){
                if (!this.listOulinesDisplayName[outline]) {
                  this.listOulinesDisplayName[
                    outline
                  ] = levelInfo.outlineDisplayName
                    ? levelInfo.outlineDisplayName
                    : outline;
                }
              }

              this.listLevelsMap[outline].push(levelInfo);
              $scope.listLevelDepth[index] = this.listLevelsMap[outline].length - 1;
              $scope.listLevelDepth[levelInfo.level] = this.listLevelsMap[outline].length - 1;
            }

            $scope.addOutlinesDisabled = Object.keys( this.listOulinesDisplayName).length === 0 ;
            
          

            $scope.safeApply();
          }
          let newList = [];
          let uniqueId = `outline--${Math.random() * 100000000000}`;
          this.outlineArray = [{ name: "static", uniqueId: uniqueId }];
          let lease = document.querySelector('.lease');
          if (lease) {
            lease.querySelectorAll('span[list], br[style*="mso-break-type:section-break"],outline,outline-end,lease-start,lease-end,section-end,reset-lists')
              .forEach((node) => {
                if (node.tagName) {
                  if (node.tagName === 'OUTLINE') {
                    this.listsCounters[node.getAttribute('name')] = {};
                  }
                  if (node.tagName === 'OUTLINE' || node.tagName === 'LEASE-START') {
                    uniqueId = `outline--${Math.random() * 100000000000}`;
                    node.setAttribute('data-outline-unique-id', uniqueId)
                    this.outlineArray.push({
                      name: node.tagName === 'LEASE-START' ? 'main' : node.getAttribute('name'),
                      uniqueId: uniqueId,
                    });
                    return;
                  } else if (node.tagName === 'OUTLINE-END' || node.tagName === 'LEASE-END') {
                    const outline = this.outlineArray.pop();
                    if (outline) {
                      node.setAttribute('data-outline-unique-id', outline.uniqueId);
                    }
                    return;
                  }
                }
                insertListItemToMap(node,null,newList);
              });
          }
          this.listItemObjectsArray = newList;
        };

        this.getNewItemListIdAndFormat = (afterFreeText, level, outline, isNewArticle) => {

          let returnObject = {
            listId: null,
            listFormat: '',
            nodeToInsertBefor: null,
            level : null,
          };

          let nodeToInsertAfter = getNodeToInsertAfter(afterFreeText, outline);


          let newItemLevelInfo = listLevelByName[level];
          let newItemLevelDepth = $scope.listLevelDepth[level];
          returnObject.level = newItemLevelInfo ? newItemLevelInfo.level : null;

          if(nodeToInsertAfter.tagName === "OUTLINE"){
            returnObject.listId = newItemLevelInfo.name;
            returnObject.listFormat = newItemLevelInfo.format;
            returnObject.nodeToInsertBefor = nodeToInsertAfter.nextElementSibling;
            return returnObject;
          }

          let prevListItemNode = this.getClosestListItem(nodeToInsertAfter);
          let prevListItemInfo = prevListItemNode ? prevListItemNode.getListItemInfoFromNode() : null;
          let prevItemLevelDepth = prevListItemInfo ? $scope.listLevelDepth[prevListItemInfo.levelName] : -1;



          let nextListItemNode;
          if (level.toLowerCase() === "article") {
            nextListItemNode = isNewArticle ?
              getBeforeNode(nodeToInsertAfter, level) :
              $(document.querySelector('lease-end'));
          } else {
            nextListItemNode = getBeforeNode(nodeToInsertAfter, level);
          }

          nextListItemNode = nextListItemNode.get ? nextListItemNode[0] : nextListItemNode;
          let nextListItemLevelInfo = nextListItemNode ? nextListItemNode.getListItemInfoFromNode() : null;

          if(nextListItemLevelInfo && nextListItemLevelInfo.static){
            let workinfo = nextListItemLevelInfo;
            while(workinfo && workinfo.static){
              workinfo = this.listItemObjectsArray[workinfo.currentIndex+1];
            }
            if(workinfo && workinfo.outline === newItemLevelInfo.outline &&
             newItemLevelDepth === $scope.listLevelDepth[workinfo.levelName]){
                nextListItemLevelInfo = workinfo;
             }

          }

          if (!nextListItemLevelInfo && nextListItemNode) {
            let infoNode = nextListItemNode.querySelector('[data-list-map-index]');
            nextListItemLevelInfo = infoNode && infoNode.getAttribute('data-list-map-index') ?
              this.listItemObjectsArray[infoNode.getAttribute('data-list-map-index') * 1] : null;
          }

          let nextListItemLevelDepth = nextListItemLevelInfo ? $scope.listLevelDepth[nextListItemLevelInfo.levelName] : -1;

          if (prevItemLevelDepth > -1) {
            if (newItemLevelDepth === prevItemLevelDepth) {
              returnObject.listId = prevListItemInfo.list;
              returnObject.listFormat = prevListItemInfo.format;
            } else if (newItemLevelDepth > prevItemLevelDepth) {
              if (newItemLevelDepth === nextListItemLevelDepth) {
                returnObject.listId = nextListItemLevelInfo.list;
                returnObject.listFormat = nextListItemLevelInfo.format;
              } else {
                // new list id
                returnObject.listId = createNewListId(newItemLevelInfo.name, newItemLevelInfo.levelName);
                returnObject.listFormat = newItemLevelInfo.format;
              }
            } else if (newItemLevelDepth < prevItemLevelDepth) {
              if (nextListItemLevelDepth > -1 && nextListItemLevelDepth === newItemLevelDepth) {
                returnObject.listId = nextListItemLevelInfo.list;
                returnObject.listFormat = nextListItemLevelInfo.format;
              } else {
                // locate previous list item with same level
                let scanIndex = prevListItemInfo.currentIndex;

                while (
                  scanIndex >= 0 &&
                  ((this.listItemObjectsArray[scanIndex].outline ===
                    prevListItemInfo.outline &&
                    $scope.listLevelDepth[
                      this.listItemObjectsArray[scanIndex].levelName
                    ] !== newItemLevelDepth) ||
                    (this.listItemObjectsArray[scanIndex] &&
                      this.listItemObjectsArray[scanIndex].static))
                ) {
                  scanIndex--;
                }

                if (scanIndex !== -1 && $scope.listLevelDepth[this.listItemObjectsArray[scanIndex].levelName] === newItemLevelDepth) {
                  returnObject.listId = this.listItemObjectsArray[scanIndex].list;
                  returnObject.listFormat = this.listItemObjectsArray[scanIndex].format;
                } else {
                  // new list id
                  returnObject.listId = createNewListId(newItemLevelInfo.name, newItemLevelInfo.levelName);
                  returnObject.listFormat = newItemLevelInfo.format;
                }
              }
            }
          } else {
            if (newItemLevelDepth === nextListItemLevelDepth) {
              returnObject.listId = nextListItemLevelInfo.list;
              returnObject.listFormat = nextListItemLevelInfo.format;
            } else {
              // new list id
              returnObject.listId = createNewListId(newItemLevelInfo.name, newItemLevelInfo.levelName);
              returnObject.listFormat = newItemLevelInfo.format;
            }
          }

          
          if (nextListItemNode && nextListItemNode.previousElementSibling &&
            nextListItemNode.previousElementSibling.textContent.trim().startsWith('!!') &&
            nextListItemNode.previousElementSibling.textContent.trim().endsWith('!!')) {
            nextListItemNode = nextListItemNode.previousElementSibling;
          }
          returnObject.nodeToInsertBefor = nextListItemNode;

          if(!returnObject.nodeToInsertBefor){
            
            const freeText  = document.querySelector(`[free-text="${afterFreeText}"]`);
            const blockData = freeText ?  freeText.getBlockData() : null;
            
            if(blockData){
              returnObject.nodeToInsertBefor = blockData.containingNode.nextElementSibling;
            }

          }

          return returnObject;
        }


        this.listsInsertItemAnywhere = (itemObject, byAdmin) => {
          const retValue = applyShowAll(this._listsInsertItemAnywhere,[itemObject, byAdmin]);
          return retValue;
        };

        this.locateNewListItemAnchor = (itemObject) => {

          if (itemObject.fromUI) {

            delete itemObject.fromUI;
            const depth = $scope.listLevelDepth[itemObject.level];
            let fromAnchor = $scope.currentlyEditedNode
              ? $scope.currentlyEditedNode.getTopAnchor
                ? $scope.currentlyEditedNode.getTopAnchor()
                : null
              : null;

            if (fromAnchor) {
              const block = fromAnchor.getBlockData();

              if (
                block.isInsertedListItem() &&
                !block.isInsertedListItemSplitted()
              ) {
                const nodes = block.getInsertedListItemIdElements();

                if (
                  nodes &&
                  nodes.length > 1 &&
                  nodes[0].closest("table") === null
                ) {
                  fromAnchor = nodes[nodes.length - 1];
                }
              }

              const leaseElement = document.querySelector(
                ".lease.lease--original"
              );

              const allFreeTexts = leaseElement.querySelectorAll(
                "[pid],outline-end,lease-end,lease-start,outline"
              );

              const myIndex = allFreeTexts.indexOf(fromAnchor);

              let scanIndex = myIndex + 1;
              let stopScan = false;

              let currentAnchor = fromAnchor;

              while (
                !stopScan &&
                allFreeTexts[scanIndex] &&
                allFreeTexts[scanIndex].tagName !== "OUTLINE" &&
                allFreeTexts[scanIndex].tagName !== "LEASE-START" &&
                allFreeTexts[scanIndex].tagName !== "OUTLINE-END" &&
                allFreeTexts[scanIndex].tagName !== "LEASE-END" &&
                !allFreeTexts[scanIndex].closest(".inserted-list-item") &&
                allFreeTexts[scanIndex].closest(".lp-hide")
              ) {
                if (
                  allFreeTexts[scanIndex].getListLevelNumber() > depth &&
                  !allFreeTexts[scanIndex].closest("del.diff-item--accepted") &&
                  !allFreeTexts[scanIndex].closest("table")
                ) {
                  currentAnchor = allFreeTexts[scanIndex];
                } else {
                  stopScan = true;
                }
                scanIndex++;
              }

              itemObject.afterFreeText = currentAnchor.getAttribute("pid");
              itemObject.index = currentAnchor.getNodeOccurrenceIndexByAttribute(
                "pid"
              );
            }
        }
      }

        this._listsInsertItemAnywhere = (itemObject, byAdmin) => {
          const levelInfo = listItemsManager.getLevelInfoByName(
            itemObject.level
          );
          const depth = $scope.listLevelDepth[itemObject.level];

          if(itemObject.fromUI && !itemObject.singleFT ){
            itemObject.singleFT = true;
          }

          let template = this.createListItemFromTemplate(
            levelInfo ? levelInfo.level : itemObject.level,
            levelInfo ? levelInfo.format : null,
            levelInfo ? levelInfo.name : null,
            itemObject,
          );

          this.locateNewListItemAnchor(itemObject);

          let containerToIsertAfter = null;

          if (itemObject.level === "sub-paragraph") {
            let freeTextElement = document.querySelector(`[free-text="${itemObject.afterFreeText}"]`);
            containerToIsertAfter = freeTextElement && freeTextElement.parentElement ? freeTextElement.parentElement : null;
          } else {
            containerToIsertAfter = document.querySelectorWithInstance(
              `[pid="${itemObject.afterFreeText}"]`,
              itemObject.index
            );
          }

          // handle lease if deprecated
          if (!containerToIsertAfter) {
            const lastDataFTSElement = document
              .querySelectorAll(
                '[data-fts*="|' + itemObject.afterFreeText + '|"]'
              )
              .lastItem();
            const ftsData = lastDataFTSElement
              ? lastDataFTSElement.getAttribute("data-fts")
              : null;

            if (ftsData) {
              containerToIsertAfter = document.querySelectorWithInstance(
                '[data-fts="' + ftsData + '"]',
                itemObject.index
              );

              containerToIsertAfter = containerToIsertAfter
                ? containerToIsertAfter
                : document
                    .querySelectorAll('[data-fts="' + ftsData + '"]')
                    .lastItem();
            }
          }

          if (!containerToIsertAfter) {
            return null;
          }

          const freeTextInDom = document.querySelector(`[free-text="${itemObject.headerFreeText}"]`);
          // Get block data for list items based on PID
          const block = itemObject.fromPID ? containerToIsertAfter.getBlockData() : null;
          if(!freeTextInDom){
            const myListItemId = $scope.nextUnusedListItemId;
  
            // insert to document
            const newListItemBox = document.createElement('div');
            newListItemBox.classList.add('new-list-item-container');
            newListItemBox.classList.add("lp-show-element", "lp-if-ignore");
  
            while (template.children.length !== 0) {
              const element = template.children[template.children.length - 1];
  
              if (itemObject.deleted && element && element.classList) {
                element.classList.add("deleted-container");
              }
  
              element.classList.add("lp-show-element", "lp-if-ignore");
  
              if (!byAdmin) {
                element.classList.add("inserted-list-item");
                element.setAttribute("inserted-list-item-id", myListItemId);
              } else {
                element.classList.add("list-item-override");
              }
              newListItemBox.insertBefore(element,newListItemBox.children[0]);
            }
  
            
           
  
            if (
              containerToIsertAfter.parentElement.closest(
                ".new-list-item-container"
              )
            ) {
              while (newListItemBox.children.length !== 0) {
                containerToIsertAfter.parentElement.insertBefore(
                  newListItemBox.children[newListItemBox.children.length - 1],
                  containerToIsertAfter.nextElementSibling
                );
              }
            } else {
              containerToIsertAfter.parentElement.insertBefore(
                newListItemBox,
                containerToIsertAfter.nextElementSibling
              );
            }
  
            
            $scope.nextUnusedListItemId++;
            $scope.listItemUndoTracking[
              itemObject.headerFreeText + "-" + itemObject.bodyFreeText
            ] = myListItemId;
            $scope.listItemObjects[
              itemObject.headerFreeText + "-" + itemObject.bodyFreeText
            ] = itemObject;
          }



          if (block) {
            block.remove();
            containerToIsertAfter = block.containingNode;
          }

          return containerToIsertAfter;

          
        };

        this.listsInsertItem = (itemObject, byAdmin) => {
          if (itemObject.isNewListItem) {
            return this.listsInsertItemAnywhere(itemObject, byAdmin);
          }
          else
          {
            return window.applyFilterVisible(this._listsInsertItem,[itemObject, byAdmin]);
          }
        }
        this._listsInsertItem = (itemObject, byAdmin) => {

          /* make sure new article will be placed in correct place */
          if (itemObject.level.toLowerCase() === "article") {
            if (Object.keys(itemObject).indexOf('isNewArticle') === -1) {
              itemObject.isNewArticle = true;
            }
          }

          let levelAndLocationInfo = this.getNewItemListIdAndFormat(itemObject.afterFreeText, itemObject.level, itemObject.outline, itemObject.isNewArticle);
          var template = this.createListItemFromTemplate(levelAndLocationInfo.level, levelAndLocationInfo.listFormat, levelAndLocationInfo.listId, itemObject);
          var insertList = template.childNodes;
          var myListItemId = $scope.nextUnusedListItemId;
          const insertListParts = Array.from(insertList);

          for (let i = 0; i < insertListParts.length; i++) {
            var toInsert = $(insertListParts[i]);

            if (insertListParts[i].nodeType === Node.TEXT_NODE) {
              continue;
            }

            if (itemObject.deleted && toInsert[0] && toInsert[0].classList) {
              toInsert[0].classList.add('deleted-container');
            }

            if (!byAdmin) {
              toInsert.addClass('inserted-list-item');
              toInsert.attr('inserted-list-item-id', myListItemId);
            } else {
              toInsert.addClass('list-item-override');
            }
            $(levelAndLocationInfo.nodeToInsertBefor).before(toInsert);
            if (toInsert[0].querySelector('[list]')) {
              insertListItemToMap(toInsert.get(0).querySelector('[list]'), true);
            }
          }
          $scope.nextUnusedListItemId++;

          $scope.listItemUndoTracking[
            itemObject.headerFreeText + '-' + itemObject.bodyFreeText
          ] = myListItemId;
          $scope.listItemObjects[
            itemObject.headerFreeText + '-' + itemObject.bodyFreeText
          ] = itemObject;


          return $(levelAndLocationInfo.nodeToInsertBefor);

        };

        this.registerClientTemplates = function(){
          let styleSettings = null;
          let defaultFontSize = '';
          let defaultFontFamily = '';
          let fontSize;
          let fontFamily;
          let lineHeight;

          const fontFamilyNode = document.querySelector(".lease--original [style*='font-family']");
          const fontSizeNode = document.querySelector(".lease--original [style*='font-size']");
          const lineHeightNode = document.querySelector(".lease--original [style*='line-height']");

          if (fontFamilyNode) {
            fontFamily = fontFamilyNode.style.fontFamily.replace(/"/g, "");
          }

          if (fontSizeNode) {
            fontSize = fontSizeNode.style.fontSize;
          }

          if (lineHeightNode) {
            lineHeight = lineHeightNode.style.lineHeight;
          }

          $scope.defaultFont = styleSettings = {
            fontSize: fontSize,
            fontFamily: fontFamily,
            lineHeight: lineHeight,
          }

          if($scope?.lease?.form?.defaultFontData){
            try{
              const data = JSON.parse($scope.lease.form.defaultFontData);
              
              $scope.defaultFont = styleSettings = {
                ...styleSettings,
                ...data,
              }
            }
            catch(e){

            }
          }

          if(styleSettings){
            $scope.defaultFontData= styleSettings;
            if(styleSettings.fontSize){
              defaultFontSize = `font-size:${styleSettings.fontSize};`
            }
            if(styleSettings.fontFamily){
              defaultFontFamily = `font-family:${styleSettings.fontFamily};`
            }
          }

          const paragraphTemplate = `<p class="list-item-paragraph" style="margin-top:0px; margin-bottom:0px;${defaultFontSize}"><span free-text="[[[ headerfreetext ]]]"><span style="${defaultFontFamily}"></span></span></p>`;
          const normalTemplate = `<p class="list-item-paragraph" style="margin-top:0px; margin-bottom:0px;${defaultFontSize}"><span free-text="[[[ headerfreetext ]]]"><span style="${defaultFontFamily}"></span></span></p>`;
          const subParagraphTemplate = `<p class="list-item-paragraph list-item-sub-paragraph" style="margin-top:0px;margin-bottom:0px;${defaultFontSize}"><span free-text="[[[ headerfreetext ]]]"><span style="${defaultFontFamily}"></span></span></p>`;
          const ulParagraphTemplate = `<ul class="list-item-paragraph list-item-ul-paragraph" free-text="[[[ headerfreetext ]]]" bullet="true"></ul>`;

          $templateCache.put('paragraph' + '.html', paragraphTemplate);
          $templateCache.put('normal' + '.html', normalTemplate);
          $templateCache.put('sub-paragraph' + '.html', subParagraphTemplate);
          $templateCache.put('ul-paragraph' + '.html', ulParagraphTemplate);
        }

        // remove body free-text from none table list items
        this.removeBodyFromTemplate = (template,itemObject)=>{
          const keepSingleFT = itemObject.singleFT ? itemObject.singleFT : false;
          if(keepSingleFT){
            const freetextList = template.querySelectorAll('[free-text]');
            if(freetextList.length > 1 && freetextList[0].closest('table') === null){
              const nodeToRemove = freetextList[1].closest('p,h1,h2,h3,h4,h5,h6');
              if(nodeToRemove){
                nodeToRemove.remove();
              }
            }
          }

        };

        this.createListItemFromTemplate = (templateName, listFormat, listId, itemObject,compileTemplate = true, isImage = false) => {
          
          // after reload all "normal" list items will be converted to "paragraph" list items

          let templateText;
          let headerFreeText = itemObject.headerFreeText;
          let bodyFreeText = itemObject.bodyFreeText;
          let addMissingFreeText = itemObject.addMissingFreeText;
          let fromPID = itemObject.fromPID;
          let fromJson = itemObject.json;
          let jsonId = itemObject.jsonID || itemObject.afterFreeText;

          if (fromPID) {
            compileTemplate = false;
          }
          
          templateText = $templateCache.get(templateName + '.html');

          templateText = templateText.replace(/&#91;/gi, '[');
          templateText = templateText.replace(/&#93;/gi, ']');
          templateText = templateText.replace('[[[ listname ]]]', listId);
          templateText = templateText.replace('[[[ listformat ]]]', listFormat);
          templateText = templateText.replace(/free-text-placeholder/g, 'free-text');


          // header, if exists
          templateText = templateText.replace(
            '[[[ headerfreetext ]]]',
            headerFreeText,
          );
          templateText = templateText.replace('[[[ headerdefault ]]]', '');

          // body, if exists
          templateText = templateText.replace(
            '[[[ bodyfreetext ]]]',
            bodyFreeText,
          );
          templateText = templateText.replace('[[[ bodydefault ]]]', '');

          var template = document.createElement('div');
          template.innerHTML = templateText;


          if (templateName === "normal" || templateName == "paragraph" || templateName == "sub-paragraph") {
            let fontFamilyElement = document.querySelector(
              `[free-text="${itemObject.afterFreeText}"] span[style*="font-family"]`
            );

            fontFamilyElement = fontFamilyElement
              ? fontFamilyElement
              : document.querySelector(
                  `[free-text="${itemObject.afterFreeText}"][style*="font-family"]`
                );

            if (fontFamilyElement) {
              let templateFreeText = template.querySelector("[free-text]");
              if (templateFreeText) {
                templateFreeText.style.fontFamily =
                  fontFamilyElement.style.fontFamily;
              }
            }
          }

          // add marker class for image-container
          if(isImage){
            template.firstElementChild.classList.add('image-container');
          }

          // copy font to containing element for level change 

          const freeTextFirstSpanElement = template.querySelector('[free-text] span[style]');
          if(freeTextFirstSpanElement){
            const paretContainer = template.querySelector('[free-text]').parentElement;

            if(freeTextFirstSpanElement.style.fontSize){
              paretContainer.style.fontSize = freeTextFirstSpanElement.style.fontSize;
            }

            if(freeTextFirstSpanElement.style.fontFamily){
              paretContainer.style.fontFamily = freeTextFirstSpanElement.style.fontFamily;
            }
          }


          /* add pid to template Start */

          Array.from( template.children).forEach((node)=>{
            if(node && node.querySelector ){
              let freeTextNode = node.querySelector('[free-text]');
              if(freeTextNode){
                node.setAttribute('pid',freeTextNode.getAttribute('free-text'));
              }
            }
          });

          this.removeBodyFromTemplate(template,itemObject);

          // handle from json

          if(fromJson){
            const  jsonElement = ElementJson.fromJson(itemObject.json);
            const templateFreeText = template.querySelector('[free-text]');

            while (templateFreeText.nextElementSibling) {
              templateFreeText.nextElementSibling.remove();
            }

            while (jsonElement.childNodes.length > 0) {
              let el = jsonElement.childNodes[0];
              el.remove();
              templateFreeText.parentElement.appendChild(el);
            }
            templateFreeText.remove();

            let newPid = template.querySelector('[free-text]').getAttribute('free-text');

          }

          let listSpan = template.querySelector('[list]');
          
          if (listSpan && listSpan.parentElement) {
            const freeTextElement = listSpan.parentElement.querySelector(
              "[free-text]"
            );

            if (freeTextElement) {
              const parent = freeTextElement.closest("p, h1, h2, h3, h4, h5, h6");
  
              if (parent) {
                parent.setAttribute(
                  "pid",
                  freeTextElement.getAttribute("free-text")
                );
                parent.classList.add("inserted-list-item");
  
                if (itemObject.jsonID) {
                  parent.setAttribute("data-json-id", jsonId);
                }
              }
            }
          } else {
            const freeTextElement = template.querySelector("[free-text]");

            if (freeTextElement) {
              const parent = freeTextElement.closest("p, h1, h2, h3, h4, h5, h6");
  
              if (parent) {
                parent.setAttribute(
                  "pid",
                  freeTextElement.getAttribute("free-text")
                );
                parent.classList.add("inserted-list-item");
  
                if (itemObject.jsonID) {
                  parent.setAttribute("data-json-id", jsonId);
                }
              }
            }
          }

          /* add missing free text if needed */
          if(addMissingFreeText){
            const bodyFreeTextEl = template.querySelector(`[free-text="${bodyFreeText}"]`);
            const headreFreeTextEl = template.querySelector(`[free-text="${headerFreeText}"]`);
            if(!bodyFreeTextEl || !headreFreeTextEl ){
              const exsitingFreeText = bodyFreeTextEl || headreFreeTextEl;
              const freeTextId = bodyFreeTextEl ?  headerFreeText : bodyFreeText;

              if(exsitingFreeText){
                const el = document.createElement('span');
                el.setAttribute('free-text',freeTextId);
                if(exsitingFreeText === headreFreeTextEl){
                  exsitingFreeText.parentElement.insertBefore(el,exsitingFreeText.nextElementSibling);

                }
                else{
                  exsitingFreeText.parentElement.insertBefore(el,exsitingFreeText);
                }
              }

            }
            else{
              delete itemObject.addMissingFreeText;
            }
          }

          /* add pid to template End */

          $(template)
            .find('[list]')
            .attr('inserted-list', true);


          template.querySelectorAll('[free-text]').forEach((e)=>{
            if(e.previousSibling && e.previousSibling.nodeType === Node.TEXT_NODE){
              e.previousSibling.remove();
            }
            e.removeWhitespace();
          });

          if(templateName === "normal"){
            const element = document.querySelector(`[free-text="${itemObject.afterFreeText}"]`);
            const sourStyleBlock =  element && element.getBlockData ?  element.getBlockData() : null;
            const pidElement = template.querySelector('[pid]');
            let stylePid = pidElement ? pidElement.getAttribute('pid') : null;
            stylePid = stylePid == "null" ? null : stylePid;
            if(!stylePid  && fromPID ){
              stylePid = itemObject.afterFreeText;
            }
            
            

            let restyle = $scope.lease.restyle[stylePid];

            if (!restyle) {
              restyle =
                sourStyleBlock && sourStyleBlock.containingNode && stylePid
                  ? window.getRestyleObjectFromParagraph(
                      sourStyleBlock.containingNode,
                      stylePid
                    )
                  : null;
            }

            if (restyle) {
              restyle = { ...restyle, textIndent: 0, paragraphIndent: 0 };
            }

            if (restyle && stylePid ) {
              $scope.lease.restyle[stylePid] = restyle;
              setTimeout($scope.restyle,100);              
            }


          }


          if (compileTemplate) {
            if ($rootScope.listItemsPromises) {
              //Know when all free-texts in list-item completely compiled.
              $(template)
                .find('[free-text]')
                .each(function () {
                  var freeTextId = $(this).attr('free-text');
                  $rootScope.listItemsPromises[freeTextId] = $q.defer();
                });
            }
            $compile(template)($scope);
          }

          if (fromPID) {            
            const pidElement = document.querySelector(`[pid="${itemObject.afterFreeText}"]`);

            if(pidElement){
              const block = pidElement.getBlockData();
              block.moveBlockElementsToTemplate(template);
            }
          }

          // setTimeout(()=>{
            
          // });

          return template;

        }

        this.asyncCreateListItemFromTemplate = async (templateName, listFormat, listId, headerFreeText, bodyFreeText) => {

          const templateText = $templateCache.get(templateName + '.html');
          const templateHasBody = templateText.indexOf('bodyfreetext') !== -1;
          const templateHasHeader = templateText.indexOf('headerfreetext') !== -1;
          let itemObject = {headerFreeText:headerFreeText,bodyFreeText:bodyFreeText};

          let template = this.createListItemFromTemplate(templateName, listFormat, listId, itemObject);

          const waitForFreeTextCompile = function (resolve, reject) {
            let testCounter = 0;

            const isReady = () => {
              const headerReady = template.querySelector(`[free-text="${headerFreeText}"] .EOCE`);
              const bodyReady = template.querySelector(`[free-text="${bodyFreeText}"] .EOCE`);
              if (templateHasBody && templateHasHeader && headerReady && bodyReady) {
                return true;
              } else if (templateHasBody && bodyReady) {
                return true;
              } else if (templateHasHeader && headerReady) {
                return true;
              }
            }

            const reTest = () => {
              testCounter++;
              if (isReady()) {
                resolve(template);
              } else if (testCounter < 100) {
                setTimeout(reTest, 20);
              } else {
                reject(template);
              }
            };

            if (isReady()) {
              resolve(template);
            } else {
              setTimeout(reTest, 20);
            }
          }
          return new Promise(waitForFreeTextCompile);
        }


        this.updateListItemsLevelsInDom = ()=>{
          applyShowAll(()=>{
            const list = document.querySelectorAll('[list]');
            let containingNode = null;

            for (let index = 0; index < list.length; index++) {
              containingNode = list[index].getTopAnchor();

              if (!containingNode.hasAttribute('data-list-set-level') && containingNode.previousElementSibling) {
                let roundTripInfo = containingNode.previousElementSibling.textContent.split('!!').filter((item) => {
                  return item.trim() !== ''
                });

                if (roundTripInfo && roundTripInfo.length && roundTripInfo.length === 2) {
                  containingNode.setAttribute('data-list-set-level', roundTripInfo[0]);
                  containingNode.setAttribute('data-list-set-heading', roundTripInfo[1]);
                }
              }
            }

          });
        }

        let getNodeToInsertAfter = (afterFreeText, outline) => {
          // add support for outlines
          let nodeToInsertAfter = document.querySelector('[free-text="' + afterFreeText + '"]');
          if (!nodeToInsertAfter) {
            nodeToInsertAfter = document.querySelector('outline[pid="' + afterFreeText + '"]');
            if(nodeToInsertAfter)
            {
              return nodeToInsertAfter;
            }
            let orgFreeTextNumber = +afterFreeText;
            let freeTextId = 0;
            document.querySelectorAll('[free-text]').forEach((node) => {
              let textId = node.getAttribute('free-text') * 1;
              if (textId < orgFreeTextNumber) {
                freeTextId = Math.max(textId, freeTextId);
              }
            });
            if (freeTextId != 0) {
              // we hit the end of the lease if there was a previous
              nodeToInsertAfter = $("[free-text='" + freeTextId + "']");
            } else {
              // couldn't find anything with a ftId just below the missing one
              nodeToInsertAfter = getOutlineStartNode(outline);
            }
          }
          return nodeToInsertAfter;
        }

        this.getClosestListItem = (node) => {
          node = node.get ? node[0] : node;
          if (!node || !node.getAttribute) {
            return null;
          }

          node = node.getAttribute('pid') ? node :
            node.closest('[pid]') ? node.closest('[pid]') : node.parentElement;

          if (node.hasAttribute('data-list-map-index') &&
            (!node.hasAttribute('data-list-static-item') || node.getAttribute('data-list-static-item') == 'false')) {
            return node;
          } else {
            if (!node.getAttribute('pid')) {
              let freeTextNode = node.querySelector('[free-text]');
              if (freeTextNode) {
                node.setAttribute('pid', freeTextNode.getAttribute('free-text'));
              }
            }
            let list = Array.from(document.querySelectorAll('[pid], outline'));
            let index = list.indexOf(node);

            while (index >= 0 && list[index].tagName !== "OUTLINE" && !list[index].hasAttribute('data-list-map-index') &&
              (!list[index].hasAttribute('data-list-static-item') || list[index].getAttribute('data-list-static-item') == 'false')) {
              index--;
            }
            if (index === -1) {
              return document.querySelector('lease-start');
            }
            return list[index];
          }
        }

        let getBeforeNode = (afterNode, insertLevel) => {
          afterNode = afterNode.get ? afterNode : $(afterNode);
          if (afterNode && afterNode.selector === 'lease-start') {
            return $(document.querySelector('lease-end'));
          }
          let returnNode = null;
          let currentNode = afterNode.closest('[pid]').get(0);
          if (!currentNode) {
            currentNode = afterNode.parent().get(0);
          }
          currentNode = this.getClosestListItem(currentNode);

          if (currentNode.tagName === "OUTLINE") {
            returnNode = document.querySelector('outline-end[data-outline-unique-id="' + currentNode.getAttribute('data-outline-unique-id') + '"]').previousElementSibling;
          } else if (!currentNode || !currentNode.hasAttribute('data-list-map-index')) {
            let node = document.querySelector('[data-list-map-index][data-list-static-item="false"]');
            if (node) {
              let info = this.listItemObjectsArray[node.getAttribute('data-list-map-index') * 1];
              returnNode = info.tableNode ? $(info.tableNode) : $(info.containingNode);
            } else {
              returnNode = $(document.querySelector('lease-end').previousElementSibling);
            }
          }

          if (currentNode && currentNode.hasAttribute('data-list-map-index')) {
            let currentListItemIdex = currentNode.getAttribute('data-list-map-index') * 1;
            let currentListItemInfo = this.listItemObjectsArray[currentListItemIdex];

            if (currentListItemInfo) {
              let outlineLevels = this.listLevelsMap[currentListItemInfo.outline];
              let listDefItem = outlineLevels ? outlineLevels.filter((item) => {
                return item.level === insertLevel
              }) : null;
              let levelIndex = outlineLevels && listDefItem && listDefItem.length === 1 ? outlineLevels.indexOf(listDefItem[0]) : -1;

              if (levelIndex !== -1 && currentListItemIdex < this.listItemObjectsArray.length - 1) {
                let searchIndex = currentListItemIdex + 1;
                let item = this.listItemObjectsArray[searchIndex];

                while ((item.level > levelIndex || (item.static && !item.isSectionEnd)) && searchIndex < this.listItemObjectsArray.length - 1) {
                  searchIndex++;
                  item = this.listItemObjectsArray[searchIndex];
                }
                if (item.outline === currentListItemInfo.outline || item.isSectionEnd) {
                  if (item.level <= levelIndex) {
                    returnNode = item.tableNode ? $(item.tableNode) : $(item.containingNode);
                  } else {
                    if (currentListItemInfo.outline === 'main') {
                      returnNode = $('lease-end');
                    } else {
                      returnNode = $(document.querySelector(`outline-end[name="${currentListItemInfo.outline}"]`));
                    }
                  }
                } else {
                  if (currentListItemInfo.outline === 'main') {
                    returnNode = $('lease-end');
                  } else {
                    returnNode = $(document.querySelector(`outline-end[name="${currentListItemInfo.outline}"]`));
                  }
                }

              } else {
                if (currentListItemInfo.outline === 'main') {
                  returnNode = $('lease-end');
                } else {
                  returnNode = $(document.querySelector(`outline-end[name="${currentListItemInfo.outline}"]`));
                }
              }
            }
          }
          if (!returnNode) {
            console.error('error finding location to add new list item');
          }
          return returnNode;
        }

        let insertListItemToMap = (node, isInsertedListItem, workList) => {
          
          if (isInsertedListItem) {
            listItemsManager.createListItemsMap();
            return;
          }

          if(node.previousElementSibling && node.tagName && node.tagName === "SPAN"){            
            node.remove();
            return;
          }

          workList = workList ? workList : this.listItemObjectsArray;
          let nodeData = node.getListItemInfoFromNode(true);
          if (nodeData) {
            workList.push(nodeData);
            if (nodeData.node.hasAttribute('data-list-map-index')) {
              nodeData.node.setAttribute('data-list-map-index', workList.length - 1);              
            } else {
              nodeData.containingNode.setAttribute('data-list-map-index', workList.length - 1);
            }
            nodeData.currentIndex = workList.length - 1;
            updateListItemNumber(nodeData, workList.length - 1,workList);
            return nodeData;
          }

          let returnValue = null;
          if (node.tagName && ( node.tagName === "BR" || node.tagName === "RESET-LISTS") ) {
            workList.forEach((item) => {
              if (!item.isAutoNumber && item.list && item.outline && this.listsCounters[item.outline]) {
                this.listsCounters[item.outline][item.list] = 0;
              }
            });
          } else if (node.tagName && node.tagName === "SPAN") {
            let itemLevel = getListItemLevelAndOutLine(node);
            let containingNode = node.closest('[pid]');
            containingNode = containingNode ? containingNode : node.parentElement;

            let insNode = node.closest('ins');
            if (insNode && insNode.classList.contains('diff-item--processing')) {
              if (itemLevel && itemLevel.listLevel  && this.listCurrentLevels[itemLevel.listLevel.name]){
                node.setAttribute('list', this.listCurrentLevels[itemLevel.listLevel.name]);
              }
            }

            if (itemLevel.level !== -1) {
              let newItem = {
                ...itemLevel,
                node: node,
                containingNode: containingNode,
                format: node.getAttribute('format'),
                list: node.getAttribute('list'),
                tableNode: node.closest('table'),
                static: false,
                levelName: itemLevel.listLevel.level,
              };
              returnValue = newItem;

              if (!isInsertedListItem) {
                workList.push(newItem);
                newItem.currentIndex = workList.length - 1;
                containingNode.setAttribute('data-list-map-index', newItem.currentIndex);
                containingNode.setAttribute('data-list-static-item', false);

                updateListItemNumber(newItem, newItem.currentIndex ,workList);
              }

              // update props on continer for map and round trip

              containingNode.setAttribute('data-list-format', newItem.format);
              containingNode.setAttribute('data-list-list', newItem.list);

              if (!containingNode.hasAttribute('pid')) {
                let freeTextElement = containingNode.querySelector('[free-text]');
                if (freeTextElement) {
                  containingNode.setAttribute('pid', freeTextElement.getAttribute('pid'));
                }
              }

              if (!containingNode.hasAttribute('data-list-set-level') && containingNode.previousElementSibling) {
                let roundTripInfo = containingNode.previousElementSibling.textContent.split('!!').filter((item) => {
                  return item.trim() !== ''
                });

                if (roundTripInfo && roundTripInfo.length && roundTripInfo.length === 2) {
                  containingNode.setAttribute('data-list-set-level', roundTripInfo[0]);
                  containingNode.setAttribute('data-list-set-heading', roundTripInfo[1]);
                  newItem.setLevel = roundTripInfo[0];
                  newItem.setHeading = roundTripInfo[1];
                }
              }
              else {
                newItem.setLevel = containingNode.getAttribute('data-list-set-level');
                newItem.setHeading = containingNode.getAttribute('data-list-set-heading');
              }

            } else {
              let newStaticItem = {
                ...itemLevel,
                node: node,
                containingNode: containingNode,
                format: node.getAttribute('format'),
                list: node.getAttribute('list'),
                tableNode: node.closest('table'),
                static: true,
                outline: 'static',
                isAutoNumber: (node.getAttribute('style') && node.getAttribute('style').indexOf('pre-wrap') !== -1) ||
                  !node.hasAttribute('style') ? true : false,
              };
              workList.push(newStaticItem);
              if (containingNode) {
                containingNode.setAttribute('data-list-map-index', workList.length - 1);
                containingNode.setAttribute('data-list-static-item', true);
              } else {
                node.setAttribute('data-list-map-index', workList.length - 1);
                node.setAttribute('data-list-static-item', true);
              }

              updateListItemNumber(newStaticItem, workList.length - 1,workList);
              returnValue = newStaticItem;
            }
          } else if (node && node.tagName === "SECTION-END" && node.getAttribute('data-listlevel') !== "999" ) {
            let newStaticItem = {
              node: node,
              containingNode: node,
              static: true,
              outline: 'static',
              isSectionEnd: true,
            };
            newStaticItem.currentIndex = workList.length;

            // get prev level if needed for section-end
            if (!node.hasAttribute('data-list-static-level')) {

              let index = -1;

              if (workList.length > 1) {
                index = workList.length - 1;
                while (workList[index].static && index > 0) {
                  index--;
                }
              }

              let itemData = index !== -1 ? workList[index] : {
                level: 0,
                outline: 'main'
              };

              newStaticItem.level = itemData.level;

              if (node.hasAttribute('data-listlevel')) {
                newStaticItem.level = node.getAttribute('data-listlevel') * 1;
                let elList = Array.from(document.querySelectorAll('span[list], br[style*="mso-break-type:section-break"],outline,outline-end,lease-start,lease-end,section-end,reset-lists'));
                let myIndex = elList.indexOf(node) - 1;
                while (myIndex >= 0 && elList[myIndex].tagName === 'BR') {
                  myIndex--;
                }
                if(myIndex === -1){
                  return;
                }
                if (elList[myIndex].tagName === 'OUTLINE-END' || elList[myIndex].tagName === 'LEASE-END') {
                  newStaticItem.outline = 'static';
                } else if (elList[myIndex].tagName === 'OUTLINE') {
                  newStaticItem.outline = elList[myIndex].getAttribute('name');
                } else if (elList[myIndex].tagName === 'LEASE-START') {
                  newStaticItem.outline = 'main';
                } else if (elList[myIndex].tagName === 'SECTION-END') {
                  newStaticItem.outline = elList[myIndex].getAttribute('data-list-static-outline');
                } else {
                  let listItemIndex = elList[myIndex].getAttribute('data-list-map-index') * 1;
                  newStaticItem.outline = workList[listItemIndex].outline;
                }
              } else {
                newStaticItem.outline = itemData.outline;
              }
              node.setAttribute('data-list-static-level', newStaticItem.level);
              node.setAttribute('data-list-static-outline', newStaticItem.outline);
            } else {
              newStaticItem.level = node.getAttribute('data-list-static-level') * 1;
              newStaticItem.outline = node.getAttribute('data-list-static-outline');
            }
            workList.push(newStaticItem);
            newStaticItem.currentIndex = workList.length;
            node.setAttribute('data-list-map-index', workList.length - 1);
            node.setAttribute('data-list-static-item', true);
            returnValue = newStaticItem;
          }
          return returnValue;
        };


        let getListItemFormattedText = (item) => {
          if (!item || item.isSectionEnd) {
            return '';
          }


          let matches = item.formatUpdate.replace(/({.*?})/g, (data) => {
            let counterIndex = data.replace(/{/, '').replace(/}/, '');
            let value = this.listsCounters[item.outline][counterIndex];
            if (value === undefined) {
              value = 1;
              this.listsCounters[item.outline][counterIndex] = 1;
            }
            let returnValue;

            if (counterIndex.indexOf(':') !== -1) {
              let tmp = counterIndex.split(':');
              let leadingZero = tmp[0] * 1;
              value = this.listsCounters[item.outline][tmp[1]];
              returnValue = pad(value, leadingZero);
            } else if (counterIndex.startsWith('D#')) {
              returnValue = this.listsCounters[item.outline][counterIndex.substr(1)];
            } else if (counterIndex.startsWith('#')) {
              returnValue = toRoman(value, counterIndex.startsWith('#U'));
            } else if (!Number.isNaN(counterIndex * 1)) {
              returnValue = value;
            } else {
              let toLowerCase = counterIndex.toUpperCase() === counterIndex;
              returnValue = toLowerCase ? numberToLetters(value) : numberToLetters(value).toLowerCase();
            }

            if(returnValue === undefined){
              returnValue = 0;
            }

            return returnValue;
          });

          if (item.node.textContent !== matches) {
            if (
              item.node.children.length === 0 ||
              !$scope.diffgram.processing
            ) {
              item.node.textContent = matches;
            }
          }
        };

        let updateListItemNumber = (newItem, itemIndex, liteItemsList) => {
          newItem.levelCounters = [];

          //update list Identifier from list level details
          if(newItem.listLevel && newItem.listLevel.name){
            newItem.list = newItem.listLevel.name;
            newItem.format = newItem.listLevel.format;
          }

          if (newItem.containingNode && newItem.containingNode.classList.contains('deleted-container')) {
            return;
          }

          let delElement = newItem.node.closest('del');
          if (delElement && delElement.classList.contains('diff-item--accepted')) {
            newItem.vcNotInUse = true;
            return;
          }

          let insElement = newItem.node.closest('ins');
          if (insElement && !insElement.classList.contains('diff-item--accepted')) {
            newItem.vcNotInUse = true;
            return;
          }
          newItem.vcNotInUse = false;

          if (!this.listsCounters) {
            this.listsCounters = {};
          }

          if (!this.listsCounters[newItem.outline]) {
            this.listsCounters[newItem.outline] = {};
          }

          if (!this.listsCounters[newItem.outline][newItem.list] || newItem.reset) {
            this.listsCounters[newItem.outline][newItem.list] = 0;
          }

          this.listsCounters[newItem.outline][newItem.list] = this.listsCounters[newItem.outline][newItem.list] + 1 + newItem.skip;
          newItem.counter = this.listsCounters[newItem.outline][newItem.list];

          if (newItem.listLevel) {
            if (
              this.listCurrentLevels[newItem.listLevel.name] &&
              this.listCurrentLevels[newItem.listLevel.name] !== newItem.list
            ) {
              this.listCurrentLevels[newItem.listLevel.name] = newItem.list;
            } else {
              this.listCurrentLevels[newItem.listLevel.name] = newItem.list;
            }

            let newFormat = newItem.listLevel.format.replace(/({.*?})/g, (data) => {
              let counterIndex = data.replace(/{/, '').replace(/}/, '');
              let starstWithD = counterIndex.startsWith('D');
              let startsWithTwo = counterIndex.startsWith('2:');
              let prefixString = "";
              prefixString = prefixString + (starstWithD ? "D" : '');
              prefixString = prefixString + (startsWithTwo ? "2:" : '');
              counterIndex = starstWithD ? counterIndex.substr(1) : counterIndex;
              counterIndex = startsWithTwo ? counterIndex.substr(2) : counterIndex;
              newItem.levelCounters.push(this.listCurrentLevels[counterIndex]);

              return "{" + prefixString + this.listCurrentLevels[counterIndex] + "}"
            });
            newItem.formatUpdate = newFormat;
          } else {
            this.listCurrentLevels[newItem.list] = newItem.list;
            newItem.formatUpdate = newItem.format;
          }

          if (
            !newItem.static &&
            newItem.levelCounters &&
            newItem.levelCounters.length > 0
          ) {
            let prevItem = null;
            itemIndex--;
            while (itemIndex >= 0 && !prevItem) {
              if (!liteItemsList[itemIndex].static && !liteItemsList[itemIndex].vcNotInUse &&
                !liteItemsList[itemIndex].containingNode.classList.contains('deleted-container')) {
                prevItem = liteItemsList[itemIndex];
              }
              itemIndex--;
            }

            if (prevItem) {
              if (prevItem.level < newItem.level) {
                let fullPrevCounters = this.getListItemFullCounters(prevItem);
                newItem.levelCounters.forEach((item) => {
                  if (fullPrevCounters.indexOf(item) === -1) {
                    this.listsCounters[newItem.outline][item] = 1;
                  }
                });
              } else if (prevItem.level > newItem.level && prevItem.levelCounters) {
                prevItem.levelCounters.forEach((item) => {
                  if (newItem.levelCounters.indexOf(item) === -1) {
                    this.listsCounters[newItem.outline][item] = 0;
                  }
                });
              }
            }
          }

          getListItemFormattedText(newItem);
        };

        this.getListItemFullCounters = (item)=>{
          let retArr = [];
          let counter =item.level;

          while(listItemsManager.listLevelsMap[item.outline][counter])
          {
            retArr.push(listItemsManager.listLevelsMap[item.outline][counter].name);
            counter--;
          }
          return retArr;
        }

        let numberToLetters = (number) => {
          let count = 0;
          while (Math.floor(number / 26) > 0 && number > 26) {
            count++;
            number -= 26;
          }
          let ret = count > 0 ? `${String.fromCharCode(64 + count)}${String.fromCharCode(64 + number )}` : `${String.fromCharCode(64 + number)}`;
          return ret;
        };

        let getListItemLevelAndOutLine = (node) => {
          let arr = $scope.listLevels;
          let currentOutline = this.outlineArray[this.outlineArray.length - 1];

          if (!currentOutline || (currentOutline && currentOutline.name === 'main')) {
            currentOutline = null;
          }

          let levelAndOutline = {
            level: -1,
            outline: '',
            listLevel: null,
            skip: node.getAttribute && node.getAttribute('skip') ? node.getAttribute('skip') * 1 : 0,
            reset: node.getAttribute && node.getAttribute('reset') && (node.getAttribute('reset') === "true") ? true : false,
          };

          if (!!node.getAttribute('format')) {
            for (var i = 0; i < arr.length; i++) {
              if (node.parentElement.previousElementSibling &&
                node.parentElement.previousElementSibling.textContent.indexOf(`!!${arr[i].listHeader}!!`) !== -1) {
                levelAndOutline.outline = arr[i].outline;
                levelAndOutline.outline = levelAndOutline.outline ? levelAndOutline.outline : 'main';

                if (arr[i].outline && this.listLevelsMap[arr[i].outline]) {
                  levelAndOutline.level = $scope.listLevelDepth[i];
                  levelAndOutline.listLevel = arr[i];
                } else {
                  levelAndOutline.level = i;
                  levelAndOutline.listLevel = arr[i];
                }
                break;
              }
            }
          }

          if (levelAndOutline.level === -1) {
            var levelIdx = $scope.listsLookupLevelIdx(
              node.getAttribute('list'),
              node.getAttribute('format'),
              node.parentElement.tagName,
              node.getAttribute('outline') ? node.getAttribute('outline') : currentOutline,
            );

            if (levelIdx !== null) {
              levelAndOutline.outline = arr[levelIdx].outline;
              levelAndOutline.outline = levelAndOutline.outline ? levelAndOutline.outline : 'main';

              if (arr[levelIdx].outline && this.listLevelsMap[arr[levelIdx].outline]) {
                levelAndOutline.level = $scope.listLevelDepth[levelIdx];
                levelAndOutline.listLevel = arr[levelIdx];
              } else {
                levelAndOutline.level = levelIdx;
                levelAndOutline.listLevel = arr[levelIdx];
              }
            }
          }

          return levelAndOutline;
        };

        let listsLevelIncrement = (level, amount) => {
          level = level.toString();
          var val = level.replace('#L', '').replace('#U', '');

          if (!isNaN(level)) {
            val = 1 * val + amount;
          } else if (isLowercaseLetter(level.charAt(0))) {
            val = numberToLettersIncrement(
              lettersToNumber(val, false) + amount - 1,
              false,
            );
          } else if (isUppercaseLetter(level.charAt(0))) {
            val = numberToLettersIncrement(
              lettersToNumber(val, true) + amount - 1,
              true,
            );
          } else if (_.startsWith(level, '#L')) {
            val = '#L' + (parseInt(val) + amount);
          } else if (_.startsWith(level, '#U')) {
            val = '#U' + (parseInt(val) + amount);
          }

          return val;
        };

        let createNewListId = (listName, levelName) => {

          if (levelName === 'article') {
            return listName;
          }
          let increment = 100000;
          let newListName = listsLevelIncrement(listName, increment);
          while (document.querySelector('span[list="' + newListName + '"]')) {
            increment++;
            newListName = listsLevelIncrement(listName, increment);
          }
          return newListName;
        };


        if (!HTMLElement.prototype.getListItemInfoFromNode) {
          HTMLElement.prototype.getListItemInfoFromNode = function (fromInserListItem = false) {
            if (!this.hasAttribute('data-list-map-index') && ! fromInserListItem) {
              let node = this.closest('[data-list-map-index]');
              return node ? node.getListItemInfoFromNode() : null;
            } else {
              let ret = this.hasAttribute('data-list-map-index') ?
                listItemsManager.listItemObjectsArray[this.getAttribute('data-list-map-index') * 1] :
                null;
                if(fromInserListItem){
                  return ret && ret.node === this ? ret : null;
                }
                else{
                  return ret;
                }
            }
          }
        }
        this.registerClientTemplates();
      }

      window.listItemsManager = new ListItemsManager(scope);

      scope.listItemsManager = window.listItemsManager;
    })($scope);

    /* list items manager ------------------------------------------------------------------------------------ End    */

    /* SML Snippet ------------------------------------------------------------------------------------ Start    */

    (function() {
      function SMLSnippetManager() {
        this.editorElement = null;
        this.leaseElement = null;
        this.scope = null;
        this.leaseDocumentElement = null;

        Object.defineProperty(SMLSnippetManager.prototype,'inited',{
          value: function() {
            return this.leaseElement && this.scope ? true : false;
          },
          writable: false,
        });

        this.show = ()=>{
          if(!this.inited()){
            init();
          }
          // this.editorElement.innerHTML = '';
          showSmlEditor();
        };

        this.hide = ()=>{
          tinymce.remove('#sml-snippet-editor');
          showDocumentElement();
        };

        this.testSML = () => {
          const snippet = tinyMCE.get("sml-snippet-editor").getContent();

          if (!snippet) {
            this.displayElement.innerText = '';
            return;
          }

          this.displayElement.innerText = "Loading...";

          ApiService.snippet(snippet).then(data => {
            if (!this.specificUrl) {
              const data = Object.keys(this.scope).filter(x => {
                return x.indexOf("SpecificUrl") !== -1;
              });
              if (data && data[0]) {
                this.specificUrl = this.scope[data[0]];
              }
            }

            const decoder = new TextDecoder();
            const answer = decoder.decode(data.data);
            let dummy = document.createElement("dummy");
            dummy.innerHTML = answer;
            this.displayElement.innerHTML = dummy.firstElementChild.innerText;
            this.displayElement
              .querySelectorAll("building-variable")
              .forEach(buildingVariable => {
                buildingVariable.setAttribute("ng-attr-resource-url", "specificUrl");
              });
            $compile(this.displayElement)(this.scope);
          });
        };

        this.clearTestSML = () => {
          this.displayElement.innerText = '';
          tinyMCE.get("sml-snippet-editor").setContent("");
        };

        const init = () => {
          this.leaseElement = document.querySelector(".lease.lease--original");
          this.leaseDocumentElement = this.leaseElement.querySelector('.document');
          this.scope = angular.element(this.leaseElement).scope();
          this.editorElement = document.createElement('div');
          this.editorElement.innerHTML = smlSnippetEditrHTML;
          this.leaseElement.appendChild(this.editorElement);
          this.closeBtn = document.querySelector('.btn-close-sml-editor');
          this.closeBtn.addEventListener('click',this.hide);
          this.testBtn = document.querySelector('.btn-test-sml');
          this.testBtn.addEventListener('click',this.testSML);
          this.clearTestBtn = document.querySelector('.btn-clear-test-sml');
          this.clearTestBtn.addEventListener('click',this.clearTestSML);
          this.displayElement = document.querySelector('.sml-snippet__result');
        };

        const smlSnippetEditrHTML = `
          <div id="sml-snippet">
            <div class="sml-snippet__title">
              <div class="text"> Edit SML </div>
              <button class="btn-close-sml-editor btn dangerous">Close</button>
              <button class="btn-test-sml btn blue">Test</button>
              <button class="btn-clear-test-sml btn blue">Clear</button>
            </div>
            <div class="sml-editor--wrapper">
              <div id="sml-snippet-editor"> </div>
            </div>
            <div class="sml-snippet__result"></div>
          </div>
        `;

        let hideDocumentElement = ()=>{
          this.editorElement.classList.remove('lp-hide-element');
          this.leaseDocumentElement.classList.add('lp-hide-element');
        }

        let showDocumentElement = ()=>{
          this.leaseDocumentElement.classList.remove('lp-hide-element');
          this.editorElement.classList.add('lp-hide-element');
        }

        let showSmlEditor = ()=>{
          hideDocumentElement();
          tinymce.init({
            selector: '#sml-snippet-editor',
            resize: 'false',
            plugins: [
              'autoresize',
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks fullscreen',
              'insertdatetime media table contextmenu',
              'textcolor colorpicker',
            ],
            menubar : false,
            // invalid_styles: 'color',
            branding: false,
            auto_focus: 'sml-snippet-editor',
            invalid_elements: 'a',
            toolbar: [
              {
                name: 'history', items: [ 'undo', 'redo' ]
              },
              {
                name: 'styles', items: [ 'styleselect' ]
              },
              {
                name: 'fontsize', items: [ 'fontsizeselect' ]
              },
              {
                name: 'formatting', items: [ 'bold', 'italic', 'underline']
              },
              {
                name : 'color', items: ['forecolor', 'backcolor']
              },
              {
                name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ]
              },
              {
                name: 'indentation', items: [ 'outdent', 'indent' ]
              },

            ],
          });
        }
      }

      window.smlSnippetManager = new SMLSnippetManager();
    })();

    /* SML Snippet ------------------------------------------------------------------------------------ End      */



    /* editor search  -----------------------------------------------  start */
    (function (scopeVar) {

      function getSingleItemFromArrayByProperty(array, property, value) {
        let returnValue = null;
        for (let i = 0; i < array.length && !returnValue; i++) {
          if (array[i][property] === value) {
            returnValue = array[i];
          }
        }
        return returnValue;
      }

      function cleanLeaseVarName(name) {
        if (Object.keys(window?.lease?.form?.customEditor || {}).length === 0)  {
          return name.replace(/\[.*?]/g, '');
        } else {
          return name.replace(/\[.*?]/g, '').replace("lease.", "").replace("title.", "");
        }
      }

      function FieldDataItem(spanLabelElement) {

        this.leaseVarName = spanLabelElement.closest('editor-label').getAttribute('for');
        this.cleanLeaseVarName = cleanLeaseVarName(this.leaseVarName);
        if (Object.keys(window?.lease?.form?.customEditor || {}).length === 0)  {
          this.element = spanLabelElement;
          this.name = scopeVar.labels[this.cleanLeaseVarName];
          this.longName = scopeVar.fullLabels[this.cleanLeaseVarName];
        } else {
          this.element = spanLabelElement.closest("[data-type]").querySelector('label');
          this.name = this.element.textContent.trim();
          this.longName = this.name;
        }
        this.lowerCaseName = this.name ? this.name.toLowerCase() : '';
        this.lowerCaseLongName = this.longName ? this.longName.toLowerCase() : '';
        this.cardElement = this.element.closest('.card');
        this.cardElement = this.cardElement ? this.cardElement : this.element.closest('.collapse-card');
        if (!this.cardElement) return;
        this.cardTitle =  this.cardElement.querySelector('.collapse-card__title');
        this.cardName = this.cardElement ? this.cardElement.querySelector('.collapse-card__title').textContent.trim() : '';
        if (this.cardName === '') {
          let input = this.cardElement.querySelector('.collapse-card__title')
            .querySelector('input');
          if (input) {
            this.cardName = input.value.trim()
          }
        }
        this.searchName = this.lowerCaseName !== '' ? this.lowerCaseName : this.lowerCaseLongName;
        this.displayName = `${this.cardName} -> ${this.name ? this.name : this.longName}`;
        this.editorControl = this.element.closest('editor-control');
        this.isVisible = () => {
          this.isCurrenlyVisible = this.element.closest('.ng-hide') ? false : true;
          return this.isCurrenlyVisible;
        };
        this.isCurrenlyVisible = this.isVisible();

        this.hasName = () => {
          return this.name || this.longName;
        }

        if (this.editorControl) {
          this.editorControlFor = this.editorControl.getAttribute('for');
        }

        this.goTo = async (displayCardIfNotVisible = true) => {
          var deferred = $q.defer();
          await this._openContainingCard();
          setTimeout(()=>{
            this._scrollTo(displayCardIfNotVisible);
            deferred.resolve();
          }, 300);
          await deferred.promise;
          return this.isVisible();
        }

        this._getConditionFields = (condition)=>{
          let reg = new RegExp(/[a-zA-Z.\[\]0-9]*/g);
          let fieldOptions = condition.match(reg).filter((item) => {
            return item.trim() !== ""
          });
          return fieldOptions;
        }
        this._findEditorShowElements = async (element, allFields) => {
          if (!element) {
            return;
          }
          let editorShowElement = element.closest('[editor-show]');
          if (editorShowElement) {
            allFields = allFields ? allFields : leftSideEditorSearch.getAllFields();
            let editorShowCondition = editorShowElement.getAttribute('editor-show');
            let fieldOptions = this._getConditionFields(editorShowCondition)
            let field = null;
            for (let index = 0; index < fieldOptions.length && field === null; index++) {
              field = getSingleItemFromArrayByProperty(allFields, 'leaseVarName', fieldOptions[index]);
              if (field) {
                await field._openContainingCard();
              }
              if (field && !field.isVisible()) {
                field = null;
              }
            }
            if (field) {
              field.goTo();
            } else {
              this._findEditorShowElements(editorShowElement.parentElement, allFields);
            }
          }

        }
        this._scrollTo = async (displayCardIfNotVisible) => {
          if (this.isVisible()) {
            this.element.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            this.highlight();
          } else if(!displayCardIfNotVisible) {
            this._findEditorShowElements(this.element);
          }
          else{
            this.highlightCard();
          }
        }

        this.highlight = (time = 3) => {
          this.element.classList.add('search-highlight');
          if (time > 0) {
            setTimeout(this.removeHighlight, time * 1000);
          }
        }

        this.removeHighlight = () => {
          this.element.classList.remove('search-highlight');
        }

        this.highlightCard = (time = 3) => {

          this.cardTitle.classList.add('search-highlight');
          this.cardTitle.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          if (time > 0) {
            setTimeout(this.removeCradHighlight, time * 1000);
          }
        }

        this.removeCradHighlight = () => {
          this.cardTitle.classList.remove('search-highlight');
        }

        this._openContainingCard = async () => {
          let cardBody;
          let cardHeading;
          if (this.cardElement) {
            cardBody = this.cardElement.querySelector('.collapse-card__body');
            cardHeading = this.cardElement.querySelector('.collapse-card__heading');
            if (!cardBody.offsetParent) {
              cardHeading.click();
            }
          }

          const waitForCardToOpen = function (resolve, reject) {
            let testCounter = 0;
            const isReady = () => {
              return (cardBody.offsetParent ? true : false);
            }
            const reTest = () => {
              if (!cardBody) {
                reject();
              }
              testCounter++;
              if (isReady()) {
                resolve();
              } else if (testCounter < 100) {
                setTimeout(reTest, 20);
              } else {
                reject();
              }
            };

            if (isReady()) {
              resolve();
            } else {
              setTimeout(reTest, 20);
            }
          }
          return new Promise(waitForCardToOpen);
        }
      }

      function LeftSideEditorSearch() {
        this.getAllFields = () => {
          let fields = [];
          Array.from(document.querySelectorAll('editor-label span')).forEach((node) => {
            let field = new FieldDataItem(node);
            if (field.hasName && field.hasName()) {
              fields.push(field);
            }
          });
          return fields;
        }

        this.searchByName = (name) => {
          let workName = name.toLowerCase();
          const result = this.getAllFields().filter((filed) => {
            return (filed.searchName.indexOf(workName) !== -1);
          });
          return result;
        }

        this.searchByLeaseVarName = (name) => {
          const cleanName = cleanLeaseVarName(name);
          const result = this.getAllFields().filter((filed) => {
            return filed.leaseVarName === name || filed.cleanLeaseVarName == cleanName;
          });
          return result;
        }

        this.getFieldWithIndex = (name, index) => {
          return this.searchByLeaseVarName(name)[index];
        }

        this.openFieldByRelateds = (element) => {
          let name = element.getAttribute('ng-attr-name');
          var relateds = scopeVar.inline.findNestedControls(element);
          // remove yourself from array and reposition at beginning
          var myIdx = relateds.indexOf(name);
          if (myIdx > -1) {
            relateds.splice(myIdx, 1);
          }
          relateds.unshift(name);
          relateds = scopeVar.inline.util.checkRelateds(relateds, element);

          var index = null;
          if (name.indexOf('[') !== -1) {
            var elScope = angular.element(element).scope();
            index = elScope.$index;
          }

          let field = null;
          let allFields = this.getAllFields();
          if (index !== null) {
            field = getSingleItemFromArrayByProperty(allFields, 'leaseVarName', relateds[index])
          }

          for (let i = 0; i < relateds.length && !field; i++) {
            let item = relateds[i];
            item = item.replace('.length', '');
            field = getSingleItemFromArrayByProperty(allFields, 'leaseVarName', item)
          }

          if (field) {
            field.goTo();
          }
        }

        this.searchByLeaseVarElement = element => {
          if (element instanceof jQuery) {
            element = element[0];
          }
          
          let name;
          
          if (element.tagName === "LEASE-VAR") {
            name = element.getAttribute("ng-attr-name");
          } else {
            name = element.getAttribute("var");
          }

          let cleanName = cleanLeaseVarName(name);
          var scope = angular.element(element).scope();

          let fields = this.searchByLeaseVarName(cleanName);
          if (fields.length === 0) {
            this.openFieldByRelateds(element);
          } else if (fields.length === 1) {
            fields[0].goTo();
          } else {
            if (scope.$index && fields[scope.$index].isVisible()) {
                fields[scope.$index].goTo();
            } else {
              let index = Array.from(
                document.querySelectorAll(
                  'lease-var[ng-attr-name="' + name + '"]'
                )
              ).indexOf(element);
              if (fields[index] && fields[index].isVisible()) {
                fields[index].goTo();
              } else {
                for (var i = 0; i < fields.length; i++) {
                  if (fields[i].isVisible()) {
                    fields[i].goTo();
                    break;
                  }
                }
              }
            }
          }
        };
      }

      window["leftSideEditorSearch"] = new LeftSideEditorSearch();

    })($scope);

    /* editor search  -----------------------------------------------  end   */

    function preserveAsposeCustomStyles() {
      // Evidently, browsers don't support custom css rules
      // meaning, when you try to make a change to any css rules,
      // they disappear altogether. we need to preserve them to allow
      // Aspose convert HTml to Word properly
      $('.lease')
      .not('.lease--modified')
      .find('span[style*="-aw-"]')
      .each(function() {
        let style = $(this).attr('style') || '';
        let parts = style.split(/[:;]/g);

        for (let partIndex = 0; partIndex < parts.length; partIndex += 2) {
          if (parts[partIndex].indexOf('-aw-') !== -1) {
            let customStyle = $(this).attr('data-custom-style') || '';

            if (customStyle.indexOf(parts[partIndex] === -1)) {
              customStyle += `${parts[partIndex]}: ${parts[partIndex + 1]};`;
              $(this).attr('data-custom-style', customStyle);
            }
          }

          if (parts[partIndex].trim() === '-aw-import' && parts[partIndex + 1].trim() === 'spaces' && this.textContent) {
            if (style.indexOf('7pt') === -1) {
              // Replace hard-space with normal space
              this.textContent = this.textContent.replace(/ /g, ' ');
            }
          }
        }
      });
    }

    function fixSubSupTextDecorations() {
      const root = document.querySelector('.lease:not(.lease--modified)');
      
      if (root) {
        const sup = root.querySelectorAll("[style*='vertical-align: super']");
        sup.forEach((item) => {
          item.style.fontSize = "75%";

          const span = document.createElement("span");

          if (item.style.textDecoration === "underline") {
            span.style.textDecoration = "underline";
            item.style.textDecoration = "none";
            item.parentElement.insertBefore(span, item);
            span.appendChild(item);
          }
        });
  
        const sub = root.querySelectorAll("[style*='vertical-align: sub']");
        sub.forEach((item) => {
          item.style.fontSize = "75%";

          const span = document.createElement("span");

          if (item.style.textDecoration === "underline") {
            span.style.textDecoration = "underline";
            item.style.textDecoration = "none";
            item.parentElement.insertBefore(span, item);
            span.appendChild(item);
          }
        });
      }
    }

    function fixEmptyParagraphs() {
      const root = document.querySelector('.lease:not(.lease--modified)');

      if (root) {
        const paragraphs = Array.from(root.querySelectorAll("[pid]"));
        let paragraph;
        let placeholder;
        let clone;
  
        for (let i = 0; i < paragraphs.length; i++) {
          paragraph = paragraphs[i];
          clone = document.createElement("dummy");
          clone.innerHTML = paragraph.outerHTML;
          clone.removeWhitespace();
  
          // This checks if the paragraph only consists of a &nbsp;
          if (clone.textContent === ' ' && !clone.querySelector("img")) {
            placeholder = paragraphs[i].querySelector(".PLACEHOLDER");
    
            if  (placeholder) {
              placeholder.textContent = window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE;
            }
          }
        }
      }
    }

    function fixInsertedImages() {
      const root = document.querySelector('.lease:not(.lease--modified)');

      if (root) {
        const images = root.querySelectorAll(".inserted-list-item img[data-original-src]");
        let container;

        for (let i = 0; i < images.length; i++) {
          container = images[i].getTopAnchor();
          container.classList.add("image-container");
        }
      }
    }

    function fixGenerateTableFirstColumnStyles() {
      const root = document.querySelector('.lease:not(.lease--modified)');

      if (root) {
        const cells = root.querySelectorAll("generate-table tbody tr td:not([style]) + td[style]");

        for (let i = 0; i < cells.length; i++) {
          cells[i].previousElementSibling.setAttribute("style", cells[i].getAttribute("style"));
        }
      }
    }

    $scope.listsRefresh = function(invalidateCrossReferences = true) {
      if (!$scope.inInitialLoad) {
        $scope.lists = [];

        listItemsManager.createListItemsMap();

        if (invalidateCrossReferences) {
          const crrs = document.querySelectorAll('crr[id]', true);

          for (let crrIndex = 0; crrIndex < crrs.length; crrIndex++) {
            let crr = crrs[crrIndex];
            let id = crr.getAttribute('id');

            if (!id.match(/^[a-zA-Z0-9]*(-?[a-zA-Z0-9])*$/)) {
              const errorMessage = `There's an issue with the way the \`crr-${id}\` is set up`;
              console.error(errorMessage);
              Raven.captureMessage(errorMessage);
            } else {
              let crb = document.querySelector(`crb[id="${id}"]`, true);

              if (!crb) {
                const errorMessage = `Couldn't find the bookmark for \`crr-${id}\``;
                console.error(errorMessage);
                Raven.captureMessage(errorMessage);
                continue;
              }

              let parent = $(crb.closest("[pid]"));
              let refs = Array.from(parent.get(0).querySelectorAll('[format], crb'));
              let refCrbIndex = refs.indexOf(crb);
              let ref = '';
              let transform;

              // Generate the reference,
              // we concatenate all lists prior to the bookmark, to support
              // complex scenarios consisting of multiple auto-numbers
              for (let refIndex = 0; refIndex < refCrbIndex; refIndex++) {
                ref += refs[refIndex].textContent.trim();
              }

              // Legacy - Then, we check if the CRB specifies any transformation
              if (crb.getAttribute('is-lower-case')) {
                if (crb.getAttribute('is-lower-case') === 'true') {
                  ref = ref.toLowerCase();
                }
              }

              // New - Then, we check if the CRB specifies any transformation
              if (crb.hasAttribute('transform')) {
                transform = crb.getAttribute('transform');

                if (transform === 'lowercase') {
                  ref = ref.toLowerCase();
                } else if (transform === 'uppercase') {
                  ref = ref.toUpperCase();
                } else if (transform === 'capitalize') {
                  ref = $filter('capitalize')(ref).toString();
                } else if (transform === 'capitalize all') {
                  ref = $filter('capitalizeAll')(ref).toString();
                }
              }

              // Then, we check if the CRR override it with a different transformation
              if (crr.hasAttribute('transform')) {
                transform = crr.getAttribute('transform');

                if (transform === 'lowercase') {
                  ref = ref.toLowerCase();
                } else if (transform === 'uppercase') {
                  ref = ref.toUpperCase();
                } else if (transform === 'capitalize') {
                  ref = $filter('capitalize')(ref).toString();
                } else if (transform === 'capitalize all') {
                  ref = $filter('capitalizeAll')(ref).toString();
                }
              }

              if (crr.hasAttribute("format")) {
                const format = crr.getAttribute('format');
                const matcher = new RegExp(format, 'g');
                let delimiter = "";
                
                if (crr.hasAttribute('delimiter')) {
                  delimiter = crr.getAttribute('delimiter');
                }

                const result = ref.match(matcher);

                if (result.length <= 1) {
                  ref = ref[0];
                } else {
                  ref = result.join(delimiter);
                }
              }

              // If the reference ends with a dot, remove it
              if (ref[ref.length - 1] === '.') {
                ref = ref.substring(0, ref.length - 1);
              }

              crr.innerHTML = `<a href="#cross_ref_${id}">${ref}</a>`
            }
          }
        }

        if (!$scope.isPrerender) {
          // set lists spacing (based on listSpaces global array variable specification) -- not required for pre-render
          $scope.listsSetSpaces();
        }
      }
    };

    // check if two list formats are equal, besides their set numbering
    $scope.listFormatsEqual = function(format1, format2) {
      format1 = format1
        .replace(/([A-Z]+)(}|\])+/g, 'A}')
        .replace(/([0-9]+)(}|\])+/g, '0}')
        .replace(/([a-z]+)(}|\])+/g, 'a}');
      format2 = format2
        .replace(/([A-Z]+)(}|\])+/g, 'A}')
        .replace(/([0-9]+)(}|\])+/g, '0}')
        .replace(/([a-z]+)(}|\])+/g, 'a}');
      return format1 == format2;
    };

    // check if two list levels are of the same type
    $scope.listLevelsEqual = function(level1, level2) {
      level1 = level1
        .replace('#U', '$$')
        .replace('#L', '!!')
        .replace(/([0-9])+/g, '1')
        .replace(/([A-Z])+/g, 'A')
        .replace(/([a-z])+/g, 'a');
      level2 = level2
        .replace('#U', '$$')
        .replace('#L', '!!')
        .replace(/([0-9])+/g, '1')
        .replace(/([A-Z])+/g, 'A')
        .replace(/([a-z])+/g, 'a');
      return level1 == level2;
    };

    // go through the listLevels list to find the index of the matching level for given attributes
    $scope.listsLookupLevelIdx = function(name, format, outerTag, outlineName) {
      if (name) {
        for (var i = 0; i < $scope.listLevels.length; i++) {
          var level = $scope.listLevels[i];
          if (
            $scope.listLevelsEqual(level.name, name) &&
            $scope.listFormatsEqual(level.format, format) &&
            outlineName == level.outline &&
            level.outerTag == outerTag
          ) {
            return i;
          }
        }
      }
      return null;
    };

    $scope.listsFindMostInferiorLevelIdx = function(nodeSectionPath) {
      var toReturn = -1;
      for (var i = 0; i < $scope.listLevels.length; i++) {
        if (nodeSectionPath[$scope.listLevels[i].level]) {
          toReturn = Math.max(toReturn, i);
        }
      }
      return toReturn;
    };

    // get the next freetext value
    $scope.getNextFreeTextValue = function() {
      for (
        var i = 0;
        $scope.overrideOrderedListitem &&
        i < $scope.overrideOrderedListitem.length;
        i++
      ) {
        $scope.lastFreeText = Math.max(
          Math.max(
            $scope.overrideOrderedListitem[i].bodyFreeText,
            $scope.overrideOrderedListitem[i].headerFreeText,
          ),
          $scope.lastFreeText,
        );
      }
      for (var i = 0; i < $scope.listItems.length; i++) {
        $scope.lastFreeText = Math.max(
          Math.max(
            $scope.listItems[i].bodyFreeText,
            $scope.listItems[i].headerFreeText,
          ),
          $scope.lastFreeText,
        );
      }
      $scope.lastFreeText += 100;
      return $scope.lastFreeText;
    };


    /*
     * Anything in $scope.listItems pointing to the oldPointingTo value
     * will be redirected to point to the newPointingTo value as it's afterVal
     */
    $scope.listsFixLinkedList = function(oldPointingTo, newPointingTo) {
      for (var i = 0; i < $scope.listItems.length; i++) {
        if ($scope.listItems[i].afterFreeText == oldPointingTo) {
          $scope.listItems[i].afterFreeText = newPointingTo;
        }
      }
    };

    // http://stackoverflow.com/questions/4233265/contenteditable-set-caret-at-the-end-of-the-text-cross-browser
    $rootScope.createCaretPlacer = $scope.createCaretPlacer = function(
      atStart
    ) {
      return function(el) {
        if (!el) {
          return;
        }
        try {
          var $elCe = $(el).closest("[contenteditable='tempFalse']");
          if ($elCe.length > 0) {
            $elCe.attr("contenteditable", "true");
          }
          $(el).focus();
          if (
            typeof window.getSelection != "undefined" &&
            typeof document.createRange != "undefined"
          ) {
            var range = document.createRange();
            range.selectNodeContents($(el).get(0));
            range.collapse(atStart);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(atStart);
            textRange.select();
          }
        } catch (e) {}
      };
    };
    $rootScope.placeCaretAtStart = $scope.placeCaretAtStart = $scope.createCaretPlacer(
      true,
    );
    $rootScope.placeCaretAtEnd = $scope.placeCaretAtEnd = $scope.createCaretPlacer(
      false,
    );

    $rootScope.placeCaretAtPosition = function(element, offset) {
      if (!$.contains(document, element)) {
        return;
      }
      var range = rangy.createRange();
      range.setStart(element, offset);
      range.collapse(true);
      var sel = rangy.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    };

    $rootScope.selectNode = function(node) {
      const sel = rangy.getSelection();
      sel.removeAllRanges();
      const range = rangy.createRange();
      range.selectNode(node);
      sel.addRange(range);
    };

    $rootScope.setSelection = function(
      anchorNode,
      anchorOffset,
      focusNode,
      focusOffset,
    ) {
      if (anchorNode instanceof Array) {
        anchorNode = anchorNode[0];
      }
      if (focusNode instanceof Array) {
        focusNode = focusNode[0];
      }

      var range = rangy.createRange();
      range.setStart(anchorNode, anchorOffset);
      range.setEnd(focusNode, focusOffset);
      var sel = rangy.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    };

    // for use by contenteditable directives
    $scope.listsDeleteListItem = function(
      hvbvId,
      noUndo,
      insertedListItemId,
      fromBulkAction,
    ) {
      if (hvbvId === false) {
        hvbvId = _.findKey($scope.listItemUndoTracking, function(o) {
          return o === +insertedListItemId;
        });
      }

      if (!hvbvId) {
        return;
      }

      window.track.event(new DeleteListItemEvent({
        context: $rootScope.getContext(),
      }));

      let currentLayers = $scope.lease.layers
        ? JSON.stringify($scope.lease.layers)
        : JSON.stringify([]);
      let newLayers =  $scope.lease.layers
      ? JSON.stringify($scope.lease.layers)
      : JSON.stringify([]);
      let changeLayers = false;

      var getDeleteListItemFunc = function($scope, hvbvId, noUndo) {
        return function(isFirstTimeOfRedo) {
        
          var insertedListItemId = $scope.listItemUndoTracking[hvbvId];
          var allToDelete = $(
            "[inserted-list-item-id='" + insertedListItemId + "']",
          );

          var actionsCount = 0;

          // update anchors for outlines 
          
          if (allToDelete.length > 0) {
            const blockData = allToDelete[0].getBlockData();
            const listItemsIndex = blockData.getListItemObjectIndex();
            const deletingItemObject =
              listItemsIndex !== -1 ? $scope.listItems[listItemsIndex] : null;
            if (deletingItemObject) {
              blockData.containingNode
                .querySelectorAll("[free-text]")
                .forEach(span => {
                  const freeText = span.getAttribute("free-text");
                  pageLayersManager.getByAnchorId(freeText).forEach(layer => {
                    if (changeLayers === false) {
                      changeLayers = true;
                    }

                    layer.anchor = deletingItemObject.afterFreeText;
                  });
                });
            }
          }

          if (changeLayers) {
            newLayers = JSON.stringify(JSON.stringify($scope.lease.layers));
          }

            


          if (allToDelete.length > 0) {
            var selection = $rootScope.getSelectionData();
            allToDelete.find('[free-text]').each(function() {
              var ftSectionId = +$(this).attr('free-text');

              if (!noUndo) {
                $(this)
                  .find('[contenteditable]')
                  .text('');
                $(this)
                  .find('[contenteditable]')
                  .trigger('change');
              }

              // delete freetext from listItems
              var listItemIdx = $scope.listItems.findIndex(function(ele) {
                return (
                  +ele.headerFreeText == ftSectionId ||
                  +ele.bodyFreeText == ftSectionId
                );
              });
              if (listItemIdx > -1) {
                // fix the linked list
                var newPointingTo = $scope.listItems[listItemIdx].afterFreeText;
                $scope.listsFixLinkedList(
                  $scope.listItems[listItemIdx].headerFreeText,
                  newPointingTo,
                );
                $scope.listsFixLinkedList(
                  $scope.listItems[listItemIdx].bodyFreeText,
                  newPointingTo,
                );

                // remove the specific listItem
                $scope.listItems.splice(listItemIdx, 1);
                $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
              }

              if (!fromBulkAction && $scope.leaseFreeTexts[ftSectionId]) {
                $scope.addToHistory({
                  desc: 'deleted free-text [section id: ' + ftSectionId + ']',
                  redo: (function(sectionId) {
                    return function() {
                      var deferred = $q.defer();
                      $scope.lease.layers = JSON.parse(newLayers);
                      $scope.deleteFreeText(sectionId).then(function() {
                        deferred.resolve({
                          selection: selection,
                        });
                      });
                      return deferred.promise;
                    };
                  })(ftSectionId),
                  undo: (function(sectionId, html) {
                    return function() {
                      var deferred = $q.defer();
                      $scope.lease.layers = JSON.parse(currentLayers);
                      var freeText = new FreeTextService({
                        section_id: sectionId,
                        lease_id: $rootScope.getLeaseId(),
                        amendment_id: $rootScope.getAmendmentId(),
                        text: html,
                      });
                      freeText.create().then(function(res) {
                        $scope.leaseFreeTexts[sectionId] = res;
                        //we need to wait the new contenteditable to be compiled before continue.
                        $timeout(function() {
                          deferred.resolve();
                        }, 200);
                      });
                      return deferred.promise;
                    };
                  })(ftSectionId, $scope.leaseFreeTexts[ftSectionId].text),
                });
                actionsCount++;
                $scope.histIgnoreClearBatch++;
              }
            });

            if (isFirstTimeOfRedo && !fromBulkAction) {
              $scope.histRegisterBatch($scope.histIndex + 1, actionsCount + 1);
            }

            allToDelete.remove();
            $scope.update();
          }

          $scope.structureChanged();
          var currSelection = $rootScope.getSelectionData();
          return Promise.resolve({
            selection: currSelection,
          });
        };
      };

      var itemObject = $scope.listItemObjects[hvbvId];

      var getUndeleteListItemFunc = function($scope, itemObject) {
        return function() {
          const originalAfterFreeTextElement = document.querySelector(`[section-id="${itemObject.afterFreeText}"]`);
          $scope.lease.layers = JSON.parse(currentLayers);
          if (originalAfterFreeTextElement) {
            const eocElements = originalAfterFreeTextElement.querySelectorAll('.EOCE');
            const eocElement =
              eocElements && eocElements.length > 0
                ? eocElements[eocElements.length - 1]
                : null;

            if (eocElement && eocElement.previousElementSibling) {
              scopeVar.$root.placeCaretAtEnd(eocElement.previousElementSibling);
            }
          }

          return $scope.listsNewListItem(false, itemObject, true);
        };
      };

      if (noUndo) {
        return getDeleteListItemFunc($scope, hvbvId, noUndo)();
      }

      var idxOne = $scope.addToHistory({
        desc: 'removed list item [article: ' + itemObject.afterFreeText + ']',
        redo: getDeleteListItemFunc($scope, hvbvId, noUndo),
        undo: getUndeleteListItemFunc($scope, itemObject),
      });
    };


    $scope.changeCurrentListLevel = function(e,name){
      e.stopPropagation();
      e.preventDefault();

      if($scope.currentlyEditedNode){
        const block =  $scope.currentlyEditedNode.getBlockData ?
          $scope.currentlyEditedNode.getBlockData() :
          $scope.currentlyEditedNode.get(0).getBlockData()
        if(block.listLevel.level !== name){
          block.moveToLevel(name);
          document.body.click();
        }
      }
    }

    /*
     * Inserts a new list item, at the specified level, at the most recently edited location
     * If itemObject is false, must create one based on insertLevel - otherwise, complete insertion without undo
     */
    $scope.listsNewListItem = function(
      insertLevel,
      itemObject,
      isUndo,
      outline,
      forceHistory = false
    ) {
      return window.applyFilterVisible($scope._listsNewListItem, [
        insertLevel,
        itemObject,
        isUndo,
        outline,
        forceHistory
      ]);
    };

    $scope._listsNewListItem = function(
      insertLevel,
      itemObject,
      isUndo,
      outline,
      forceHistory = false,
    ) {


      let useOldListItems = !(
        ($scope.features && $scope.features.insertListItemsAnywhere) ||
        window.isDebug
      );


      if ($scope.outlineToInsert) {
        let newOutline = $scope.outlineToInsert;
        $scope.outlineToInsert = null;
        $scope.addOutlineAndListItem(newOutline, insertLevel, !useOldListItems);
        return;
      }
      var getNewListItemFunc = function($scope, itemObject, isUndo, outline) {
        return function() {
          // new insertions happen after the most recently inserted node

          // new list items from UI flags
          if (!itemObject.isNewListItem) {
            itemObject.isNewListItem = !useOldListItems;
          }

          if (itemObject.fromUI !== false && !isUndo) {
            itemObject.fromUI = !useOldListItems;
          }

          var insertedItem = listItemsManager.listsInsertItem(itemObject);

          if (!insertedItem) {
            return;
          }

          let scorllToItem = insertedItem.get ? insertedItem[0] : insertedItem;

          if (itemObject.json ) {
            $scope.safeApply();
          }

          if(!scorllToItem.parentElement){
            scorllToItem = document.querySelector(`[free-text="${itemObject.afterFreeText}"]`);
          }

          if(scorllToItem && scorllToItem.scrollIntoViewIfNeeded && !itemObject.fromPID && !itemObject.json){
            scorllToItem.scrollIntoViewIfNeeded({
              behavior: 'smooth',
              block: 'center'
            });
          }
          $scope.currentlyEditedNode = $(insertedItem)
            .prevUntil("div[class*='Section']")
            .find('[free-text]')
            .last()
            .children()
            .first();

          // add the article to the database
          $scope.listItems.push(itemObject);
          $scope.lease.orderedListitem = JSON.stringify($scope.listItems);

          if (!isUndo) {
            // focusing on the first possible free text value, children to get the actual contenteditable
            setTimeout(function() {
              let anchor;

              // do the body then the header - if header doesn't exist, will end up defaulting to body
              anchor = $(`span[section-id='${itemObject.bodyFreeText}'][contenteditable]`)
                .attr('contenteditable', 'true')
                .focus();

              if ($(anchor).find('.LIST-BODY').length > 0) {
                $rootScope.placeCaretAtEnd($(anchor).find('.LIST-BODY').get(0));
              } else {
                $rootScope.placeCaretAtEnd($(anchor).find('.PLACEHOLDER').next().get(0));
              }

              anchor = $(`span[section-id='${itemObject.headerFreeText}'][contenteditable]`)
                .attr('contenteditable', 'true')
                .focus();

              if ($(anchor).find('.LIST-HEADER').length > 0) {
                $rootScope.placeCaretAtEnd($(anchor).find('.LIST-HEADER').get(0));
              } else {
                $rootScope.placeCaretAtEnd($(anchor).find('.PLACEHOLDER').next().get(0));
              }

            }, 200); // timeout so it's not at the exact same time the user is clicking on the buttons
            if(!itemObject.fromPID && !itemObject.json){
              scorllToItem.scrollIntoViewIfNeeded({
                behavior: 'smooth',
                block: 'center'
              });
            }
          }

          if (!$rootScope.adminMode) {
            $scope.update(); // TODO: check if needed
          }

          $scope.structureChanged();
          var currSelection = $rootScope.getSelectionData();
          return Promise.resolve({
            selection: currSelection,
          });
        };
      };

      var getDeleteListItemFunc = function($scope, hvbvId) {
        return function() {
          return $scope.listsDeleteListItem(hvbvId, true, null);
        };
      };

      if (!itemObject) {
        let afterFreeText = $scope.currentlyEditedNode.length !== 0 ?
          $($scope.currentlyEditedNode).parents('[free-text]')
          .first()
          .attr('free-text') : null;
        if (!afterFreeText) {
          let item = JSON.parse(scopeVar.lease.orderedListitem).pop();
          if (item) {
            if (document.querySelector('[free-text="' + item.bodyFreeText + '"]')) {
              afterFreeText = item.bodyFreeText;
            } else if (document.querySelector('[free-text="' + item.headerFreeText + '"]')) {
              afterFreeText = item.headerFreeText;
            }
          }
        }

        itemObject = {
          level: insertLevel,
          outline: outline,
          headerFreeText: $scope.getNextFreeTextValue(),
          bodyFreeText: $scope.getNextFreeTextValue(),
          afterFreeText: afterFreeText,
        };
        // itemObject.afterFreeText = $compile(itemObject.afterFreeText)($scope); // TODO:
      } else {
        if (!forceHistory) {
          return getNewListItemFunc($scope, itemObject, isUndo, outline)();
        }
      }

      window.track.event(new InsertListItemEvent({
        level: itemObject.level,
        outline: itemObject.outline,
        context: $rootScope.getContext(),
      }));

      if ($scope.histStack[$scope.histIndex]) {
        var bookmark = $scope.histStack[$scope.histIndex].currSelection;
        $rootScope.saveSelection(true, bookmark);
      }
      var myIdx = $scope.addToHistory({
        desc:
          'added list item [afterFreeText: ' + itemObject.afterFreeText + ']',
        redo: getNewListItemFunc($scope, itemObject, isUndo),
        undo: getDeleteListItemFunc(
          $scope,
          itemObject.headerFreeText + '-' + itemObject.bodyFreeText,
        ),
      });

      if (itemObject.level === 'article') {
        $scope.histIgnoreClearBatch++;
        $scope.histRegisterBatch(myIdx + 1, 2); // Article triggers section, TODO: verify for all forms/customers
      }

      return Promise.resolve();
    };

    $rootScope.isListItem = function(container) {
      let isListItem = false;

      if(!container.get(0).parentElement){
        return false;
      }

      container = container.get(0);

      const list = container.querySelector("[list]");

      if (list) {
        const roundTrip = list.parentElement.previousElementSibling;

        if (LeaseEditorService.isNodeWordStyle($(roundTrip))) {
          isListItem = true;
        }
      }

      return isListItem;
    };

    $rootScope.isNewParagraph = function(container) {
      return container.classList.contains('new-paragraph');
    };

    $rootScope.listItemIsEmpty = function(listItem) {
      return (
        !$rootScope.listItemHasContent(listItem) &&
        !$rootScope.listItemHasChildLists(listItem)
      );
    };

    $rootScope.isDummyListItem = function(element) {
      return
        element.get(0).nodeName === 'P' &&
        element.find('.word-indent[style*="7pt"]').length !== 0;
    }

    $rootScope.listItemHasContent = function(listItem) {
      if (!listItem) {
        return false;
      }

      if (listItem.get && !listItem[0]) {
        return false;
      }

      var contents = listItem.find('[free-text] [contenteditable]');

      listItem = $(listItem).get(0);

      if (!!listItem.querySelector('.new-paragraph')) {
        return true;
      }

      return _.some(contents, function(el) {
        return !LeaseEditorService.isEmpty(el, true);
      });
    };

    $rootScope.listItemHasChildLists = function(listItem) {

      if (
        ($scope.features && $scope.features.insertListItemsAnywhere) ||
        window.isDebug
      ) {
        return false;
      }

      var list = listItem.find('[list]');
      var levelId = $scope.listsLookupLevelIdx(
        list.attr('list'),
        list.attr('format'),
        listItem[0].tagName,
        list.attr('outline'),
      );
      var nextCE = LeaseEditorService.findAllContentEditable(listItem).last();

      var nextContainer;
      while (nextCE.length > 0) {
        let newNextCE = LeaseEditorService.findNextContentEditable(nextCE);
        if(nextCE[0] === newNextCE[0]){
          break;
        }
        nextCE = newNextCE;
        nextContainer = LeaseEditorService.findClosestContainer(nextCE);
        if (nextContainer && $rootScope.isListItem(nextContainer)) {
          var nextList = nextContainer.find('[list]');
          var nextLevelId = $scope.listsLookupLevelIdx(
            nextList.attr('list'),
            nextList.attr('format'),
            nextContainer[0].tagName,
            list.attr('outline'),
          );
          return nextLevelId > levelId;
        }
      }

      return false;
    };

    /*----------------------------------------------------------------------------*/

    $scope.listsSetSpaces = function() {
      if ($scope.listSpaces) {
        var spaces = [];
        var freeListGap = -1;

        for (var i = 0; i < $scope.listSpaces.length; i++) {
          if ($scope.listSpaces[i].name == '*') {
            freeListGap = $scope.listSpaces[i].value;
          }
        }

        var prevListItemNode = null;
        var prevListName = '';

        $('.lease')
          .not('.lease--modified')
          .find('span[style*="inline-block"]:not(.word-indent)')
          .addClass('word-indent word-indent--no-cursor')
          .css({
            'user-select': 'none',
            'overflow': 'hidden',
          })
          .attr('contenteditable', 'false');

        $('.word-indent.PLACEHOLDER').each(function() {
          $(this).removeClass('PLACEHOLDER');
          var closestCE = LeaseEditorService.findClosestContentEditable($(this));
          LeaseEditorService.addMarkers(closestCE);
        });

        $('.word-indent').each(function() {
          if ($(this).text().length === 0) {
            $(this).html("&nbsp;");
          }
        });

        const tabs = applyFilterVisible(()=>{
          return $('.lease')
          .not('.lease--modified')
          .find('.word-indent[style*="7pt"]')
          .filter((index,item)=> {return item.closest('.deleted-container') === null});
        })  

        let current;
        for (let tabIndex = 0; tabIndex < tabs.length; tabIndex++) {
          current = tabs[tabIndex];
          if(current.closest('.list-item-paragraph.inserted-list-item')){
            continue;
          }
          current.style.userSelect = 'none';

          // auto numbered list
          var prev = $(current).prev();
          var listItemInfo = current.getListItemInfoFromNode();
          var listName = $(prev).attr('list');

          if(listName === ""){
            continue;
          }

          if (listItemInfo && listItemInfo.listLevel) {
            const levelLookup = $scope.listSpaces.filter(level => level.name === listName);
            if (levelLookup.length === 0) {
              listName = listItemInfo.listLevel.name;
            }
          }

          if (listName) {
            for (var i = 0; i < $scope.listSpaces.length; i++) {
              var name = $scope.listSpaces[i].name;
              var type = $scope.listSpaces[i].type;
              var value = $scope.listSpaces[i].value;

              // check if the current list name appears in the list of list spaces
              if (
                listName && name &&
                /* list names equal */
                (listName === name ||
                  /* list names start with */
                  (name[name.length - 1] === '%' &&
                    listName.length >= name.length &&
                    listName.startsWith(
                      name.substring(0, name.length - 1),
                    )) ||
                  /* list names end with */
                  (name[0] === '%' && listName.endsWith(name.substring(1))) ||
                  /* list names a lower case letter */
                  (name === '?1' &&
                    isLowercaseLetter(listName[0]) &&
                    listName.length == 1) ||
                  /* list names a digit             */
                  (name === '*1' &&
                    !isNaN(listName[0]) &&
                    listName.length == 1) ||
                  /* list names a upper case letter */
                  (name === '^1' &&
                    isUppercaseLetter(listName[0]) &&
                    listName.length == 1) ||
                  /* list names lower case letters  */
                  (name === '?' && isLowercaseLetter(listName[0])) ||
                  /* list names is a number         */
                  (name === '*' && !isNaN(listName)) ||
                  /* list names upper case letters  */
                  (name === '^' && isUppercaseLetter(listName[0])))
              ) {
                if (type === 'left') {
                  var data1 = prev[0].getBoundingClientRect();
                  var first = data1.right;
                  // set spaces width according to variable distance from list item text
                  if ($scope.inline.on) {
                    current.style.width = value - 160.0 - first + 'px';
                  } else {
                    current.style.width = value - first + 'px';
                  }
                } else if (type === 'gap') {
                  // initialize gap
                  let calculatedGap = value;

                  var listItemNodeWidth = $(current)
                    .prev()
                    .width();

                  var space = spaces[listName];

                  // calculate the gap starting from the configured value, up or down according to the size of the preceding list item width
                  if (space) {
                    var prevListItemNodeWidth = space.width;
                    calculatedGap = space.gap;
                    if(listItemNodeWidth !== prevListItemNodeWidth) {
                      // calculate gab by list item width (add if previous was wider and subtract if previous was narrower)
                      if (listItemNodeWidth > prevListItemNodeWidth) {
                        calculatedGap -=
                          listItemNodeWidth - prevListItemNodeWidth;
                      } else {
                        calculatedGap +=
                          prevListItemNodeWidth - listItemNodeWidth;
                      }
                      // Update list width & space gap on space object
                      space.width = listItemNodeWidth;
                      space.gap = calculatedGap;
                    }
                  } else {
                    // Init space object with list item width & gap by list name
                    spaces[listName] = {width: listItemNodeWidth, gap: calculatedGap};
                  }

                  prevListItemNode = $(current).prev();
                  // set spaces width according to absolute gap size
                  current.style.width = calculatedGap + 'px';
                  current.style.height = '0';
                } else if (type === 'total') {
                  const total = value;
                  const numberSize = prev.width();
                  const spaceSize = total - numberSize;

                  current.style.width = spaceSize + 'px';
                  current.style.height = '0';
                }

                break;
              }
            }
          } else if (freeListGap >= 0) {
            // "free" list
            current.style.width = freeListGap + 'px';
          }

          // set previous list name for tracking list changes
          prevListName = listName;
        }
      }
    };

    /*
     * Call when the DOM structure changes
     */
    $scope.structureChanged = function(invalidateCrossReferences = true) {
      return window.applyFilterVisible($scope._structureChanged, [invalidateCrossReferences]);
    }
    $scope._structureChanged = async function(invalidateCrossReferences = true) {
      $scope.listsRefresh(invalidateCrossReferences);
      $scope.appendEditingSpaceToLeaseVars();
      $scope.maintainHardReturns();
      $scope.maintainSubdocuments();
      setTimeout(LeaseEditorService.createRemoveTable);
      

      if (!$scope.diffgram.on) {
        $scope.maintainNestedParagraphsIndex();
      }
    };

    $scope.maintainHardReturns = function() {
      const originalHardReturns = document.querySelectorAll('hard-return.hard-return--original');

      if (originalHardReturns.length > 0) {
        originalHardReturns.forEach(hardReturn => {
          let container = LeaseEditorService.findClosestContainer(hardReturn).get(0);
          let originalHardReturn = container.querySelector('hard-return:not([class])');

          // Deletes the `hard-return` from the original HTML
          if (originalHardReturn) {
            originalHardReturn.remove();
          }
        });
      }

      const root = document.querySelector('.lease:not(.lease--modified)');

      if (root) {
        const containers = root.querySelectorAll('h1, h2, h3, h4, h5, h6, p');
  
        containers.forEach(container => {
          if (!container.textContent.trim().startsWith('!!') && !container.querySelector('hard-return')) {
            container.appendChild(document.createElement('hard-return'));
          }
        });
      }
    };

    $scope.maintainSubdocuments = function() {
      $scope.subdocuments = [];
      
      const subdocuments = document.querySelectorAll(".lease.lease--original subdocument");

      let subdocument;
      let subdocumentName;
      for (let index = 0; index < subdocuments.length; index++) {
        subdocument = subdocuments[index];
        subdocumentName = subdocument.getAttribute("name");
        $scope.subdocuments.push(subdocumentName);
      }
    };

    $scope.maintainNestedParagraphsIndex = function() {
      const paragraphs = document.querySelectorAll('.lease.lease--original [contenteditable] p');

      let paragraph;
      for (let index = 0; index < paragraphs.length; index++) {
        paragraph = paragraphs[index];

        paragraph.setAttribute('data-nested-paragraph-index', index);
      }
    };

    function getOutlineStartNode(outlineName) {
      if (outlineName) {
        return $('outline[name="' + outlineName + '"]');
      } else {
        return $('lease-start');
      }
    }

    function getOutlineEndNode(outlineName) {
      if (outlineName) {
        return $('outline-end[name="' + outlineName + '"]');
      } else {
        return $('lease-end');
      }
    }

    function getParentOutline(node) {


      let currentOutline = null;
      let returnObject = null;
      let startNode;
      let endNode;

      if (!$(node)[0]) {
        return null;
      }

      if ($(node)[0].nodeType === Node.TEXT_NODE) {
        node = $(node).parentElement;
      }

      if(node && node.get){
        node = node.get(0);
      }

      const topAnchor = node.getTopAnchor();
      const pid = topAnchor.getAttribute("pid");
      let query;
      if (!pid || pid === "null") {
        // add to support test leases only
        // in some test leases the polyfill for pid is placing 'null'
        query =
          "lease-start,lease-end,outline,outline-end," + topAnchor.tagName + "";
      } else {
        query = 'lease-start,lease-end,outline,outline-end,[pid="' + pid + '"]';
      }

      const elementsArray = Array.from(document.querySelectorAll(query));
      const myIndex = elementsArray.indexOf(topAnchor);
      const nodeNameArray = ["LEASE-END", "LEASE-START", "OUTLINE-END","OUTLINE"];

      if (elementsArray[myIndex - 1]) {
        let scanIndex = myIndex;
        while (elementsArray[scanIndex] && !startNode) {
          let tagname = elementsArray[scanIndex].tagName;
          if (nodeNameArray.indexOf(tagname) !== -1) {
            startNode = elementsArray[scanIndex];
          }
          scanIndex--;
        }
        if (startNode) {
          if (
            startNode.tagName === "LEASE-END" ||
            startNode.tagName === "OUTLINE-END"
          ) {
            currentOutline = null;
          } else if (startNode.tagName === "LEASE-START") {
            currentOutline = "main";
            endNode = document.querySelector("LEASE-END");
          } else if (startNode.tagName === "OUTLINE") {
            currentOutline = startNode.getAttribute("name");
            endNode = document.querySelector(
              'OUTLINE-END[name="' + currentOutline + '"]'
            );
          }
        }
      }
      else{
        currentOutline = null;
      }

      if (currentOutline !== null) {
        returnObject = {
          startNode: startNode,
          endNode: endNode,
          name: currentOutline
        };
      }

      return returnObject;
    }

    $scope.updateCurrentOutline = function(){
      $scope.currentOutline = getParentOutline($scope.currentlyEditedNode);
      $scope.updateAddListItems();
    }

    $scope.$watch('currentlyEditedNode', function(newValue) {
      if (!newValue || (newValue && newValue.length === 0)) {
        $scope.addItemDisabled = true;
        return;
      }

      if(newValue.nodeType === Node.TEXT_NODE) {
        newValue = newValue.parentElement;
      }

      $scope.listItemsRootLevel = 'atricle';
      $scope.currentOutline = getParentOutline(newValue);
      let leaseEnd = document.querySelector('lease-end');
      let leaseStart =document.querySelector('lease-start');
      let currentElement;

      if (newValue) {
        currentElement = newValue.get ? newValue[0] : newValue;
      }

      let articleLevel = $scope.listLevels.filter((item)=>{ return item.level === 'article' });
      $scope.hasArticleLevel = articleLevel && articleLevel.length !== 0;
      $scope.hasOutlineLevel = listItemsManager.listLevelsMap && Object.keys(listItemsManager.listLevelsMap).length > 0;

      if ($scope.hasArticleLevel) {
        let getBoundingClientRect = currentElement.getBoundingClientRect();
        let newItemY = getBoundingClientRect ? getBoundingClientRect.y : null;

        if (newItemY) {
          if (
            (leaseEnd &&
              leaseEnd.getBoundingClientRect &&
              leaseEnd.getBoundingClientRect().y < newItemY) ||
            (leaseStart &&
              leaseStart.getBoundingClientRect &&
              leaseStart.getBoundingClientRect().y > newItemY)
          ) {
            $scope.addArticleDisabled = true;
          } else {
            $scope.addArticleDisabled = false;
          }
        }
      } else {
        $scope.addArticleDisabled = true;
      }

      if (newValue.closest('ol')) {
        $scope.addItemDisabled = true;
        return;
      }

      $scope.updateAddListItems();
    });

    $scope.updateAddListItems = function(){

      $scope.changeListItemLevel = false;

      if ($scope.currentOutline) {

        if($scope.currentlyEditedNode && !LeaseEditorService.isSelecting()){
          const block =  $scope.currentlyEditedNode.getBlockData ?
           $scope.currentlyEditedNode.getBlockData():
           $scope.currentlyEditedNode.get(0).getBlockData();

          if(block.containingNode && block.blockType !== BLOCK_TYPE.NESTED_PARAGRAPH){
            const tblNode = block.containingNode.closest('table');

            if(!tblNode && block.containingNode.getNodeOutline() && !block.tableNode){
              $scope.changeListItemLevel = true;
            }
          }
          else{
            $scope.changeListItemLevel = false;
          }
          
        }

        const normalListLevel = {
          format: "",
          level: "normal",
          listHeader: "",
          name: "",
          outerTag: "P",
          outlineDisplayName: "",
          previewText: "Normal"
        };

        $scope.outlineListLevels = $scope.listLevels.filter(function(item) {
          return $scope.currentOutline && item.outline == $scope.currentOutline.name || (
            !item.outline && $scope.currentOutline.name === 'main'
          );
        });
        
        if ($scope.outlineListLevels.length) {
          if (
            listItemsManager.getOutlineParagraphLevel(
              $scope.currentOutline.name
            ) === listItemsManager.getOutlineParagraphLevel(null)
          ) {
            $scope.outlineListLevels.push(normalListLevel);
          }
          
          $scope.listItemsRootLevel = $scope.outlineListLevels[0].level;
          $scope.addItemDisabled = false;
        } else {
          $scope.addItemDisabled = true;
        }
      } else {
        $scope.outlineListLevels = [];
        $scope.addItemDisabled = true;
      }
    }

    $scope.$watch('numberOfHighlighted', function(newValue) {
      if (newValue > 0) {
        window.applyFilterVisible(() => {
          var allHls = $scope.getAllHls();
          var hls = allHls.filter("[exclude!='true']");

          // restore original classes
          $('.current').each(function() {
            $(this).removeClass('current');
          });

          $(hls[$scope.highlightScrollIndex]).addClass('current');

          // Handle excluded part's styles
          var newInAll = 0;

          var i = newInAll + 1;
          while (i < allHls.length && $(allHls[i]).attr('exclude') === 'true') {
            $(allHls[i]).addClass('current');
            i++;
          }
        });
      }
    });

    $scope.updateCurrentHighlightedItem = function(newValue, oldValue) {
      var allHls = $scope.getAllHls();
      var hls = allHls.filter("[exclude!='true']");

      if (newValue != oldValue) {
        // restore original classes
        if (!isNaN(oldValue) && oldValue >= 0) {
          $("*[class*='current']").each(function() {
            $(this).removeClass('current');
          });
        }

        // Highlight the current item
        $(hls[newValue]).addClass('current');

        // Handle excluded part's styles
        var newInAll = allHls.index(hls[newValue]);

        var i = newInAll + 1;
        while (i < allHls.length && $(allHls[i]).attr('exclude') === 'true') {
          // Add 'current' class as first (to allow priority on '!Important' css
          $(allHls[i]).addClass('current');
          i++;
        }
      }

      // Only highlight if there's anything to highlight!
      if (newValue > 0) {
        $scope.setCurrentlyUpdatingElement(hls[newValue]);
      }
    };

    $scope.setTermsIncludingFreeRent = function(term) {
      var newCollection = [];

      if (term && term.length) {
        // push an additional rent period for free rent
        if ($scope.lease.freeRent) {
          newCollection.push({
            rentPerSqFoot: 0.0,
          });
        }

        for (var i = 0; i < term.length; i++) {
          newCollection.push(term[i]);
        }
      }

      $scope.termsIncludingFreeRent = newCollection;
    };

    $scope.getHalfTerm = function(term) {
      var newCollection = [];

      if (term && term.length) {
        for (var i = 0; i < Math.ceil(term.length / 2); i++) {
          newCollection.push(term[i]);
        }
      }
      return newCollection;
    };

    $scope.getHighlightedElement = function() {
      var hls = $scope.getAllHls().filter("[exclude!='true']");

      if (hls && hls[$scope.highlightScrollIndex]) {
        return hls[$scope.highlightScrollIndex];
      }

      return null;
    };

    $scope.setCurrentlyUpdatingElement = function(element) {
      if (element && element.className.indexOf('current') < 0) {
        $(element).addClass('current');
      }
    };

    $scope.$watch('highlightScrollIndex', function(newValue, oldValue) {
      if (!isNaN(newValue)) {
        $scope.updateCurrentHighlightedItem(newValue, oldValue);
      }
    });

    /*
     *
     * Limit number of lease years to 50, to prevent massive DB entries.
     *
     */
    $scope.$watch('lease.leaseYears', function(newValue) {
      if (newValue) {
        if (newValue > 50) {
          $scope.lease.leaseYears = 50;
        }

        setTimeout(function() {
          $scope.setCalculatedExpirationDate();
          $scope.update();
        });
      }
    });

    $scope.$watch('lease.leaseMonths', function(newValue) {
      if (newValue) {
        if (newValue > 50) {
          $scope.lease.leaseMonths = 50;
        }

        setTimeout(function() {
          $scope.setCalculatedExpirationDate();
          $scope.update();
        });
      }
    });

    /* Limit number of terms to 50, to prevent massive DB entries. */
    $scope.$watch('lease.renewalTerm', function(newValue, oldValue) {
      if (newValue > 50) $scope.lease.renewalTerm = 50;
    });

    $scope.startSaving = function() {
      $scope.allChangesSaved.push(0)
      LeaseEditorService.setLeaseTitle();
      $scope.isSaving = true;
      ProcessStatusService.start('save');
      $scope.safeApply();
    };

    $scope.endSaving = function() {
      if ($scope.allChangesSaved.length > 0) {
        $scope.allChangesSaved.pop();
      }

      if ($scope.allChangesSaved.length === 0) {
        setTimeout(() => {
          LeaseEditorService.setLeaseTitle();
          $scope.isSaving = false;
          ProcessStatusService.end('save');
          $scope.safeApply();
        }, 1000);
      }
    };

    function isSupportedSelection() {
      let result = true;

      if (
        ($rootScope.hasEditorConfig('premisesModType', 'noPremisesModNoExtensionUnsupported') &&
        !$scope.lease.hasExtensionOfTerm &&
        $scope.lease.premisesModType === 'none') ||
        ($rootScope.hasEditorConfig('premisesModType', 'reductionNoExtensionUnsupported') &&
        !$scope.lease.hasExtensionOfTerm &&
        $scope.lease.premisesModType === 'reduction')
      ) {
        result = false;
      }

      return result;
    }

    function showUnsupportedActionMessage(message, delay = 5000) {
      if (message) {
        $rootScope.infoMessage = message;
        $rootScope.infoMessageType = 'error';
        $rootScope.safeApply();

        setTimeout(function() {
          $rootScope.infoMessage = '';
          $rootScope.infoMessageType = '';
          $rootScope.safeApply();
        }, delay);
      }
    }

    window.showUnsupportedActionMessage = showUnsupportedActionMessage;

    $scope.$watch('lease.premisesModType', function(newValue, oldValue) {
      if ($scope.isLoading || !$scope.lease) {
        return;
      }

      let message = '';

      if (!isSupportedSelection()) {
        message = `The scenario with this selection is not supported within LeasePilot`;
        $scope.lease.premisesModType = oldValue;
      }

      if (message) {
        showUnsupportedActionMessage(message);
      }
    });

    $scope.$watch('lease.hasExtensionOfTerm', function(newValue, oldValue) {
      if ($scope.isLoading || !$scope.lease) {
        return;
      }

      let message = '';

      if (!isSupportedSelection()) {
        message = `The scenario with this selection is not supported within LeasePilot`;
        $scope.lease.hasExtensionOfTerm = oldValue;
      }

      if (message) {
        showUnsupportedActionMessage(message);
      }
    });

    $rootScope.$on('leaseStatusChanged', function(event, status) {
      if (!window.isDownload) {
        if (status.lock) {
          $scope.disableEditing();
        } else {
          $scope.enableEditing();
        }
      }
    });

    $scope.disableEditing = function() {
      $scope.editingEnabled = false;
      $scope.lease.isLocked = true;

      const contentEditables = document.querySelectorAll(".lease.lease--original .editable");

      for (let i = 0; i < contentEditables.length - 1; i++) {
        contentEditables[i].setAttribute("contenteditable", "false");
        contentEditables[i].setAttribute("data-is-editable", "true");
        contentEditables[i].classList.remove("editable");
      }
    };

    $scope.enableEditing = function() {
      $scope.editingEnabled = true;
      $scope.lease.isLocked = false;

      const contentEditables = document.querySelectorAll(".lease.lease--original [data-is-editable]");

      for (let i = 0; i < contentEditables.length - 1; i++) {
        contentEditables[i].setAttribute("contenteditable", "true");
        contentEditables[i].removeAttribute("data-is-editable");
        contentEditables[i].classList.add("editable");
      }
    };

    $scope.$watch('lease.rentCommenceAt', function(newValue) {
      if (newValue) {
        setTimeout(function() {
          $scope.setCalculatedExpirationDate();
          $scope.update();
        });
      }
    });

    $scope.$watch('isConnected', function() {
      if ($scope.isConnected) {
        $('.leases-link').removeClass('ng-hide');
        $('.title').removeClass('ng-hide');
      } else {
        $('.leases-link').addClass('ng-hide');
        $('.title').addClass('ng-hide');
      }
    });

    $window.addEventListener(
      'offline',
      function() {
        $scope.isConnected = false;
        $scope.safeApply();
      },
      false,
    );

    $window.addEventListener(
      'online',
      function() {
        $scope.isConnected = true;
        $scope.safeApply();
      },
      false,
    );

    // Handle exceptions which are tied to an updated field yet use 'hl' technique to highlight.
    $scope.$watch('currentlyChanging', function(newValue, oldValue) {
      setTimeout(function() {
        if (
          typeof oldValue != 'undefined' &&
          typeof newValue != 'undefined'
        )
          if (newValue.indexOf('renewalValues') >= 0) {
            // consolidated renewal values (rebewalValuesX all highlight 'renewalValues' in addition to support for currentlyUpdating field renewalValuesX
            $scope.highlightItem('renewalValues');
          }

        // consolidated reduction values
        if (newValue.indexOf('reductionValues') >= 0) {
          $scope.highlightItem('reductionValues');
        }
      }, 1);
    });

    $scope.combinedArrays = function(variables, condition) {
      var combinedArray = [];
      for (let index in variables) {
        combinedArray = combinedArray.concat(
          _.filter(
            _.map(variables[index], function(element, i) {
              element._leaseVariableIndex = index;
              element._originalIndex = i;
              return element;
            }),
            function(item) {
              $scope._tmpItem = item;
              return !condition || $parse('_tmpItem.' + condition)($scope);
            },
          ),
        );
      }
      delete $scope._tmpItem;
      return combinedArray;
    };

    function calcMonthlyBaseRentPerTerm(term) {
      switch (term.rentInputType) {
        case 'fixed':
        case 'fixed-no-escalation':
          if (term.rentPeriods && term.rentPeriods[0]) {
            term.monthlyBaseRent = term.rentPeriods[0].totalRent || 0;
          }
          break;
        case 'manual':
          if (term.rentPeriods && term.rentPeriods[0]) {
            term.monthlyBaseRent =
              (term.rentPeriods[0].rentPerSqFoot *
                $scope.lease.premiseAreaSize || 0) / 12;
          }
          break;
        case 'automatic':
          term.monthlyBaseRent =
            (term.baseRent * $scope.lease.premiseAreaSize || 0) / 12;
          break;
      }
    }

    function calcAnnualBaseRentPerTerm(term) {
      switch (term.rentInputType) {
        case 'fixed':
        case 'fixed-no-escalation':
          if (term.rentPeriods && term.rentPeriods[0]) {
            term.annualBaseRent = term.rentPeriods[0].totalRent * 12 || 0;
          }
          break;
        case 'manual':
          if (term.rentPeriods && term.rentPeriods[0]) {
            term.annualBaseRent =
              term.rentPeriods[0].rentPerSqFoot *
                $scope.lease.premiseAreaSize || 0;
          }
          break;
        case 'automatic':
          term.annualBaseRent =
            term.baseRent * $scope.lease.premiseAreaSize || 0;
          break;
        case 'flexible':
          var annualBaseRent = 0;
          for(var i=0; term.rentBumps[i] && term.rentBumps[i].begin < 13; i++) {
            var duration = term.rentBumps[i].duration;
            if ((term.rentBumps[i].begin + duration) > 13) {
              duration = 13 - term.rentBumps[i].begin;
            }
            annualBaseRent += (term.rentBumps[i].rentPerSqFoot * term.premiseAreaSize) / 12 * duration;
          }
          term.annualBaseRent = annualBaseRent;
          break;
      }
    }

    function calcTotalRentPerTerm(term) {
      if (term.rentInputType === 'fixed-no-escalation') {
        _.mapValues(term.rentPeriods, function(period) {
          period.totalRent = term.rentPeriods[0].totalRent;
          return period;
        });
      }
    }

    function calcRemainingMonthlyRentPerTerm(term) {
      term.remainingMonthlyRent = [];
      term.payableMonthlyRent = [];

      let areaSize;
      let rentBumps;

      if (!term.premisesModType) {
        areaSize = term.premiseAreaSize;
        rentBumps = term.rentBumps;
      } else {
        if ($scope.lease.premisesModType === 'expansion') {
          if ($scope.lease.hasExtensionOfTerm) {
            areaSize = $scope.lease.expansionPremiseAreaSize;
            rentBumps = $scope.lease.expansion.rentBumps;

            if ($scope.lease.rentTableType === 'blended') {
              areaSize += $scope.lease.premiseAreaSize;
            }
          } else {
            areaSize = $scope.lease.expansionPremiseAreaSize;
            rentBumps = $scope.lease.expansion.rentBumps;
          }
        } else if ($scope.lease.premisesModType === 'reduction') {
          areaSize = $scope.lease.reductionRemainingAreaSize;
          rentBumps = $scope.lease.reduction.rentBumps;
        } else if ($scope.lease.premisesModType === 'relocation') {
          areaSize = $scope.lease.relocationPremisesAreaSize;
          rentBumps = $scope.lease.relocation.rentBumps;
        } else if ($scope.lease.premisesModType === 'none') {
          areaSize = $scope.lease.premiseAreaSize;
          rentBumps = $scope.lease.rentBumps;
        }
      }

      if (term.rentInputType === 'manual' || term.rentInputType === 'automatic') {
        let freeRentPeriods = term.freeRentPeriods;

        for (let i = 0; i < freeRentPeriods.length; i++) {
          if (!(term.freeRentPeriods[i] && term.freeRentPeriods[i].beginIn)) {
            continue;
          }

          const rentPeriodIndex = Math.trunc(term.freeRentPeriods[i].beginIn / 12);

          if (!(term.rentPeriods[rentPeriodIndex] && term.rentPeriods[rentPeriodIndex].rentPerSqFoot)) {
            continue;
          }

          const monthlyBaseRent =
                  (term.rentPeriods[rentPeriodIndex].rentPerSqFoot * areaSize) / 12;

          const monthlyRent = {
            beginIn: term.freeRentPeriods[i].beginIn,
            duration: term.freeRentPeriods[i].duration,
            remainingRentAmount:
              monthlyBaseRent - term.freeRentPeriods[i].amount,
          };

          if (term.rentPeriods[rentPeriodIndex].rentPerSqFoot * areaSize / 12 - term.freeRentPeriods[i].amount >= 0.01) {
            term.remainingMonthlyRent.push(monthlyRent);
          } else {
            monthlyRent.remainingRentAmount = 0;
          }

          term.payableMonthlyRent.push(monthlyRent);
        }
      } else if (term.rentInputType === 'flexible') {
        if (!rentBumps) {
          return;
        }

        for (let i = 0; i < rentBumps.length; i++) {
          let currentRentBump = rentBumps[i];
          if (term.freeRentPeriods) {
            for (let j = 0; j < term.freeRentPeriods.length; j++) {
              let currentFreeRentPeriod = term.freeRentPeriods[j];

              if (
                currentRentBump.begin <= currentFreeRentPeriod.beginIn &&
                currentFreeRentPeriod.beginIn +
                  currentFreeRentPeriod.duration -
                  1 <=
                  currentRentBump.begin + currentRentBump.duration
              ) {
                const monthlyBaseRent =
                  (currentRentBump.rentPerSqFoot * areaSize) / 12;

                const monthlyRent = {
                  beginIn: currentFreeRentPeriod.beginIn,
                  duration: currentFreeRentPeriod.duration,
                  remainingRentAmount:
                    monthlyBaseRent - currentFreeRentPeriod.amount,
                };

                if (monthlyBaseRent - currentFreeRentPeriod.amount > 0.01) {
                  term.remainingMonthlyRent.push(monthlyRent);
                } else {
                  monthlyRent.remainingRentAmount = 0;
                }

                term.payableMonthlyRent.push(monthlyRent);
              }
            }
          }
        }
      }
    }

    function getRentPeriodsInMonths() {
      var rentPeriods;
      if ($scope.lease.rentInputType === 'flexible') {
        rentPeriods = $scope.lease.rentBumps;
      } else if ($scope.lease.rentInputType === 'flexible-amendment') {
        if ($scope.lease.premisesModType === 'expansion') {
          if ($scope.lease.hasExtensionOfTerm) {
            if ($scope.lease.rentTableType === 'blended') {
              rentPeriods = $scope.lease.expansion.rentBumps;
            } else if ($scope.lease.rentTableType === 'separate') {
              rentPeriods = $scope.lease.expansion.rentBumps;
            }
          } else {
            rentPeriods = $scope.lease.expansion.rentBumps;
          }
        } else if ($scope.lease.premisesModType === 'reduction') {
          rentPeriods = $scope.lease.reduction.rentBumps;
        } else if ($scope.lease.premisesModType === 'relocation') {
          rentPeriods = $scope.lease.relocation.rentBumps;
        } else if ($scope.lease.premisesModType === 'none') {
          rentPeriods = $scope.lease.rentBumps;
        }
      } else {
        rentPeriods = $scope.lease.rentPeriods;
      }
      var rentPeriodsInMonths = _.chain(rentPeriods)
        .cloneDeep()
        .flatMap(function(row, rowIndex) {
          var monthsInRow = ($scope.lease.rentInputType === 'flexible') ? row.duration : 12;
          var monthsList = _.times(monthsInRow, function(i) {
            var obj = _.clone(row);
            return obj;
          });
        return monthsList;
      }).value();
      return rentPeriodsInMonths;
    }

    $scope.calculatedFields = {
      calculatedFields: {
        'lease.customFields.fileName': {
          dependsOn: [
            "lease.tenantInfo.name",
            "lease.tenantInfo.tradeName",
          ],
          calcFunc: function() {
            if (window.lease?.tenantInfo) {
              const tenant = $scope.lease.tenantInfo.tradeName || $scope.lease.tenantInfo.name;

              if (!$scope.lease.customFields) {
                $scope.lease.customFields = {};  
              }
              
              $scope.lease.customFields.fileName = tenant;

              LeaseEditorService.setLeaseTitle();
            }
          },
        },
        'building.landlords': {
          dependsOn: [],
          calcFunc: function() {
            if ($scope.building && $scope.building.landlordName && $scope.building.landlordStateOfFormation && $scope.building.landlordEntityType) {
              const landlords = $scope.building.landlordName.split(";");
              const states = $scope.building.landlordStateOfFormation.split(';')
              const entities = $scope.building.landlordEntityType.split(';')
              const result = [];
  
              for (let i = 0; i < landlords.length; i++) {
                result.push({
                  name: landlords[i],
                  state: states[i],
                  entityType: entities[i],
                });
              }
  
              $scope.building.landlords = result;
            }
          },
        },
        'lease.securityDepositCents': {
          dependsOn: ['lease.securityDeposit'],
          calcFunc: function() {
            $scope.lease.securityDepositCents = $scope.lease.securityDeposit * 100;
            $scope.update();
          },
        },
        'lease.fixedCam': {
          dependsOn: ['lease.camType'],
          calcFunc: function() {
            if (!$scope.lease.camType) {
              $scope.lease.camType = $scope.lease.fixedCam
                ? 'fixed'
                : 'passthrough';
            } else {
              $scope.lease.fixedCam =
                $scope.lease.camType === 'passthrough' ? false : true;
            }
          },
        },
        'lease.hasConsecutiveAbatementDates': {
          dependsOn: [
            'lease.abatementDates.date',
            'lease.abatementDates.length',
          ],
          calcFunc: function() {
            if ($scope.lease.abatementDates.length >= 3) {
              $scope.lease.hasConsecutiveAbatementDates = true;
              _.forEach($scope.lease.abatementDates, function(date, i) {
                if (
                  i > 0 &&
                  moment(date.date).diff(
                    $scope.lease.abatementDates[i - 1].date,
                    'month',
                  ) !== 1
                ) {
                  $scope.lease.hasConsecutiveAbatementDates = false;
                }
              });
            } else {
              $scope.lease.hasConsecutiveAbatementDates = false;
            }
          },
        },
        'lease.numberOfExistingReleasedGuarantors': {
          dependsOn: [
            'lease.existingGuarantorInfo.entities.length',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.individuals.length',
            'lease.existingGuarantorInfo.individuals.hasBeenReleased',
            'lease.existingGuarantorInfo.entities.hasBeenReleased',
            'lease.existingGuarantorInfo.marriedCouples.hasBeenReleased',
          ],
          calcFunc: function() {
            $scope.lease.numberOfExistingReleasedGuarantors = _.filter(
              _.union(
                $scope.lease.existingGuarantorInfo.individuals || [],
                $scope.lease.existingGuarantorInfo.marriedCouples || [],
                $scope.lease.existingGuarantorInfo.entities || [],
              ),
              {
                hasBeenReleased: true,
              },
            ).length;
          },
        },
        'lease.firstExistingReleasedGuarantor': {
          dependsOn: [
            'lease.existingGuarantorInfo.entities.length',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.individuals.length',
            'lease.existingGuarantorInfo.individuals.hasBeenReleased',
            'lease.existingGuarantorInfo.entities.hasBeenReleased',
            'lease.existingGuarantorInfo.marriedCouples.hasBeenReleased',
          ],
          calcFunc: function() {
            $scope.lease.firstExistingReleasedGuarantor = _.first(
              _.filter(
                _.union(
                  $scope.lease.existingGuarantorInfo.individuals || [],
                  $scope.lease.existingGuarantorInfo.marriedCouples || [],
                  $scope.lease.existingGuarantorInfo.entities || [],
                ),
                {
                  hasBeenReleased: true,
                },
              ),
            );
          },
        },
        'lease.calc.fixedRenewalOptions': {
          dependsOn: [
            'lease.hasRenewalOption',
            'lease.renewalCount',
            'lease.renewalInfo.length',
            'lease.renewalInfo.renewalOptionRent',
          ],
          calcFunc: function() {
            $scope.lease.calc.fixedRenewalOptions = [];

            if ($scope.lease?.renewalInfo?.length > 0) {
              $scope.lease.calc.fixedRenewalOptions = $scope.lease.renewalInfo.filter(x => x.renewalOptionRent == "fixed");
            }
          }
        },
        'lease.calc.fmvRenewalOptions': {
          dependsOn: [
            'lease.hasRenewalOption',
            'lease.renewalCount',
            'lease.renewalInfo.length',
            'lease.renewalInfo.renewalOptionRent',
          ],
          calcFunc: function() {
            $scope.lease.calc.fmvRenewalOptions = [];
            
            if ($scope.lease?.renewalInfo?.length > 0) {
              $scope.lease.calc.fmvRenewalOptions = $scope.lease.renewalInfo.filter(x => x.renewalOptionRent == "fmv");
            }
          }
        },
        'lease.calc.termLengthPeriods': {
          dependsOn: [
            'lease.leaseYears',
            'lease.leaseMonths',
          ],
          calcFunc: function() {
            let periods = $scope.lease.leaseYears;
            let result = [];

            if ($scope.lease.leaseMonths > 0) {
              periods++;
            }

            for (let i = 0; i < periods; i++) {
              result.push(i);
            }

            $scope.lease.calc.termLengthPeriods = result;
          },
        },
        'lease.calc.existingGuarantors': {
          dependsOn: [
            'lease.existingGuarantorInfo.entities.length',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.individuals.length',
          ],
          calcFunc: function() {
            const result = [];

            $scope.lease.existingGuarantorInfo.individuals.forEach(guarantor => {
              result.push({
                ...guarantor,
                type: "individual",
              });
            });

            $scope.lease.existingGuarantorInfo.marriedCouples.forEach(guarantor => {
              result.push({
                ...guarantor,
                type: "married couple",
              });
            });

            $scope.lease.existingGuarantorInfo.entities.forEach(guarantor => {
              result.push({
                ...guarantor,
                type: "entity",
              });
            });

            $scope.lease.calc.existingGuarantors = result;
          },
        },
        'lease.calc.existingGuarantorsGroupByDate': {
          dependsOn: [
            'lease.existingGuarantorInfo.entities.length',
            'lease.existingGuarantorInfo.entities.name',
            'lease.existingGuarantorInfo.entities.address',
            'lease.existingGuarantorInfo.entities.city',
            'lease.existingGuarantorInfo.entities.state',
            'lease.existingGuarantorInfo.entities.zip',
            'lease.existingGuarantorInfo.entities.stateOfFormation',
            'lease.existingGuarantorInfo.entities.entityTypeCombined',
            'lease.existingGuarantorInfo.entities.guarantyDate',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.marriedCouples.name',
            'lease.existingGuarantorInfo.marriedCouples.address',
            'lease.existingGuarantorInfo.marriedCouples.city',
            'lease.existingGuarantorInfo.marriedCouples.state',
            'lease.existingGuarantorInfo.marriedCouples.zip',
            'lease.existingGuarantorInfo.marriedCouples.guarantyDate',
            'lease.existingGuarantorInfo.individuals.length',
            'lease.existingGuarantorInfo.individuals.name',
            'lease.existingGuarantorInfo.individuals.address',
            'lease.existingGuarantorInfo.individuals.city',
            'lease.existingGuarantorInfo.individuals.state',
            'lease.existingGuarantorInfo.individuals.zip',
            'lease.existingGuarantorInfo.individuals.guarantyDate',
          ],
          calcFunc: function() {
            const guarantors = [];

            $scope.lease.existingGuarantorInfo.individuals.forEach((guarantor, index) => {
              guarantors.push({
                ...guarantor,
                type: "individual",
                key: "individuals",
                _originalIndex: index,
              });
            });

            $scope.lease.existingGuarantorInfo.marriedCouples.forEach((guarantor, index) => {
              guarantors.push({
                ...guarantor,
                type: "married couple",
                key: "marriedCouples",
                _originalIndex: index,
              });
            });

            $scope.lease.existingGuarantorInfo.entities.forEach((guarantor, index) => {
              guarantors.push({
                ...guarantor,
                type: "entity",
                key: "entities",
                _originalIndex: index,
              });
            });

            const result = guarantors.reduce((acc, guarantor) => {
              const date = guarantor.guarantyDate;

              if (!acc[date]) {
                acc[date] = {
                  individuals: [],
                  marriedCouples: [],
                  entities: [],
                  all: [],
                };
              }

              if (guarantor.type === "individual") {
                acc[date].individuals.push(guarantor);
              } else if (guarantor.type === "married couple") {
                acc[date].marriedCouples.push(guarantor);
              } else if (guarantor.type === "entity") {
                acc[date].entities.push(guarantor);
              }

              acc[date].all.push(guarantor);

              return acc;
            }, {});

            $scope.lease.calc.existingGuarantorsGroupByDate = Object.keys(result).map(key => {
              return result[key];
            });
          },
        },
        'lease.calc.existingGuarantorsGroupByAddress': {
          dependsOn: [
            'lease.existingGuarantorInfo.entities.length',
            'lease.existingGuarantorInfo.entities.name',
            'lease.existingGuarantorInfo.entities.address',
            'lease.existingGuarantorInfo.entities.city',
            'lease.existingGuarantorInfo.entities.state',
            'lease.existingGuarantorInfo.entities.zip',
            'lease.existingGuarantorInfo.entities.stateOfFormation',
            'lease.existingGuarantorInfo.entities.entityTypeCombined',
            'lease.existingGuarantorInfo.entities.guarantyDate',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.marriedCouples.name',
            'lease.existingGuarantorInfo.marriedCouples.address',
            'lease.existingGuarantorInfo.marriedCouples.city',
            'lease.existingGuarantorInfo.marriedCouples.state',
            'lease.existingGuarantorInfo.marriedCouples.zip',
            'lease.existingGuarantorInfo.marriedCouples.guarantyDate',
            'lease.existingGuarantorInfo.individuals.length',
            'lease.existingGuarantorInfo.individuals.name',
            'lease.existingGuarantorInfo.individuals.address',
            'lease.existingGuarantorInfo.individuals.city',
            'lease.existingGuarantorInfo.individuals.state',
            'lease.existingGuarantorInfo.individuals.zip',
            'lease.existingGuarantorInfo.individuals.guarantyDate',
          ],
          calcFunc: function() {
            const guarantors = [];

            $scope.lease.existingGuarantorInfo.individuals.forEach((guarantor, index) => {
              guarantors.push({
                ...guarantor,
                type: "individual",
                key: "individuals",
                _originalIndex: index,
              });
            });

            $scope.lease.existingGuarantorInfo.marriedCouples.forEach((guarantor, index) => {
              guarantors.push({
                ...guarantor,
                type: "married couple",
                key: "marriedCouples",
                _originalIndex: index,
              });
            });

            $scope.lease.existingGuarantorInfo.entities.forEach((guarantor, index) => {
              guarantors.push({
                ...guarantor,
                type: "entity",
                key: "entities",
                _originalIndex: index,
              });
            });

            const result = guarantors.reduce((acc, guarantor) => {
              const address = guarantor.address || "";
              const city = guarantor.city || "";
              const state = guarantor.state || "";
              const zip = guarantor.zip || "";
              const fullAddress = [address, city, state, zip].join(", ");
              const date = guarantor.guarantyDate;

              if (!acc[date]) {
                acc[date] = {};
              }

              if (!acc[date][fullAddress]) {
                acc[date][fullAddress] = {
                  individuals: [],
                  marriedCouples: [],
                  entities: [],
                  all: [],
                };
              }

              if (guarantor.type === "individual") {
                acc[date][fullAddress].individuals.push(guarantor);
              } else if (guarantor.type === "married couple") {
                acc[date][fullAddress].marriedCouples.push(guarantor);
              } else if (guarantor.type === "entity") {
                acc[date][fullAddress].entities.push(guarantor);
              }

              acc[date][fullAddress].all.push(guarantor);

              return acc;
            }, {});

            $scope.lease.calc.existingGuarantorsGroupByAddress = Object.keys(result).map(key1 => {
              return Object.keys(result[key1]).map(key2 => {
                return result[key1][key2];
              });
            });
          },
        },
        'lease.calc.existingReleasedGuarantors': {
          dependsOn: [
            'lease.existingGuarantorInfo.entities.length',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.individuals.length',
            'lease.existingGuarantorInfo.individuals.hasBeenReleased',
            'lease.existingGuarantorInfo.entities.hasBeenReleased',
            'lease.existingGuarantorInfo.marriedCouples.hasBeenReleased',
          ],
          calcFunc: function() {
            $scope.lease.calc.existingReleasedGuarantors = _.filter(
              _.union(
                $scope.lease.existingGuarantorInfo.individuals || [],
                $scope.lease.existingGuarantorInfo.marriedCouples || [],
                $scope.lease.existingGuarantorInfo.entities || [],
              ),
              {
                hasBeenReleased: true,
              },
            );
          },
        },
        'lease.calc.existingUnreleasedGuarantors': {
          dependsOn: [
            'lease.existingGuarantorInfo.entities.length',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.individuals.length',
            'lease.existingGuarantorInfo.individuals.hasBeenReleased',
            'lease.existingGuarantorInfo.entities.hasBeenReleased',
            'lease.existingGuarantorInfo.marriedCouples.hasBeenReleased',
          ],
          calcFunc: function() {
            $scope.lease.calc.existingUnreleasedGuarantors = _.filter(
              _.union(
                $scope.lease.existingGuarantorInfo.individuals || [],
                $scope.lease.existingGuarantorInfo.marriedCouples || [],
                $scope.lease.existingGuarantorInfo.entities || [],
              ),
              {
                hasBeenReleased: false,
              },
            );
          },
        },
        'lease.calc.orderedPreviousAmendments': {
          dependsOn: [
            'lease.previousAmendment.date',
            'lease.previousAmendment.length',
          ],
          calcFunc: function() {
            const orderedPreviousAmendments = _.orderBy($scope.lease.previousAmendment, ['date'], ['asc']);

            $scope.lease.calc.orderedPreviousAmendments = orderedPreviousAmendments;
          }
        },
        'lease.calc.orderedPriorAssignments': {
          dependsOn: [
            'lease.priorAssignments.date',
            'lease.priorAssignments.length',
          ],
          calcFunc: function() {
            const orderedPriorAssignments = _.orderBy($scope.lease.priorAssignments, ['date'], ['asc']);

            $scope.lease.calc.orderedPriorAssignments = orderedPriorAssignments;
          }
        },
        'lease.hasMultipleGuarantors': {
          dependsOn: [
            'lease.existingGuarantorInfo.individuals.length',
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.entities.length',
          ],
          calcFunc: function() {
            $scope.lease.hasMultipleGuarantors =
              $scope.lease.guarantorInfo.individuals.length +
                $scope.lease.guarantorInfo.marriedCouples.length +
                $scope.lease.guarantorInfo.entities.length >
              1;
          },
        },
        'lease.multipleGuarantors': {
          dependsOn: [
            'lease.guarantorInfo.individuals.length',
            'lease.guarantorInfo.individuals.address',
            'lease.guarantorInfo.individuals.city',
            'lease.guarantorInfo.individuals.name',
            'lease.guarantorInfo.individuals.phone',
            'lease.guarantorInfo.individuals.email',
            'lease.guarantorInfo.individuals.SSN',
            'lease.guarantorInfo.individuals.state',
            'lease.guarantorInfo.individuals.zip',
            'lease.guarantorInfo.marriedCouples.length',
            'lease.guarantorInfo.marriedCouples.address1',
            'lease.guarantorInfo.marriedCouples.address2',
            'lease.guarantorInfo.marriedCouples.city1',
            'lease.guarantorInfo.marriedCouples.city2',
            'lease.guarantorInfo.marriedCouples.hasSameAddress',
            'lease.guarantorInfo.marriedCouples.name1',
            'lease.guarantorInfo.marriedCouples.name2',
            'lease.guarantorInfo.marriedCouples.phone1',
            'lease.guarantorInfo.marriedCouples.phone2',
            'lease.guarantorInfo.marriedCouples.email1',
            'lease.guarantorInfo.marriedCouples.email2',
            'lease.guarantorInfo.marriedCouples.state1',
            'lease.guarantorInfo.marriedCouples.state2',
            'lease.guarantorInfo.marriedCouples.zip1',
            'lease.guarantorInfo.marriedCouples.zip2',
            'lease.guarantorInfo.marriedCouples.SSN1',
            'lease.guarantorInfo.marriedCouples.SSN2',
            'lease.guarantorInfo.entities.length',
            'lease.guarantorInfo.entities.address',
            'lease.guarantorInfo.entities.attention',
            'lease.guarantorInfo.entities.city',
            'lease.guarantorInfo.entities.entityType',
            'lease.guarantorInfo.entities.entityTypeCombined',
            'lease.guarantorInfo.entities.entityTypeOther',
            'lease.guarantorInfo.entities.name',
            'lease.guarantorInfo.entities.phone',
            'lease.guarantorInfo.entities.email',
            'lease.guarantorInfo.entities.stateOfFormation',
            'lease.guarantorInfo.entities.state',
            'lease.guarantorInfo.entities.taxId',
            'lease.guarantorInfo.entities.zip',
            'lease.guarantorInfo.entities.agentName',
            'lease.guarantorInfo.entities.agentAddress',
            'lease.guarantorInfo.entities.agentCity',
            'lease.guarantorInfo.entities.agentState',
            'lease.guarantorInfo.entities.agentZip',
          ],
          calcFunc: function() {
            const result = [];

            for (let i = 0; i < $scope.lease.guarantorInfo.individuals.length; i++) {
              result.push({
                ...$scope.lease.guarantorInfo.individuals[i],
                type: "individual",
                _originalIndex: i,
              })
            }
            for (let i = 0; i < $scope.lease.guarantorInfo.marriedCouples.length; i++) {
              result.push({
                ...$scope.lease.guarantorInfo.marriedCouples[i],
                type: "marriedCouple",
                _originalIndex: i,
              })
            }
            for (let i = 0; i < $scope.lease.guarantorInfo.entities.length; i++) {
              result.push({
                ...$scope.lease.guarantorInfo.entities[i],
                type: "entity",
                _originalIndex: i,
              })
            }

            $scope.lease.multipleGuarantors = result;
          },
        },
        'lease.hasExistingMarriedCouplesReleasedGuarantors': {
          dependsOn: [
            'lease.existingGuarantorInfo.marriedCouples.length',
            'lease.existingGuarantorInfo.marriedCouples.hasBeenReleased',
          ],
          calcFunc: function() {
            $scope.lease.hasExistingMarriedCouplesReleasedGuarantors =
              _.filter($scope.lease.existingGuarantorInfo.marriedCouples, {
                hasBeenReleased: true,
              }).length > 0
                ? true
                : false;
          },
        },
        'lease.hasHardCommencementDate': {
          dependsOn: [
            'lease.hasSpecificExpirationDate',
            'lease.estimatedCommencementDate',
            'lease.termCommencementDate',
            'lease.commencementDateType',
            'lease.fixedCommencementDateType',
            'lease.estimatedCommencementDateType',
            'lease.terms.hasSpecificExpirationDate',
            'lease.terms.estimatedCommencementDate',
            'lease.terms.termCommencementDate',
            'lease.terms.estimatedCommencementDateType',
            'lease.hasInitialDeliveryWork',
            'lease.tenantImprovements.type',
          ],
          calcFunc: function() {
            $scope.lease.hasHardCommencementDate =
              $scope.lease.tenantImprovements &&
              (($scope.lease.tenantImprovements.type === 'landlord' &&
                ($scope.lease.hasSpecificExpirationDate ||
                ($scope.hasEditorConfig('commencementDate') &&
                  ($scope.lease.fixedCommencementDateType !== 'execution of lease' ||
                    !$scope.hasEditorConfig('commencementDate', 'landlordFixedExecutionOfLease')) &&
                    ($scope.lease.commencementDateType !== 'floating' ||
                      (!$scope.hasEditorConfig('commencementDate', 'landlordFloatingSoftPeriodDays') &&
                        !$scope.hasEditorConfig('commencementDate', 'landlordFloatingDate')) ) )) ) ||
                ($scope.lease.tenantImprovements.type === 'tenant' &&
                  ((!$scope.hasEditorConfig('commencementDate') && !$scope.lease.hasInitialDeliveryWork &&
                  $scope.lease.estimatedCommencementDateType === 'hard date') ||
                  (($scope.hasEditorConfig('commencementDate') &&
                    ($scope.lease.fixedCommencementDateType !== 'execution of lease' ||
                      !$scope.hasEditorConfig('commencementDate', 'tenantFixedExecutionOfLease')) &&
                      ($scope.lease.commencementDateType !== 'floating' ||
                        (!$scope.hasEditorConfig('commencementDate', 'tenantFloatingSoftPeriodDays') &&
                          !$scope.hasEditorConfig('commencementDate', 'tenantFloatingDate')) ) )) ))  ||
                ($scope.lease.tenantImprovements.type === 'none' &&
                  ($scope.lease.fixedCommencementDateType !== 'execution of lease' ||
                    !$scope.hasEditorConfig('commencementDate', 'asIsFixedExecutionOfLease')) &&
                    ($scope.lease.commencementDateType !== 'floating' ||
                      (!$scope.hasEditorConfig('commencementDate', 'asIsFloatingSoftPeriodDays') &&
                        !$scope.hasEditorConfig('commencementDate', 'asIsFloatingDate')) ) ));
          },
        },
        'lease.hasAmendedProvisions': {
          dependsOn: [
            "lease.hasAmendedProvisions",
          ],
          calcFunc: function() {
            if ($scope.lease.hasLeaseAssignment && !$scope.lease.hasAmendedProvisions) {
              $scope.lease.hasGrossSalesReporting = false;
              $scope.lease.hasExtensionOfTerm = false;
              $scope.lease.hasReplacementSignageCriteria = false;
              $scope.lease.tenantInfo.hasBroker = false;
              $scope.lease.hasRenewalOption = false;
              $scope.lease.hasRentAbatement = false;
              $scope.lease.hasRentDeferral = false;
              $scope.lease.hasPylonSign = false;
              $scope.lease.hasSecurityDeposit = false;
              $scope.lease.hasExtensionOfTerm = false;
              $scope.lease.changesToPercentageRent = "none";
            }
          }
        },
        'lease.hasSpecialRights': {
          dependsOn: [
            'lease.hasRenewalOption',
            'lease.hasRooftopEquipment',
            'lease.hasTenantTerminationOption',
            'lease.hasRightOfFirstOffer',
            'lease.hasExpansionOption',
            'lease.hasParking',
          ],
          calcFunc: function() {
            $scope.lease.hasSpecialRights =
              $scope.lease.hasRenewalOption ||
              $scope.lease.hasRightOfFirstOffer ||
              $scope.lease.hasExpansionOption ||
              $scope.lease.hasParking ||
              $scope.lease.hasRooftopEquipment ||
              $scope.lease.hasTenantTerminationOption;
          },
        },
        'lease.hasLimitedGrossSalesReporting': {
          dependsOn: ['lease.hasPercentageRent'],
          calcFunc: function() {
            if ($scope.lease.hasPercentageRent) {
              $scope.lease.hasLimitedGrossSalesReporting = false;
            }
          },
        },
        'lease.percentageRent': {
          dependsOn: ['lease.hasPercentageRent'],
          calcFunc: function() {
            if ($scope.lease.hasPercentageRent == false) {
              $scope.lease.percentageRent = '';
            }
          },
        },
        'lease.hasAreaRemeasurement': {
          dependsOn: ['lease.hasPremisesAreaSizeStipulated'],
          calcFunc: function() {
            if ($scope.lease.hasPremisesAreaSizeStipulated) {
              $scope.lease.hasAreaRemeasurement = false;
            }
          },
        },
        'lease.minimumSales': {
          dependsOn: [
            'lease.hasExtensionOfTerm',
            'lease.hasRenewalOption',
            'lease.leaseYears',
            'lease.leaseMonths',
            'lease.renewalCount',
            'lease.renewalTerm',
          ],
          calcFunc: function() {
            let count = 0;

            if ($rootScope.hasEditorConfig('optionalExtensionTermLength')) {
              if ($scope.lease.hasExtensionOfTerm) {
                count = $scope.lease.leaseYears;
              }
            } else {
              count = $scope.lease.leaseYears;
            }

            if ($scope.lease.hasRenewalOption) {
              count += $scope.lease.renewalCount * $scope.lease.renewalTerm;
            }

            if (
              $scope.lease.type ===  "Lease" &&
              $scope.lease.leaseMonths > 0 &&
              $rootScope.hasEditorConfig('termLength', 'addRentRowForPartialYear')
            ) {
              count += 1;
            }

            if (
              $scope.lease.type ===  "Amendment" &&
              $scope.lease.leaseMonths > 0 &&
              $rootScope.hasEditorConfig('optionalExtensionTermLength', 'addRentRowForPartialYear')
            ) {
              count += 1;
            }

            if (count > $scope.lease.minimumSales.length) {
              var totalMinimumSales =
                count - $scope.lease.minimumSales.length;
              for (var i = 0; i < totalMinimumSales; i++) {
                $scope.lease.minimumSales.push({
                  value: undefined,
                });
              }
            } else {
              count = $scope.lease.minimumSales.length - count;
              $scope.lease.minimumSales.splice(-count, count);
            }
          },
        },
        'lease.rentRows': {
          dependsOn: [
            'lease.existingLeaseExpirationDate',
            'lease.hasFreeRentAddedToTerm',
            'lease.freeRentPeriods.softEndDate',
            'lease.freeRentPeriods.softStartDate',
            'lease.freeRentPeriods.hardEndDate',
            'lease.freeRentPeriods.hardStartDate',
            'lease.freeRentPeriods.isFree',
            'lease.freeRentExtendedAt',
            'lease.abatementDates.date',
            'lease.hasRentAbatement',
            'lease.hasBlendAndExtend',
            'lease.abatementDates.length',
            'lease.leaseYears',
            'lease.leaseMonths',
            'lease.rentCommenceAt',
            'rentValues',
            'lease.rentInputType',
            'lease.hasFreeRent',
            'lease.hasCpiRent',
            'lease.cpiAnchorYears',
            'lease.freeRent',
            'lease.premiseAreaSize',
            'lease.expansionPremiseAreaSize',
            'lease.relocationPremisesAreaSize',
            'lease.reductionRemainingAreaSize',
            'lease.freeRentPeriods.beginIn',
            'lease.freeRentPeriods.duration',
            'lease.freeRentPeriods.percentFree',
            'lease.freeRentPeriods.amount',
            'lease.freeRentPeriods.length',
            'lease.rentBumps.length',
            'lease.rentBumps.begin',
            'lease.rentBumps.duration',
            'lease.rentBumps.rentPerSqFoot',
            'lease.expansion.rentBumps.length',
            'lease.expansion.rentBumps.begin',
            'lease.expansion.rentBumps.duration',
            'lease.expansion.rentBumps.rentPerSqFoot',
            'lease.reduction.rentBumps.length',
            'lease.reduction.rentBumps.begin',
            'lease.reduction.rentBumps.duration',
            'lease.reduction.rentBumps.rentPerSqFoot',
            'lease.relocation.rentBumps.length',
            'lease.relocation.rentBumps.begin',
            'lease.relocation.rentBumps.duration',
            'lease.relocation.rentBumps.rentPerSqFoot',
            'lease.rentTableType',
            'lease.terms.hasFreeRentAddedToTerm',
            'lease.terms.freeRentPeriods.softEndDate',
            'lease.terms.freeRentPeriods.softStartDate',
            'lease.terms.freeRentPeriods.hardEndDate',
            'lease.terms.freeRentPeriods.hardStartDate',
            'lease.terms.freeRentPeriods.isFree',
            'lease.terms.freeRentExtendedAt',
            'lease.terms.rentInputType',
            'lease.terms.hasFreeRent',
            'lease.terms.freeRent',
            'lease.terms.freeRentPeriods.beginIn',
            'lease.terms.freeRentPeriods.duration',
            'lease.terms.freeRentPeriods.percentFree',
            'lease.terms.freeRentPeriods.amount',
            'lease.terms.freeRentPeriods.length',
            'lease.terms.rentBumps.length',
            'lease.terms.rentBumps.begin',
            'lease.terms.rentBumps.duration',
            'lease.terms.rentBumps.rentPerSqFoot',
            'lease.termCommencementDate',
            'lease.hasMonthToMonth',
            'lease.monthToMonthEnd',
          ],
          calcFunc: function() {
            $scope.$broadcast('rentValuesChanged');
            return true;
          },
        },
        'lease.renewalRows': {
          dependsOn: [
            'lease.hasRenewalOption',
            'lease.hasExtensionOfTerm',
            'lease.renewalCount',
            'lease.renewalTerm',
            'lease.renewalInfo.renewalInputType',
            'lease.renewalInfo.renewalRentPeriods.rentPerSqFoot',
            'lease.renewalInfo.renewalBaseRent',
            'lease.renewalInfo.renewalAnnualIncrease',
            'lease.renewalInfo.hasCpiRent',
            'lease.renewalInfo.cpiAnchorYears',
            'renewalValues',
            'lease.existingLeaseExpirationDate',
            'lease.hasFreeRentAddedToTerm',
            'lease.freeRentPeriods.softEndDate',
            'lease.freeRentPeriods.softStartDate',
            'lease.freeRentPeriods.hardEndDate',
            'lease.freeRentPeriods.hardStartDate',
            'lease.freeRentPeriods.isFree',
            'lease.freeRentExtendedAt',
            'lease.abatementDates.date',
            'lease.hasRentAbatement',
            'lease.abatementDates.length',
            'lease.leaseYears',
            'lease.leaseMonths',
            'rentValues',
            'lease.rentInputType',
            'lease.hasFreeRent',
            'lease.freeRent',
            'lease.freeRentExtendedAt',
            'lease.premiseAreaSize',
            'lease.freeRentPeriods.beginIn',
            'lease.freeRentPeriods.duration',
            'lease.freeRentPeriods.percentFree',
            'lease.freeRentPeriods.amount',
            'lease.freeRentPeriods.length',
            'lease.renewalRentBumps',
            'lease.renewalRentBumps',
            'lease.renewalRentBumps.periods.length',
            'lease.renewalRentBumps.periods.begin',
            'lease.renewalRentBumps.periods.duration',
            'lease.renewalRentBumps.periods.rentPerSqFoot',
            'lease.terms.hasFreeRentAddedToTerm',
            'lease.terms.freeRentPeriods.softEndDate',
            'lease.terms.freeRentPeriods.softStartDate',
            'lease.terms.freeRentPeriods.hardEndDate',
            'lease.terms.freeRentPeriods.hardStartDate',
            'lease.terms.freeRentPeriods.isFree',
            'lease.terms.freeRentExtendedAt',
            'lease.terms.freeRentPeriods.beginIn',
            'lease.terms.freeRentPeriods.duration',
            'lease.terms.freeRentPeriods.percentFree',
            'lease.terms.freeRentPeriods.amount',
            'lease.terms.freeRentPeriods.length',
          ],
          calcFunc: function() {
            $scope.$broadcast('rentValuesChanged');
            return true;
          },
        },
        'lease.annualBaseRent': {
          dependsOn: [
            'lease.baseRent',
            'lease.rentInputType',
            'lease.rentPeriods.rentPerSqFoot',
            'lease.rentPeriods.totalRent',
            'rentValues',
            'lease.premiseAreaSize',
            'lease.terms.baseRent',
            'lease.terms.rentInputType',
            'lease.terms.rentPeriods.rentPerSqFoot',
            'lease.terms.rentPeriods.totalRent',
            'lease.rentBumps.length',
            'lease.rentBumps.begin',
            'lease.rentBumps.duration',
            'lease.rentBumps.rentPerSqFoot',
            'lease.terms.rentBumps.length',
            'lease.terms.rentBumps.begin',
            'lease.terms.rentBumps.duration',
            'lease.terms.rentBumps.rentPerSqFoot',
          ],
          calcFunc: function() {
            if ($scope.isMultipleTerms) {
              for (
                var termIndex = 0;
                termIndex < $scope.lease.terms.length;
                termIndex++
              ) {
                calcAnnualBaseRentPerTerm($scope.lease.terms[termIndex]);
              }
            } else {
              calcAnnualBaseRentPerTerm($scope.lease);
            }
          },
        },
        'lease.monthlyBaseRent': {
          dependsOn: [
            'lease.baseRent',
            'lease.rentInputType',
            'lease.rentPeriods.rentPerSqFoot',
            'lease.rentPeriods.totalRent',
            'rentValues',
            'lease.premiseAreaSize',
            'lease.terms.baseRent',
            'lease.terms.rentInputType',
            'lease.terms.rentPeriods.rentPerSqFoot',
            'lease.terms.rentPeriods.totalRent',
          ],
          calcFunc: function() {
            if ($scope.isMultipleTerms) {
              for (
                var termIndex = 0;
                termIndex < $scope.lease.terms.length;
                termIndex++
              ) {
                calcMonthlyBaseRentPerTerm($scope.lease.terms[termIndex]);
              }
            } else {
              calcMonthlyBaseRentPerTerm($scope.lease);
            }
          },
        },
        'lease.hasBroker': {
          dependsOn: ['lease.hasLandlordBroker'],
          calcFunc: function() {
            var broker = $scope.lease.hasLandlordBroker;

            if ($scope.lease.tenantInfo) {
              broker = $scope.lease.tenantInfo.hasBroker;
            }

            $scope.lease.hasBroker = broker;
          },
        },
        'lease.rentPeriods': {
          dependsOn: [
            'lease.rentPeriods.totalRent',
            'lease.terms.rentPeriods.totalRent',
          ],
          calcFunc: function() {
            if ($scope.isMultipleTerms) {
              for (
                var termIndex = 0;
                termIndex < $scope.lease.terms.length;
                termIndex++
              ) {
                calcTotalRentPerTerm($scope.lease.terms[termIndex]);
              }
            } else {
              calcTotalRentPerTerm($scope.lease);
            }
          },
        },
        'lease.landlords': {
          dependsOn: [],
          calcFunc: function() {
            $scope.lease.landlords = [];

            if ($scope.building && $scope.building.hasMultipleLandlords) {
              var landlordNames = $scope.building.landlordName.split(';');
              var landlordStateOfFormations = $scope.building.landlordStateOfFormation.split(
                ';',
              );
              var landlordEntityTypes = $scope.building.landlordEntityType.split(
                ';',
              );

              for (var i = 0; i < landlordNames.length; i++) {
                $scope.lease.landlords.push({
                  name: landlordNames[i],
                  stateOfFormation: landlordStateOfFormations[i],
                  entityType: landlordEntityTypes[i],
                });
              }
            }
          },
        },
        'lease.premiseAreaSize': {
          dependsOn: [
            'lease.premiseSuites.areaSize',
            'lease.premiseSuites.length',
          ],
          calcFunc: function() {
            if ($rootScope.hasEditorConfig("multiplePremiseSuites")) {
              let areaSize = null;

              for (var i = 0; i < $scope.lease.premiseSuites.length; i++) {
                let suiteAreaSize = parseFloat($scope.lease.premiseSuites[i].areaSize);

                if (!isNaN(suiteAreaSize)) {
                  if (areaSize === null) {
                    areaSize = 0;
                  }
                  areaSize += suiteAreaSize;
                }
              }

              $scope.lease.premiseAreaSize = areaSize;
            }

            $scope.calculateRentPSF($scope.lease);
            $scope.calculateRenewalPSF($scope.lease);
            $scope.consolidateRentPeriods();
            $scope.consolidateRenewalInfo();
          },
        },
        'lease.terms.premiseAreaSize': {
          dependsOn: ['lease.terms.spaces', 'lease.terms.spaces.length', 'lease.premiseSuites.areaSize'],
          calcFunc: function() {
            for (
              var termIndex = 0;
              termIndex < $scope.lease.terms.length;
              termIndex++
            ) {
              var areaSize = null;
              var term = $scope.lease.terms[termIndex];
              var spaces = term.spaces || [];

              for (var i = 0; i < spaces.length; i++) {
                var last = _.findLast($scope.lease.premiseSuites, function(x) {
                  return x.number == spaces[i].number;
                });
                var currAreaSize = 1 * (last ? last.areaSize : null);

                if (!isNaN(currAreaSize)) {
                  if (areaSize === null) {
                    areaSize = 0;
                  }

                  areaSize += currAreaSize;
                  term.spaces[i].areaSize = currAreaSize;
                }
              }

              if ($rootScope.hasEditorConfig("multiplePremiseSuites")) {
                term.premiseAreaSize = areaSize;
              }

              let rentBumpsInMonths = [];
              let rentBump = null;

              if (term.rentBumps) {
                for (let rentBumpIndex = 0; rentBumpIndex < term.rentBumps.length; rentBumpIndex++) {
                  rentBump = term.rentBumps[rentBumpIndex];
                  
                  for (let month = 0; month < rentBump.duration; month++) {
                    rentBumpsInMonths.push(rentBump.rentPerSqFoot);
                  }
                }
  
                if (term.freeRentPeriods) {
                  for (let i = 0; i < term.freeRentPeriods.length; i++) {
                    if (term.freeRentPeriods[i].percentFree) {
                      term.freeRentPeriods[i].amount = term.premiseAreaSize * rentBumpsInMonths[term.freeRentPeriods[i].beginIn] * (term.freeRentPeriods[i].percentFree / 100 / 12);
                    }
                  }
                }
              }
            }

            $scope.calculateRentPSF($scope.lease);
            $scope.calculateRenewalPSF($scope.lease);
            $scope.consolidateRentPeriods();
            $scope.consolidateRenewalInfo();
          },
        },
        'lease.terms.freeRentPeriods.amount': {
          dependsOn: [
            'lease.premiseSuites.areaSize',
            'lease.premiseSuites.length',
            'lease.freeRentPeriods.beginIn', 
            'lease.freeRentPeriods.percentFree', 
            'lease.freeRentPeriods.length', 
            'lease.rentBumps.length',
            'lease.rentBumps.duration',
            'lease.rentBumps.rentPerSqFoot',
            'lease.terms.spaces', 
            'lease.terms.spaces.length', 
            'lease.terms.freeRentPeriods.beginIn', 
            'lease.terms.freeRentPeriods.percentFree', 
            'lease.terms.freeRentPeriods.length', 
            'lease.terms.rentBumps.length',
            'lease.terms.rentBumps.duration',
            'lease.terms.rentBumps.rentPerSqFoot',
          ],
          calcFunc: function() {
            let terms;

            if ($scope.lease.terms.length > 0) {
              terms = $scope.lease.terms;
            } else {
              terms = [$scope.lease];
            }

            for (
              var termIndex = 0;
              termIndex < terms.length;
              termIndex++
            ) {
              let term = terms[termIndex];
              let rentBumpsInMonths = [];
              let rentBump = null;

              if (term.rentBumps) {
                for (let rentBumpIndex = 0; rentBumpIndex < term.rentBumps.length; rentBumpIndex++) {
                  rentBump = term.rentBumps[rentBumpIndex];
                  
                  for (let month = 0; month < rentBump.duration; month++) {
                    rentBumpsInMonths.push(rentBump.rentPerSqFoot);
                  }
                }

                if (term.freeRentPeriods) {
                  for (let i = 0; i < term.freeRentPeriods.length; i++) {
                    if (term.freeRentPeriods[i].percentFree) {
                      term.freeRentPeriods[i].amount = term.premiseAreaSize * rentBumpsInMonths[term.freeRentPeriods[i].beginIn] * (term.freeRentPeriods[i].percentFree / 100 / 12);
                    }
                  }
                }
              }
            }
          },
        },
        'lease.calculatedAreaSize': {
          dependsOn: [
            'lease.premiseAreaSize',
            'lease.premisesModType',
            'lease.expansionPremiseAreaSize',
            'lease.reductionRemainingAreaSize',
            'lease.relocationPremisesAreaSize',
          ],
          calcFunc: function() {
            $scope.lease.calculatedAreaSize = getAreaSize();
          },
        },
        'lease.freeRentPeriods': {
          dependsOn: ['lease.leaseYears'],
          calcFunc: function() {
            if (!$scope.isMultipleFreeRent) {
              return;
            }
            if (!$scope.lease.freeRentPeriods) {
              $scope.lease.freeRentPeriods = [];
            }

            if ($scope.lease.freeRentPeriods.length < $scope.lease.leaseYears) {
              for (
                var i = $scope.lease.freeRentPeriods.length;
                i < $scope.lease.leaseYears;
                i++
              ) {
                $scope.lease.freeRentPeriods.push({});
              }
            } else {
              var count =
                $scope.lease.freeRentPeriods.length - $scope.lease.leaseYears;
              $scope.lease.freeRentPeriods.splice(-count, count);
            }
          },
        },
        'lease.multipleFreeRent.length': {
          dependsOn: [
            'lease.baseRent',
            'lease.leaseYears',
            'lease.leaseMonths',
            'lease.hasFreeRent',
            'lease.rentBumps.length',
            'lease.rentBumps.duration',
            'lease.rentBumps.rentPerSqFoot',
            'lease.freeRentPeriods.isFree',
            'lease.freeRentPeriods.hardStartDate',
            'lease.freeRentPeriods.hardEndDate',
            'lease.freeRentPeriods.softStartDate',
            'lease.freeRentPeriods.softEndDate',
            'rentValues',
            'lease.tenantImprovements.type',
          ],
          calcFunc: function() {
            let freeRent = [];
            let period;

            if ($scope.lease.hasFreeRent) {
              if ($scope.lease.freeRentPeriods) {
                if ($scope.lease.rentInputType === 'flexible') {
                  let totalTermLength = getTermLength();
                  let freeRentPeriodsInMonths = []
                  let rentBumpsInMonths = new Array(totalTermLength);
                  let areaSize = getAreaSize();

                  rentBumpsInMonths.fill(0);
                  for (let rentBumpIndex = 0; rentBumpIndex < $scope.lease.rentBumps.length; rentBumpIndex++) {
                    for (let rentBumpMonthIndex = $scope.lease.rentBumps[rentBumpIndex].begin - 1; rentBumpMonthIndex <= $scope.lease.rentBumps[rentBumpIndex].begin - 1 + $scope.lease.rentBumps[rentBumpIndex].duration - 1; rentBumpMonthIndex++) {
                      let amount = $scope.lease.rentBumps[rentBumpIndex].rentPerSqFoot * areaSize / 12;
                      rentBumpsInMonths[rentBumpMonthIndex] = amount;
                    }
                  }

                  for (let freeRentIndex = 0; freeRentIndex < $scope.lease.freeRentPeriods.length; freeRentIndex++) {
                    for (let freeRentMonthIndex = 0; freeRentMonthIndex < 12; freeRentMonthIndex++) {
                      if (freeRentMonthIndex >= $scope.lease.freeRentPeriods[freeRentIndex].softStartDate - 1 && freeRentMonthIndex <= $scope.lease.freeRentPeriods[freeRentIndex].softEndDate - 1) {
                        freeRentPeriodsInMonths.push($scope.lease.freeRentPeriods[freeRentIndex].isFree);
                      } else {
                        freeRentPeriodsInMonths.push(false);
                      }
                    }
                  }

                  for (let i = 0; i < $scope.lease.freeRentPeriods.length; i++) {
                    let freeRentAmount = 0;

                    if (
                      $scope.lease.hasHardCommencementDate &&
                      $scope.lease.freeRentPeriods[i].hardEndDate &&
                      $scope.lease.freeRentPeriods[i].hardStartDate
                    ) {
                      period =
                        moment($scope.lease.freeRentPeriods[i].hardEndDate).diff(
                          $scope.lease.freeRentPeriods[i].hardStartDate,
                          'months',
                        ) + 1;
                    } else {
                      period =
                        $scope.lease.freeRentPeriods[i].softEndDate -
                        $scope.lease.freeRentPeriods[i].softStartDate +
                        1;
                    }

                    for (let j = 0; j < 12; j++) {
                      let index = i * 12 + j;

                      if (freeRentPeriodsInMonths[index]) {
                        freeRentAmount += rentBumpsInMonths[index];
                      }
                    }

                    if (freeRentAmount) {
                      freeRent.push({
                        period: period,
                        hardStartDate:
                          $scope.lease.freeRentPeriods[i].hardStartDate,
                        hardEndDate: $scope.lease.freeRentPeriods[i].hardEndDate,
                        softStartDate:
                          $scope.lease.freeRentPeriods[i].softStartDate,
                        softEndDate: $scope.lease.freeRentPeriods[i].softEndDate,
                        freeRentYear: i + 1,
                        freeRentAmount: freeRentAmount,
                      });
                    }
                  }
                } else {
                  for (let i = 0; i < $scope.lease.freeRentPeriods.length; i++) {
                    if ($scope.lease.freeRentPeriods[i].isFree) {
                      if (
                        $scope.lease.hasHardCommencementDate &&
                        $scope.lease.freeRentPeriods[i].hardEndDate &&
                        $scope.lease.freeRentPeriods[i].hardStartDate
                      ) {
                        period =
                          moment($scope.lease.freeRentPeriods[i].hardEndDate).diff(
                            $scope.lease.freeRentPeriods[i].hardStartDate,
                            'months',
                          ) + 1;
                      } else {
                        period =
                          $scope.lease.freeRentPeriods[i].softEndDate -
                          $scope.lease.freeRentPeriods[i].softStartDate +
                          1;
                      }

                      freeRent.push({
                        period: period,
                        hardStartDate:
                          $scope.lease.freeRentPeriods[i].hardStartDate,
                        hardEndDate: $scope.lease.freeRentPeriods[i].hardEndDate,
                        softStartDate:
                          $scope.lease.freeRentPeriods[i].softStartDate,
                        softEndDate: $scope.lease.freeRentPeriods[i].softEndDate,
                        freeRentYear: i + 1,
                        freeRentAmount:
                          period * ($scope.lease.rentPeriods[i].totalRent / 12),
                      });
                    }
                  }
                }
              }
            }

            $scope.lease.multipleFreeRent = freeRent;
          },
        },
        reductionValues: {
          //used only for highlighting oxford-office-lease rent-table
          dependsOn: [
            'lease.reductionPeriods.year',
            'lease.reductionPeriods.amountReduced',
          ],
          calcFunc: function() {
            return true;
          },
        },
        'lease.reductionPeriods.length': {
          dependsOn: ['lease.reductionPeriodsNumber', 'reductionValues'],
          calcFunc: function() {
            return true;
          },
        },
        'lease.assigneeInfo.entityTypeCombined': {
          dependsOn: [
            'lease.assigneeInfo.entityType',
            'lease.assigneeInfo.entityTypeOther',
          ],
          calcFunc: function() {
            if ($scope.lease.assigneeInfo) {
              if ($scope.lease.assigneeInfo.entityType === 'Other') {
                $scope.lease.assigneeInfo.entityTypeCombined =
                  $scope.lease.assigneeInfo.entityTypeOther;
              } else {
                $scope.lease.assigneeInfo.entityTypeCombined =
                  $scope.lease.assigneeInfo.entityType;
              }
            }
          },
        },
        'lease.tenantInfo.postLeaseAddress': {
          dependsOn: [
            'lease.hasPostAddressSameAsPre',
            'lease.tenantInfo.preLeaseAddress',
          ],
          calcFunc: function() {
            if ($scope.lease.tenantInfo) {
              if ($scope.lease.hasPostAddressSameAsPre) {
                $scope.lease.tenantInfo.postLeaseAddress =
                  $scope.lease.tenantInfo.preLeaseAddress;
              }
            }
          },
        },
        'lease.tenantInfo.postLeaseCity': {
          dependsOn: [
            'lease.hasPostAddressSameAsPre',
            'lease.tenantInfo.preLeaseCity',
          ],
          calcFunc: function() {
            if ($scope.lease.tenantInfo) {
              if ($scope.lease.hasPostAddressSameAsPre) {
                $scope.lease.tenantInfo.postLeaseCity =
                  $scope.lease.tenantInfo.preLeaseCity;
              }
            }
          },
        },
        'lease.tenantInfo.postLeaseState': {
          dependsOn: [
            'lease.hasPostAddressSameAsPre',
            'lease.tenantInfo.preLeaseState',
          ],
          calcFunc: function() {
            if ($scope.lease.tenantInfo) {
              if ($scope.lease.hasPostAddressSameAsPre) {
                $scope.lease.tenantInfo.postLeaseState =
                  $scope.lease.tenantInfo.preLeaseState;
              }
            }
          },
        },
        'lease.tenantInfo.postLeaseZipCode': {
          dependsOn: [
            'lease.hasPostAddressSameAsPre',
            'lease.tenantInfo.preLeaseZipCode',
          ],
          calcFunc: function() {
            if ($scope.lease.tenantInfo) {
              if ($scope.lease.hasPostAddressSameAsPre) {
                $scope.lease.tenantInfo.postLeaseZipCode =
                  $scope.lease.tenantInfo.preLeaseZipCode;
              }
            }
          },
        },
        'lease.tenantInfo.postLeasePhoneNumber': {
          dependsOn: [
            'lease.hasPostAddressSameAsPre',
            'lease.tenantInfo.preLeasePhoneNumber',
          ],
          calcFunc: function() {
            if ($scope.lease.tenantInfo) {
              if ($scope.lease.hasPostAddressSameAsPre) {
                $scope.lease.tenantInfo.postLeasePhoneNumber =
                  $scope.lease.tenantInfo.preLeasePhoneNumber;
              }
            }
          },
        },
        'lease.tenantInfo.postLeaseEmail': {
          dependsOn: [
            'lease.hasPostAddressSameAsPre',
            'lease.tenantInfo.preLeaseEmail',
          ],
          calcFunc: function() {
            if ($scope.lease.tenantInfo) {
              if ($scope.lease.hasPostAddressSameAsPre) {
                $scope.lease.tenantInfo.postLeaseEmail =
                  $scope.lease.tenantInfo.preLeaseEmail;
              }
            }
          },
        },
        'lease.tenantInfo.postLeaseAttention': {
          dependsOn: [
            'lease.hasPostAddressSameAsPre',
            'lease.tenantInfo.preLeaseAttention',
          ],
          calcFunc: function() {
            if ($scope.lease.tenantInfo) {
              if ($scope.lease.hasPostAddressSameAsPre) {
                $scope.lease.tenantInfo.postLeaseAttention =
                  $scope.lease.tenantInfo.preLeaseAttention;
              }
            }
          },
        },
        'lease.remainingMonthlyRent': {
          dependsOn: [
            'rentValues',
            'lease.baseRent',
            'lease.annualIncrease',
            'lease.freeRentPeriods.beginIn',
            'lease.freeRentPeriods.duration',
            'lease.freeRentPeriods.amount',
            'lease.rentBumps.begin',
            'lease.rentBumps.duration',
            'lease.rentBumps.rentPerSqFoot',
            'lease.expansion.rentBumps.begin',
            'lease.expansion.rentBumps.duration',
            'lease.expansion.rentBumps.rentPerSqFoot',
            'lease.relocation.rentBumps.begin',
            'lease.relocation.rentBumps.duration',
            'lease.relocation.rentBumps.rentPerSqFoot',
            'lease.reduction.rentBumps.begin',
            'lease.reduction.rentBumps.duration',
            'lease.reduction.rentBumps.rentPerSqFoot',
            'lease.rentTableType',
            'lease.hasExtensionOfTerm',
          ],
          calcFunc: function() {
            calcRemainingMonthlyRentPerTerm($scope.lease);
          },
        },
        'lease.terms.remainingMonthlyRent': {
          dependsOn: [
            'rentValues',
            'lease.baseRent',
            'lease.annualIncrease',
            'lease.terms.freeRentPeriods.beginIn',
            'lease.terms.freeRentPeriods.duration',
            'lease.terms.freeRentPeriods.amount',
            'lease.terms.rentBumps.begin',
            'lease.terms.rentBumps.duration',
            'lease.terms.rentBumps.rentPerSqFoot',
          ],
          calcFunc: function() {
            if ($scope.lease.terms) {
              for (
                var termIndex = 0;
                termIndex < $scope.lease.terms.length;
                termIndex++
              ) {
                calcRemainingMonthlyRentPerTerm($scope.lease.terms[termIndex]);
              }
            }
          },
        },
        'lease.payableMonthlyRent': {
          dependsOn: [
            'rentValues',
            'lease.baseRent',
            'lease.annualIncrease',
            'lease.rentPeriods.rentPerSqFoot',
            'lease.rentPeriods.length',
            'lease.freeRentPeriods.beginIn',
            'lease.freeRentPeriods.duration',
            'lease.freeRentPeriods.amount',
            'lease.freeRentPeriods.length',
            'lease.rentBumps.begin',
            'lease.rentBumps.duration',
            'lease.rentBumps.rentPerSqFoot',
            'lease.rentBumps.length',
            'lease.expansion.rentBumps.begin',
            'lease.expansion.rentBumps.duration',
            'lease.expansion.rentBumps.rentPerSqFoot',
            'lease.expansion.rentBumps.length',
            'lease.relocation.rentBumps.begin',
            'lease.relocation.rentBumps.duration',
            'lease.relocation.rentBumps.rentPerSqFoot',
            'lease.relocation.rentBumps.length',
            'lease.reduction.rentBumps.begin',
            'lease.reduction.rentBumps.duration',
            'lease.reduction.rentBumps.rentPerSqFoot',
            'lease.reduction.rentBumps.length',
            'lease.rentTableType',
            'lease.hasExtensionOfTerm',
          ],
          calcFunc: function() {
            calcRemainingMonthlyRentPerTerm($scope.lease);
          },
        },
        'lease.terms.payableMonthlyRent': {
          dependsOn: [
            'rentValues',
            'lease.baseRent',
            'lease.annualIncrease',
            'lease.rentPeriods.length',
            'lease.rentPeriods.rentPerSqFoot',
            'lease.terms.freeRentPeriods.beginIn',
            'lease.terms.freeRentPeriods.duration',
            'lease.terms.freeRentPeriods.amount',
            'lease.terms.freeRentPeriods.length',
            'lease.terms.rentBumps.begin',
            'lease.terms.rentBumps.duration',
            'lease.terms.rentBumps.rentPerSqFoot',
            'lease.terms.rentBumps.length',
          ],
          calcFunc: function() {
            if ($scope.lease.terms) {
              for (
                var termIndex = 0;
                termIndex < $scope.lease.terms.length;
                termIndex++
              ) {
                calcRemainingMonthlyRentPerTerm($scope.lease.terms[termIndex]);
              }
            }
          },
        },
        'lease.totalAbatedBaseRent': {
          dependsOn: [
            'lease.freeRentPeriods.isFree',
            'lease.freeRentPeriods.hardStartDate',
            'lease.freeRentPeriods.hardEndDate',
            'lease.freeRentPeriods.softStartDate',
            'lease.freeRentPeriods.softEndDate',
            'lease.freeRentPeriods.beginIn',
            'lease.freeRentPeriods.duration',
            'lease.freeRentPeriods.percentFree',
            'lease.freeRentPeriods.amount',
            'lease.freeRentPeriods.length',
            'lease.terms.freeRentPeriods.isFree',
            'lease.terms.freeRentPeriods.hardStartDate',
            'lease.terms.freeRentPeriods.hardEndDate',
            'lease.terms.freeRentPeriods.softStartDate',
            'lease.terms.freeRentPeriods.softEndDate',
            'lease.terms.freeRentPeriods.beginIn',
            'lease.terms.freeRentPeriods.duration',
            'lease.terms.freeRentPeriods.percentFree',
            'lease.terms.freeRentPeriods.amount',
            'lease.terms.freeRentPeriods.length',
            'lease.rentInputType',
            'lease.rentPeriods.rentPerSqFoot',
            'lease.rentBumps.rentPerSqFoot',
            'lease.expansion.rentBumps.rentPerSqFoot',
            'lease.reduction.rentBumps.rentPerSqFoot',
            'lease.relocation.rentBumps.rentPerSqFoot',
            'lease.rentPeriods.totalRent',
            'lease.premisesModType',
            'lease.premiseAreaSize',
            'lease.rentTableType',
            'lease.hasExtensionOfTerm',
            'lease.expansionPremiseAreaSize',
            'lease.reductionRemainingAreaSize',
            'lease.relocationPremisesAreaSize',
            'rentValues',
          ],
          calcFunc: function() {
            let totalAbatedBaseRent = 0;
            var rentPeriodsInMonths = getRentPeriodsInMonths();

            var freeRentPeriods = $scope.isMultipleTerms
              ? _.filter(_.flatMap($scope.lease.terms, 'freeRentPeriods'), null)
              : $scope.lease.freeRentPeriods;
            for (let i = 0; i < freeRentPeriods.length; i++) {
              let currAbatement = 0;

              if (freeRentPeriods[i].amount !== undefined) {
                currAbatement = freeRentPeriods[i].amount * freeRentPeriods[i].duration;
              } else if (
                freeRentPeriods[i].beginIn !== undefined &&
                freeRentPeriods[i].duration !== undefined &&
                freeRentPeriods[i].amount === undefined)
              {
                let endIn = freeRentPeriods[i].beginIn + freeRentPeriods[i].duration;
                let percentFree = freeRentPeriods[i].percentFree ? freeRentPeriods[i].percentFree / 100 : 1;
                const areaSize = getFreeRentAreaSize();
                for (var j = freeRentPeriods[i].beginIn; j < endIn; j++) {
                  if (rentPeriodsInMonths[j - 1]) {
                    if (rentPeriodsInMonths[j - 1].rentPerSqFoot) {
                      var abatement = ((rentPeriodsInMonths[j - 1].rentPerSqFoot * areaSize) / 12) * percentFree;
                      totalAbatedBaseRent += abatement;
                    } else if (rentPeriodsInMonths[j - 1].totalRent) {
                      var abatement = rentPeriodsInMonths[j - 1].totalRent * percentFree;
                      totalAbatedBaseRent += abatement;
                    }
                  }
                }

              } else if (freeRentPeriods[i].percentFree === undefined && freeRentPeriods[i].amount === undefined) {
                if (freeRentPeriods[i].isFree) {
                  let duration = freeRentPeriods[i].softEndDate - freeRentPeriods[i].softStartDate + 1;
                  let amount = $scope.lease.rentPeriods[i].totalRent;
                  currAbatement = amount * (duration / 12);
                }
              }
              totalAbatedBaseRent += currAbatement;
            }

            $scope.lease.totalAbatedBaseRent = totalAbatedBaseRent;
          },
        },
        'lease.renewalRentFloor': {
          dependsOn: [
            'lease.rentBumps.length',
            'lease.rentBumps.rentPerSqFoot',
            'lease.terms.rentBumps.length',
            'lease.terms.rentBumps.rentPerSqFoot',
          ],
          calcFunc: function() {
            if ($scope.isMultipleTerms) {
              if ($scope.terms && $scope.terms.length) {
                var lastTerm =
                  _.findLast($scope.lease.terms, function(t) {
                    return t.rentInputType == 'flexible';
                  }) || {};
                $scope.lease.renewalRentFloor = lastTerm.rentPerSqFoot;
              }
            } else if ($scope.lease.rentBumps) {
              if ($scope.lease.rentBumps.length > 0) {
                $scope.lease.renewalRentFloor =
                  $scope.lease.rentBumps[
                    $scope.lease.rentBumps.length - 1
                  ].rentPerSqFoot;
              }
            }
          },
        },
        'lease.calc.hasRenewalRentFMV': {
          dependsOn: [
            'lease.renewalInfo.length',
            'lease.renewalInfo.renewalOptionRent'
          ],
          calcFunc: function() {
            $scope.lease.calc.hasRenewalRentFMV = false;

            if ($rootScope.hasEditorConfig("renewalRent", "rentOptionTypePerRenewalTerm")) {
              $scope.lease.calc.hasRenewalRentFMV = $scope.lease.renewalInfo.filter(renewalInfo => renewalInfo.renewalOptionRent === "fmv").length > 0
            }
          }
        },
        'lease.calc.rentBumpsAverages': {
          dependsOn: [
            'lease.premiseAreaSize',
            'lease.leaseYears',
            'lease.leaseMonths',
            'lease.rentBumps.length',
            'lease.rentBumps.duration',
            'lease.rentBumps.rentPerSqFoot',
            'lease.hasPercentageRent',
            'lease.percentageRent',
          ],
          calcFunc: function() {
            const result = [];

            if ($scope.lease.hasPercentageRent) {
              let rentBumpsInMonths = [];
              let rentBumpsInYears = [];
              let rentBump;
  
              for (let rentBumpIndex = 0; rentBumpIndex < $scope.lease.rentBumps.length; rentBumpIndex++) {
                rentBump = $scope.lease.rentBumps[rentBumpIndex];
                
                for (let month = 0; month < rentBump.duration; month++) {
                  rentBumpsInMonths.push(rentBump.rentPerSqFoot);
                }
              }
  
              let year = 0;
              while (rentBumpsInMonths.length > 0) {
                rentBumpsInYears[year++] = rentBumpsInMonths.slice(0, 12);
                rentBumpsInMonths = rentBumpsInMonths.slice(12);
              }
  
              let average = 0;
              for (let yearIndex = 0; yearIndex < rentBumpsInYears.length; yearIndex++) {
                average = rentBumpsInYears[yearIndex].reduce((a,b) => a+b) / rentBumpsInYears[yearIndex].length;
                result.push({
                  averagePSF: average,
                });
              }
            }

            $scope.lease.calc.rentBumpsAverages = result;
          },
        },
        'lease.calc.renewalRentBumpsAverages': {
          dependsOn: [
            'lease.premiseAreaSize',
            'lease.renewalCount',
            'lease.renewalTerm',
            'lease.renewalInfo.renewalInputType',
            'lease.renewalRentBumps.length',
            'lease.renewalRentBumps.periods.length',
            'lease.renewalRentBumps.periods.duration',
            'lease.renewalRentBumps.periods.rentPerSqFoot',
            'lease.hasPercentageRent',
            'lease.percentageRent',
          ],
          calcFunc: function() {
            const result = [];

            if ($scope.lease.hasPercentageRent) {
              for (let renewalRentBumpIndex = 0; renewalRentBumpIndex < $scope.lease.renewalRentBumps.length; renewalRentBumpIndex++) {
                let renewalResult = [];
                let renewalRentBumpsInMonths = [];
                let renewalRentBumpsInYears = [];
                let renewalRentBump;

                for (let renewalRentBumpPeriod = 0; renewalRentBumpPeriod < $scope.lease.renewalRentBumps[renewalRentBumpIndex].periods.length; renewalRentBumpPeriod++) {
                  renewalRentBump = $scope.lease.renewalRentBumps[renewalRentBumpIndex].periods[renewalRentBumpPeriod];
                  
                  for (let month = 0; month < renewalRentBump.duration; month++) {
                    renewalRentBumpsInMonths.push(renewalRentBump.rentPerSqFoot);
                  }
                }

                let year = 0;
                while (renewalRentBumpsInMonths.length > 0) {
                  renewalRentBumpsInYears[year++] = renewalRentBumpsInMonths.slice(0, 12);;
                  renewalRentBumpsInMonths = renewalRentBumpsInMonths.slice(12);
                }
    
                let average = 0;
                for (let yearIndex = 0; yearIndex < renewalRentBumpsInYears.length; yearIndex++) {
                  average = renewalRentBumpsInYears[yearIndex].reduce((a,b) => a+b) / renewalRentBumpsInYears[yearIndex].length;
                  renewalResult.push({
                    averagePSF: average,
                  });
                }

                result.push({
                  periods: renewalResult,
                });
              }
            }

            $scope.lease.calc.renewalRentBumpsAverages = result;
          },
        },
        'lease.calc.totalRenewalInfo': {
          dependsOn: [
            'lease.renewalRows',
          ],
          calcFunc: function() {
            const result = [];

            if ($scope.lease.hasRenewalOption) {
              let current = null;

              for (let i = 0; i < $scope.lease.renewalInfo.length; i++) {
                for (let j = 0; j < $scope.lease.renewalInfo[i].renewalRentPeriods.length; j++) {
                  current = {
                    ...$scope.lease.renewalInfo[i],
                    ...$scope.lease.renewalInfo[i].renewalRentPeriods[j],
                  };

                  delete current.renewalRentPeriods;

                  result.push(current)
                }
              }
            }

            $scope.lease.calc.totalRenewalInfo = result;
          },
        },
        'lease.reducedSDAmounts': {
          dependsOn: [
            'reductionValues',
            'lease.leaseYears',
            'lease.reductionPeriodsNumber',
            'lease.listOfLeaseYears',
            'lease.hasSecurityDeposit',
            'lease.hasSecurityDepositBurndown',
            'lease.reductionPeriods.year',
            'lease.reductionPeriods.amountReduced',
            'lease.securityDeposit',
          ],
          calcFunc: function() {
            const lease = $scope.lease;
            const rentReductions = [];

            if (lease.reductionPeriods) {
              lease.reductionPeriods.forEach(period => {
                if (period.year) {
                  rentReductions[period.year] = parseFloat(period.amountReduced);
                }
              });
            }
            
            lease.rentPeriods.forEach((period, index) => {
              period.reducedSDAmount = null;

              if (lease.hasSecurityDeposit && lease.securityDeposit) {
                if (index === 0) {
                  period.reducedSDAmount = parseFloat(lease.securityDeposit);
                } else {
                  if (rentReductions[index]) {
                    period.reducedSDAmount = rentReductions[index];
                  } else {
                    period.reducedSDAmount = lease.rentPeriods[index-1].reducedSDAmount;
                  }
                }
              }
            });

            $scope.lease.reducedSDAmounts = lease.rentPeriods;
          }
        },
        totalRentBumpsInMonths: {
          dependsOn: [
            'lease.leaseYears',
            'lease.leaseMonths',
            'lease.rentBumps.length',
            'lease.rentBumps.duration',
            'rentValues',
          ],
          calcFunc: function() {
            if ($scope.lease.rentBumps) {
              $scope.totalRentBumpsInMonths = $scope.getRentBumpsInMonths(
                $scope.lease.rentBumps,
              );
            }
          },
        },
        totalRenewalRentBumpsInMonths: {
          dependsOn: [
            'lease.renewalCount',
            'lease.renewalTerm',
            'lease.renewalInfo.renewalInputType',
            'lease.renewalRentBumps.length',
            'lease.renewalRentBumps.periods.length',
            'lease.renewalRentBumps.periods.duration',
            'rentValues',
          ],
          calcFunc: function() {
            let totalRenewalRentBumpsInMonths = [];
            if ($scope.lease.renewalRentBumps) {
              for (let i = 0; i < $scope.lease.renewalRentBumps.length; i++) {
                totalRenewalRentBumpsInMonths.push(
                  $scope.getRentBumpsInMonths(
                    $scope.lease.renewalRentBumps[i].periods,
                  ),
                );
              }
            }

            $scope.totalRenewalRentBumpsInMonths = totalRenewalRentBumpsInMonths;
          },
        },
        'terms.totalRentBumpsInMonths': {
          dependsOn: [
            'lease.leaseYears',
            'lease.leaseMonths',
            'lease.terms.rentBumps.length',
            'lease.terms.rentBumps.duration',
            'rentValues',
          ],
          calcFunc: function() {
            for (let i = 0; i < $scope.lease.terms.length; i++) {
              var term = $scope.lease.terms[i];
              if (term.rentBumps) {
                term.totalRentBumpsInMonths = $scope.getRentBumpsInMonths(
                  term.rentBumps,
                );
              }
            }
          },
        },
        'lease.numberOfMustTakeSpaces': {
          dependsOn: [
            'lease.terms.length',
            'lease.terms.hasMustTakeSpace',
          ],
          calcFunc: function() {
            $scope.lease.numberOfMustTakeSpaces = 0;

            if ($scope.lease.terms) {
              for (let i = 0; i < $scope.lease.terms.length; i++) {
                var term = $scope.lease.terms[i];
                if (term.hasMustTakeSpace) {
                  $scope.lease.numberOfMustTakeSpaces++;
                }
              }
            }
          },
        },
        "lease.totalMonthsFreeRent": {
          dependsOn: [
            "lease.freeRentPeriods.length",
            "lease.freeRentPeriods.softStartDate",
            "lease.freeRentPeriods.softEndDate",
            "lease.freeRentPeriods.duration",
            "lease.freeRent",
          ],
          calcFunc: function() {
            let result = 0;

            if ($rootScope.hasEditorConfig("freeRent", "flexible")) {
              if ($scope.lease.freeRentPeriods && $scope.lease.freeRentPeriods.length !== 0) {
                for (var i = 0; i < $scope.lease.freeRentPeriods.length; i++) {
                  let record = $scope.lease.freeRentPeriods[i];
                  result += record.duration;
                }
              }
            }

            if ($rootScope.hasEditorConfig("freeRent", "multiplePeriods")) {
              if ($scope.lease.freeRentPeriods && $scope.lease.freeRentPeriods.length !== 0) {
                for (var i = 0; i < $scope.lease.freeRentPeriods.length; i++) {
                  let record = $scope.lease.freeRentPeriods[i];
                  result += record.softEndDate - record.softStartDate + 1;
                }
              }
            }

            if ($rootScope.hasEditorConfig("freeRent", "months")) {
              result = $scope.lease.freeRent;
            }

            $scope.lease.totalMonthsFreeRent = result;
          }
        },
        "lease.totalMonthsFreeRentAddedToTerm": {
          dependsOn: [
            "lease.hasFreeRentAddedToTerm",
            "lease.totalMonthsFreeRent",
          ],
          calcFunc: function() {
            let result = 0;

            if ($scope.lease.hasFreeRentAddedToTerm) {
              result = $scope.lease.totalMonthsFreeRent;
            }

            $scope.lease.totalMonthsFreeRentAddedToTerm = result;
          }
        },
        totalTermLength: {
          dependsOn: [
            'lease.leaseYears',
            'lease.leaseMonths',
            'lease.totalMonthsFreeRentAddedToTerm'
          ],
          calcFunc: function() {
            let totalTermLength = getTermLength();
            $scope.totalTermLength = totalTermLength + $scope.lease.totalMonthsFreeRentAddedToTerm;
          },
        },

        totalRenewalTermLength: {
          dependsOn: ['lease.renewalCount', 'lease.renewalTerm'],
          calcFunc: function() {
            let totalRenewalTermLength = 0;

            if ($scope.lease.renewalTerm) {
              totalRenewalTermLength = $scope.lease.renewalTerm * 12;
            }

            $scope.totalRenewalTermLength = totalRenewalTermLength;
          },
        },
        'lease.numberOfTenantAssignments': {
          dependsOn: [
            'lease.priorAssignments.length',
            'lease.priorAssignments.type',
          ],
          calcFunc: function() {
            $scope.lease.numberOfTenantAssignments = _.countBy($scope.lease.priorAssignments, 'type').tenant || 0;
          },
        },
        'lease.priorTenantAssignments': {
          dependsOn: [
            'lease.priorAssignments.length',
            'lease.priorAssignments.type',
            'lease.priorAssignments.assignor',
            'lease.priorAssignments.date',
          ],
          calcFunc: function() {
            $scope.lease.priorTenantAssignments = $scope.lease.priorAssignments.filter(assignment => !assignment.type || assignment.type === 'tenant');
          },
        },
        'lease.numberOfLandlordAssignments': {
          dependsOn: [
            'lease.priorAssignments.length',
            'lease.priorAssignments.type',
          ],
          calcFunc: function() {
            $scope.lease.numberOfLandlordAssignments = _.countBy($scope.lease.priorAssignments, 'type').landlord || 0;
          },
        },
        'lease.priorLandlordAssignments': {
          dependsOn: [
            'lease.priorAssignments.length',
            'lease.priorAssignments.type',
            'lease.priorAssignments.assignor',
            'lease.priorAssignments.date',
          ],
          calcFunc: function() {
            $scope.lease.priorLandlordAssignments = $scope.lease.priorAssignments.filter(assignment => assignment.type === 'landlord');
          },
        },
        "lease.calc.freeRentSpecificMonths": {
          dependsOn: [
            "lease.termCommencementDate",
            "lease.existingLeaseExpirationDate",
            "lease.freeRentPeriods.length",
            "lease.freeRentPeriods.duration",
            "lease.freeRentPeriods.beginIn",
          ],
          calcFunc: function() {
            let anchorDate;

            if ($scope.lease.type === 'Lease') {
              anchorDate = $scope.lease.termCommencementDate;

              // If not the first day of the month
              if (moment(anchorDate).date() > 1) {
                anchorDate = $scope.addMonths(anchorDate, 1);
              }
            } else if ($scope.lease.type === 'Amendment') {
              anchorDate = $scope.addDays($scope.lastDayOfMonth($scope.lease.existingLeaseExpirationDate), 1);
            }

            const result = [];

            for (let i = 0; i < $scope.lease.freeRentPeriods.length; i++) {
              let freeRentPeriodSpecificMonths = [];

              for (let j = 0; j < $scope.lease.freeRentPeriods[i].duration; j++) {
                let freeRentDate = null;

                if (anchorDate) {
                  freeRentDate = moment(anchorDate).add($scope.lease.freeRentPeriods[i].beginIn + j - 1, 'months');

                  freeRentPeriodSpecificMonths.push({
                    date: freeRentDate.toDate(),
                    month: freeRentDate.format('MMMM'),
                    year: parseInt(freeRentDate.format('YYYY')),
                  })
                } else {
                  freeRentPeriodSpecificMonths.push({
                    date: null,
                    month: null,
                    year: null,
                  })
                }
              }

              result.push(freeRentPeriodSpecificMonths);
            }

            $scope.lease.calc.freeRentSpecificMonths = result;
          }
        },
        "lease.calc.effectiveCommencementDate": {
          dependsOn: [
            "lease.tenantImprovements.type",
            "lease.termCommencementDate",
            "lease.estimatedCommencementDate",
            "lease.terms.termCommencementDate",
            "lease.terms.estimatedCommencementDate",
            "lease.rentCommenceAt"
          ],
          calcFunc: function() {
            if ($scope.lease.type === 'Lease') {
              var effectiveCommencementDate;
              var currentCommencementDate;
              var currTerm = null;

              if ($scope.lease.tenantImprovements && $scope.lease.tenantImprovements.type) {
                for (let i = 0; i < $scope.lease.terms.length; ++i) {
                  currTerm = $scope.lease.terms[i];
                  if ($rootScope.hasEditorConfig('commencementDate')) {
                    effectiveCommencementDate = currTerm.termCommencementDate;
                  } else {
                      switch ($scope.lease.tenantImprovements.type) {
                        case 'landlord':
                          currentCommencementDate = currTerm.estimatedCommencementDate;
                          break;
                        case 'tenant':
                          currentCommencementDate = currTerm.estimatedCommencementDate;
                          break;
                        case 'none':
                          currentCommencementDate = currTerm.termCommencementDate;
                          break;
                      }
                      if (!effectiveCommencementDate ||  moment(currentCommencementDate).isBefore(effectiveCommencementDate)) {
                        effectiveCommencementDate = currentCommencementDate;
                      }
                  }
                }
              } else if ($scope.lease.rentCommenceAt) {
                effectiveCommencementDate = $scope.lease.rentCommenceAt;
              }

              if (effectiveCommencementDate) {
                effectiveCommencementDate.setHours(12);
              }
              $scope.lease.calc.effectiveCommencementDate = effectiveCommencementDate;
              $scope.update();

              return effectiveCommencementDate;
            }
          }
        },
      },

      calc: function(field) {
        var prevValue = $parse(field)($scope);
        var updated = this.calculatedFields[field].calcFunc();
        var currentValue = $parse(field)($scope);
        if (updated || !_.isEqual(prevValue, currentValue)) {
          $timeout(function() {
            $scope.highlightItem(field, null, true);
          }, 0);

          this.updateDependencies(field);
        }
      },

      updateDependencies: function(changedField) {
        var that = this;
        changedField = changedField.replace(/\[[\d]+\]/g, '');

        _.each(that.calculatedFields, function(val, field) {
          if (_.includes(val.dependsOn, changedField)) {
            that.calc(field);
            $scope.highlightItem(field);
          }
        });
      },

      init: function() {
        var calculatedFields = this.calculatedFields;
        _.each(calculatedFields, function(val, key) {
          val.calcFunc();
        });
      },
    };

    // move old guarantors structure to the new structure
    const initGuarantorsFromOldObject = function() {
      if ($scope.lease.guarantorInfo.type == 'individual') {
        if (
          $scope.lease.guarantorInfo.marriedCouple &&
          !$scope.lease.guarantorInfo.marriedCouples
        ) {
          $scope.lease.guarantorInfo.marriedCouples = [
            _.merge(
              _.mapKeys($scope.lease.guarantorInfo.guarantors[0], function(
                value,
                key,
              ) {
                return key + '1';
              }),
              _.mapKeys($scope.lease.guarantorInfo.guarantors[1], function(
                value,
                key,
              ) {
                return key + '2';
              }),
            ),
          ];
        } else if (!$scope.lease.guarantorInfo.individuals) {
          $scope.lease.guarantorInfo.individuals = _.clone(
            $scope.lease.guarantorInfo.guarantors,
          );
        }
      } else if (!$scope.lease.guarantorInfo.entities) {
        $scope.lease.guarantorInfo.entities = [
          _.merge($scope.lease.guarantorInfo.guarantors[0], {
            stateOfFormation: $scope.lease.guarantorInfo.stateOfFormation,
            entityType: $scope.lease.guarantorInfo.entityType,
          }),
        ];
      }
    };

    $scope.initCombinedGuarantorEntities = function(context) {
      var i;

      if (context) {
        if (context.entities) {
          for (i in context.entities) {
            var entity = context.entities[i];
            $scope.setCombinedGuarantorEntity(entity);
          }
        }
        if (context.individuals) {
          for (i in context.individuals) {
            var individual = context.individuals[i];
            $scope.setCombinedGuarantorEntity(individual);
          }
        }
        if (context.marriedCouples) {
          for (i in context.marriedCouples) {
            var marriedCouple = context.marriedCouples[i];
            $scope.setCombinedGuarantorEntity(marriedCouple);
          }
        }
      }
    };

    $scope.setCombinedGuarantorEntity = function(guarantor) {
      if (guarantor) {
        if (guarantor.entityType === 'Other') {
          guarantor.entityTypeCombined = guarantor.entityTypeOther;
        } else {
          guarantor.entityTypeCombined = guarantor.entityType;
        }
      }
    };

    $scope.$watch('lease.estimatedCommencementDate', function() {
      $scope.setCalculatedExpirationDate();
    });

    $scope.$watch(
      'lease.existingGuarantorInfo',
      function(newValue, oldValue) {
        if (newValue) {
          // Maintain the `numberOfContinuingGuarantors`
          $scope.numberOfContinuingGuarantors = _.filter(
            _.union(
              $scope.lease.existingGuarantorInfo.individuals || [],
              $scope.lease.existingGuarantorInfo.marriedCouples || [],
              $scope.lease.existingGuarantorInfo.entities || [],
            ),
            {
              hasBeenReleased: false,
            },
          ).length;
        }
      },
      true,
    );

    $scope.$watch(
      'lease.tenantInfo',
      function(newValue, oldValue) {
        if ($scope.lease) {
          if ($scope.lease.hasSameBillingAddress) {
            $scope.lease.tenantInfo.billingAddress1 =
              $scope.lease.tenantInfo.address1;
            $scope.lease.tenantInfo.billingAddressCity =
              $scope.lease.tenantInfo.addressCity;
            $scope.lease.tenantInfo.billingAddressState =
              $scope.lease.tenantInfo.addressState;
            $scope.lease.tenantInfo.billingAddressZip =
              $scope.lease.tenantInfo.addressZip;
            $scope.lease.tenantInfo.billingPhoneNumber =
              $scope.lease.tenantInfo.phoneNumber;
            $scope.lease.tenantInfo.billingAttention =
              $scope.lease.tenantInfo.attention;
          }
        }
      },
      true,
    );

    $scope.$watch(
      'lease.guarantorInfo.marriedCouples',
      function(newValue, oldValue) {
        if (newValue) {
          var marriedCouple = newValue;

          for (var i = 0; i < marriedCouple.length; i++) {
            var item = marriedCouple[i];

            if (item.hasSameAddress) {
              item.address2 = item.address1;
              item.city2 = item.city1;
              item.state2 = item.state1;
              item.zip2 = item.zip1;

              if ($rootScope.hasEditorConfig("guarantors", "copyPhoneNumberForSpouse")) {
                item.phone2 = item.phone1;
              }
              if ($rootScope.hasEditorConfig("guarantors", "copyEmailForSpouse")) {
                item.email2 = item.email1;
              }
            }
          }
        }
      },
      true,
    );

    $scope.$watch('lease.tenantInfo.entityType', function(newValue, oldValue) {
      if (typeof newValue != 'undefined') {
        if (newValue === 'Other')
          $scope.lease.tenantInfo.entityTypeCombined =
            $scope.lease.tenantInfo.entityTypeOther;
        else $scope.lease.tenantInfo.entityTypeCombined = newValue;
      }
    });

    $scope.$watch('lease.tenantInfo.entityTypeOther', function(
      newValue,
      oldValue,
    ) {
      if (typeof newValue != 'undefined') {
        if ($scope.lease.tenantInfo.entityType === 'Other')
          $scope.lease.tenantInfo.entityTypeCombined = newValue;
      }
    });

    $scope.$watch('lease.franchisorInfo.entityType', function(
      newValue,
      oldValue,
    ) {
      if (typeof newValue != 'undefined') {
        if (newValue === 'Other')
          $scope.lease.franchisorInfo.entityTypeCombined =
            $scope.lease.franchisorInfo.entityTypeOther;
        else $scope.lease.franchisorInfo.entityTypeCombined = newValue;
      }
    });

    $scope.$watch('lease.franchisorInfo.entityTypeOther', function(
      newValue,
      oldValue,
    ) {
      if (typeof newValue != 'undefined') {
        if ($scope.lease.franchisorInfo.entityType === 'Other')
          $scope.lease.franchisorInfo.entityTypeCombined = newValue;
      }
    });

    $scope.$watch('lease.guarantorInfo.entityType', function(
      newValue,
      oldValue,
    ) {
      if (typeof newValue != 'undefined') {
        if (newValue === 'Other')
          $scope.lease.guarantorInfo.entityTypeCombined =
            $scope.lease.franchisorInfo.entityTypeOther;
        else $scope.lease.guarantorInfo.entityTypeCombined = newValue;
      }
    });

    $scope.$watch('lease.guarantorInfo.entityTypeOther', function(
      newValue,
      oldValue,
    ) {
      if (typeof newValue != 'undefined') {
        if ($scope.lease.guarantorInfo.entityType === 'Other')
          $scope.lease.guarantorInfo.entityTypeCombined = newValue;
      }
    });

    $scope.$watch('lease.warranties.length', function(newValue, oldValue) {
      if (typeof newValue != 'undefined') {
        $timeout(function() {
          $scope.highlightItem('warrantyText()[1]');
        }, 0);
      }
    });

    $scope.$watch('lease.hasReleaseGuarantors', function(newValue, oldValue) {
      if (
        typeof oldValue != 'undefined' &&
        typeof newValue != 'undefined'
      ) {
        $timeout(function() {
          $scope.highlightItem('lease.hasReleaseGuarantors');
        }, 0);
      }
    });

    $scope.$watch('lease.rentPeriods.length', function(newValue, oldValue) {
      if (
        typeof oldValue != 'undefined' &&
        typeof newValue != 'undefined'
      ) {
        $timeout(function() {
          $scope.highlightItem('lease.rentPeriods.length');
        }, 0);
      }
    });

    $scope.$watch('lease.renewalInfo[0].cpiAnchorYears', function(
      newValue,
      oldValue,
    ) {
      if (
        typeof oldValue != 'undefined' &&
        typeof newValue != 'undefined'
      ) {
        $timeout(function() {
          $scope.highlightItem('renewal.cpiAnchorYears');
        }, 0);
      }
    });

    $scope.$watch('lease.reductionPeriodsNumber', function(newValue, oldValue) {
      if (!$scope.isLoading) {
        if (newValue > 50) {
          $scope.lease.reductionPeriodsNumber = 50;
        }
  
        setTimeout(function() {
          if (
            typeof oldValue != 'undefined' &&
            typeof newValue != 'undefined'
          ) {
            // 
            $scope.structureChanged();
            $scope.highlightItem('lease.reductionPeriodsNumber');
          }
        }, 1);
      }
    });

    /* ================================= */

    function updateNumberOfRentPeriodsPerTerm(term, numberOfYears) {
      if (!term.rentPeriods) {
        term.rentPeriods = [];
      }

      // Update the collection of rent periods accordingly
      if (term.rentPeriods.length < numberOfYears) {
        for (var i = term.rentPeriods.length; i < numberOfYears; i++) {
          term.rentPeriods.push({});
        }
      } else {
        var count = term.rentPeriods.length - numberOfYears;
        term.rentPeriods.splice(-count, count);
      }
    }

    $scope.updateNumberOfRentPeriods = function(numberOfYears, numberOfMonth) {
      // Limit number of terms and number of years to 50, to prevent massive DB entries
      numberOfYears = Math.min(numberOfYears, 50);
      if (
        (($rootScope.optionalExtensionTermLengthConfig &&
        $rootScope.optionalExtensionTermLengthConfig.fields.addRentRowForPartialYear) ||
        ($rootScope.termLengthConfig &&
        $rootScope.termLengthConfig.fields.addRentRowForPartialYear)) &&
        numberOfMonth != 0 &&
        numberOfYears != 50 &&
        numberOfYears > 0
      ) {
        numberOfYears++;
      }
      numberOfYears =
        numberOfYears == 0 && numberOfMonth > 0 ? 1 : numberOfYears;
      $scope.lease.leaseYearsCalculated = numberOfYears;
      $scope.lease.calc.rentPeriods = Array.apply(null, Array(numberOfYears)).map((x, i) => i);

      // create rent periods if it does not exist

      if ($scope.isMultipleTerms) {
        for (
          var termIndex = 0;
          termIndex < $scope.lease.terms.length;
          termIndex++
        ) {
          updateNumberOfRentPeriodsPerTerm(
            $scope.lease.terms[termIndex],
            numberOfYears,
          );
        }
      } else {
        updateNumberOfRentPeriodsPerTerm($scope.lease, numberOfYears);
      }

      // Also, update the number of reduction periods accordingly
      var listOfLeaseYears = [];
      var listOfLeaseYearsWords = {};
      let listOfLeaseYearsLength;

      if ($rootScope.hasEditorConfig("optionalExtensionTermLength")) {
        listOfLeaseYearsLength = 20;
      } else {
        listOfLeaseYearsLength = $scope.lease.leaseYears || 0;
      }

      for (var i = 0; i < listOfLeaseYearsLength; i++) {
        listOfLeaseYears.push(i + 1);
        listOfLeaseYearsWords[i + 1] = 'Year ' + (i + 1);
      }

      $scope.lease.listOfLeaseYears = listOfLeaseYears;
      $scope.lease.listOfLeaseYearsWords = listOfLeaseYearsWords;
    };

    $scope.updateHasSecurityDepositBurndown = function() {
      if ($scope.lease.hasSecurityDepositBurndown) {
        if (
          !$scope.lease.reductionPeriods ||
          ($scope.lease.reductionPeriods &&
            $scope.lease.reductionPeriods.length === 0)
        ) {
          $scope.lease.reductionPeriodsNumber = 1;
          $scope.lease.reductionPeriods = [{}];
        }
      } else {
        $scope.lease.reductionPeriodsNumber = 0;
        $scope.lease.reductionPeriods = [];
      }
    };

    $scope.updateHasCashBurndown = function() {
      if ($scope.lease.security.hasCashBurndown) {
        if (
          !$scope.lease.security.cashBurndownPeriods ||
          ($scope.lease.security.cashBurndownPeriods &&
            $scope.lease.security.cashBurndownPeriods.length === 0)
        ) {
          $scope.lease.security.cashBurndownPeriodsNumber = 1;
          $scope.lease.security.cashBurndownPeriods = [{}];
        }
      } else {
        $scope.lease.security.cashBurndownPeriodsNumber = 0;
        $scope.lease.security.cashBurndownPeriods = [];
      }
    };

    $scope.updateHasLocBurndown = function() {
      if ($scope.lease.security.hasLocBurndown) {
        if (
          !$scope.lease.security.locBurndownPeriods ||
          ($scope.lease.security.locBurndownPeriods &&
            $scope.lease.security.locBurndownPeriods.length === 0)
        ) {
          $scope.lease.security.locBurndownPeriodsNumber = 1;
          $scope.lease.security.locBurndownPeriods = [{}];
        }
      } else {
        $scope.lease.security.locBurndownPeriodsNumber = 0;
        $scope.lease.security.locBurndownPeriods = [];
      }
    };

    $scope.updateHasGuarantyCapBurndown = function() {
      if ($scope.lease.hasGuarantyCapBurndown) {
        if (
          !$scope.lease.guarantyCapBurnOffReductionPeriods ||
          ($scope.lease.guarantyCapBurnOffReductionPeriods &&
            $scope.lease.guarantyCapBurnOffReductionPeriods.length === 0)
        ) {
          $scope.lease.guarantyCapBurnOffReductionPeriodsNumber = 1;
          $scope.lease.guarantyCapBurnOffReductionPeriods = [{}];
        }
      } else {
        $scope.lease.guarantyCapBurnOffReductionPeriodsNumber = 0;
        $scope.lease.guarantyCapBurnOffReductionPeriods = [];
      }
    };

    $scope.updateLengthOfArray = function(newLength, array) {
      // Limit length of array to 50, to prevent massive DB entries
      newLength = Math.min(newLength, 50);
      let arrayRef = _.get($scope.lease, array);

      // push new items if it does not exist
      if (!arrayRef) {
        _.set($scope.lease, array, []);
        arrayRef = _.get($scope.lease, array);
      }

      // Update the collection accordingly
      if (arrayRef.length < newLength) {
        for (var i = arrayRef.length; i < newLength; i++) {
          arrayRef.push({});
        }
      } else {
        var count = arrayRef.length - newLength;
        arrayRef.splice(-count, count);
      }
    };

    function updateCpiAnchorYearsPerTerm(term) {
      var numberOfYears = $scope.lease.leaseYearsCalculated;

      if (numberOfYears) {
        // If the lease term is only 1 year, then you won't be able to set CPI
        if (numberOfYears === 1) {
          term.hasCpiRent = false;
        } else {
          if (term.hasCpiRent) {
            // If the already selected cpiAnchorYears is greater than the new numberOfYears, let's reset to 1
            if (term.cpiAnchorYears > numberOfYears) {
              term.cpiAnchorYears = 1;
            }

            // Update the numberOfYears according the cpiAnchorYears
            numberOfYears = term.cpiAnchorYears;

            // Update the dropdown to select the number of years prior to CPI
            $scope.cpiAnchorYearsOptions = Array.from(
              new Array($scope.lease.leaseYears - 1),
              function(val, index) {
                return index + 1;
              },
            );
          }

          // Limit number of terms and number of years to 50, to prevent massive DB entries
          numberOfYears = Math.min(numberOfYears, 50);

          // Update the collection of rent periods accordingly
          if (term.rentPeriods.length < numberOfYears) {
            for (var i = term.rentPeriods.length; i < numberOfYears; i++) {
              term.rentPeriods.push({});
            }
          } else {
            var count = term.rentPeriods.length - numberOfYears;
            term.rentPeriods.splice(-count, count);
          }
        }
      }
    }

    $scope.updateCpiAnchorYears = function() {
      if ($scope.isMultipleTerms) {
        for (
          var termIndex = 0;
          termIndex < $scope.lease.terms.length;
          termIndex++
        ) {
          updateCpiAnchorYearsPerTerm($scope.lease.terms[termIndex]);
        }
      } else {
        updateCpiAnchorYearsPerTerm($scope.lease);
      }
    };

    $scope.updateRenewalCpiAnchorYears = function(index = 0) {
      if ($scope.lease.renewalTerm) {
        $scope.lease.renewalInfo = $scope.lease.renewalInfo || [];
        if (!$scope.lease.renewalInfo[index]) {
          return;
        }

        var numberOfYears = $scope.lease.renewalTerm;

        // If the lease term is only 1 year, then you won't be able to set CPI
        if (numberOfYears === 1) {
          $scope.lease.renewalInfo[index].hasCpiRent = false;
        } else {
          if ($scope.lease.renewalInfo[index].hasCpiRent) {
            // If the already selected cpiAnchorYears is greater than the new numberOfYears, let's reset to 1
            if (
              $scope.lease.renewalInfo[index].cpiAnchorYears > numberOfYears
            ) {
              $scope.lease.renewalInfo[index].cpiAnchorYears = 1;
            }

            // Update the numberOfYears according the cpiAnchorYears
            numberOfYears =
              $scope.lease.renewalInfo[index].cpiAnchorYears || null;

            // Update the dropdown to select the number of years prior to CPI
            $scope.cpiRenewalAnchorYearsOptions = Array.from(
              new Array($scope.lease.renewalTerm - 1),
              function(val, index) {
                return index + 1;
              },
            );
          }

          // Limit number of terms and number of years to 50, to prevent massive DB entries
          numberOfYears = Math.min(numberOfYears, 50);

          // Update the collection of rent periods accordingly
          if (
            $scope.lease.renewalInfo[index].renewalRentPeriods.length <
            numberOfYears
          ) {
            for (
              var i = $scope.lease.renewalInfo[index].renewalRentPeriods.length;
              i < numberOfYears;
              i++
            ) {
              $scope.lease.renewalInfo[index].renewalRentPeriods.push({});
            }
          } else {
            var count =
              $scope.lease.renewalInfo[index].renewalRentPeriods.length -
              numberOfYears;
            $scope.lease.renewalInfo[index].renewalRentPeriods.splice(
              -count,
              count,
            );
            // remove all next renewals once the CPI is set for the first renewal option
            if ($scope.lease.renewalInfo[index].hasCpiRent) {
              for (var i = index + 1; i < $scope.lease.renewalInfo.length; i++) {
                $scope.lease.renewalInfo[i].renewalRentPeriods = [];
              }
            }
          }
        }
      }
    };

    $scope.getTermDatesWithFreeRent = function(
      index,
      momentDateFormat,
      angularDateFormat,
    ) {
      if (!$scope.lease) {
        return;
      }

      // If `hasFreeRent` is turned off, just continue with the normal flow
      if (!$scope.lease.hasFreeRent) {
        return $scope.getTermDates(index, angularDateFormat);
      }

      var commencementDate;

      // Find the correct deliveryDate based on the user selection
      if (
        $scope.lease.tenantImprovements &&
        $scope.lease.tenantImprovements.type
      ) {
        if ($scope.lease.tenantImprovements.type === 'landlord') {
          commencementDate = $scope.lease.estimatedCommencementDate;
        } else if ($scope.lease.tenantImprovements.type === 'tenant') {
          commencementDate = $scope.lease.estimatedCommencementDate;
        } else if ($scope.lease.tenantImprovements.type === 'none') {
          commencementDate = $scope.lease.termCommencementDate;
        }
      }

      // If `commencementDate` is not defined, return a placeholder
      if (!commencementDate) {
        return '________ - ________';
      }

      // NOTE: `index` refers to the first term after the free rent term!
      var startDate;
      var endDate;

      // Free rent is added to the term, meaning, the term length increased with the term of free rent
      if ($scope.lease.hasFreeRentAddedToTerm) {
        startDate = moment(commencementDate)
          .add('year', index)
          .add('month', $scope.lease.freeRent)
          .toDate();

        endDate = moment(commencementDate)
          .add('year', index + 1)
          .add('month', $scope.lease.freeRent)
          .add('day', -1)
          .toDate();
      } else {
        // Free rent isn't added to the term, meaning, part of the first year is free,
        // the term length remain as is

        startDate = moment(commencementDate)
          .add('year', index)
          .toDate();

        endDate = moment(commencementDate)
          .add('year', index + 1)
          .add('day', -1)
          .toDate();

        if (index === 0) {
          startDate = moment(commencementDate)
            .add('month', $scope.lease.freeRent)
            .toDate();
        }
      }

      startDate = moment(startDate).format(momentDateFormat);
      endDate = moment(endDate).format(momentDateFormat);

      return startDate + ' - ' + endDate;
    };

    $scope.getTermDates = function(index, dateFormat) {
      if ($scope.lease) {
        var deliveryDate;

        if (
          $scope.lease.tenantImprovements &&
          $scope.lease.tenantImprovements.type
        ) {
          if ($scope.lease.tenantImprovements.type === 'landlord') {
            deliveryDate = $scope.lease.estimatedCommencementDate;
          } else if ($scope.lease.tenantImprovements.type === 'tenant') {
            deliveryDate = $scope.lease.estimatedCommencementDate;
          } else if ($scope.lease.tenantImprovements.type === 'none') {
            deliveryDate = $scope.lease.termCommencementDate;
          } else {
            return 'Lease Year ' + (index + 1);
          }
        } else {
          if ($scope.lease.rentCommenceAt) {
            deliveryDate = $scope.lease.rentCommenceAt;
          } else {
            return 'Lease Year ' + (index + 1);
          }
        }

        // If `deliveryDate` is not defined, return a placeholder
        if (!deliveryDate) {
          return '________ - ________';
        }

        deliveryDate = new Date(deliveryDate);
        var startDate = new Date(
          deliveryDate.setFullYear(deliveryDate.getFullYear() + index),
        );
        var endDate = new Date(
          deliveryDate.setFullYear(deliveryDate.getFullYear() + 1),
        );

        if ($scope.lease.leaseMonths && $scope.lease.leaseMonths > 0) {
          startDate = moment(startDate)
            .add($scope.lease.leaseMonths, 'month')
            .toDate();
          endDate = moment(endDate)
            .add($scope.lease.leaseMonths, 'month')
            .toDate();
        }

        startDate = $filter('date')(startDate, dateFormat);
        endDate.setDate(endDate.getDate() - 1);
        endDate = $filter('date')(endDate, dateFormat);

        return startDate + ' - ' + endDate;
      } else {
        return 'Lease Year ' + (index + 1);
      }
    };

    $scope.updateNumberOfRenewalTermsAndRentPeriods = function(
      numberOfTerms,
      numberOfYears,
    ) {
      // Limit number of terms and number of years to 50, to prevent massive DB entries
      numberOfTerms = Math.min(numberOfTerms, 50);
      numberOfYears = Math.min(numberOfYears, 50);

      if (numberOfYears) {
        $scope.lease.calc.renewalRentPeriods = Array.apply(null, Array(numberOfYears)).map((x, i) => i);

        $scope.lease.renewalInfo.forEach(function(renewalInfo) {
          while (renewalInfo.renewalRentPeriods.length < numberOfYears) {
            renewalInfo.renewalRentPeriods.push({});
          }
          renewalInfo.renewalRentPeriods = renewalInfo.renewalRentPeriods.slice(
            0,
            numberOfYears,
          );
        });
      }
      
      if (numberOfTerms) {
        if (!$scope.lease.renewalInfo) {
          $scope.lease.renewalInfo = [];
        }

        if ($scope.lease.renewalInfo.length < numberOfTerms) {
          while ($scope.lease.renewalInfo.length < numberOfTerms) {
            let renewalInputType;

            if (
              $scope.lease?.form?.editor?.defaultFields?.renewalInfo?.[0]?.renewalInputType
            ) {
              renewalInputType = $scope.lease.form.editor.defaultFields.renewalInfo[0].renewalInputType;
            }
    
            if (
              !renewalInputType &&
              $scope.lease?.renewalInfo?.[0]?.renewalInputType
            ) {
              renewalInputType = $scope.lease.renewalInfo[0].renewalInputType;
            }

            if (
              $scope.lease?.form?.editor?.defaultFields?.renewalInfo?.[0]
            ) {
              $scope.lease.renewalInfo.push({
                renewalRentPeriods: [],
                renewalInputType,
                ...$scope.lease.form.editor.defaultFields.renewalInfo[0],
              });
            } else {
              $scope.lease.renewalInfo.push({
                renewalRentPeriods: [],
                renewalInputType,
              });
            }  
          }
        } else {
          $scope.lease.renewalInfo = $scope.lease.renewalInfo.slice(
            0,
            numberOfTerms,
          );
        }

        $scope.lease.renewalInfo.forEach(function(renewalInfo) {
          while (renewalInfo.renewalRentPeriods.length < numberOfYears) {
            renewalInfo.renewalRentPeriods.push({});
          }
          renewalInfo.renewalRentPeriods = renewalInfo.renewalRentPeriods.slice(
            0,
            numberOfYears,
          );
        });
      }
    };

    // TODO:[liork] do we need that?
    $scope.selected = function(buildingId) {
      $scope.lease.buildingId = buildingId;
      $scope.update();
      BuildingService.get({
        id: buildingId,
      }).then(function(building) {
        $scope.building = building;
        $scope.building.displayName ||= $scope.building.name;
        $scope.building.dashboardName ||= $scope.building.displayName;
      });
    };

    // TODO:[liork] do we need that?
    $scope.buildings = function() {
      BuildingService.get().then(function(buildings) {
        return buildings;
      });
    };

    $scope.formatDate = function(date, format) {
      if (!date) {
        return '________';
      }

      if (date === '________') {
        return;
      }

      if (!moment(date).isValid()) {
        return '________';
      }

      if (!format) {
        format = 'MMMM D, YYYY';
      }

      return moment(date).format(format);
    };

    function calculateRentPSFPerTerm(
      term,
      premiseAreaSize,
      baseRent,
      annualIncrease,
    ) {
      var roundTo =
        $scope.lease.rentCalculationMethod === 'psf escalation' ? 4 : 2;

      let isExcel = false;
      let isDecimal = false;

      if (
        $scope.lease.rentCalculationMethod &&
        $scope.lease.rentCalculationMethod.startsWith('psf escalation|')
      ) {
        roundTo = parseInt($scope.lease.rentCalculationMethod.match(/\d/)[0]);
        isExcel = true;
      }

      if (
        $scope.lease.rentCalculationMethod &&
        $scope.lease.rentCalculationMethod.startsWith('psf escalation decimal|')
      ) {
        roundTo = parseInt($scope.lease.rentCalculationMethod.match(/\d/)[0]);
        isDecimal = true;
      }

      if (term.rentInputType === 'automatic') {
        term.rentPeriods.forEach(function(rentPeriod, index) {
          if (!baseRent && baseRent !== 0) {
            rentPeriod.rentPerSqFoot = undefined;
            rentPeriod.totalRent = undefined;
          }

          if ((baseRent || baseRent === 0) && (annualIncrease || annualIncrease == 0)) {
            if (isExcel) {
              if (index === 0) {
                rentPeriod.rentPerSqFoot = baseRent;
              } else {
                rentPeriod.rentPerSqFoot = round(term.rentPeriods[index -1].rentPerSqFoot * (1 + annualIncrease / 100), roundTo)
              }
            } else if (isDecimal) {
              if (index === 0) {
                rentPeriod.rentPerSqFoot = baseRent;
              } else {
                rentPeriod.rentPerSqFoot = new Decimal(term.rentPeriods[index - 1].rentPerSqFoot).toDecimalPlaces(roundTo).mul(1 + annualIncrease / 100).toDecimalPlaces(roundTo).toNumber();
              }
            } else {
              rentPeriod.rentPerSqFoot =
                baseRent * Math.pow(1 + annualIncrease / 100, index);
              rentPeriod.rentPerSqFoot = parseFloat(
                rentPeriod.rentPerSqFoot.toFixed(roundTo),
              );
            }
  
            if (index == 0) {
              rentPeriod.totalRent = baseRent * premiseAreaSize;
            } else {
              rentPeriod.totalRent =
                term.rentPeriods[index - 1].totalRent *
                (1 + annualIncrease / 100);
              if ($scope.lease.rentCalculationMethod === 'annual escalation') {
                rentPeriod.rentPerSqFoot = parseFloat(
                  rentPeriod.totalRent / premiseAreaSize,
                );
              }
            }
          }
        });
      } else if (term.rentPeriods && term.rentInputType === 'manual') {
        for (var i = 0; i < term.rentPeriods.length; i++) {
          if (term.rentPeriods[i].rentPerSqFoot) {
            term.rentPeriods[i].totalRent =
              term.rentPeriods[i].rentPerSqFoot.toFixed(10) * premiseAreaSize;
          }
        }
      }
      $scope.setTermsIncludingFreeRent(term.rentPeriods);
    }

    $scope.calculateRentPSF = function(lease) {
      var premiseAreaSize;
      switch (lease.premisesModType) {
        case 'expansion':
          premiseAreaSize =
            parseInt(lease.premiseAreaSize) +
            parseInt(lease.expansionPremiseAreaSize);
          break;
        case 'relocation':
          premiseAreaSize = parseInt(lease.relocationPremisesAreaSize);
          break;
        case 'reduction':
          premiseAreaSize = parseInt(lease.reductionRemainingAreaSize);
          break;
        default:
          premiseAreaSize = parseInt(lease.premiseAreaSize);
          break;
      }

      if (lease.leaseYears) {
        if ($scope.isMultipleTerms) {
          for (
            var termIndex = 0;
            termIndex < $scope.lease.terms.length;
            termIndex++
          ) {
            let annualIncrease = $scope.lease.terms[termIndex].annualIncrease;

            if (($scope.lease.leaseYears * 12) + $scope.lease.leaseMonths <= 12) {
              annualIncrease = 0;
            }

            calculateRentPSFPerTerm(
              $scope.lease.terms[termIndex],
              premiseAreaSize,
              $scope.lease.terms[termIndex].baseRent,
              annualIncrease,
            );
          }
        } else {
          let annualIncrease = $scope.annualIncrease;

          if (($scope.lease.leaseYears * 12) + $scope.lease.leaseMonths <= 12) {
            annualIncrease = 0;
          }

          calculateRentPSFPerTerm(
            $scope.lease,
            premiseAreaSize,
            $scope.baseRent,
            annualIncrease,
          );
        }
      }
    };

    $scope.calculateRenewalPSF = function(lease) {
      if (
        lease.renewalCount &&
        lease.renewalTerm
      ) {
        var roundTo = lease.rentCalculationMethod === 'psf escalation' ? 4 : 2;

        let isExcel = false;
        let isDecimal = false;
        
        if (
          $scope.lease.rentCalculationMethod &&
          $scope.lease.rentCalculationMethod.startsWith('psf escalation|')
        ) {
          roundTo = parseInt($scope.lease.rentCalculationMethod.match(/\d/)[0]);
          isExcel = true;
        }

        if (
          $scope.lease.rentCalculationMethod &&
          $scope.lease.rentCalculationMethod.startsWith('psf escalation decimal|')
        ) {
          roundTo = parseInt($scope.lease.rentCalculationMethod.match(/\d/)[0]);
          isDecimal = true;
        }

        if (lease.hasApplyIncreaseToRenewals) {
          if (
            lease.rentPeriods &&
            lease.rentPeriods.length > 0
          ) {
            lease.renewalInfo.forEach(function(renewalInfo, renewalInfoIndex) {
              renewalInfo.renewalInputType = 'automatic';
              renewalInfo.renewalAnnualIncrease = $scope.annualIncrease;

              var renewalBaseRent;
              if (renewalInfoIndex == 0) {
                renewalBaseRent =
                  lease.rentPeriods[lease.rentPeriods.length - 1].rentPerSqFoot;
              } else {
                renewalBaseRent =
                  lease.renewalInfo[renewalInfoIndex - 1].renewalRentPeriods[
                    lease.renewalTerm - 1
                  ].rentPerSqFoot;
              }

              if (isExcel) {
                renewalInfo.renewalBaseRent = round(round(renewalBaseRent, roundTo) * (1 + $scope.annualIncrease / 100), roundTo);
              } else if (isDecimal) {
                renewalInfo.renewalBaseRent = new Decimal(renewalBaseRent).toDecimalPlaces(roundTo).mul(1 + $scope.annualIncrease / 100).toDecimalPlaces(roundTo).toNumber();
              } else {
                renewalInfo.renewalBaseRent = round(parseFloat(
                  renewalBaseRent * (1 + $scope.annualIncrease / 100),
                ), roundTo);
              }

              renewalInfo.renewalRentPeriods.forEach(function(
                rentPeriod,
                renewalRentPeriodsIndex,
              ) {
                if (isExcel) {
                  if (renewalRentPeriodsIndex === 0) {
                    rentPeriod.rentPerSqFoot = renewalInfo.renewalBaseRent;
                  } else {
                    rentPeriod.rentPerSqFoot = round(round(renewalInfo.renewalRentPeriods[renewalRentPeriodsIndex - 1].rentPerSqFoot, roundTo) * (1 + $scope.annualIncrease / 100), roundTo);
                  }
                } else if (isDecimal) {
                  if (renewalRentPeriodsIndex === 0) {
                    rentPeriod.rentPerSqFoot = renewalInfo.renewalBaseRent;
                  } else {
                    rentPeriod.rentPerSqFoot = new Decimal(renewalInfo.renewalRentPeriods[renewalRentPeriodsIndex - 1].rentPerSqFoot).toDecimalPlaces(roundTo).mul(1 + $scope.annualIncrease / 100).toDecimalPlaces(roundTo).toNumber();
                  }
                } else {
                  rentPeriod.rentPerSqFoot =
                    renewalInfo.renewalBaseRent *
                    Math.pow(
                      1 + $scope.annualIncrease / 100,
                      renewalRentPeriodsIndex,
                    );
                  rentPeriod.rentPerSqFoot = parseFloat(
                    rentPeriod.rentPerSqFoot.toFixed(roundTo),
                  );
                }
                rentPeriod.totalRent =
                  rentPeriod.rentPerSqFoot * lease.premiseAreaSize;
                if (lease.rentCalculationMethod === 'annual escalation') {
                  rentPeriod.rentPerSqFoot = parseFloat(
                    rentPeriod.totalRent / lease.premiseAreaSize,
                  );
                }
              });
            });
          }
        } else {
          lease.renewalInfo.forEach(function(renewalInfo, renewalInfoIndex) {
            let renewalAnnualIncrease = renewalInfo.renewalAnnualIncrease;
            
            if (lease.renewalTerm <= 1) {
              renewalAnnualIncrease = 0;
            }
            
            if (
              renewalInfo.renewalInputType === 'automatic' &&
              renewalInfo.renewalBaseRent &&
              (renewalAnnualIncrease ||
                renewalAnnualIncrease == 0)
            ) {
              renewalInfo.renewalRentPeriods.forEach(function(
                rentPeriod,
                index,
              ) {
                if (isExcel) {
                  if (index === 0) {
                    rentPeriod.rentPerSqFoot = renewalInfo.renewalBaseRent;
                  } else {
                    rentPeriod.rentPerSqFoot = round(renewalInfo.renewalRentPeriods[index - 1].rentPerSqFoot * (1 + renewalAnnualIncrease / 100), roundTo);
                  }
                } else if (isDecimal) {
                  if (index === 0) {
                    rentPeriod.rentPerSqFoot = renewalInfo.renewalBaseRent;
                  } else {
                    rentPeriod.rentPerSqFoot = new Decimal(renewalInfo.renewalRentPeriods[index - 1].rentPerSqFoot).toDecimalPlaces(roundTo).mul(1 + renewalAnnualIncrease / 100).toDecimalPlaces(roundTo).toNumber();
                  }
                } else {
                  rentPeriod.rentPerSqFoot =
                    renewalInfo.renewalBaseRent *
                    Math.pow(1 + renewalAnnualIncrease / 100, index);
                  rentPeriod.rentPerSqFoot = parseFloat(
                    rentPeriod.rentPerSqFoot.toFixed(roundTo),
                  );
                }

                if (index == 0) {
                  rentPeriod.totalRent =
                    renewalInfo.renewalBaseRent * lease.premiseAreaSize;
                } else {
                  rentPeriod.totalRent =
                    lease.renewalInfo[renewalInfoIndex].renewalRentPeriods[
                      index - 1
                    ].totalRent *
                    (1 + renewalAnnualIncrease / 100);
                  if (lease.rentCalculationMethod === 'annual escalation') {
                    rentPeriod.rentPerSqFoot = parseFloat(
                      lease.renewalInfo[renewalInfoIndex].renewalRentPeriods[
                        index
                      ].totalRent / lease.premiseAreaSize,
                    );
                  }
                }
              });
            } else if (
              renewalInfo.renewalRentPeriods &&
              renewalInfo.renewalInputType === 'manual'
            ) {
              renewalInfo.renewalRentPeriods.forEach(function(rentPeriod) {
                rentPeriod.totalRent =
                  rentPeriod.rentPerSqFoot * lease.premiseAreaSize;
              });
            }
          });
        }
      }
    };

    function consolidateRentPeriodsPerTerm(term) {
      var rentPeriods = term.rentPeriods;

      if (rentPeriods) {
        var length = term.rentPeriods.length;

        if (rentPeriods.length === 0) {
          return;
        }

        term.consolidatedRentPeriods = [
          {
            startYear: 1,
            endYear: 1,
            rentPerSqFoot: rentPeriods[0].rentPerSqFoot,
            totalRent: rentPeriods[0].totalRent,
          },
        ];

        for (var i = 1; i < length; i++) {
          var isConsolidate = false;

          if (
            term.rentPeriods &&
            (term.rentInputType === 'manual' ||
              term.rentInputType === 'automatic')
          ) {
            isConsolidate =
              rentPeriods[i].rentPerSqFoot === rentPeriods[i - 1].rentPerSqFoot;
          } else if (
            term.rentPeriods &&
            (term.rentInputType === 'fixed' ||
              term.rentInputType === 'fixed-no-escalation')
          ) {
            isConsolidate =
              rentPeriods[i].totalRent === rentPeriods[i - 1].totalRent;
          }

          if (isConsolidate) {
            var lastItem = term.consolidatedRentPeriods.pop();
            lastItem.endYear++;
            term.consolidatedRentPeriods.push(lastItem);
          } else {
            var newRentPeriod = {
              startYear: i + 1,
              endYear: i + 1,
              rentPerSqFoot: rentPeriods[i].rentPerSqFoot,
              totalRent: rentPeriods[i].totalRent,
            };
            term.consolidatedRentPeriods.push(newRentPeriod);
          }
        }
      }
    }

    $scope.consolidateRentPeriods = function() {
      if ($scope.isMultipleTerms) {
        for (
          var termIndex = 0;
          termIndex < $scope.lease.terms.length;
          termIndex++
        ) {
          consolidateRentPeriodsPerTerm($scope.lease.terms[termIndex]);
        }
      } else {
        consolidateRentPeriodsPerTerm($scope.lease);
      }
    };

    $scope.consolidateRenewalInfo = function() {
      var renewalInfo = $scope.lease.renewalInfo;
      if (!renewalInfo || renewalInfo.length === 0) {
        return; // prevent JS errors
      }
      var renewalLength = renewalInfo.length;
      $scope.lease.consolidatedRenewalInfo = [];

      for (var i = 0; i < renewalLength; i++) {
        var renewalPeriods = renewalInfo[i].renewalRentPeriods;
        var periodLength = renewalInfo[i].renewalRentPeriods.length;

        $scope.lease.consolidatedRenewalInfo.push({
          hasCpiRent: renewalInfo[i].hasCpiRent
            ? renewalInfo[i].hasCpiRent
            : false,
          cpiAnchorYears: renewalInfo[i].cpiAnchorYears,
          consolidatedRenewalRentPeriods: [
            {
              startYear: 1,
              endYear: 1,
              rentPerSqFoot: renewalInfo[i].renewalRentPeriods[0]
                ? renewalInfo[i].renewalRentPeriods[0].rentPerSqFoot
                : 0,
              totalRent: renewalInfo[i].renewalRentPeriods[0]
                ? renewalInfo[i].renewalRentPeriods[0].totalRent
                : 0,
            },
          ],
        });

        for (var j = 1; j < periodLength; j++) {
          var isConsolidate = false;
          if (
            renewalPeriods &&
            (renewalInfo[i].renewalInputType === 'manual' ||
              renewalInfo[i].renewalInputType === 'automatic')
          ) {
            isConsolidate =
              renewalPeriods[j].rentPerSqFoot ===
              renewalPeriods[j - 1].rentPerSqFoot;
          } else if (
            renewalPeriods &&
            renewalInfo[i].renewalInputType === 'fixed'
          ) {
            isConsolidate =
              renewalPeriods[j].totalRent === renewalPeriods[j - 1].totalRent;
          }

          if (isConsolidate) {
            var lastItem = $scope.lease.consolidatedRenewalInfo[
              i
            ].consolidatedRenewalRentPeriods.pop();
            lastItem.endYear++;
            $scope.lease.consolidatedRenewalInfo[
              i
            ].consolidatedRenewalRentPeriods.push(lastItem);
          } else {
            var newRenewalRentPeriod = {
              startYear: j + 1,
              endYear: j + 1,
              rentPerSqFoot: renewalPeriods[j].rentPerSqFoot,
              totalRent: renewalPeriods[j].totalRent,
            };
            $scope.lease.consolidatedRenewalInfo[i].hasCpiRent = renewalInfo[i]
              .hasCpiRent
              ? renewalInfo[i].hasCpiRent
              : false;
            $scope.lease.consolidatedRenewalInfo[i].cpiAnchorYears =
              renewalInfo[i].cpiAnchorYears;
            $scope.lease.consolidatedRenewalInfo[
              i
            ].consolidatedRenewalRentPeriods.push(newRenewalRentPeriod);
          }
        }
      }
    };

    $scope.setGuarantorLists = function(individuals, married, entities) {
      $scope.lease.guarantorInfo.individuals =
        $scope.lease.guarantorInfo.individuals || [];
      $scope.lease.guarantorInfo.marriedCouples =
        $scope.lease.guarantorInfo.marriedCouples || [];
      $scope.lease.guarantorInfo.entities =
        $scope.lease.guarantorInfo.entities || [];
    };

    $scope.initializeExistingGuarantors = function() {
      var individuals = $scope.lease.existingGuarantorInfo.individuals;
      var marriedCouples = $scope.lease.existingGuarantorInfo.marriedCouples;
      var entities = $scope.lease.existingGuarantorInfo.entities;

      // If the user turned on the hasExistingGuarantors flag and all the lists are empty
      // Lets create the first one for him - 1 individual guarantor
      if ($scope.lease.hasExistingGuarantors) {
        $scope.lease.individualExistingGuarantors = 1;
        if (
          individuals.length === 0 &&
          marriedCouples.length === 0 &&
          entities.length === 0
        ) {
          individuals.push({
            nickname: '',
            hasBeenReleased: false,
            guarantyDate: undefined,
            releaseDate: undefined,
          });
          $scope.lease.hasReleaseGuarantors = false;
        } else {
          var allGuarantors =
            _.union(individuals, marriedCouples, entities) || [];
          var hasReleaseGuarantors = _.find(allGuarantors, function(o) {
            return o.hasBeenReleased == true;
          });
          $scope.lease.hasReleaseGuarantors = hasReleaseGuarantors
            ? true
            : false;
        }
      }
    };

    $scope.initializeReleaseGuarantors = function() {
      if (!$scope.lease.hasExistingGuarantors) {
        $scope.lease.hasReleaseGuarantors = false;
      }
    };

    $scope.addExistingGuarantor = function(guarantorType) {
      $scope.lease.existingGuarantorInfo[guarantorType].push({
        nickname: '',
        hasBeenReleased: false,
        guarantyDate: undefined,
        releaseDate: undefined,
      });
      var index = $scope.lease.existingGuarantorInfo[guarantorType].length - 1;
      $scope.$watch(
        'lease.existingGuarantorInfo[' +
          guarantorType +
          '][' +
          index +
          '].hasBeenReleased',
        function(newValue, oldValue) {
          setTimeout(function() {
            if (
              typeof oldValue != 'undefined' &&
              typeof newValue != 'undefined'
            ) {
              $scope.structureChanged();
              $scope.highlightItem(
                'lease.existingGuarantorInfo[' +
                  guarantorType +
                  '][' +
                  index +
                  '].hasBeenReleased',
              );
            }
          }, 1);
        },
      );
    };

    $scope.removeExistingGuarantor = function(guarantorType, index) {
      $scope.lease.existingGuarantorInfo[guarantorType].splice(index, 1);
      $scope.updateReleaseGuarantors();
    };

    $scope.addGuarantor = function(guarantorType = "guarantors") {
      const guarantor = {};

      if (guarantorType === 'marriedCouples') {
        guarantor.hasSameAddress = true;
      }

      $scope.lease.guarantorInfo[guarantorType] = $scope.lease.guarantorInfo[guarantorType] || [];
      $scope.lease.guarantorInfo[guarantorType].push(guarantor);
    };

    $scope.addSpaceToTerm = function(termIndex) {
      var term = $scope.lease.terms[termIndex];
      if (!term.spaces) {
        term.spaces = [];
      }
      term.spaces.push(JSON.parse(term.selectedSpace));
      term.selectedSpace = null;
      $scope.setAvailableSpaces();
    };

    $scope.removeSpaceFromTerm = function(termIndex, spaceIndex) {
      $scope.lease.terms[termIndex].spaces.splice(spaceIndex, 1);
      $scope.setAvailableSpaces();
    };

    $scope.allowRemoveTerm = true;
    $scope.addTerm = function() {
      if(!$scope.allowRemoveTerm){
        return;
      }
      if($scope.isAdmin){
        $scope.allowRemoveTerm = true;
      }
      else{
        $scope.allowRemoveTerm = false;
      }
      $scope.safeApply();
      var rentInputType =
        $scope.lease.terms && $scope.lease.terms.length
          ? $scope.lease.terms[0].rentInputType
          : null;
      $scope.lease.terms.push({
        rentBumps: [{ begin: 1 }],
        rentInputType: rentInputType,
        isInitialized: true,
        isNew: !$scope.isAdmin,
      });
      updateNumberOfRentPeriodsPerTerm(
        $scope.lease.terms[$scope.lease.terms.length - 1],
        $scope.lease.leaseYears,
      );
      $scope.setAvailableSpaces();
    };

    $scope.removeTerm = function(index) {
      if(!$scope.allowRemoveTerm){
        return;
      }
      if($scope.isAdmin){
        $scope.allowRemoveTerm = true;
      }
      else{
        $scope.allowRemoveTerm = false;
      }
      $scope.safeApply();
      $scope.lease.terms.splice(index, 1);
      $scope.setAvailableSpaces();
    };

    $scope.listGuarantor = function(data, typeArray) {
      var str,
        list = [];
      _.forEach(data, function(value) {
        str = [];
        _.forEach(typeArray, function(type) {
          if (value[type]) str.push(value[type]);
        });
        if (str.length) list.push(str.join(' and '));
      });
      return list.join(', ');
    };

    $scope.removeGuarantor = function(index, guarantorType) {
      $scope.lease.guarantorInfo[guarantorType || 'guarantors'].splice(
        index,
        1,
      );
    };

    $scope.removeGuarantorConfirm = function(index, type, typeObj) {
      var confirm = $mdDialog.confirm({
        template:
          '<div id="confirm-remove-guarantor-header">' +
          '<h2 class="modal-header">Remove Guarantor</h2>' +
          '<div class="md-dialog-content">' +
          '<span>  Are you sure you want to delete?</span>' +
          '<div class="modal-actions">' +
          '<button class="btn blue" id="delete" ng-click="dialog.hide()">OK</button>' +
          '<button class="btn blue-outline" id="cancel" ng-click="dialog.abort()">Cancel</button>' +
          '</div>' +
          '</div>' +
          '</div>',
      });

      $mdDialog.show(confirm).then(function() {
        $scope.removeGuarantor(index, type);
        $scope.update();
        $scope.updateCurrent(typeObj);
        $scope.inline.hideInline();
      });
    };

    $scope.guarantorList = function() {
      switch ($scope.lease.guarantorInfo.guarantors.length) {
        case 1:
          return $scope.lease.guarantorInfo.guarantors[0].name || '__________';
        case 2:
          return (
            ($scope.lease.guarantorInfo.guarantors[0].name || '__________') +
            ' and ' +
            ($scope.lease.guarantorInfo.guarantors[1].name || '__________')
          );
        default:
          var guarantorNames = $scope.lease.guarantorInfo.guarantors.map(
            function(currentValue) {
              return currentValue.name || '__________';
            },
          );
          var guarantorList =
            guarantorNames.slice(0, -1).join(', ') +
            ' and ' +
            guarantorNames.slice(-1);
          return guarantorList;
      }
    };

    $scope.updateGuarantors = function() {
      var guarantorInfo = $scope.lease.guarantorInfo;
      guarantorInfo.guarantors = guarantorInfo.guarantors.slice(0, 1);
      if (guarantorInfo.type == 'entity') {
        guarantorInfo.marriedCouple = false;
      }
      $scope.update();
    };

    $scope.stateAbbreviationToName = function(state) {
      if (state) {
        return stateAbbreviationToName(state);
      }
      return null;
    };

    $scope.toVowelA = function(str) {
      if (str) {
        if (str.toLowerCase() == 'utah') {
          return 'a';
        } else if (isVowel(str.charAt(0))) {
          return 'an';
        } else {
          return 'a';
        }
      } else {
        return 'a';
      }
    };

    $scope.securityDepositText = function(deposit) {
      if (deposit && deposit != 0) {
        var depositArray = deposit.split('.');
        if (parseInt(depositArray[1])) {
          return (
            toTitleCase(toWords(depositArray[0])) +
            'and ' +
            depositArray[1] +
            '/100 Dollars' +
            ' (' +
            $filter('currency')(deposit, '$', 2) +
            ')'
          );
        } else {
          return (
            toTitleCase(toWords(depositArray[0])) +
            'and No/100 Dollars' +
            ' (' +
            $filter('currency')(deposit, '$', 2) +
            ')'
          );
        }
      } else {
        return '______________ and No Dollars ($________)';
      }
    };

    $scope.premiseFullAddress = function(lease) {
      if (lease) {
        var address = lease.premiseAddress ? lease.premiseAddress + ', ' : '';
        address += lease.premiseAddressCity
          ? lease.premiseAddressCity + ', '
          : '';
        address += lease.premiseAddressState
          ? lease.premiseAddressState + ' '
          : '';
        address += lease.premiseAddressZip ? lease.premiseAddressZip : '';
        return address;
      }
    };

    $scope.sitePlanName = function() {
      if ($scope.lease && $scope.lease.sitePlan && $scope.lease.sitePlan.url) {
        return $scope.lease.sitePlan.url.split('/').pop();
      } else {
        return 'No File Chosen';
      }
    };

    $scope.premisesPlanName = function() {
      if (
        $scope.lease &&
        $scope.lease.premisesPlan &&
        $scope.lease.premisesPlan.url
      ) {
        return $scope.lease.premisesPlan.url.split('/').pop();
      } else {
        return 'No File Chosen';
      }
    };

    $scope.clearWarranties = function() {
      if ($scope.lease.landlordWarranty) {
        $scope.lease.landlordWarranty.hvac = false;
        $scope.lease.landlordWarranty.electrical = false;
        $scope.lease.landlordWarranty.plumbing = false;
        $scope.lease.landlordWarranty.roof = false;
        $scope.lease.landlordWarranty.commonAreas = false;
        $scope.lease.landlordWarranty.landlordContractor = false;
      }
    };

    $scope.countWarranties = function() {
      var warrantyArray = [];
      angular.forEach($scope.lease.landlordWarranty, function(value, system) {
        if (value == true && system != 'roof') {
          if (system == 'hvac') {
            warrantyArray.push('HVAC');
          } else {
            warrantyArray.push(system);
          }
        }
      });
      $scope.lease.warranties = warrantyArray;
    };

    $scope.warrantyText = function() {
      if ($scope.lease && $scope.lease.warranties) {
        $scope.countWarranties();
        var warranties = $scope.lease.warranties;
        switch (warranties.length) {
          case 0:
            return ['___________', '___________'];
          case 1:
            return [warranties[0] + ' system', warranties[0] + ' system'];
          case 2:
            return [
              warranties[0] + ' and ' + warranties[1] + ' systems',
              warranties[0] + ' or ' + warranties[1] + ' systems',
            ];
          case 3:
            return [
              warranties[0] +
                ', ' +
                warranties[1] +
                ' and ' +
                warranties[2] +
                ' systems',
              warranties[0] +
                ', ' +
                warranties[1] +
                ' or ' +
                warranties[2] +
                ' systems',
            ];
        }
      }
    };

    function getBuildingOverrides() {
      var res = [];
      var buildingOverrides = $rootScope.buildingOverrides || {};
      Object.values(buildingOverrides).forEach(function(buildingOverride) {
        Object.values(buildingOverride).forEach(function(data) {
          res.push({
            text: data.text,
            formId: data.formId,
            buildingId: data.buildingId,
            bsName: data.bsName,
          });
        });
      });
      // convert buildingOverrides array to object, before sending to the server
      res = res.reduce(function(data, cur, i) {
        data[i] = cur;
        return data;
      }, {});
      return res;
    }

    $rootScope.inlineComputedStyles = function(html, ignoreAutomationText) {
      if (typeof ignoreAutomationText === 'undefined') {
        ignoreAutomationText = false;
      }

      var $html = $(html);

      // copy computed styles into inline style
      $html.find('*').each(function() {
        var current = $(this);
        var computedStyle = window.getComputedStyle(this);
        var fontStyle = computedStyle.getPropertyValue('font-style');
        var fontWeight = computedStyle.getPropertyValue('font-weight');
        var textDecoration = computedStyle.getPropertyValue('text-decoration');
        var color = computedStyle.getPropertyValue('color');
        var backgroundColor = computedStyle.getPropertyValue(
          'background-color',
        );

        current.css('font-style', fontStyle);
        current.css('font-weight', fontWeight);

        if (backgroundColor !== 'rgba(0, 0, 0, 0)') {
          current.css('background-color', backgroundColor);
        }

        if (color !== 'rgb(0, 156, 33)' && color !== 'rgb(0, 0, 0)') {
          // We don't want to keep the 'blue's coming from the inline editor
          if (ignoreAutomationText && color === 'rgb(21, 133, 212)') {
            current.css('color', '');
          } else {
            current.css('color', color);
          }
        }

        if (textDecoration.indexOf('underline') !== -1) {
          current.css('text-decoration', 'underline');
        }
      });

      return $html.html();
    };

    $scope.saveOverride = function(isPublishVersion, pathToRedirect) {
      //remove inline-editor style
      $('.preview').addClass('ignore-style');

      var arrData = {};
      // save new section/article from previous minor version
      if (JSON.parse($scope.lease.orderedListitem).length) {
        arrData[0] = {
          overrideType: 'ordered_listitem',
          orderedListitem: $scope.lease.orderedListitem,
          formId: $scope.lease.formId,          
        };
      }

      // save restyle      
      if (Object.keys($scope.lease.restyle).length !== 0) {
        const restyleData = JSON.stringify(lease.restyle);
        arrData[1] = {
          overrideType: 'restyle',
          orderedListitem: restyleData,
          formId: $scope.lease.formId,
          sectionId: 1,
        };
      }

      // save layers/outlones  
      if (lease.layers && Object.keys(lease.layers).length !== 0) {
        const layersData = JSON.stringify(lease.layers);
        arrData[2] = {
          overrideType: 'layers',
          orderedListitem: layersData,
          formId: $scope.lease.formId,
          sectionId: 2,
        };
      }

      var className = 'override-color',
        color = 'rgb(0, 156, 33)';
      $('*[style*="color: ' + color + '"]')
        .addClass(className)
        .css('color', '');
      // save overrides from previous minor version
      for (var override in $scope.prevOverrides) {
        var overrideData = $scope.prevOverrides[override];
        if (overrideData && overrideData.overrideType != 'ordered_listitem' 
        && overrideData.overrideType != 'restyle' 
        && overrideData.overrideType != 'layers')
          arrData[overrideData.sectionId] = {
            sectionId: overrideData.sectionId,
            text: overrideData.text,
            originalText: overrideData.originalText,
            formId: $scope.lease.formId,
          };
      }


      // handle new list items changes      
      var deletedElements = document.querySelectorAll('.deleted-element');
      const deletedSectionInnerHtml = `<span style="display:none;user-select:none;" class="deleted-paragraph"></span>`;
      deletedElements.forEach((el)=>{
        el.classList.remove('deleted-element');
        el.classList.add('deleted-container');
        const sections = el.querySelectorAll('[section-id]');
        sections.forEach((section)=>{
          section.setAttribute('override',true);
          section.innerHTML = deletedSectionInnerHtml;
        });
      });

      // save overrides from current edit
      var overrides = document.querySelectorAll('[override=true]');
      overrides.forEach(function(override) {
        override = $(override)
          .find('building-variable')
          .empty()
          .end()[0];
        var sectionId = override.getAttribute('section-id');
        arrData[sectionId] = {
          sectionId: sectionId,
          text: $rootScope.inlineComputedStyles(override, true),
          originalText: $scope.defaultProvisions[sectionId].htmlWithStyle,
          formId: $scope.lease.formId,
        };
      });

      var data = {
        overrides: arrData,
        buildingOverrides: getBuildingOverrides(),
        formId: $scope.lease.formId,
        publishVersion: isPublishVersion,
      };
      
      if (
        Object.keys(data.overrides).length ||
        Object.keys(data.buildingOverrides).length
      ) {
        new AllOverrideService(data).create().then(function(override) {
          window.removeEventListener('beforeunload', beforeUnloadFn);
          window.location = pathToRedirect;
        });
      }

      $('.ignore-style').removeClass('ignore-style');
    };


    const beforeUnloadFn = function (e) {
      if (!window.isTestEnv) {
        if ($scope.hasNewOverrides()) {
          e.preventDefault();
          e.returnValue = '';
      
          const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
          e.returnValue = confirmationMessage;
          return confirmationMessage;
        }

        if ($scope.isSaving) {
          e.preventDefault();
          e.returnValue = '';
      
          const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
          e.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      }
    };

    window.addEventListener('beforeunload', beforeUnloadFn);
    
    $scope.hasNewOverrides = function() {
      return (
        document.querySelectorAll('[override=true]').length > 0 ||
        $rootScope.hasNewBuildingOverride
      );
    };

    $scope.setPreviousAmendmentList = function() {
      if ($scope.lease.previousAmendment.length === 0) {
        $scope.addPriorAmendment();
      }
    };

    $scope.addPriorAmendment = function() {
      if ($scope.lease.form.editor.defaultFields.previousAmendment && $scope.lease.form.editor.defaultFields.previousAmendment[0]) {
        $scope.lease.previousAmendment.push({
          date: '',
          ...$scope.lease.form.editor.defaultFields.previousAmendment[0],
        });
      } else {
        $scope.lease.previousAmendment.push({
          date: '',
        });
      }
    };

    $scope.deletePriorAmendment = function(index) {
      $scope.lease.previousAmendment.splice(index, 1);
    };

    $scope.addPriorAssignment = function() {
      if ($scope.lease.form.editor.defaultFields.priorAssignments && $scope.lease.form.editor.defaultFields.priorAssignments[0]) {
        $scope.lease.priorAssignments.push({
          date: undefined,
          _originalIndex: $scope.lease.priorAssignments.length,
          ...$scope.lease.form.editor.defaultFields.priorAssignments[0],
        });
      } else {
        $scope.lease.priorAssignments.push({
          date: undefined,
          _originalIndex: $scope.lease.priorAssignments.length,
        });
      }
    };

    $scope.addEventTime = function() {
      if ($scope.lease.form.editor.defaultFields.event?.times && $scope.lease.form.editor.defaultFields.event?.times[0]) {
        $scope.lease.event.times.push({
          date: '',
          ...$scope.lease.form.editor.defaultFields.event.times[0],
        });
      } else {
        $scope.lease.event.times.push({
          date: '',
        });
      }

    };

    $scope.deleteEventTime = function(index) {
      $scope.lease.event.times.splice(index, 1);
    };

    /*  Add additional Copies Start  */

    $scope.showHideCopyToAddress = evt => {
      if ($scope.lease.tenantInfo.hasCopyTo) {
        if (!$scope.lease.tenantInfo.copyTo) {
          $scope.addCopyToAddress();
        }
      }
    };

    $scope.addCopyToAddress = () => {
      if (!$scope.lease.tenantInfo.copyTo) {
        $scope.lease.tenantInfo.copyTo = [];
      }
      if ($scope.lease.form.editor.defaultFields.tenantInfo.copyTo && $scope.lease.form.editor.defaultFields.tenantInfo.copyTo[0]) {
        $scope.lease.tenantInfo.copyTo.push({
          ...$scope.lease.form.editor.defaultFields.tenantInfo.copyTo[0],
        });
      } else {
        $scope.lease.tenantInfo.copyTo.push({});
      }
    };

    $scope.removeCopyToAddress = index => {
      if (
        !$scope.lease.tenantInfo.copyTo ||
        !$scope.lease.tenantInfo.copyTo[index]
      ) {
        return;
      }
      $scope.lease.tenantInfo.copyTo.splice(index, 1);
    };

    /*  Add additional Copies End  */

    /*  Add additional Tenant Consent Contingency Start  */

    $scope.showHideConsentContingencies = evt => {
      if ($scope.lease.hasTenantConsentContingency) {
        if (!$scope.lease.consentContingencies) {
          $scope.addTenantConsentContingency();
        }
      }
    };

    $scope.addConsentContingency = () => {
      if (!$scope.lease.consentContingencies) {
        $scope.lease.consentContingencies = [];
      }
      if ($scope.lease.form.editor.defaultFields.consentContingencies && $scope.lease.form.editor.defaultFields.consentContingencies[0]) {
        $scope.lease.consentContingencies.push({
          ...$scope.lease.form.editor.defaultFields.consentContingencies[0],
        });
      } else {
        $scope.lease.consentContingencies.push({});
      }
    };

    $scope.removeConsentContingency = index => {
      if (
        !$scope.lease.consentContingencies ||
        !$scope.lease.consentContingencies[index]
      ) {
        return;
      }
      $scope.lease.consentContingencies.splice(index, 1);
    };

    /*  Add additional Tenant Consent Contingency End  */

    $scope.addSuite = function() {
      if ($scope.lease.form.editor.defaultFields.premiseSuites && $scope.lease.form.editor.defaultFields.premiseSuites[0]) {
        $scope.lease.premiseSuites.push({
          ...$scope.lease.form.editor.defaultFields.premiseSuites[0],
        });
      } else {
        $scope.lease.premiseSuites.push({});
      }
      $scope.setAvailableSpaces();
    };

    $scope.deleteSuite = function(index) {
      $scope.lease.premiseSuites.splice(index, 1);
      $scope.setAvailableSpaces();
    };

    $scope.addFreeRentPeriod = function() {
      if ($scope.lease.form.editor.defaultFields.freeRentPeriods && $scope.lease.form.editor.defaultFields.freeRentPeriods[0]) {
        $scope.lease.freeRentPeriods.push({
          ...$scope.lease.form.editor.defaultFields.freeRentPeriods[0],
        });
      } else {
        $scope.lease.freeRentPeriods.push({});
      }
    };

    $scope.deleteFreeRentPeriod = function(index) {
      $scope.lease.freeRentPeriods.splice(index, 1);
    };

    $scope.addMultipleTermsFreeRentPeriod = function(termIndex) {
      if (!$scope.lease.terms[termIndex].freeRentPeriods) {
        $scope.lease.terms[termIndex].freeRentPeriods = [];
      }
      $scope.lease.terms[termIndex].freeRentPeriods.push({});
    };

    $scope.deleteMultipleTermsFreeRentPeriod = function(termIndex, index) {
      $scope.lease.terms[termIndex].freeRentPeriods.splice(index, 1);
    };

    $scope.addTenant = function() {
      if ($scope.lease.form.editor.defaultFields.tenantInfo.individuals && $scope.lease.form.editor.defaultFields.tenantInfo.individuals[0]) {
        $scope.lease.tenantInfo.individuals.push({
          ...$scope.lease.form.editor.defaultFields.tenantInfo.individuals[0],
        });
      } else {
        $scope.lease.tenantInfo.individuals.push({});
      }
    };

    $scope.addIndividualAssignee = function() {
      if ($scope.lease.form.editor.defaultFields.assigneeInfo.individuals && $scope.lease.form.editor.defaultFields.assigneeInfo.individuals[0]) {
        $scope.lease.assigneeInfo.individuals.push({
          ...$scope.lease.form.editor.defaultFields.assigneeInfo.individuals[0],
        });
      } else {
        $scope.lease.assigneeInfo.individuals.push({});
      }
    };

    $scope.deleteTenant = function(index) {
      $scope.lease.tenantInfo.individuals.splice(index, 1);
    };

    $scope.deleteIndividualAssignee = function(index) {
      $scope.lease.assigneeInfo.individuals.splice(index, 1);
    };

    $scope.deletePriorAssignment = function(index) {
      $scope.lease.priorAssignments.splice(index, 1);
      $scope.lease.priorAssignments.forEach((assignment, index) => assignment._originalIndex = index);
    };

    $scope.setPriorAssignmentList = function() {
      if ($scope.lease.priorAssignments.length === 0) {
        $scope.addPriorAssignment();
      }
    };

    /* The following functions handles the automatic increase renewal */
    $scope.clearHasApplyIncreaseToRenewals = function(lease) {
      if (lease.rentInputType === 'manual' || lease.hasCpiRent) {
        lease.hasApplyIncreaseToRenewals = false;
        $scope.update();
      }
      if (lease.rentInputType === 'manual') {
        $scope.calculateRentPSF(lease);
        $scope.consolidateRentPeriods();
      } else {
        $scope.updateBaseRent();
        $scope.updateAnnualIncrease();
      }
    };

    /* The following copies the tenant notice address to the billing address */
    $scope.setSameBillingAddress = function() {
      if ($scope.lease.hasSameBillingAddress) {
        $scope.lease.tenantInfo.billingAddress1 =
          $scope.lease.tenantInfo.address1;
        $scope.lease.tenantInfo.billingAddressCity =
          $scope.lease.tenantInfo.addressCity;
        $scope.lease.tenantInfo.billingAddressState =
          $scope.lease.tenantInfo.addressState;
        $scope.lease.tenantInfo.billingAddressZip =
          $scope.lease.tenantInfo.addressZip;
        $scope.lease.tenantInfo.billingPhoneNumber =
          $scope.lease.tenantInfo.phoneNumber;
        $scope.lease.tenantInfo.billingEmail =
          $scope.lease.tenantInfo.email;
        $scope.lease.tenantInfo.billingAttention =
          $scope.lease.tenantInfo.attention;
      }
    };

    /* The following copies the address of the first person in a married couple into the second person */
    $scope.setMarriedCoupleSameAddress = function(guarantor) {
      if (guarantor.hasSameAddress) {
        guarantor.address2 = guarantor.address1;
        guarantor.city2 = guarantor.city1;
        guarantor.state2 = guarantor.state1;
        guarantor.zip2 = guarantor.zip1;

        if ($rootScope.hasEditorConfig("guarantors", "copyPhoneNumberForSpouse")) {
          guarantor.phone2 = guarantor.phone1;
        }
        if ($rootScope.hasEditorConfig("guarantors", "copyEmailForSpouse")) {
          guarantor.email2 = guarantor.email1;
        }
      }
    };

    $scope.deleteAbatementDate = function(index) {
      $scope.lease.abatementDates.splice(index, 1);
    };

    $scope.addAbatementDate = function() {
      if ($scope.lease.form.editor.defaultFields.abatementDates && $scope.lease.form.editor.defaultFields.abatementDates[0]) {
        $scope.lease.abatementDates.push({
          date: '',
          ...$scope.lease.form.editor.defaultFields.abatementDates[0],
        });
      } else {
        $scope.lease.abatementDates.push({
          date: '',
        });
      }
    };

    $scope.setAbatementDatesList = function() {
      if ($scope.lease.abatementDates.length === 0) {
        $scope.addAbatementDate();
      }
    };

    $scope.addNamedTenant = function() {
      if ($scope.lease.form.editor.defaultFields.ongoingCoTenancy.namedTenantsList && $scope.lease.form.editor.defaultFields.ongoingCoTenancy.namedTenantsList[0]) {
        $scope.lease.ongoingCoTenancy.namedTenantsList.push({
          ...$scope.lease.form.editor.defaultFields.ongoingCoTenancy.namedTenantsList[0],
        });
      } else {
        $scope.lease.ongoingCoTenancy.namedTenantsList.push({});
      }
    };

    $scope.deleteNamedTenant = function(index) {
      $scope.lease.ongoingCoTenancy.namedTenantsList.splice(index, 1);
    };

    $scope.setNamedTenantsList = function() {
      if ($scope.lease.ongoingCoTenancy.namedTenantsList.length === 0) {
        $scope.addNamedTenant();
      }
    };

    /* The following maintains the validity of 'lease.hasAllGuarantorsRemoved',
     * Meaning, if all guarantors are released, it sets 'true', otherwise, it sets 'false' */
    $scope.updateReleaseGuarantors = function(turnOff, isOldDesign) {
      var individuals = $scope.lease.existingGuarantorInfo.individuals;
      var marriedCouples = $scope.lease.existingGuarantorInfo.marriedCouples;
      var entities = $scope.lease.existingGuarantorInfo.entities;
      var allGuarantors = _.union(individuals, marriedCouples, entities) || [];
      var hasAllGuarantorsRemoved = true;

      var hasReleaseGuarantors = !!_.find(allGuarantors, function(o) {
        return o.hasBeenReleased == true;
      });
      $scope.lease.hasReleaseGuarantors = isOldDesign
        ? $scope.lease.hasReleaseGuarantors
        : hasReleaseGuarantors;

      for (
        var i = 0;
        i < allGuarantors.length && hasAllGuarantorsRemoved;
        i++
      ) {
        // We're turning off all released guarantors if the toggle is turned off
        if (turnOff === false) {
          allGuarantors[i].hasBeenReleased = false;
        }
        if (!allGuarantors[i].hasBeenReleased) {
          hasAllGuarantorsRemoved = false;
        }
      }

      if (allGuarantors.length > 0) {
        $scope.lease.hasAllGuarantorsRemoved = hasAllGuarantorsRemoved;
      }
    };

    $scope.updateReleaseGuarantor = function() {
      $scope.updateReleaseGuarantors();

      setTimeout(function() {
        $scope.structureChanged();
        $scope.highlightItem('lease.hasReleaseGuarantors');
      }, 1);
    };

    $scope.createBreakpointTable = function(config) {
      var result = [];
      var consolidation = [];
      var start, end, breakpoint, period;

      if ($scope.lease.pctRentHasNaturalBreakpoint) {
        // Natural breakpoint
        if ($scope.lease.rentPeriods && $scope.lease.rentPeriods.length > 0) {
          // Add the first term
          start = $scope.formatDateAs(
            moment(
              $scope.lease.existingLeaseExpirationDate,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).add(1, 'day'),
            config.format.date,
          );
          end = $scope.formatDateAs(
            moment(
              $scope.lease.existingLeaseExpirationDate,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).add(1, 'year'),
            config.format.date,
          );
          breakpoint =
            ($scope.lease.rentPeriods[0].rentPerSqFoot *
              $scope.lease.premiseAreaSize) /
            (($scope.lease.existingPercentageRentRate
              ? $scope.lease.existingPercentageRentRate
              : 100) /
              100);
          result.push({
            start: start,
            end: end,
            breakpoint: breakpoint,
          });

          // We skip the first one, the first one is for the lease term itself,
          // We already took care of it above
          for (var i = 1; i < $scope.lease.rentPeriods.length; i++) {
            // Lets consolidate the data
            start = $scope.formatDateAs(
              moment(result[result.length - 1].start, config.format.date).add(
                1,
                'year',
              ),
              config.format.date,
            );
            end = $scope.formatDateAs(
              moment(result[result.length - 1].end, config.format.date).add(
                1,
                'year',
              ),
              config.format.date,
            );
            breakpoint =
              ($scope.lease.rentPeriods[i].rentPerSqFoot *
                $scope.lease.premiseAreaSize) /
              (($scope.lease.existingPercentageRentRate
                ? $scope.lease.existingPercentageRentRate
                : 100) /
                100);
            result.push({
              start: start,
              end: end,
              breakpoint: breakpoint,
            });
          }
        }

        // Now add the renewal years
        if (result.length > 0) {
          if ($scope.lease.renewalInfo && $scope.lease.renewalInfo.length > 0) {
            for (var j = 0; j < $scope.lease.renewalInfo.length; j++) {
              for (
                var k = 0;
                k < $scope.lease.renewalInfo[j].renewalRentPeriods.length;
                k++
              ) {
                period = $scope.lease.renewalInfo[j].renewalRentPeriods[k];
                start = $scope.formatDateAs(
                  moment(
                    result[result.length - 1].start,
                    config.format.date,
                  ).add(1, 'year'),
                  config.format.date,
                );
                end = $scope.formatDateAs(
                  moment(result[result.length - 1].end, config.format.date).add(
                    1,
                    'year',
                  ),
                  config.format.date,
                );
                breakpoint =
                  (period.rentPerSqFoot * $scope.lease.premiseAreaSize) /
                  (($scope.lease.existingPercentageRentRate
                    ? $scope.lease.existingPercentageRentRate
                    : 100) /
                    100);
                result.push({
                  start: start,
                  end: end,
                  breakpoint: breakpoint,
                });
              }
            }
          }

          // Add the first item as an anchor
          consolidation.push(result[0]);
        }
      } else {
        // Unnatural breakpoint
        if ($scope.lease.minimumSales && $scope.lease.minimumSales.length > 0) {
          // Add the first term
          start = $scope.formatDateAs(
            moment(
              $scope.lease.existingLeaseExpirationDate,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).add(1, 'day'),
            config.format.date,
          );
          end = $scope.formatDateAs(
            moment(
              $scope.lease.existingLeaseExpirationDate,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).add(1, 'year'),
            config.format.date,
          );
          result.push({
            start: start,
            end: end,
            breakpoint: $scope.lease.minimumSales[0].value,
          });

          if ($scope.lease.hasRenewalOption) {
            // We skip the first one, the first one is for the lease term itself,
            // We already took care of it above
            for (var i = 1; i < $scope.lease.minimumSales.length; i++) {
              // Lets consolidate the data
              start = $scope.formatDateAs(
                moment(result[result.length - 1].start, config.format.date).add(
                  1,
                  'year',
                ),
                config.format.date,
              );
              end = $scope.formatDateAs(
                moment(result[result.length - 1].end, config.format.date).add(
                  1,
                  'year',
                ),
                config.format.date,
              );
              result.push({
                start: start,
                end: end,
                breakpoint: $scope.lease.minimumSales[i].value,
              });
            }
          }

          // Add the first item as an anchor
          consolidation.push(result[0]);
        }
      }

      if (!config.consolidate) {
        $scope.breakpointTable = result;
      } else {
        for (var j = 1; j < result.length; j++) {
          if (
            result[j].breakpoint ===
            consolidation[consolidation.length - 1].breakpoint
          ) {
            consolidation[consolidation.length - 1].end = result[j].end;
          } else {
            consolidation.push(result[j]);
          }
        }
        $scope.breakpointTable = consolidation;
      }
    };

    var setDeepValue = function(pointer, array, value) {
      if (array.length > 1) {
        var first = array.shift();
        setDeepValue(pointer[first], array, value);
      } else {
        pointer[array[0]] = value;
      }
      return;
    };

    $scope.resetContent = function(
      elementsGroupId,
      resetTo,
      currentElementValue,
      value,
    ) {
      if (currentElementValue == value) {
        document
          .querySelectorAll('#' + elementsGroupId + ' [ng-model]')
          .forEach(function(element) {
            var elem = element
              .getAttribute('ng-model')
              .replace('lease.', '')
              .split('.');
            setDeepValue($scope.lease, elem, resetTo);
          });
      }
    };

    /* ============================= Flexible Rent Bumps ============================= */

    $scope.updateNumberOfRenewalRentBumps = function() {
      if (!$scope.lease.renewalRentBumps) {
        $scope.lease.renewalRentBumps = [];
      }

      if ($scope.lease.renewalRentBumps.length > $scope.lease.renewalCount) {
        var count =
          $scope.lease.renewalRentBumps.length - $scope.lease.renewalCount;
        $scope.lease.renewalRentBumps.splice(-count, count);
      } else if (
        $scope.lease.renewalRentBumps.length < $scope.lease.renewalCount
      ) {
        var count =
          $scope.lease.renewalCount - $scope.lease.renewalRentBumps.length;
        for (let i = 0; i < count; i++) {
          $scope.lease.renewalRentBumps.push({
            periods: [
              {
                begin: 1,
              },
            ],
          });
        }
      }
    };

    $scope.getRentBumpsInMonths = function(list) {
      let rentBumpsInMonths = 0;

      if (list) {
        list.forEach(rentPeriod => {
          if (rentPeriod.duration) {
            rentBumpsInMonths += rentPeriod.duration;
          }
        });
      }

      return rentBumpsInMonths;
    };

    $scope.deleteRentBumpPeriod = function(list, index) {
      if (list) {
        list.splice(index, 1);

        // Invalidate the `begin` field throughout the table
        list.forEach((rentPeriod, index) => {
          if (index === 0) {
            rentPeriod.begin = 1;
          } else {
            rentPeriod.begin = list[index - 1].begin + list[index - 1].duration;
          }
        });
      }
    };

    $scope.addRentBumpPeriod = function(list) {
      if (list) {
        let begin;

        // Calculate the `begin`ing of the new row based on the previous row
        if (list.length > 0) {
          begin = list[list.length - 1].begin + list[list.length - 1].duration;
        }

        list.push({
          begin,
        });
      }
    };
  },
]);
